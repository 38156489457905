<template>
  <div>
    <ButtonApp :isSecondary="true" @click="openModal">
      <div>
        <p class="px-3 py-2 averta-bold">Pilih Material</p>
      </div>
    </ButtonApp>

    <ModalTemplate :showModal="showModal" width="500px" @closeModal="closeModal">
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="flex-center-vertical">
            <img :src="require('@/assets/icons/tabler-tool-used.svg')" alt="" />
            <p class="averta-bold label-18 ml-3">Pilih Material</p>
          </div>
          <div class="is-clickable" @click="closeModal">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="wrap-body-modal">
          <div class="body-modal">
            <p>Pilih Material</p>
            <b-input
              type="text"
              icon="magnify"
              class="mt-1 label-14 cs-search-modal-select-material input"
              placeholder="Cari Material"
              @input="searchMaterial"
            ></b-input>

            <div v-if="isLoading" class="mt-4">
              <SpinnerIcon />
            </div>

            <div v-else-if="!isLoading && data.length > 0" class="data-item-wrapper">
              <div
                v-for="(item, index) in data"
                :key="`item-${index}`"
                class="flex-center-vertical py-3 pr-2 is-clickable"
                @click="() => handleSelectMaterial(item, index)"
              >
                <input
                  type="checkbox"
                  :checked="checkMaterial(item.id)"
                  class="flex-shrink-0 is-clickable"
                >
                <div class="img-item-material mx-2 flex-shrink-0">
                  <img :src="item.picture || require('@/assets/img/empty-img-2.png')" alt="img-material">
                </div>
                <p>{{ item.name }}</p>
              </div>
            </div>

            <div v-else class="has-text-centered">
              <ProyekEmptyIcon />
              <p class="averta-bold mt-0">
                Material Tidak Ditemukan
              </p>
            </div>
          </div>
          <div class="footer-modal">
            <ButtonApp :isDisabled="disabled" @click="handleSubmit">
              <div class="flex-center py-2 px-3">
                <p class="mr-2 averta-bold">Pilih</p>
                <ArrowRightIconLong />
              </div>
            </ButtonApp>
          </div>
        </div>
      </template>
    </ModalTemplate>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage, debounce } from '@/helpers'
import { ArrowRightIconLong, ProyekEmptyIcon, SpinnerIcon } from '@/components/icons'
import ButtonApp from '../button/ButtonApp.vue'
import ModalTemplate from './ModalTemplate.vue'

export default {
  name: 'ModalSelectMaterial',
  components: {
    ArrowRightIconLong,
    ProyekEmptyIcon,
    SpinnerIcon,
    ButtonApp,
    ModalTemplate
  },
  data () {
    return {
      showModal: false,
      isLoading: false,
      selectedMaterial: null,
      page: 1,
      data: [],
      initData: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      storeMaterials: 'unitPrice/getSelectedMaterials'
    }),
    disabled () {
      const isEmpty = this.initData.filter(item => item.checked)
      return isEmpty.length < 1
    }
  },
  methods: {
    openModal () {
      this.showModal = !this.showModal

      if (this.showModal) {
        if (this.initData.length < 1) {
          this.getMaterial()
        } else {
          this.data = JSON.parse(JSON.stringify(this.initData))
        }
      }
    },
    closeModal () {
      this.showModal = false
    },
    searchMaterial: debounce(function (value) {
      const search = this.initData.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
      this.data = search
    }, 300),
    checkMaterial (id) {
      const find = this.initData.find(item => item.id === id)
      return find.checked || false
    },
    getMaterial () {
      if (this.initData.length < 1) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        // disable_paginate: true
        limit: 1000,
        page: this.page
      }
      this.$store
        .dispatch('product/getListProducts', payload)
        .then((response) => {
          const res = response.data.data
          const init = JSON.parse(JSON.stringify(res))
          this.isLoading = false

          if (this.storeMaterials.length > 0) {
            const temp = init.map((item) => {
              const index = this.storeMaterials.findIndex(material => Number(material.id) === item.id)
              return {
                ...item,
                checked: index > -1
              }
            })
            this.initData = JSON.parse(JSON.stringify(temp))
            this.data = temp
          } else {
            this.initData = init
            this.data = res
          }
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    handleSelectMaterial (value, index) {
      const temp = {
        ...value,
        checked: !value.checked
      }
      const indexInitData = this.initData.findIndex(item => item.id === value.id)
      this.initData.splice(indexInitData, 1, temp)
      this.data.splice(index, 1, temp)
    },
    handleSubmit () {
      const res = this.initData.filter(item => item.checked)
      this.closeModal()
      this.$store.commit('unitPrice/setSelectedMaterials', res)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.wrap-body-modal {
  background: white;
  height: 450px;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.body-modal {
  height: 85%;
  padding: 20px;
  padding-top: 10px;
}

.footer-modal {
  height: 15%;
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px -3px 30px 0px #00000012;
}

.data-item-wrapper {
  height: 82%;
  overflow-y: auto;
  overflow-x: hidden;

  > div {
    &:hover {
      background: #FFE5E5;
    }

    .input {
      width: 20px;
      height: 20px;
    }
  }

  .img-item-material {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>

<style lang="scss">
.cs-search-modal-select-material {
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  font-size: 14px;
  overflow: hidden;

  &:hover {
    border: 1px solid #E1E1E1;
  }

  input {
    border: none;
    font-size: 14px;
    padding-left: 23px;
  }
}
</style>
