<template>
  <svg width="80" height="auto" viewBox="0 0 82 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3249 44.4097C15.5111 30.4536 23.7328 16.0465 37.6887 12.2328C51.6451 8.41891 66.0508 16.6426 69.8646 30.5988C73.6784 44.5549 65.4566 58.9604 51.5001 62.7742C37.5443 66.588 23.1387 58.3659 19.3249 44.4097Z"
      fill="#FFCB27"
    />
    <path
      d="M51.9211 12.3293C55.5116 15.5713 58.2468 19.8435 59.6165 24.8555C63.4303 38.8117 55.2082 53.2172 41.2523 57.031C36.4048 58.3556 31.5044 58.2269 27.0114 56.912C33.4186 62.6961 42.5473 65.1954 51.4914 62.7513C65.4479 58.9374 73.6698 44.5322 69.856 30.5761C67.3667 21.4668 60.3649 14.8019 51.9211 12.3293Z"
      fill="#F9C021"
    />
    <path
      d="M23.4884 46.134C19.9475 33.1765 27.5808 19.8012 40.538 16.2604C48.1118 14.1907 55.8274 15.9408 61.6392 20.3386C55.666 14.4122 46.7784 11.7516 38.079 14.1288C25.1215 17.6697 17.4883 31.0453 21.0292 44.0028C22.5004 49.3866 25.6699 53.8498 29.8013 56.9755C26.8776 54.0749 24.652 50.3918 23.4884 46.134Z"
      fill="#FFD95F"
    />
    <path
      d="M59.2281 29.2731C58.9696 28.8798 58.5193 28.6714 58.0524 28.7289L48.2156 29.9394L41.6419 22.5223C41.3301 22.1712 40.8536 22.0278 40.3994 22.152C39.9456 22.276 39.6082 22.6417 39.5183 23.1026L37.6299 32.8322L28.5454 36.792C28.114 36.9802 27.8316 37.3888 27.8093 37.8589C27.7871 38.3293 28.0291 38.7633 28.4408 38.9911L37.11 43.7937L38.0691 53.6578C38.1145 54.1267 38.4167 54.5211 38.857 54.687C39.2966 54.8537 39.7841 54.7573 40.1279 54.4367L47.3743 47.6756L57.0521 49.8119C57.2519 49.8556 57.4534 49.8494 57.6415 49.7979C57.8861 49.7311 58.108 49.5888 58.2742 49.3808C58.5689 49.0138 58.6282 48.5209 58.4291 48.0937L54.2382 39.1131L59.2599 30.5692C59.4988 30.1636 59.4865 29.6668 59.2281 29.2731Z"
      fill="white"
    />
    <mask
      id="mask0_1410_80054"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="19"
      y="44"
      width="29"
      height="20"
    >
      <path
        d="M47.8788 63.4957C35.1333 65.122 22.8105 57.1578 19.3263 44.4079C19.2928 44.2851 19.2603 44.1628 19.2285 44.0403C19.8403 44.0509 20.4534 44.0793 21.0638 44.1255C22.5493 49.4552 25.7021 53.8744 29.8024 56.9768C26.8786 54.0762 24.6531 50.3931 23.4895 46.1352C23.3263 45.538 23.1868 44.9393 23.0703 44.3414C28.2926 45.0711 33.3625 47.1043 37.7579 50.438L38.0705 53.6565C38.1159 54.1254 38.4181 54.5198 38.8584 54.6857C39.1069 54.78 39.3704 54.79 39.615 54.7232C39.8031 54.6718 39.9798 54.5747 40.1293 54.4355L41.1863 53.4492C41.2088 53.472 41.2322 53.4955 41.2544 53.5184C42.1119 54.3925 42.9078 55.3032 43.6435 56.2461C42.8671 56.54 42.0711 56.7989 41.2542 57.0221C36.4067 58.3468 31.5063 58.2181 27.0133 56.9031C32.5551 61.9059 40.1332 64.4515 47.8665 63.4662C47.8709 63.4763 47.8747 63.4854 47.8788 63.4957Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1410_80054)">
      <path
        d="M47.8788 63.4957C35.1333 65.122 22.8105 57.1578 19.3263 44.4079C19.2928 44.2851 19.2603 44.1628 19.2285 44.0403C19.8403 44.0509 20.4534 44.0793 21.0638 44.1255C22.5493 49.4552 25.7021 53.8744 29.8024 56.9768C26.8786 54.0762 24.6531 50.3931 23.4895 46.1352C23.3263 45.538 23.1868 44.9393 23.0703 44.3414C28.2926 45.0711 33.3625 47.1043 37.7579 50.438L38.0705 53.6565C38.1159 54.1254 38.4181 54.5198 38.8584 54.6857C39.1069 54.78 39.3704 54.79 39.615 54.7232C39.8031 54.6718 39.9798 54.5747 40.1293 54.4355L41.1863 53.4492C41.2088 53.472 41.2322 53.4955 41.2544 53.5184C42.1119 54.3925 42.9078 55.3032 43.6435 56.2461C42.8671 56.54 42.0711 56.7989 41.2542 57.0221C36.4067 58.3468 31.5063 58.2181 27.0133 56.9031C32.5551 61.9059 40.1332 64.4515 47.8665 63.4662C47.8709 63.4763 47.8747 63.4854 47.8788 63.4957Z"
        fill="url(#paint0_radial_1410_80054)"
      />
    </g>
    <mask
      id="mask1_1410_80054"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="27"
      y="56"
      width="21"
      height="8"
    >
      <path
        d="M47.8642 63.4582C40.1309 64.4435 32.5528 61.8979 27.011 56.8951C31.504 58.2101 36.4045 58.3388 41.2519 57.0141C42.0688 56.7909 42.8648 56.532 43.6412 56.2381C45.3952 58.4874 46.8033 60.9188 47.8642 63.4582Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_1410_80054)">
      <path
        d="M47.8642 63.4582C40.1309 64.4435 32.5528 61.8979 27.011 56.8951C31.504 58.2101 36.4045 58.3388 41.2519 57.0141C42.0688 56.7909 42.8648 56.532 43.6412 56.2381C45.3952 58.4874 46.8033 60.9188 47.8642 63.4582Z"
        fill="url(#paint1_radial_1410_80054)"
      />
    </g>
    <mask
      id="mask2_1410_80054"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="21"
      y="44"
      width="9"
      height="13"
    >
      <path
        d="M29.801 56.9678C25.7007 53.8654 22.5479 49.4462 21.0624 44.1165C21.733 44.167 22.4023 44.2391 23.0689 44.3324C23.1854 44.9303 23.3249 45.5291 23.4881 46.1262C24.6517 50.3841 26.8773 54.0672 29.801 56.9678Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_1410_80054)">
      <path
        d="M29.801 56.9678C25.7007 53.8654 22.5479 49.4462 21.0624 44.1165C21.733 44.167 22.4023 44.2391 23.0689 44.3324C23.1854 44.9303 23.3249 45.5291 23.4881 46.1262C24.6517 50.3841 26.8773 54.0672 29.801 56.9678Z"
        fill="url(#paint2_radial_1410_80054)"
      />
    </g>
    <mask
      id="mask3_1410_80054"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="37"
      y="50"
      width="5"
      height="5"
    >
      <path
        d="M39.615 54.7391C39.3705 54.8059 39.1069 54.7959 38.8585 54.7017C38.4182 54.5358 38.116 54.1413 38.0706 53.6725L37.7579 50.4539C38.9544 51.3614 40.1006 52.3649 41.1863 53.4651L40.1293 54.4514C39.9798 54.5906 39.8032 54.6877 39.615 54.7391Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_1410_80054)">
      <path
        d="M39.615 54.7391C39.3705 54.8059 39.1069 54.7959 38.8585 54.7017C38.4182 54.5358 38.116 54.1413 38.0706 53.6725L37.7579 50.4539C38.9544 51.3614 40.1006 52.3649 41.1863 53.4651L40.1293 54.4514C39.9798 54.5906 39.8032 54.6877 39.615 54.7391Z"
        fill="url(#paint3_radial_1410_80054)"
      />
    </g>
    <path
      d="M20.8438 22.8285C14.8679 22.2136 14.4803 22.4873 13.05 28.322C13.6649 22.3461 13.3913 21.9579 7.55641 20.5282C13.5323 21.1431 13.9205 20.8695 15.3503 15.0346C14.7359 21.0106 15.009 21.3987 20.8438 22.8285Z"
      fill="#FEE131"
    />
    <path
      d="M13.0709 42.8881C7.97091 47.9712 7.93078 48.5389 12.265 54.2889C7.18187 49.1889 6.61466 49.1484 0.864653 53.4826C5.96463 48.3995 6.00432 47.8322 1.67048 42.0826C6.75323 47.1822 7.32088 47.2223 13.0709 42.8881Z"
      fill="#10BD41"
    />
    <path
      d="M58.8361 69.2453C63.5906 65.5723 63.6829 65.1069 60.6979 59.8935C64.371 64.6472 64.8363 64.7403 70.0497 61.7553C65.2956 65.4279 65.2034 65.8934 68.1879 71.1071C64.5152 66.353 64.05 66.26 58.8361 69.2453Z"
      fill="#FEE131"
    />
    <path
      d="M9.33574 33.4481C6.7488 33.1821 6.58081 33.3002 5.96174 35.826C6.22779 33.239 6.10962 33.0714 3.5838 32.4523C6.17073 32.7184 6.33838 32.6002 6.95751 30.074C6.69141 32.6613 6.80991 32.829 9.33574 33.4481Z"
      fill="url(#paint4_linear_1410_80054)"
    />
    <path
      d="M22.0348 62.6872C17.6492 62.2362 17.3644 62.4364 16.3153 66.7191C16.7665 62.3329 16.5656 62.0486 12.2836 60.9991C16.6692 61.4501 16.954 61.2499 18.0035 56.9679C17.5519 61.3534 17.7528 61.6377 22.0348 62.6872Z"
      fill="url(#paint5_linear_1410_80054)"
    />
    <path
      d="M71.9853 31.7412C74.951 31.0651 75.0924 30.8709 74.8303 27.8404C75.5063 30.8061 75.7006 30.9478 78.731 30.6854C75.7654 31.3615 75.6237 31.5557 75.8861 34.5861C75.2097 31.6205 75.0157 31.4788 71.9853 31.7412Z"
      fill="#FEE131"
    />
    <path
      d="M72.4547 20.8644C74.0744 17.5981 74.0015 17.3194 70.9887 15.2662C74.255 16.886 74.5336 16.8132 76.5867 13.8005C74.967 17.0668 75.0401 17.3452 78.0526 19.3983C74.7866 17.7786 74.5078 17.8516 72.4547 20.8644Z"
      fill="#10BD41"
    />
    <path
      d="M64.9389 12.8205C67.1153 12.3242 67.2194 12.182 67.0266 9.95775C67.5229 12.1344 67.6654 12.2381 69.8894 12.0457C67.713 12.542 67.609 12.6843 67.8014 14.9082C67.3054 12.7318 67.1629 12.6281 64.9389 12.8205Z"
      fill="url(#paint6_linear_1410_80054)"
    />
    <path
      d="M54.6795 7.25925C58.1682 4.56376 58.2361 4.22274 56.0457 0.396632C58.7412 3.88538 59.0822 3.95327 62.9083 1.76286C59.4196 4.45835 59.3517 4.79937 61.5424 8.62596C58.8466 5.13673 58.5056 5.06884 54.6795 7.25925Z"
      fill="#E10009"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1410_80054"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6668 75.6769) rotate(-44.4508) scale(31.6417 31.6417)"
      >
        <stop stop-color="#8E7116" />
        <stop offset="1" stop-color="#FFCB27" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1410_80054"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6646 75.6689) rotate(-44.4507) scale(31.6416 31.6416)"
      >
        <stop stop-color="#8B6B12" />
        <stop offset="1" stop-color="#F9C021" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1410_80054"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6654 75.6679) rotate(-44.4508) scale(31.6417 31.6417)"
      >
        <stop stop-color="#8E7935" />
        <stop offset="1" stop-color="#FFD95F" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_1410_80054"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.6668 75.6928) rotate(-44.4506) scale(31.6417 31.6416)"
      >
        <stop stop-color="#8E8E8E" />
        <stop offset="1" stop-color="white" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_1410_80054"
        x1="8.80115"
        y1="36.0594"
        x2="1.51371"
        y2="34.0449"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4BBEFF" />
        <stop offset="1" stop-color="#594BFF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1410_80054"
        x1="21.1284"
        y1="67.1147"
        x2="8.77407"
        y2="63.6995"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4BBEFF" />
        <stop offset="1" stop-color="#594BFF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1410_80054"
        x1="64.6542"
        y1="10.5501"
        x2="71.1336"
        y2="10.1805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4BBEFF" />
        <stop offset="1" stop-color="#594BFF" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'RatingShineIcon'
}
</script>
