export const SUMMARECON_MTOWN = process.env.VUE_APP_SUMMARECON_MTOWN
export const ALFAMART_CODE = 'ALFMRT'
export const BRANCH = 'Cabang'
export const STORE = 'Toko'
export const PIC = 'PIC'
export const PIC_BRANCH = 'PIC Cabang'
export const PIC_STORE = 'PIC Toko'
export const BRANCH_SMRC = 'Unit'
export const STORE_SMRC = 'Building'
export const PIC_BRANCH_SMRC = 'Chief Engineering'
export const PIC_STORE_SMRC = 'Koordinator Engineering'
export const ADMIN_SMRC = 'Admin'
// export const HEAD_ENGINEERING_SMRC = 'Head of Engineering'
export const HEAD_ENGINEERING_SMRC = 'Pusat'
export const AREA_KOORDINATOR_SMRC = 'Area Koordinator'
export const PROPERTY_MANAGER_SMRC = 'Property Manager'

export const SPRADM = process.env.VUE_APP_SPRADM
export const PICCAB = process.env.VUE_APP_PICCAB
export const PICTK = process.env.VUE_APP_PICTK // PIC TOKO & Koordinator Engineering
export const ADM = process.env.VUE_APP_ADM
export const AREACOORD = process.env.VUE_APP_AREACOORD
export const HEADOFENG = process.env.VUE_APP_HEADOFENG
export const PROPERTY_MANAGER = process.env.VUE_APP_PROPERTY_MANAGER || 7

// TITLE
export const TITLE_WO_FULL = 'No. Work Order (WO)'
export const TITLE_WO_LESS = 'No. WO'
