<template>
  <svg :width="size" height="auto" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.8333 19.2161H15.1667V8.21615H18.8333M15.1667 22.8828H18.8333V26.5495H15.1667M23.8383 0.882812H10.1617L0.5 10.5445V24.2211L10.1617 33.8828H23.8383L33.5 24.2211V10.5445L23.8383 0.882812Z"
      fill="#E10009"
    />
  </svg>
</template>

<script>
export default {
  name: 'HexagonAlertIcon',
  props: {
    size: {
      type: String,
      default: '50'
    },
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>
