<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-modal width="96vw" v-model="isHandleOpenModalsPreviewSertifikat" :can-cancel="false">
      <div class="modal-content">
        <div class="modal-header">
          <div
            class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
          >
            <div
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3"
            >
              <div class="avatar-circle-users">
                <img :src="selectWorkers.worker_image || selectWorkers.image" />
              </div>
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start gap-1"
              >
                <p class="averta-bold color-white label-18">
                  {{ selectWorkers.worker_name || selectWorkers.name }}
                </p>
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
                >
                  <p class="averta-reguler color-white label-16">
                    {{ selectWorkers.worker_id || selectWorkers.workersId }}
                  </p>
                  <div class="badge-new-comment ml-3 gap-2">
                    <SertifikatBadgeIcon size="18" />
                    <p
                      class="m-0 label-14 averta-bold-italic color-green"
                      style="white-space: nowrap"
                    >
                      Bersertifikat
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
            >
              <div class="card-count-sertifikat">
                <MedaliSertifikatIcon size="45" />
                  <!-- class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start gap-1" -->
                <div
                  class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                >
                  <p class="averta-reguler color-white label-14">Sertifikat Keahlian</p>
                  <p class="averta-bold color-white label-18">
                    {{
                      (selectWorkers.certificates && selectWorkers.certificates.length) ||
                      selectWorkersCertified.length
                    }}
                    Sertifikat
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <!-- style="background: #ffffff; min-height: 550px; position: relative; overflow: hidden" -->
          <div
            style="background: #ffffff; position: relative; overflow: hidden"
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-75 c-h-100"
          >
            <div class="image-background">
              <img src="@/assets/img/Background_Sertifikat.png" style="border-radius: 20px" />
            </div>

            <!-- Main Content Card Selected -->
            <div
              style="z-index: 9999"
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-h-100 p-3 gap-1 c-w-100"
            >
              <div
                v-if="
                  (selectedCertificate && selectedCertificate.certificate_image) ||
                  (selectWorkers.certificates && selectWorkers.certificates[0].certificate_image)
                "
                class="card-image-sertifikat"
              >
                <img
                  :src="
                    selectedCertificate
                      ? selectedCertificate.certificate_image
                      : selectWorkers.certificates &&
                        selectWorkers.certificates[0].certificate_image
                  "
                  style="border-radius: 20px"
                />
                <div
                  class="watermark"
                  v-if="selectedCertificate && selectedCertificate.certificate_image"
                >
                  <p class="averta-black">Property Kanggo</p>
                </div>
                <div v-else class="image-condition">
                  <p class="averta-reguler-italic label-30">Image Belum Tergenerate</p>
                </div>
                <div
                  class="zoom-image-icon is-clickable"
                  @click="
                    handleOpenModalsImageSertifikat(
                      selectedCertificate && selectedCertificate.certificate_image
                    )
                  "
                >
                  <ZooomImageIcon />
                </div>
              </div>
              <div v-else class="card-image-sertifikat-condition">
                <p class="averta-reguler-italic label-30">Image Belum Tergenerate</p>
              </div>
              <div
                class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-1"
              >
                <p class="averta-reguler label-18">Sertifikat Keahlian:</p>
                <p class="averta-bold label-18">
                  {{
                    selectedCertificate
                      ? selectedCertificate.certificate_no
                      : selectWorkers.certificates && selectWorkers.certificates[0].certificate_no
                  }}
                </p>
              </div>
            </div>
            <!-- Main Content Card Selected -->
          </div>
          <!-- style="background: #e1e1e1; min-height: 550px; overflow: auto !important" -->
          <div
            style="background: #e1e1e1; overflow: auto !important"
            class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-center c-w-25 c-h-100 px-3 py-5 gap-10"
          >
            <!-- Main Option -->
            <div
              v-for="(item, index) in selectWorkers.certificates || selectWorkersCertified"
              :key="index"
              @click="selectCertificate(item)"
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100 gap-4 is-clickable"
            >
              <div
                v-if="item.certificate_image"
                class="card-image-sertifikat-childern"
                :style="{
                  border:
                    selectedCertificate &&
                    selectedCertificate.certificate_no === item.certificate_no
                      ? '4.43px solid #B40007'
                      : '',
                  background:
                    selectedCertificate &&
                    selectedCertificate.certificate_no === item.certificate_no
                      ? '#9cdcff33'
                      : ''
                }"
              >
                <img :src="item.certificate_image" style="background: #ffffff" />
              </div>
              <div
                v-else
                class="card-image-sertifikat-childern-condition"
                :style="{
                  border:
                    selectedCertificate &&
                    selectedCertificate.certificate_no === item.certificate_no
                      ? '4.43px solid #B40007'
                      : '',
                  background:
                    selectedCertificate &&
                    selectedCertificate.certificate_no === item.certificate_no
                      ? '#9cdcff33'
                      : ''
                }"
              >
                <p class="averta-reguler-italic label-18">Image Belum Tergenerate</p>
              </div>
              <p class="averta-bold label-14">{{ item && item.certificate_no }}</p>
            </div>
            <!-- Main Option -->
          </div>
        </div>
        <div class="modal-footer" @click="closeModal">
          <ButtonApp width="200px" height="40px" :isHoveredEnabled="true" :isSecondary="true">
            <p class="averta-bold label-18">Tutup</p>
          </ButtonApp>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="isHandleOpenModalsImageSertifikat" :can-cancel="false">
      <div
        class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100 c-h-100 gap-6"
      >
        <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center" style="height: 72vh;">
          <img
            v-if="selectWorkers.certificates && selectWorkers.certificates[0].certificate_image"
            :src="selectWorkers.certificates && selectWorkers.certificates[0].certificate_image"
            style="max-width: 100%; border-radius: 20px; height: 100%;"
          />
          <img
            v-else
            :src="selectedCertificateImage"
            style="max-width: 100%; border-radius: 10px; height: 100%;"
          />
        </div>
        <div class="close-modals" @click="isHandleOpenModalsImageSertifikat = false">
          <XMarkIcon color="#000000" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  SertifikatBadgeIcon,
  MedaliSertifikatIcon,
  ZooomImageIcon,
  XMarkIcon
} from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'ModalsPreviewSertifikat',
  props: {
    isHandleOpenModalsPreviewSertifikat: {
      type: Boolean,
      default: false
    },
    selectWorkers: {
      type: Object,
      default: () => ({})
    },
    selectWorkersCertified: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedCertificate: this.selectWorkers.certificates
        ? this.selectWorkers.certificates[0]
        : null,
      isHandleOpenModalsImageSertifikat: false,
      selectedCertificateImage: ''
    }
  },
  components: {
    SertifikatBadgeIcon,
    MedaliSertifikatIcon,
    ButtonApp,
    ZooomImageIcon,
    XMarkIcon
  },
  methods: {
    selectCertificate (item) {
      this.selectedCertificate = item
    },
    handleOpenModalsImageSertifikat (image) {
      this.selectedCertificateImage = image
      this.isHandleOpenModalsImageSertifikat = true
    },
    closeModal () {
      this.$emit('closeModalPreviewSertifikat')
      this.selectedCertificate = null
    }
  },
  watch: {
    selectWorkersCertified (newVal) {
      if (newVal && newVal.length > 0) {
        this.selectedCertificate = newVal[0]
      }
    }
  }
}
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  width: 65vw;
  height: 85vh;
  min-height: 100%;
  max-height: 90vh;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
}
.modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 2rem;
  background: #b40007;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 9999;
}
.modal-body {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  height: 82%;
  /* min-height: 550px; */
  /* max-height: 550px; */
  /* align-items: center; */
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  background: #ffffff;
  overflow: auto;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 12%;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #ffffff;
  z-index: 9999;
}
.avatar-circle-users {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 45px;
  min-width: 45px;
  max-width: 45px;
  height: 45px;
  min-height: 45px;
  max-height: 45px;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-circle-users > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.badge-new-comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: auto;
  max-width: auto;
  height: auto;
  margin: 0;
  padding: 0.25rem 0.5rem;
  background: #ecfaf0;
  border-radius: 1000px;
}
.card-count-sertifikat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0.5rem;
  background: #e10009;
  border-radius: 20px;
  overflow: hidden;
  gap: 0.5rem;
}
.card-image-sertifikat-condition {
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: 850px; */
  width: 100%;
  height: 450px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background: #9f9c9c;
}
.card-image-sertifikat {
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: 850px; */
  width: 100%;
  height: 450px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  border-radius: 20px;
  overflow: hidden;
  background: #ffffff;
}
.watermark {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  opacity: 0.2;
  white-space: nowrap;
}
.watermark > p {
  transform: rotate(-25deg);
  font-size: 85px;
  white-space: nowrap;
}
.image-condition {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  opacity: 0.2;
  white-space: nowrap;
  background: white;
}
.card-image-sertifikat-childern-condition {
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  overflow: hidden !important;
  z-index: 1;
  background: #ffffff;
}
.card-image-sertifikat-childern {
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  overflow: hidden !important;
  z-index: 1;
  background: #ffffff;
}
.card-image-sertifikat > img,
.card-image-sertifikat-childern > img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}
.image-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0.5;
}
.zoom-image-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  background: transparent;
  z-index: 9999;
}
.close-modals {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  background: #ffffff;
  z-index: 9999;
  cursor: pointer;
}
</style>
