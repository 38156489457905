<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-modal v-model="isHandleOpenModalsRateUnderThree" :can-cancel="false">
      <div class="modal-content">
        <div class="modal-header">
          <div
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
          >
            <p class="averta-bold label-20 color-white">Report komplain ticketing</p>
          </div>
          <div
            @click="closeModals"
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center is-clickable"
          >
            <XMarkIcon />
          </div>
        </div>
        <div class="modal-body">
          <div
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100"
          >
            <p class="averta-bold label-20 color-black">Detail Tukang & Proyek</p>
          </div>
          <div class="card-rating-users">
            <div
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100 p-4 gap-6"
            >
              <div
                style="background: #fce5e6"
                class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 py-3 px-5 border-radius-100"
              >
                <p class="averta-bold label-16 color-red">
                  Tukang ini mendapat penilaian dibawah 3, akan dilakukan investigasi
                </p>
              </div>
              <div
                class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
              >
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3"
                >
                  <div class="avatar-circle-users">
                    <img
                      :src="
                        selectedWorker && selectedWorker.image
                          ? selectedWorker.image
                          : selectedWorker && selectedWorker.worker_image
                          ? selectedWorker.worker_image
                          : ''
                      "
                      alt=""
                    />
                  </div>
                  <div
                    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                  >
                    <p>
                      {{
                        selectedWorker && selectedWorker.name
                          ? selectedWorker.name
                          : selectedWorker && selectedWorker.worker_name
                          ? selectedWorker.worker_name
                          : ''
                      }}
                    </p>
                    <p>
                      ID
                      {{
                        selectedWorker && selectedWorker.workItemId
                          ? selectedWorker.workItemId
                          : selectedWorker && selectedWorker.worker_id
                          ? selectedWorker.worker_id
                          : ''
                      }}
                      -
                      {{
                        selectedWorker && selectedWorker.skillName
                          ? selectedWorker.skillName
                          : selectedWorker && selectedWorker.skill_name
                          ? selectedWorker.skill_name
                          : ''
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-5"
                >
                  <div
                    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-1"
                  >
                    <p>Rating Client</p>
                    <div
                      class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-1"
                    >
                      <StarRatingIcon size="25" color="#F1D900" />
                      <p class="averta-bold label-16 color-bold m-0 p-0">
                        {{
                          (selectedWorker &&
                            selectedWorker.ratingFromClient &&
                            selectedWorker.ratingFromClient.rating) ||
                            (selectedWorker &&
                            selectedWorker.rating_from_client &&
                            selectedWorker.rating_from_client.rating) ||
                          '0'
                        }}
                      </p>
                      <!-- <div
                        class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                      >
                        <p class="averta-reguler label-16 color-black">/5</p>
                      </div> -->
                    </div>
                  </div>
                  <div
                    class="divider-vertical-line-1"
                    style="min-height: 40px; background-color: #000000 !important"
                  ></div>
                  <div
                    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-1"
                  >
                    <p>Rating SM</p>
                    <div
                      class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-1"
                    >
                      <StarRatingIcon size="25" color="#F1D900" />
                      <p class="averta-bold label-16 color-bold m-0 p-0">
                        {{
                          rating ||
                          (selectedWorker &&
                            selectedWorker.ratingFromInternal &&
                            selectedWorker.ratingFromInternal.rating) ||
                          (selectedWorker &&
                            selectedWorker.rating_from_internal &&
                            selectedWorker.rating_from_internal.rating) ||
                          '0'
                        }}
                      </p>
                      <!-- <div
                        class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                      >
                        <p class="averta-reguler label-16 color-black">/5</p>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="background: #ffffff"
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start c-w-100 py-3 px-5 border-radius-20"
              >
                <p class="averta-reguler label-16 color-black">Item Pekerjaan</p>
                <p class="averta-bold label-16 color-black">
                  {{
                    selectedWorker && selectedWorker.workItemName
                      ? selectedWorker.workItemName
                      : selectedWorker &&
                        selectedWorker.itemWorking &&
                        selectedWorker.itemWorking.item_component_name
                      ? selectedWorker &&
                        selectedWorker.itemWorking &&
                        selectedWorker.itemWorking.item_component_name
                      : ''
                  }}
                </p>
              </div>
            </div>
            <div class="card-information-client">
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100 p-4 gap-4"
              >
                <div
                  class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 p-3"
                >
                  <div
                    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start gap-2 c-w-50"
                  >
                    <div class="image-branch">
                      <img
                        :src="
                          (getDataBranch && getDataBranch.logo) ||
                          (dataHeader && dataHeader.branch && dataHeader.branch.logo)
                        "
                      />
                    </div>
                    <p>Koordinator Engineering</p>
                    <div
                      class="is-flex is-flex-direction-row is-justify-content-center is-align-items-flex-start gap-1"
                    >
                      <div>
                        <p class="averta-bold color-black">
                          {{
                            (dataHeader && dataHeader.client && dataHeader.client.pic_name) ||
                            (getDataClient && getDataClient.pic_name)
                          }}
                        </p>
                        <p class="averta-bold color-red">
                          {{
                            (dataHeader && dataHeader.client && dataHeader.client.pic_phone) ||
                            (getDataClient && getDataClient.pic_phone)
                          }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style="text-align: right"
                    class="is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-flex-end c-w-50"
                  >
                    <p>Alamat</p>
                    <p class="averta-bold">
                      {{
                        (dataHeader && dataHeader.branch && dataHeader.branch.address) ||
                        (getDataBranch && getDataBranch.address)
                      }}
                    </p>
                  </div>
                </div>
                <div class="divider-line-1"></div>
                <div
                  class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-start c-w-100 p-3"
                >
                  <div
                    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start gap-1 c-w-50"
                  >
                    <p>No Unit</p>
                    <div
                      class="is-flex is-flex-direction-row is-justify-content-center is-align-items-flex-start gap-1"
                    >
                      <p class="averta-bold">
                        {{
                          (dataHeader && dataHeader.branch && dataHeader.branch.branch_unit_name) ||
                          '-' ||
                          (getDataBranch && getDataBranch.branch_unit_name)
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    style="text-align: right"
                    class="is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-flex-end c-w-50"
                  >
                    <p>Tanggal Proyek Dibuat</p>
                    <p class="averta-bold">
                      {{
                        (dataHeader &&
                          dataHeader.created_at &&
                          moment(dataHeader.created_at).format('DD MMMM YYYY')) ||
                        moment(getDateCreateAt).format('DD MMMM YYYY')
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="divider-line-1" style="min-height: 10px !important"></div>

          <div
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100"
          >
            <p class="averta-bold label-20 color-black">Buat Komplain Ticketing</p>
          </div>

          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100 gap-5"
          >
            <div
              class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
            >
              <p>Pilih jenis komplain</p>
              <p>Dapat dipilih lebih dari 1 jenis</p>
            </div>

            <div class="main-input-select">
              <button
                class="button-select-mitra"
                v-for="(option, index) in selectedOptions"
                :key="index"
              >
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-2"
                >
                  <p class="m-0">
                    {{ option.name }}
                  </p>
                  <p @click="toggleSelect(option)" class="m-0">
                    <XMarkIcon color="#000000" size="12" />
                  </p>
                </div>
              </button>
              <div class="icon-dropdown cursor-pointer" @click="handleShowDropdown()">
                <ArrowDownDropdown />
              </div>
              <div class="dropdown-list-select" v-if="isShowDropdown">
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 gap-3 is-flex-wrap-wrap"
                >
                  <button
                    v-for="(option, index) in getCurrentOptions"
                    :key="index"
                    class="button-comments-mitra"
                    :class="{ isSelect: selectedOptionsTicketing.includes(option) }"
                    @click="toggleSelect(option)"
                  >
                    <p class="averta-reguler label-14">{{ option.name }}</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start c-w-100 gap-3"
          >
            <p>Ceritakan Detail Komplain</p>
            <textarea
              class="textarea"
              rows="5"
              placeholder="Ceritakan detail komplain disini"
              :value="dataDescriptionComplain"
              @input="updateComment"
            ></textarea>
          </div>
          <div
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 gap-3"
          >
            <div class="input-upload-photo cursor-pointer" @click="handleUploadPhoto">
              <img :src="selectedPhoto || ''" v-if="selectedPhoto" />
              <EmptyPhotoIcon v-else />
            </div>
            <div
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-cente gap-1"
            >
              <p class="averta-bold label-16">Upload Foto Masalah</p>
              <p class="averta-reguler label-16">Maksimal ukuran 3MB</p>
              <p class="averta-bold label-16 color-red cursor-pointer" @click="handleUploadPhoto">
                Upload
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer z-index-9999">
          <ButtonApp width="100%" height="45px" @click="submitAllData()" :isDisabled="!isFormValid">
            <p class="averta-reguler label-16">Simpan</p>
          </ButtonApp>
        </div>
      </div>
    </b-modal>

    <modalsHasNoRate :isHandleOpenModalPageRating="isHandleOpenModalPageRating" />

    <div style="z-index: 99999">
      <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
  </div>
</template>

<script>
import { XMarkIcon, ArrowDownDropdown, EmptyPhotoIcon, StarRatingIcon } from '@/components/icons'
import { mapGetters } from 'vuex'
import moment from 'moment'
import ButtonApp from '@/components/button/ButtonApp.vue'
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'
import modalsHasNoRate from './modalsHasNoRate.vue'

export default {
  name: 'modalsRateUnderThree',
  props: {
    isHandleOpenModalsRateUnderThree: {
      type: Boolean,
      default: false
    },
    selectedWorker: {
      type: Object
    },
    getDataBranch: {
      type: Object
    },
    getDataClient: {
      type: Object
    },
    getDateCreateAt: {
      type: String
    },
    dataHeader: {
      type: Object
    },
    maxRating5: {
      type: Array,
      default: () => []
    },
    maxRatingUnder4: {
      type: Array,
      default: () => []
    },
    maxRatingUnder2: {
      type: Array,
      default: () => []
    },
    getProjectId: {
      type: Number,
      default: null
    },
    rating: {
      type: Number,
      default: null
    },
    selectedDate: {
      type: String,
      default: null
    },
    currentOptions: {
      type: Array,
      default: () => []
    },
    selectedOptions: {
      type: Array,
      default: () => []
    },
    approveDataSubmitRating: {
      type: Boolean,
      default: false
    },
    value: String
  },
  components: {
    ButtonApp,
    XMarkIcon,
    ArrowDownDropdown,
    EmptyPhotoIcon,
    SpinnerIcon,
    StarRatingIcon,
    modalsHasNoRate
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      getDataComment: 'rating/getDataComment'
    }),
    getCurrentOptions () {
      if (!this.rating || !this.rating) {
        return []
      }
      const rating = this.rating
      if (rating === 5) {
        return this.maxRating5
      } else if (rating === 3 || rating === 4) {
        return this.maxRatingUnder4
      } else if (rating === 1 || rating === 2) {
        return this.maxRatingUnder2
      }
      return []
    },
    isFormValid () {
      return (
        this.selectedWorker &&
        this.getDataComment &&
        this.selectedOptionsTicketing.length > 0 &&
        this.selectedPhoto
      )
    },
    dataDescriptionComplain () {
      return this.getDataComment + this.userInput
    }
  },
  data () {
    return {
      moment: moment,
      isShowDropdown: false,
      selectedOptionsTicketing: [],
      selectedPhoto: null,
      isLoading: false,
      isFullPage: true,
      internalModalState: this.isHandleOpenModalsRateUnderThree,
      selectedFile: null,
      isHandleOpenModalPageRating: false,
      approveDataSubmitTicketing: false,
      userInput: ''
    }
  },
  methods: {
    handleShowDropdown () {
      this.isShowDropdown = !this.isShowDropdown
      document.addEventListener('click', (event) => {
        if (!event.target.closest('.main-input-select')) {
          this.isShowDropdown = false
          // eslint-disable-next-line no-caller
          document.removeEventListener('click', arguments.callee)
        }
      })
    },
    toggleSelect (option) {
      if (this.selectedOptionsTicketing.includes(option)) {
        this.selectedOptionsTicketing = this.selectedOptionsTicketing.filter(
          (item) => item !== option
        )
      } else {
        this.selectedOptionsTicketing.push(option)
      }
      this.updateSelectedOptions()
    },
    updateSelectedOptions () {
      this.$emit('update:selectedOptions', [...this.selectedOptionsTicketing])
    },
    handleUploadPhoto () {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'image/jpeg,image/png'
      input.onchange = (event) => {
        const file = event.target.files[0]
        if (file) {
          const maxFileSize = 3 * 1024 * 1024
          if (file.size > maxFileSize) {
            alert('Ukuran file melebihi batas maksimal 3MB')
            return
          }

          this.selectedFile = file

          const reader = new FileReader()
          reader.onload = (e) => {
            this.selectedPhoto = e.target.result
          }
          reader.readAsDataURL(file)
        }
      }
      input.click()
    },
    approveRating (value) {
      this.$emit('approveDataSubmitRating', value)
    },
    submitAllData () {
      this.approveRating(true)
      this.$emit('submit-rating')
    },
    async submitComplaint () {
      this.isLoading = true

      if (!this.isFormValid) {
        this.isLoading = false
        return
      }

      try {
        const formData = new FormData()
        const complaintTags = this.ensureFiveTags(this.selectedOptions)

        formData.append('project_id', this.getProjectId)
        formData.append('user_id',
          (this.getDataClient && this.getDataClient.pic_id) ||
          (this.dataHeader && this.dataHeader.client && this.dataHeader.client.pic_id)
        )
        formData.append('complaint', this.dataDescriptionComplain)

        complaintTags.forEach((tagId, index) => {
          if (tagId !== null) {
            formData.append('complaint_tags[]', tagId)
          }
        })

        if (this.selectedFile instanceof File) {
          formData.append('images', this.selectedFile)
        } else if (Array.isArray(this.selectedFile)) {
          this.selectedFile.forEach(file => {
            formData.append('images', file)
          })
        }

        const res = await this.$store.dispatch('rating/postComplaint', {
          url: this.url,
          payload: formData
        })

        if (res?.data?.code === 1) {
          await this.$swal({
            icon: 'success',
            title: 'Success',
            text: res.data.message
          })

          this.$buefy.toast.open({
            message: 'Ticketing Anda Berhasil Terbit'
          })

          if (
            this.selectedWorker.formDetailProject === true &&
            this.selectedWorker.has_been_rated === false
          ) {
            this.isHandleOpenModalPageRating = true
          } else {
            this.isHandleOpenModalPageRating = false
            window.location.reload()
          }
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: res?.data?.message || 'An error occurred while submitting the rating.'
          })
        }
      } catch (error) {
        console.error('Error submitting rating:', error.response?.data || error.message)
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'An unexpected error occurred. Please try again.'
        })
      } finally {
        this.isLoading = false
      }
    },
    closeModals () {
      this.$emit('closeModalsRateUnderThree', false)
    },
    updateComment (event) {
      const originalLength = this.getDataComment.length
      const newValue = event.target.value

      if (newValue.slice(0, originalLength) !== this.getDataComment) {
        event.target.value = this.getDataComment + this.userInput
        return
      }

      this.userInput = newValue.slice(originalLength)
    },
    ensureFiveTags (options) {
      const tags = options.map(option => option.id)
      while (tags.length < 5) {
        tags.push(null)
      }
      return tags.slice(0, 5)
    }
  },
  watch: {
    selectedOptions: {
      immediate: true,
      handler (newVal) {
        this.selectedOptionsTicketing = [...newVal]
      }
    },
    isHandleOpenModalsRateUnderThree (newVal, oldVal) {
      this.internalModalState = newVal
    }
  }
}
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  min-width: 80%;
  max-width: 80%;
  height: auto;
  min-height: auto;
  max-height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
}
.modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 2rem;
  background: #b40007;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.modal-body {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 50%;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 1.5rem;
  gap: 20px;
  background: #ffffff;
  overflow: auto !important;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #ffffff;
}
@media (max-width: 1600px) {
  .modal-content {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
  .modal-body {
    overflow: auto !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
}
.card-rating-users {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  border-radius: 20px;
  background: #ededed;
  border: 1px solid #e1e1e1;
}
.card-information-client {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  border-radius: 20px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
}
.avatar-circle-users {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
}
.main-input-select {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  min-height: 50px;
  max-height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 10px;
  gap: 1rem;
  background: #ffffff;
  border: 1px solid #e1e1e1;
}
.icon-dropdown {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-10px, -50%);
}
.textarea {
  border-radius: 10px !important;
  resize: none !important;
}
.input-upload-photo {
  display: flex;
  flex-direction: column;
  width: 85px;
  min-width: 85px;
  max-width: 85px;
  height: 85px;
  min-height: 85px;
  max-inline-size: 85px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  background: #f5f5f5;
  border: 1px solid #e1e1e1;
  overflow: hidden;
}
.avatar-circle-users > img,
.input-upload-photo > img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.image-branch {
  display: flex;
  flex-direction: row;
  width: 135px;
  height: auto;
  min-height: auto;
  max-height: auto;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border-radius: 0;
}
.image-branch > img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.dropdown-list-select {
  position: absolute;
  top: 110%;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  z-index: 99999;
}
.button-comments-mitra {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 35px;
  max-height: 100%;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(1em - 1px);
  padding-right: calc(1em - 1px);
  padding-top: calc(0.5em - 1px);
  border-radius: 100px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  gap: 0.5rem;
  border: 1px solid #e1e1e1;
  background: #ffffff;
}
.button-comments-mitra.isSelect {
  background-color: #fce5e6 !important;
  border: 1px solid #b40007 !important;
  color: #000000;
}
.button-select-mitra {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 35px;
  max-height: 100%;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(1em - 1px);
  padding-right: calc(1em - 1px);
  padding-top: calc(0.5em - 1px);
  border-radius: 100px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  gap: 0.5rem;
  background: #efefef;
  border: none !important;
}
</style>
