<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_3125_81327"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="18"
      height="18"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1867 14.4694H15.9767L20.2167 18.7294C20.6267 19.1394 20.6267 19.8094 20.2167 20.2194C19.8067 20.6294 19.1367 20.6294 18.7267 20.2194L14.4767 15.9694V15.1794L14.2067 14.8994C12.8067 16.0994 10.8967 16.7194 8.8667 16.3794C6.0867 15.9094 3.8667 13.5894 3.5267 10.7894C3.0067 6.55938 6.5667 2.99938 10.7967 3.51938C13.5967 3.85938 15.9167 6.07938 16.3867 8.85938C16.7267 10.8894 16.1067 12.7994 14.9067 14.1994L15.1867 14.4694ZM5.4767 9.96938C5.4767 12.4594 7.4867 14.4694 9.9767 14.4694C12.4667 14.4694 14.4767 12.4594 14.4767 9.96938C14.4767 7.47938 12.4667 5.46938 9.9767 5.46938C7.4867 5.46938 5.4767 7.47938 5.4767 9.96938Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_3125_81327)">
      <rect width="24" height="24" :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SearchLongIcon',
  props: {
    color: {
      type: String,
      default: '#E10009'
    },
    size: {
      type: String,
      default: '32'
    }
  }
}
</script>
