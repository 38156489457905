
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6 14.3L8.425 12.125C8.24167 11.9417 8.01667 11.85 7.75 11.85C7.48333 11.85 7.25 11.95 7.05 12.15C6.86667 12.3333 6.775 12.5667 6.775 12.85C6.775 13.1333 6.86667 13.3667 7.05 13.55L9.9 16.4C10.0833 16.5833 10.3167 16.675 10.6 16.675C10.8833 16.675 11.1167 16.5833 11.3 16.4L16.975 10.725C17.1583 10.5417 17.25 10.3167 17.25 10.05C17.25 9.78333 17.15 9.55 16.95 9.35C16.7667 9.16667 16.5333 9.075 16.25 9.075C15.9667 9.075 15.7333 9.16667 15.55 9.35L10.6 14.3ZM12 22.5C10.6167 22.5 9.31667 22.2373 8.1 21.712C6.88333 21.1873 5.825 20.475 4.925 19.575C4.025 18.675 3.31267 17.6167 2.788 16.4C2.26267 15.1833 2 13.8833 2 12.5C2 11.1167 2.26267 9.81667 2.788 8.6C3.31267 7.38333 4.025 6.325 4.925 5.425C5.825 4.525 6.88333 3.81233 8.1 3.287C9.31667 2.76233 10.6167 2.5 12 2.5C13.3833 2.5 14.6833 2.76233 15.9 3.287C17.1167 3.81233 18.175 4.525 19.075 5.425C19.975 6.325 20.6873 7.38333 21.212 8.6C21.7373 9.81667 22 11.1167 22 12.5C22 13.8833 21.7373 15.1833 21.212 16.4C20.6873 17.6167 19.975 18.675 19.075 19.575C18.175 20.475 17.1167 21.1873 15.9 21.712C14.6833 22.2373 13.3833 22.5 12 22.5Z"
      fill="#10BD41"
    />
  </svg>
</template>

<script>
export default {
  name: 'ChecklistGreenIcon',
  props: {
    width: {
      type: String,
      default: '18'
    },
    height: {
      type: String,
      default: 'auto'
    },
    viewBox: {
      type: String,
      default: '0 0 18 21'
    },
    fill: {
      type: String,
      default: 'none'
    }
  }
}
</script>
