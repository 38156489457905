<template>
  <b-modal v-model="localHandleOpenModalsImage">
    <div class="zoom-in-wrapper">
      <ZoomImage :imageSrc="photoDetail" :isOneImage="true" />
    </div>
  </b-modal>
</template>

<script>
import ZoomImage from '@/components/image/ZoomImage.vue'
export default {
  name: 'modalsViewZoomImage',
  props: {
    isHandleOpenModalsImagePriview: {
      type: Boolean,
      default: false
    },
    photoDetail: {
      type: String,
      default: null
    }
  },
  components: {
    ZoomImage
  },
  data () {
    return {
      localHandleOpenModalsImage: this.isHandleOpenModalsImagePriview
    }
  },
  watch: {
    isHandleOpenModalsImagePriview (newValue) {
      this.localHandleOpenModalsImage = newValue
    },
    localHandleOpenModalsImage (newValue) {
      this.$emit('update:isHandleOpenModalsImagePriview', newValue)
    }
  }
}
</script>

<style scoped>
.zoom-in-wrapper {
  height: 100%;
}
</style>
