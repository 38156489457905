<template>
  <div v-if="areas.length > 0">
     <div class="columns is-flex is-flex-wrap-wrap">
      <div class="column is-3 " v-for="(item, index) in areas" :key="index">
        <div style="gap: 2rem;" class="is-flex is-flex-direction-column card border-radius-20 overflow-hidden border-1-grey-2 p-4" >
          <p class="averta-bold label-18">{{item.name}}</p>
            <ButtonApp  @click="detailVendor(item.id)" width="100%">
              <p class="averta-bold py-2">Lihat Detail</p>
            </ButtonApp>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="column c-w-100 has-text-centered">
    <ProyekEmptyIcon />
    <p class="averta-bold mt-2">
      Data Tidak Ditemukan
    </p>
  </div>
</template>

<script>
// import { encryptData } from '@/helpers'
import { ProyekEmptyIcon } from '@/components/icons'

// Components
import ButtonApp from '@/components/button/ButtonApp.vue'
export default {
  name: 'TabListArea',
  props: {
    areas: {
      type: Array
    }
  },
  components: {
    ButtonApp,
    ProyekEmptyIcon
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    detailVendor (areaId) {
      const vendorId = this.$route.params.vendorId
      // eslint-disable-next-line no-unused-vars
      // const encrypt = encryptData(areaId)
      this.$router.push({ path: `/am/vendors/${vendorId}/branch`, query: { area_id: areaId } })
    }
  },
  mounted () {}
}
</script>
