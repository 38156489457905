<template>
  <div>
    <ModalTemplate :showModal="showModal" width="650px" @closeModal="closeModal">
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="flex-center-vertical">
            <UploadIcon />
            <p class="averta-bold label-18 ml-3">Ubah Lampiran</p>
          </div>
          <div class="is-clickable" @click="closeModal">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="wrap-body-modal">
          <div v-if="data" class="p-5">
            <p class="averta-black">Ubah Lampiran SIK/SPK</p>
            <p class="color-grey">Pastikan file yang diunggah telah benar dan sesuai</p>

            <div
              class="border-radius-20 overflow-hidden mb-3 bg-lightgray p-3 mt-3 flex-center-vertical is-justify-content-space-between"
            >
              <div class="flex-center">
                <DocumentIcon />
                <div class="ml-2">
                  <p
                    class="averta-bold is-underlined color-red is-clickable"
                    @click="handlePreview"
                  >
                    {{ attachments.name }}
                  </p>
                  <p class="color-grey">upload pada : {{ attachments.updated_at || '-' }}</p>
                </div>
              </div>

              <label for="attachment">
                <input
                  type="file"
                  id="attachment"
                  ref="attachment"
                  accept="image/png, image/jpeg"
                  @change="changeAttachment"
                  hidden
                />
                <div class="is-clickable">
                  <EditIcon size="17" color="#130F26" />
                </div>
              </label>
            </div>
            <ErrorComponentVue v-if="attachments.fileAttachment" type="success" title="File Berhasil Diubah" />

            <div class="mt-3">
              <p>Catatan <span class="color-grey">(Opsional)</span></p>
              <textarea
                v-model="attachments.note"
                class="textarea input-search-2 averta-regular mt-1 label-14 resize-none"
                rows="3"
                placeholder="Masukan Catatan"
                @keydown="disableEnter"
              ></textarea>
            </div>
          </div>
          <div class="footer-modal">
            <ButtonApp
              height="45px"
              width="160px"
              :isDisabled="disabledButton"
              @click="handleSubmit"
            >
              <div class="flex-center">
                <p class="averta-bold">Simpan</p>
              </div>
            </ButtonApp>
          </div>
        </div>
      </template>
    </ModalTemplate>

    <ModalPreviewImage
      :isActive="modalPreview"
      :dataImage="imagePreview"
      :title="titlePreview"
      @closeModal="closeModalPreviewImage()"
    />

    <b-loading v-model="isLoadingSubmit" :is-full-page="true" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import {
  EditIcon,
  UploadIcon,
  DocumentIcon,
  SpinnerIcon
} from '@/components/icons'
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import saveImage from '@/assets/img/delete-area-component.png'

import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import ErrorComponentVue from '@/components/ErrorComponent.vue'
import moment from 'moment'

export default {
  name: 'ModalEditAttachment',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    },
    surveyId: {
      type: [String, Number]
    },
    type: {
      type: String,
      required: true
    },
    refreshData: {
      type: Function
    },
    getHistory: {
      type: Function
    }
  },
  components: {
    EditIcon,
    UploadIcon,
    DocumentIcon,
    SpinnerIcon,

    ButtonApp,
    ModalTemplate,
    ModalPreviewImage,
    ErrorComponentVue
  },
  data () {
    return {
      isLoadingSubmit: false,
      attachments: {
        name: '',
        updated_at: '',
        note: '',
        fileAttachment: null,
        previewAttachment: null
      },
      imagePreview: [],
      titlePreview: '',
      modalPreview: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    disabledButton () {
      return !this.attachments.fileAttachment
    }
  },
  methods: {
    handlePreview () {
      this.imagePreview = [this.attachments.previewAttachment]
      this.titlePreview = `Lampiran ${this.type}`
      this.modalPreview = true
    },
    handleSubmit () {
      this.$swal({
        imageUrl: saveImage,
        imageAlt: 'save data',
        width: '540px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22" style="margin-bottom: -10px; margin-top: -10px;">Simpan Perubahan Lampiran</p>',
        html: '<div class="flex-center c-w-100"><p class="averta-regular" style="width: 230px;">Pastikan file yang diunggah telah benar dan sesuai</p></div>',
        showCancelButton: true,
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component',
          popup: 'custom-modal-body-class'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoadingSubmit = true
          const { note, fileAttachment } = this.attachments

          const payload = {
            url: this.url,
            survey_id: this.surveyId,
            type: this.type
          }
          if (note) {
            payload.note = note
          }
          if (fileAttachment) {
            payload.attachment = fileAttachment
          }

          this.$store
            .dispatch('project/updateAttachment', payload)
            .then(() => {
              this.isLoadingSubmit = false
              this.refreshData()
              if (this.getHistory) {
                this.getHistory()
              }
              this.closeModal()
              this.$buefy.toast.open({
                duration: 2500,
                message: '<div style="max-width: 300px;"><p class="averta-regular">Lampiran Berhasil di ubah</p</div>',
                type: 'is-dark'
              })
            })
            .catch(error => {
              this.isLoadingSubmit = false
              alertErrorMessage(error)
            })
        }
      })
    },
    resetState () {
      this.attachments = {
        name: '',
        updated_at: '',
        note: '',
        fileAttachment: null,
        previewAttachment: null
      }
    },
    disableEnter (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    },
    changeAttachment (e) {
      e.preventDefault()
      const file = e.target.files[0]

      if (file) {
        this.attachments.fileAttachment = file
        this.attachments.previewAttachment = URL.createObjectURL(file)
        this.attachments.updated_at = moment(new Date()).format('DD MMMM YYYY')
        this.$refs.attachment.value = ''
      }
    },
    closeModal () {
      this.$emit('closeModal')
      this.resetState()
    },
    closeModalPreviewImage () {
      this.modalPreview = false
    }
  },
  watch: {
    showModal (val) {
      if (val) {
        this.attachments = {
          name: this.data.name || '',
          updated_at: this.data.updated_at || '',
          note: this.data.note || '',
          fileAttachment: null,
          previewAttachment: this.data.filename || null
        }
      }
    }
  }
}
</script>

<style scoped>
.wrap-body-modal {
}

.footer-modal {
  box-shadow: 0px -3px 30px 0px #00000012;
  padding: 20px 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
