<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-modal v-model="isHandleOpenModalConfirmAssignConsultant" :can-cancel="false">
    <div class="modal-content">
      <div class="modal-body">
        <div
          class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-10"
        >
        <AlertConfirmModalsIcon />
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-1"
          >
            <p class="averta-bold label-24">Tugaskan Konsultan Survey</p>
            <p class="averta-regular label-18" style="text-align: center">
              Pastikan seluruh informasi penugasan konsultan survey telah benar dan sesuai
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div
          class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 gap-2"
        >
          <ButtonApp height="45px" width="100%" :isSecondary="true" @click="closeModal">
            <p class="averta-bold">Cek Kembali</p>
          </ButtonApp>
          <ButtonApp
            height="45px"
            width="100%"
            :isSecondary="false"
            @click="submitAssignConsultant"
          >
            <p class="averta-bold">Tugaskan Konsultan</p>
          </ButtonApp>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { AlertConfirmModalsIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
export default {
  name: 'ModalsConfirmAssingConsultant',
  props: {
    isHandleOpenModalConfirmAssignConsultant: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ButtonApp,
    AlertConfirmModalsIcon
  },
  data () {
    return {}
  },
  methods: {
    closeModal () {
      this.$emit('closeModalConfirmAssignConsultant')
    },
    submitAssignConsultant () {
      this.$emit('submitAssignConsultant')
    }
  }
}
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  min-height: auto;
  max-height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.modal-body {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1.5rem 1.5rem;
  background: #ffffff;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #ffffff;
}
</style>
