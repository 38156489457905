import Dashboard from '../views/v2/purchasing/views/Dashboard.vue'
import ListProjectPurchasing from '../views/v2/purchasing/views/ListProject.vue'
import IndexOfferProject from '../views/v2/purchasing/views/offer-project/IndexDetailOfferProject.vue'
import Notification from '../views/v2/notification/Notification.vue'
import Catalog from '../views/v2/purchasing/views/Catalog.vue'
import DetailProjectPurchasing from './../views/v2/purchasing/views/detail-project/DetailProjectPurchasing.vue'
import SearchGlobal from '../views/v2/search/SearchGlobal.vue'
import SpecificSearch from '../views/v2/search/SpecificSearch.vue'

const routesPurchasing = [
  {
    path: '/purchasing/dashboard',
    name: 'Purchasing Dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      const rs = localStorage.getItem('webapp_shell_kanggo')
      const auth = JSON.parse(rs)
      if (auth.user.is_default_password === 1) {
        next({ name: 'ChangeDefaultPassword' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name === 'Account Manager') {
        next({ name: 'AM Dashboard' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name.split(' ') === 'Konsultan') {
        next({ name: 'Home' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name === 'Site Manager') {
        next({ name: 'SM Dashboard' })
      } else if (!auth) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  {
    path: '/purchasing/project',
    name: 'ListProjectPurchasing',
    component: ListProjectPurchasing
  },
  {
    path: '/purchasing/project/offering/:offerId/detail',
    name: 'IndexOfferProject',
    component: IndexOfferProject
  },
  {
    path: '/purchasing/notification',
    name: 'NotificationPurchasing',
    component: Notification
  },
  {
    path: '/purchasing/catalog',
    name: 'CatalogPurchasing',
    component: Catalog
  },
  {
    path: '/purchasing/project/:projectId/detail',
    name: 'DetailProjectPurchasing',
    component: DetailProjectPurchasing
  },
  {
    path: '/purchasing/search',
    name: 'SearchGlobalPurchasing',
    component: SearchGlobal
  },
  {
    path: '/purchasing/specific-search',
    name: 'Specific Search',
    component: SpecificSearch
  }
]
export default routesPurchasing
