<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="white" fill-opacity="0.5" />
    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#E1E1E1" />
    <mask
      id="mask0_3177_291881"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="13"
      y="13"
      width="18"
      height="18"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3 16.7L13.85 18.15C13.54 18.46 13 18.24 13 17.79V13.5C13 13.22 13.22 13 13.5 13H17.79C18.24 13 18.46 13.54 18.15 13.85L16.7 15.3L18.88 17.46C19.27 17.85 19.27 18.49 18.88 18.88C18.49 19.27 17.85 19.27 17.46 18.88L15.3 16.7ZM27.3 15.3L25.85 13.85C25.54 13.54 25.76 13 26.21 13H30.5C30.78 13 31 13.22 31 13.5V17.79C31 18.24 30.46 18.46 30.15 18.15L28.7 16.7L26.54 18.88C26.15 19.27 25.51 19.27 25.12 18.88C24.73 18.49 24.73 17.85 25.12 17.46L27.3 15.3ZM18.15 30.15L16.7 28.7L18.88 26.54C19.27 26.15 19.27 25.51 18.88 25.12C18.49 24.73 17.85 24.73 17.46 25.12L15.3 27.3L13.85 25.85C13.54 25.54 13 25.76 13 26.21V30.5C13 30.78 13.22 31 13.5 31H17.79C18.24 31 18.46 30.46 18.15 30.15ZM28.7 27.3L30.15 25.85C30.46 25.54 31 25.76 31 26.21V30.5C31 30.78 30.78 31 30.5 31H26.21C25.76 31 25.54 30.46 25.85 30.15L27.3 28.7L25.12 26.54C24.73 26.15 24.73 25.51 25.12 25.12C25.51 24.73 26.15 24.73 26.54 25.12L28.7 27.3Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_3177_291881)">
      <rect x="10" y="10" width="24" height="24" fill="#9A9A9A" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ZooomImageIcon',
  props: {
    size: {
      type: String,
      default: '45'
    },
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>
