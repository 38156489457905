<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1328_75281"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="2"
      width="16"
      height="15"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.5 9.5C1.5 5.375 4.875 2 9 2C13.125 2 16.5 5.375 16.5 9.5C16.5 13.625 13.125 17 9 17C4.875 17 1.5 13.625 1.5 9.5ZM8.6025 10.4675L11.6625 12.35C11.9325 12.515 12.285 12.4325 12.45 12.17C12.615 11.9 12.525 11.5475 12.255 11.3825L9.375 9.65V6.3125C9.375 6.005 9.12 5.75 8.8125 5.75C8.505 5.75 8.25 6.005 8.2425 6.3125V9.83C8.2425 10.0925 8.3775 10.3325 8.6025 10.4675Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_1328_75281)">
      <rect y="0.5" width="18" height="18" :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ClockFillIcon',
  props: {
    width: {
      type: String,
      default: '18'
    },
    height: {
      type: String,
      default: 'auto'
    },
    viewBox: {
      type: String,
      default: '0 0 18 21'
    },
    color: {
      type: String,
      default: '#868686'
    }
  }
}
</script>
