<template>
  <Skeleton v-if="isLoading" class="p-6" />
  <div class="container py-6 container-detail" v-else>

    <div class="is-flex is-justify-content-space-between">
      <div class="is-flex is-align-items-center is-clickable mb-5 mt-3" @click="goBack()">
        <ArrowLeftIcon class="mr-3" />
        <p class="averta-bold">Kembali ke Penawaran</p>
      </div>
      <div>
        <b-button class="btn-order" @click="createProject()">
          <div class="is-flex is-align-items-center">
            <p class="mr-3">Buat Order Proyek</p>
            <CheckIcon />
          </div>
        </b-button>
      </div>
    </div>

    <div class="notification is-light is-success has-text-dark my-4">
      <p class="averta-bold label-20">Buat Order Proyek</p>
      <p class="label-16">Periksa kembali penawaran yang telah di-approved sebelum kamu membuat order proyek baru</p>
    </div>

    <div class="columns my-4 mx-auto">

      <div class="column card mr-3 mb-0">
        <p class="averta-bold is-size-5 mb-4">Detail Penawaran</p>
        <div class="mb-4">
          <p class="has-text-grey">ID</p>
          <p class="averta-bold">{{ dataPreview.survey_id }}</p>
        </div>
        <div class="mb-4">
          <p class="has-text-grey">Tanggal Pembuatan Penawaran</p>
          <p class="averta-bold">{{ convertDate(dataPreview.created_at) }}</p>
        </div>
        <div class="mb-4">
          <p class="has-text-grey">PIC - No. Telp</p>
          <p class="averta-bold">{{ dataPreview.card_information.pic_name }} - <span
              class="has-text-primary averta-bold">{{ dataPreview.card_information.pic_phone }}</span></p>
        </div>
      </div>

      <div class="column card mr-3 mb-0">
        <p class="averta-bold is-size-5 mb-4">Detail Proyek</p>
        <div class="mb-4">
          <p class="has-text-grey">Alamat Pengerjaan</p>
          <p class="averta-bold has-text-danger">{{ dataPreview.detail_project.address }}</p>
        </div>
        <div class="mb-4">
          <p class="has-text-grey">Catatan Perbaikan</p>
          <p class="averta-bold">{{ dataPreview.detail_project.note || '-' }}</p>
        </div>
      </div>

      <div class="column card">
        <BerkasLampiran
          :isDetailOrder="true"
          :dataProps="dataPreview.sik_spk"
          :idSurvey="dataPreview.survey_id"
          :isSMRC="isSMRC"
          @refreshData="getPreview"
        />
      </div>
    </div>

    <TimelinePhase2 :dataProps="dataPreview.timeline" :durationProps="dataPreview.duration"
      :projectDateProps="dataPreview.project_date" :slotSkillsProps="dataPreview.slot_skills" :durationMaterialProps="dataPreview.duration_material_preparation" />
    <!-- <NewTimeline :duration="dataPreview.duration" :data="dataPreview.timeline" :projectDate="dataPreview.project_date" /> -->
    <NewDetailPenawaran :hideInformasiPenawaran="true" :hideTimeline="true" :isBS="true" :isFromDetail="false" />

    <b-modal v-model="modalDetailHarga" :can-cancel="['escape', 'x']">
      <div class="header-modal is-flex is-justify-content-space-between is-align-items-center">
        <div class="is-flex is-align-items-center">
          <BagIcon class="icon-bag mr-2" />
          <span>
            Detail Harga
          </span>
        </div>
        <button type="button" class="delete" @click="modalDetailHarga = false" />
      </div>
      <div class="body-modal" v-if="dataDetailHarga">
        <div class="notification is-light is-info is-flex is-justify-content-space-between">
          <p class="has-text-grey">Durasi Pengerjaan : <span class="has-text-dark averta-bold">{{
    dataDetailHarga.duration }} Hari</span></p>
          <p class="has-text-grey">Grand Total : <span class="text-red averta-bold">{{
    formatThousand(dataDetailHarga.price.grand_total) }}</span></p>
        </div>
        <div class="card p-4">
          <div class="columns has-text-grey">
            <div class="column">Detail Pekerjaan</div>
            <div class="column">Volume Area Pekerjaan</div>
            <div class="column">Harga Satuan</div>
            <div class="column">Total Harga</div>
          </div>
          <div v-for="dataItem in dataDetailHarga.item_component" :key="dataItem.id">
            <div class="columns averta-bold">
              <div class="column">{{ dataItem.item_working }}</div>
              <div class="column">{{ `${dataItem.volume} ${dataItem.unit}` }}</div>
              <div class="column">{{ formatThousand(dataItem.unit_price) }}</div>
              <div class="column">{{ formatThousand(dataItem.total_price) }}</div>
            </div>
            <div class="wrapper-detail mb-4 p-4">
              <div class="columns averta-bold">
                <div class="column">Tukang/Material</div>
                <div class="column">Kuantitas</div>
                <div class="column">Harga Satuan Material/Tukang</div>
                <div class="column">Harga Total</div>
              </div>
              <hr class="mt-0 mb-4">
              <p class="averta-bold mb-4">Tukang</p>
              <div class="columns" v-for="worker in dataItem.workers" :key="worker.skill_id">
                <div class="column is-flex">
                  <img :src="worker.skill_image" class="mr-3 skill-image">
                  <p>{{ worker.skill_name }}</p>
                </div>
                <div class="column">{{ worker.total_worker }} Tukang</div>
                <div class="column">{{ formatThousand(worker.unit_price) }}</div>
                <div class="column">{{ formatThousand(worker.total_price) }}</div>
              </div>
              <hr class="mt-0 mb-4">
              <p class="averta-bold mb-4">Material</p>
              <div class="columns mb-4" v-for="material in dataItem.materials" :key="material.skill_id">
                <div class="column is-flex">
                  <img :src="material.product_image" class="mr-3 material-image">
                  <p>{{ material.product_name }}</p>
                </div>
                <div class="column">{{ `${material.qty} ${material.unit}` }}</div>
                <div class="column">{{ formatThousand(material.unit_price) }}</div>
                <div class="column">{{ formatThousand(material.total_price) }}</div>
              </div>
            </div>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3 py-1">
            <p>Sub Total</p>
            <p>{{ formatThousand(dataDetailHarga.price.subtotal) }}</p>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3 py-1">
            <!-- <p>PPN ({{ dataDetailHarga.price.ppn_percent }})</p> -->
            <p>PPN</p>
            <p>{{ formatThousand(dataDetailHarga.price.ppn) }}</p>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3 py-1">
            <p>Survey Charge</p>
            <p>{{ formatThousand(dataDetailHarga.price.survey_charge) }}</p>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3 py-1">
            <p>Transport Charge</p>
            <p>{{ formatThousand(dataDetailHarga.price.transport_charge) }}</p>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3 py-1">
            <p>Biaya Parkir</p>
            <p>{{ formatThousand(dataDetailHarga.price.fee_parking) }}</p>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3 py-1">
            <p class="averta-bold">Total</p>
            <p class="averta-black">{{ formatThousand(dataDetailHarga.price.total) }}</p>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 px-3 py-1">
            <p class="averta-bold">Diskon</p>
            <p class="averta-black has-text-success">-{{ formatThousand(dataDetailHarga.price.discount) }}</p>
          </div>
          <div class="is-flex is-justify-content-space-between mb-3 wrapper-pembulatan p-3">
            <p class="averta-bold">Pembulatan</p>
            <p class="averta-black text-red">{{ formatThousand(dataDetailHarga.price.grand_total) }}</p>
          </div>
        </div>
      </div>
    </b-modal>

    <ModalPreviewImage :isActive="modalPreviewImageActive" :dataImage="imagePreviewCarousel" title=""
      @closeModal="closeModalPreviewImage()" />

    <b-loading :is-full-page="true" v-model="confirmLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { CheckIcon, ArrowLeftIcon, SpinnerIcon } from '@/components/icons'
import Skeleton from '@/components/skeleton/Skeleton'
import confirmOfferingImage from '@/assets/img/delete-area-component.png'
import successCreateImage from '../../../../assets/img/check-red-1.png'
import { formatCurrency, alertErrorMessage, isSMRC } from '@/helpers'
import Timeline from '@/components/project/Timeline'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import NewDetailPenawaran from '@/components/project/NewDetailPenawaran'
import NewTimeline from '@/components/project/NewTimeline'
import TimelinePhase2 from '@/components/project/TimelinePhase2.vue'
import BerkasLampiran from '../../../../components/project/detail/BerkasLampiran.vue'

export default {
  name: 'DetailOrder',
  components: {
    Skeleton,
    CheckIcon,
    ArrowLeftIcon,
    // eslint-disable-next-line vue/no-unused-components
    Timeline,
    ModalPreviewImage,
    SpinnerIcon,
    NewDetailPenawaran,
    // eslint-disable-next-line vue/no-unused-components
    NewTimeline,
    TimelinePhase2,
    BerkasLampiran
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isSMRC () {
      return isSMRC(this.dataPreview.vendor.code)
    }
  },
  data () {
    return {
      isLoading: false,
      confirmLoading: false,
      dataPreview: null,
      area: null,
      modalDetailHarga: false,
      dataDetailHarga: null,
      imagePreviewCarousel: [],
      modalPreviewImageActive: false,
      isCollapseArray: []
    }
  },
  methods: {
    formatThousand (x) {
      const result = formatCurrency(x)
      return result
    },
    changeIsActive (areaName) {
      this.area = areaName
    },
    goBack () {
      this.$router.push(`/am/project/survey/${this.dataPreview.survey_id}/detail`)
    },
    convertDate (date) {
      return moment(date).format('dddd, DD MMMM YYYY')
    },
    getPreview () {
      this.isLoading = true
      const payload = {
        url: this.url,
        offering_id: this.$route.params.offeringId
      }

      this.$store
        .dispatch('project/getDetailPenawaran', payload)
        .then(response => {
          const res = response.data.data
          this.dataPreview = res
          // this.area = response.data.data.area_components[0].area_name

          // res.area_components.map((itemArea, indexArea) => {
          //   itemArea.components.map((itemComponent, indexComponent) => {
          //     itemComponent.detail_components.map((itemDetail, indexDetail) => {
          //       itemDetail.item.map((itemItem, indexItem) => {
          //         itemItem.workers.map((itemWorker, indexWorker) => {
          //           this.isCollapseArray.push({
          //             id: `${indexArea}${indexComponent}${indexDetail}${indexItem}${indexWorker}`,
          //             open: false
          //           })
          //         })
          //       })
          //     })
          //   })
          // })
          this.isLoading = false
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoading = false
          console.log(error)
        })
    },
    getDetailHarga (offeringId) {
      const payload = {
        url: this.url,
        offering_id: offeringId
      }

      this.$store
        .dispatch('project/getDetailHarga', payload)
        .then(response => {
          this.dataDetailHarga = response.data.data
          this.modalDetailHarga = true
        })
        .catch(error => {
          console.log(error)
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Gagal memuat data detail harga, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    createProject () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '535px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Lanjut Order Proyek Ini?</p>',
        html: '<p class="averta-regular">Pastikan kamu sudah cek kembali proyek ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.confirmLoading = true

          const payload = {
            url: this.url,
            offering_id: this.$route.params.offeringId
          }

          this.$store
            .dispatch('project/createProject', payload)
            .then(res => {
              this.confirmLoading = false
              this.$swal({
                width: '535px',
                padding: '10px 10px 30px 10px',
                html: `
                  <img src="${successCreateImage}" style="width: 50%;" class="mb-5" />
                  <p class="averta-bold mb-4 label-22">Proyek berhasil dibuat</p>
                  <p class="averta-regular">Proyek berhasil dibuat dan sudah dapat dimulai pada tanggal yang telah ditentukan.</p>
                `,
                showCancelButton: true,
                confirmButtonText: 'Lihat Detail Proyek',
                cancelButtonText: 'Lihat Daftar',
                reverseButtons: true,
                customClass: {
                  confirmButton: 'btn-hapus-detail-component',
                  cancelButton: 'btn-batal-detail-component'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  if (res.data.data.project_id) {
                    this.$router.push(`/am/project/${res.data.data.project_id}/detail`)
                  } else {
                    this.goBack()
                  }
                } else {
                  this.$router.push('/am/project')
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push({ path: `/am/project/${res.data.data.project_id}/detail` })
                } else {
                  this.$router.push('/am/project')
                }
              })
            })
            .catch(error => {
              alertErrorMessage(error)
              this.confirmLoading = false
              console.log(error)
            })
        }
      })
    },
    closeModalPreviewImage () {
      this.imagePreviewCarousel = null
      this.modalPreviewImageActive = false
    },
    openModalPreviewImage (data) {
      this.imagePreviewCarousel = data
      this.modalPreviewImageActive = true
    },
    handleCollapse (indexArea, indexComponent, indexDetailComponent, indexItem, indexWorker) {
      const index = `${indexArea}${indexComponent}${indexDetailComponent}${indexItem}${indexWorker}`

      const findObj = this.isCollapseArray.find(item => item.id === index)
      const findIndex = this.isCollapseArray.findIndex(item => item.id === index)
      if (findObj) {
        if (findObj.open) {
          this.isCollapseArray[findIndex].open = false
        } else {
          this.isCollapseArray[findIndex].open = true
        }
      }
    },
    isOpenCollapse (indexArea, indexComponent, indexDetailComponent, indexItem, indexWorker) {
      const index = `${indexArea}${indexComponent}${indexDetailComponent}${indexItem}${indexWorker}`
      const findObj = this.isCollapseArray.find(item => item.id === index)

      if (findObj) {
        return findObj.open
      } else {
        return false
      }
    }
  },
  created () {
    this.getPreview()
  }
}
</script>

<style scoped>
.text-red {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.btn-order {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
  color: white;
  border-radius: 100px;
  font-family: 'Averta-Bold';
  padding: 0px 24px;
}

.btn-tabs {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
  font-family: 'Averta-Bold';
}

.divider {
  border-top: 2px dashed #E1E1E1;
  margin: 24px 0px;
}

.bg-grey {
  background: #FAFAFA !important;
}

.wrapper-item-pekerjaan {
  border-radius: 12px;
}

.wrapper-volume {
  background: #ffffff;
  padding: 12px 24px;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
}

.wrapper-volume {
  background: #ffffff;
  padding: 12px 24px;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
}

.section-volume {
  padding-right: 28px;
}

.section-satuan {
  border-left: 2px solid #E1E1E1;
  padding-left: 14px;
  padding-right: 120px;
}

.wrapper-tukang,
.wrapper-material {
  border-radius: 10px;
}

.wrapper-item-tukang:not(:last-child),
.wrapper-item-material:not(:last-child),
.wrapper-item-variant:not(:last-child) {
  padding-bottom: 24px;
  border-bottom: 1px solid #E1E1E1;
}

.text-hidden {
  background: #3185FC;
  font-family: 'Averta';
  font-style: italic;
  color: white;
  padding: 4px 14px;
  border-radius: 14px;
}

.text-custom {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: italic;
}

.skill-img {
  width: 34px;
  margin-right: 10px;
}

.image-preview {
  width: 80px;
}

.header-modal {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.icon-bag {
  width: 20px;
}

.body-modal {
  background: white;
  padding: 12px 24px;
  border-radius: 0px 0px 12px 12px;
}

.wrapper-detail {
  background: #FAFAFA;
  border-radius: 20px;
}

.skill-image {
  width: 50px;
  object-fit: cover;
}

.material-image {
  width: 50px;
  object-fit: cover;
}

.wrapper-pembulatan {
  background: linear-gradient(90deg, #FFFFF7 0%, #FFF5EA 100%);
  border-radius: 8px;
}

.note-material {
  border: 1px solid #E1E1E1;
  border-radius: 12px;
  padding: 10px;
}

.btn-client {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
  border-radius: 100px;
  font-family: 'Averta-Bold';
  color: white;
  padding: 12px 24px;
  border: none;
}
</style>
