
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="c-w-100 is-absolute">
    <b-modal v-model="isHandleOpenModalsEditNoteSurvey" :can-cancel="false">
      <div class="modal-content" v-if="!isMobile">
        <div class="modal-header">
          <p class="averta-bold label-24" style="text-align: center">Ubah Catatan</p>
        </div>
        <div class="modal-body">
          <div
            style="background: #fafafa"
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100 p-4 border-radius-20"
          >
            <textarea
              class="textarea"
              rows="7"
              placeholder="Masukan Catatan"
              v-model="noteSurvey"
            />
          </div>
        </div>
        <div class="modal-footer">
          <div
            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 gap-2"
          >
            <ButtonApp height="45px" width="100%" :isSecondary="true" @click="isPermission">
              <p class="averta-bold">Batalkan</p>
            </ButtonApp>
            <ButtonApp
              height="45px"
              width="100%"
              :isSecondary="false"
              :isDisabled="!isDisabled"
              @click="isHandleOpenModalsConfirmEditNoteSurvey = true"
            >
              <p class="averta-bold">Simpan</p>
            </ButtonApp>
          </div>
        </div>
      </div>

      <!-- Modals Reset Note Survey -->
      <b-modal v-model="isHandleOpenModalsClearEditNoteSurvey" :can-cancel="false">
        <div class="modal-content">
          <div class="modal-body">
            <div
              class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-10"
            >
              <AlertConfirmModalsIcon />
              <div
                class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-1 c-w-100"
              >
                <p class="averta-bold label-24" style="text-align: center">
                  Batalkan Ubah Catatan?
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div
              class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 gap-2"
            >
              <ButtonApp height="45px" width="100%" :isSecondary="true" @click="resetNoteSurvey">
                <p class="averta-bold">Tidak Jadi Ubah</p>
              </ButtonApp>
              <ButtonApp
                height="45px"
                width="100%"
                :isSecondary="false"
                @click="isHandleOpenModalsClearEditNoteSurvey = false"
              >
                <p class="averta-bold">Lanjut Mengubah Catatan</p>
              </ButtonApp>
            </div>
          </div>
        </div>
      </b-modal>
      <!-- Modals Reset Note Survey -->

      <!-- Modals Confirm Note Survey -->
      <b-modal v-model="isHandleOpenModalsConfirmEditNoteSurvey" :can-cancel="false">
        <div class="modal-content">
          <div class="modal-body">
            <div
              class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-10"
            >
              <AlertConfirmModalsIcon />
              <div
                class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-1 c-w-100"
              >
                <p class="averta-bold label-24" style="text-align: center">Simpan Catatan Baru?</p>
                <p class="averta-regular label-18" style="text-align: center">
                  Pastikan catatan yang diubah sudah benar dan tepat, ya
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div
              class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 gap-2"
            >
              <ButtonApp
                height="45px"
                width="100%"
                :isSecondary="true"
                @click="isHandleOpenModalsConfirmEditNoteSurvey = false"
              >
                <p class="averta-bold">Cek Kembali</p>
              </ButtonApp>
              <ButtonApp height="45px" width="100%" :isSecondary="false" @click="submitNoteSurvey">
                <p class="averta-bold">Simpan</p>
              </ButtonApp>
            </div>
          </div>
        </div>
      </b-modal>
      <!-- Modals Confirm Note Survey -->
    </b-modal>

    <div v-if="isHandleOpenModalsEditNoteSurveyMobile && isMobile" class="modal-wrapper-content-mobile">
      <div
        class="modal-content-mobile"
        :style="{ bottom: isHandleOpenModalsEditNoteSurveyMobile ? '0' : '-100%' }"
      >
        <div class="modal-header-mobile">
          <p class="averta-bold label-24" style="text-align: center">Ubah Catatan</p>
        </div>
        <div class="modal-body-mobile">
          <div
            style="background: #fafafa"
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100 p-4 border-radius-20"
          >
            <textarea
              class="textarea"
              rows="7"
              placeholder="Masukan Catatan"
              v-model="noteSurvey"
            />
          </div>
        </div>
        <div class="modal-footer-mobile">
          <div
            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 gap-2"
          >
            <ButtonApp height="45px" width="100%" :isSecondary="true" @click="isPermission">
              <p class="averta-bold">Batalkan</p>
            </ButtonApp>
            <ButtonApp
              height="45px"
              width="100%"
              :isSecondary="false"
              :isDisabled="!isDisabled"
              @click="isHandleOpenModalsConfirmEditNoteSurveyMobile = true"
            >
              <p class="averta-bold">Simpan</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isHandleOpenModalsClearEditNoteSurveyMobile && isMobile" class="modal-wrapper-content-mobile" style="z-index: 9999 !important">
      <div
        class="modal-content-mobile"
        :style="{ bottom: isHandleOpenModalsClearEditNoteSurveyMobile ? '0' : '-100%' }"
      >
        <div class="modal-body-mobile">
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-10"
          >
            <AlertConfirmModalsIcon />
            <div
              class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-1 c-w-100"
            >
              <p class="averta-bold label-24" style="text-align: center">Batalkan Ubah Catatan?</p>
            </div>
          </div>
        </div>
        <div class="modal-footer-mobile">
          <div
            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 gap-2"
          >
            <ButtonApp height="45px" width="100%" :isSecondary="true" @click="resetNoteSurvey">
              <p class="averta-bold">Tidak Jadi Ubah</p>
            </ButtonApp>
            <ButtonApp
              height="45px"
              width="100%"
              :isSecondary="false"
              @click="isHandleOpenModalsClearEditNoteSurveyMobile = false"
            >
              <p class="averta-bold">Batalkan Catatan</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </div>

    <div class="" v-if="isHandleOpenModalsConfirmEditNoteSurveyMobile && isMobile">
      <div
        class="modal-content-mobile"
        :style="{ bottom: isHandleOpenModalsConfirmEditNoteSurveyMobile ? '0' : '-100%' }"
      >
        <div class="modal-body-mobile">
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-10"
          >
            <AlertConfirmModalsIcon />
            <div
              class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-1 c-w-100"
            >
              <p class="averta-bold label-24" style="text-align: center">Simpan Catatan Baru?</p>
              <p class="averta-regular label-18" style="text-align: center">
                Pastikan catatan yang diubah sudah benar dan tepat, ya
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer-mobile">
          <div
            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 gap-2"
          >
            <ButtonApp
              height="45px"
              width="100%"
              :isSecondary="true"
              @click="isHandleOpenModalsConfirmEditNoteSurveyMobile = false"
            >
              <p class="averta-bold">Cek Kembali</p>
            </ButtonApp>
            <ButtonApp height="45px" width="100%" :isSecondary="false" @click="submitNoteSurvey">
              <p class="averta-bold">Simpan</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AlertConfirmModalsIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
export default {
  name: 'ModalEditNoteSurvey',
  props: {
    isHandleOpenModalsEditNoteSurvey: {
      type: Boolean,
      default: false
    },
    isHandleOpenModalsEditNoteSurveyMobile: {
      type: Boolean,
      default: false
    },
    dataSurvey: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ButtonApp,
    AlertConfirmModalsIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isDisabled () {
      return this.noteSurvey
    },
    isPermission () {
      if (this.noteSurvey === '') {
        return this.closeModal
      } else {
        return this.clearNoteSurvey
      }
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      noteSurvey: '',
      isHandleOpenModalsClearEditNoteSurvey: false,
      isHandleOpenModalsClearEditNoteSurveyMobile: false,
      isHandleOpenModalsConfirmEditNoteSurvey: false,
      isHandleOpenModalsConfirmEditNoteSurveyMobile: false
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModalEditNoteSurvey')
      this.noteSurvey = ''
    },
    async submitNoteSurvey () {
      this.isLoading = true

      try {
        const payload = {
          survey_id: this.dataSurvey.id,
          note: this.noteSurvey
        }

        console.log('payload', payload)

        const res = await this.$store.dispatch('project/postEditNoteSurvey', {
          url: this.url,
          payload
        })

        if (res?.data?.code === 1) {
          await this.$swal({
            icon: 'success',
            title: 'Success',
            text: res.data.message
          })

          this.$buefy.toast.open({
            message: 'Note Berhasil Diubah'
          })

          window.location.reload()

          this.isLoading = false
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text:
              res?.data?.message ||
              'An error occurred while submitting the Survey Berhasil Dibatalkan.'
          })
        }
      } catch (error) {
        console.error(
          'Error submitting Survey Berhasil Dibatalkan:',
          error.response?.data || error.message
        )

        this.$swal({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'An unexpected error occurred. Please try again.'
        })
      } finally {
        this.isLoading = false
      }
    },
    clearNoteSurvey () {
      this.isHandleOpenModalsClearEditNoteSurvey = true
      this.isHandleOpenModalsClearEditNoteSurveyMobile = true
    },
    resetNoteSurvey () {
      this.closeModal()
      this.isHandleOpenModalsClearEditNoteSurvey = false
      this.isHandleOpenModalsClearEditNoteSurveyMobile = false
    }
  }
}
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  width: 65%;
  height: auto;
  min-height: auto;
  max-height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.modal-header,
.modal-header-mobile {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1.5rem 1.5rem 0 1.5rem;
  background: #ffffff;
  border-radius: 10px 10px 0 0;
}
.modal-body,
.modal-body-mobile {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1.5rem 1.5rem;
  background: #ffffff;
}
.modal-footer,
.modal-footer-mobile {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1.5rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #ffffff;
}
.textarea {
  border-radius: 20px;
  resize: none;
}
.modal-wrapper-content-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.modal-content-mobile {
  position: fixed;
  bottom: -100%;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0.5rem;
  background: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 999;
  transition: all 0.5s ease-in-out;
}
</style>
