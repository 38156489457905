<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.26064 15.1678H10.9768C13.2129 15.1678 14.7147 13.5993 14.7147 11.2654V5.87902C14.7147 3.54508 13.2129 1.97656 10.9775 1.97656H5.26064C3.02519 1.97656 1.52344 3.54508 1.52344 5.87902V11.2654C1.52344 13.5993 3.02519 15.1678 5.26064 15.1678ZM5.80981 7.90908C4.90051 7.90908 4.16166 7.16922 4.16166 6.26017C4.16166 5.35111 4.90051 4.61126 5.80981 4.61126C6.71845 4.61126 7.45797 5.35111 7.45797 6.26017C7.45797 7.16922 6.71845 7.90908 5.80981 7.90908ZM13.2774 10.5022C13.4982 11.0685 13.3835 11.7492 13.1474 12.3101C12.8675 12.9771 12.3316 13.4822 11.6564 13.7027C11.3566 13.8007 11.0422 13.8436 10.7285 13.8436H5.1699C4.61675 13.8436 4.12728 13.7109 3.72602 13.4638C3.47465 13.3086 3.43021 12.9506 3.61658 12.7185C3.92831 12.3305 4.23605 11.9411 4.54645 11.5484C5.13806 10.7969 5.53667 10.5791 5.97971 10.7704C6.15945 10.8494 6.33985 10.9678 6.52556 11.093C7.02034 11.4293 7.70812 11.8915 8.61411 11.3898C9.2341 11.0426 9.59369 10.4469 9.90684 9.92825L9.91207 9.91957C9.93426 9.88318 9.95626 9.84679 9.97819 9.8105C10.0834 9.63637 10.1873 9.46457 10.3047 9.30629C10.452 9.10822 10.9978 8.48881 11.7048 8.92988C12.1552 9.2076 12.5339 9.58332 12.9391 9.98559C13.0936 10.1394 13.2037 10.3144 13.2774 10.5022Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImageEmptyIcon',
  props: {
    width: {
      type: String,
      default: '17'
    },
    height: {
      type: String,
      default: 'auto'
    },
    viewBox: {
      type: String,
      default: '0 0 18 21'
    },
    color: {
      type: String,
      default: '#E1E1E1'
    }
  }
}
</script>
