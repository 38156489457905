<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-modal v-model="isHandleOpenModalChangePhotos" :can-cancel="false">
    <div class="content-modal">
      <div class="modal-header">
        <div
          class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
        >
          <div
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
          >
            <p class="averta-bold label-18 color-white">Ganti Foto - Absen Hadir</p>
          </div>
          <div
            @click="closeModal"
            class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div
          style="background: #fce5e6"
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 border-radius-20 p-5 gap-5"
        >
          <div
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
          >
            <NotificationIcon color="#D9272D" width="35" v-if="!isMobile" />
            <NotificationIcon color="#D9272D" v-else width="20" />
          </div>
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
          >
            <p
              class="averta-black label-20 color-red"
              :style="isMobile ? '  font-size: 13px;' : '  font-size: 20px;'"
            >
              PENTING SEBELUM GANTI FOTO!
            </p>
            <p
              class="averta-regular label-16"
              :style="isMobile ? '  font-size: 10px;' : '  font-size: 20px;'"
            >
              Pastikan foto sudah sesuai dan benar, foto lama tidak dapat dikembalikan jika
              digantikan dengan yang baru
            </p>
          </div>
        </div>

        <div
          class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
        >
          <div class="card-image-before-after" v-if="!isMobile">
            <p class="averta-black label-28">Foto Awal</p>
            <figure class="image-before-after">
              <img :src="selectImage.filename || selectImage.picture_url" alt="imageBefore" />
            </figure>
          </div>
          <div
            v-if="!isMobile"
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-4"
          >
            <p class="averta-regular-italic label-20" style="white-space: nowrap">
              Digantikan dengan
            </p>
            <ArrowRightChangeImage />
          </div>
          <div class="card-image-before-after">
            <p class="averta-black label-28">Foto Baru</p>
            <figure class="image-before-after">
              <div class="image-preview-rotate" v-if="newImage">
                <button class="button-image-rotate" @click="rotate('counterclockwise')">
                  <ArrowRotateLeftIcon />
                </button>
                <button class="button-image-rotate" @click="rotate('clockwise')">
                  <ArrowRotateRightIcon />
                </button>
              </div>
              <img
                v-if="newImage"
                :src="newImage"
                alt="imageBefore"
                class="image-transition"
                :style="{ transform: `rotate(${rotation}deg)` }"
              />
              <img v-else src="@/assets/img/emptyImage.svg" alt="imageBefore" />
            </figure>
          </div>
        </div>
      </div>
      <div class="modal-footer gap-5">
        <ButtonApp height="45px" width="100%" :isSecondary="true" @click="triggerFileInput">
          <p class="averta-bold">Buka Galeri</p>
        </ButtonApp>
        <ButtonApp
          height="45px"
          width="100%"
          :isSecondary="false"
          :isDisabled="!isDisabled"
          @click="submitEditPhotos"
        >
          <p class="averta-bold">Ganti Foto</p>
        </ButtonApp>
      </div>
    </div>
    <input
      type="file"
      ref="fileInput"
      accept="image/*"
      style="display: none"
      @change="handleFileChange"
    />
    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  XMarkIcon,
  NotificationIcon,
  ArrowRightChangeImage,
  ArrowRotateLeftIcon,
  ArrowRotateRightIcon,
  SpinnerIcon
} from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'ModalChangePhotoSurvey',
  components: {
    XMarkIcon,
    NotificationIcon,
    ArrowRightChangeImage,
    ArrowRotateLeftIcon,
    ArrowRotateRightIcon,
    ButtonApp,
    SpinnerIcon
  },
  props: {
    isHandleOpenModalChangePhotos: {
      type: Boolean,
      default: false
    },
    selectImage: {
      type: Object,
      default: () => {}
    },
    dataSurvey: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isDisabled () {
      return this.newImage
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      rotation: 0,
      newImage: '',
      isLoading: false,
      isFullPage: true
    }
  },
  methods: {
    rotate (direction) {
      if (direction === 'clockwise') {
        this.rotation = (this.rotation + 90) % 360
      } else {
        this.rotation = (this.rotation - 90 + 360) % 360
      }
      console.log(`Current Rotation: ${this.rotation}°`)
      this.updateImageMetadata()
    },
    updateImageMetadata () {
      if (!this.newImage) return

      const img = new Image()
      img.src = this.newImage
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (this.rotation % 180 !== 0) {
          canvas.width = img.height
          canvas.height = img.width
        } else {
          canvas.width = img.width
          canvas.height = img.height
        }

        ctx.setTransform(1, 0, 0, 1, 0, 0)
        ctx.translate(canvas.width / 2, canvas.height / 2)
        ctx.rotate((this.rotation * Math.PI) / 180)
        ctx.drawImage(img, -img.width / 2, -img.height / 2)

        canvas.toBlob((blob) => {
          this.selectedFile = new File([blob], 'edited_image.jpg', { type: 'image/jpeg' })
        }, 'image/jpeg')
      }
    },
    triggerFileInput () {
      this.$refs.fileInput.click()
    },
    handleFileChange (event) {
      const file = event.target.files[0]
      if (file) {
        this.newImage = URL.createObjectURL(file)
        this.newImageNoEdit = file
        this.rotation = 0
      }
    },
    closeModal () {
      this.$emit('closeModalChangePhotosSurvey')
      this.newImage = null
      this.selectedFile = null
    },
    async submitEditPhotos () {
      this.isLoading = true

      try {
        const formData = new FormData()

        if (this.selectImage.fromDetail === true) {
          formData.append('photo_survey', this.selectedFile || this.newImageNoEdit)
          formData.append('survey_id', this.dataSurvey.id)
          formData.append('image_id', this.selectImage.file_id)
          formData.append('project_id', this.dataSurvey.survey_homecare_id)
          formData.append('worker_id', this.dataSurvey.detail.worker_id)
          formData.append('source', this.selectImage.source)
        } else {
          formData.append('photo_survey', this.selectedFile || this.newImageNoEdit)
          formData.append('image_id', this.selectImage && this.selectImage.id)
        }

        const res = await (this.selectImage.fromDetail === true
          ? this.$store.dispatch('project/postEditPhotos', {
            url: this.url,
            payload: formData
          })
          : this.$store.dispatch('projectV2/postEditPhotos', {
            url: this.url,
            payload: formData
          }))

        if (res?.data?.code === 1) {
          await this.$swal({
            icon: 'success',
            title: 'Success',
            text: res.data.message
          })

          this.$buefy.toast.open({
            message: 'Photo Anda Berhasil Terupdate'
          })

          if (this.selectImage.fromStepOffering === false) {
            window.location.reload()
          } else {
            window.location.reload()
          }
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: res?.data?.message || 'An error occurred while submitting the Edit Photos.'
          })
        }
      } catch (error) {
        console.error('Error submitting Edit Photos:', error.response?.data || error.message)
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'An unexpected error occurred. Please try again.'
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    isOpen (newVal) {
      if (newVal) {
        this.rotation = 0
        this.newImage = ''
      }
    },
    selectImage (newVal) {
      console.log('selectImage', newVal.id)
    }
  }
}
</script>

<style scoped>
.content-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  min-height: auto;
  max-height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  background: transparent;
}
.modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 1.5rem;
  background: #8b080d;
  border-radius: 10px 10px 0 0;
}
.modal-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 2.5rem 1.5rem;
  gap: 1.5rem;
  background: #ffffff;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 1.5rem;
  background: #ffffff;
  box-shadow: 0px -3px 30px 0px #00000012;
  border-radius: 0 0 10px 10px;
}
.card-image-before-after {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  gap: 1.5rem;
  background: transparent;
}
.image-before-after {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: 300px;
  min-height: 300px;
  max-height: 300px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  gap: 1.5rem;
  background: transparent;
  border-radius: 20px;
  overflow: hidden;
}
.image-before-after > img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}
.image-preview-rotate {
  position: absolute;
  top: 10px;
  display: flex;
  flex-direction: row;
  width: 90%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  gap: 10px;
  background: transparent;
  border-radius: 20px;
  overflow: hidden;
}
.button-image-rotate {
  width: 40px;
  height: 40px;
  background: rgb(255, 255, 255, 0.5);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 99999;
}
.image-transition {
  transition: transform 0.5s ease-in-out;
  transform: rotate(0deg);
  transform-origin: center center;
}

@media screen and (max-width: 768px) {
  .content-modal {
    width: 90%;
    min-width: 90%;
    max-width: 90%;
  }
  .modal-body {
    height: 500px !important;
    min-height: 500px !important;
    max-height: 500px !important;
    overflow: auto;
  }
}
</style>
