<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-sidebar
    type="is-light"
    :fullheight="fullheight"
    :fullwidth="fullwidth"
    :overlay="overlay"
    :right="right"
    :can-cancel="false"
    v-model="isHandleOpenSidebarDetailImageRating"
  >
    <div
      class="is-flex is-flex-direction-column is-align-items-center is-justify-content-flex-start c-w-100 c-h-100 gap-4"
    >
      <!-- Header -->
      <div
        style="border: 2px solid #e2e2e2"
        class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 py-5 px-5 gap-6"
      >
        <div
          @click="closeSidebar"
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center is-clickable"
        >
          <ArrowLeftIcon color="#000000" />
        </div>
        <div
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3"
        >
          <div class="avatar-users">
            <img :src="selectedWorker && selectedWorker.image" alt="avatar" />
          </div>
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
          >
            <p class="averta-bold black-color label-18 m-0">
              {{ selectedWorker && selectedWorker.name }}
            </p>
            <p class="averta-reguler black-color label-18 m-0">
              {{ selectedWorker && selectedWorker.id }}
            </p>
          </div>
        </div>
      </div>
      <!-- Header -->

      <!-- Body -->
      <div
        style="flex: 1"
        class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-center c-w-100 py-3 px-5"
      >
        <!-- Card Wrapper -->
        <div
          style="background: #fafafa"
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start c-w-100 py-4 px-5 border-radius-5 gap-2"
        >
          <p class="averta-bold black-color label-15 m-0">Item Pekerjaan</p>
          <p class="averta-reguler black-color label-15 m-0">
            {{ selectedWorker && selectedWorker.workItemName }}
          </p>
        </div>
        <!-- Card Wrapper -->

        <!-- Show Image Banner Before After -->
        <div
          class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 c-h-100 gap-10"
        >
          <!-- Photo Before -->
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-2"
          >
            <p class="averta-bold black-color label-15 m-0">Foto Before</p>
            <div class="card-image-before-After">
              <img
                :src="
                  selectedWorker &&
                  selectedWorker.photos &&
                  selectedWorker.photos[selectedPhotoIndex] &&
                  selectedWorker.photos[selectedPhotoIndex].before.image
                "
                alt="Before Image"
                v-if="
                  selectedWorker &&
                  selectedWorker.photos &&
                  selectedWorker.photos[selectedPhotoIndex] &&
                  selectedWorker.photos[selectedPhotoIndex].before.image
                "
              />
              <!-- <img v-else src="@/assets/img/placeholder.png" alt="Placeholder Image" /> -->
              <img
                class="zoom-preview"
                src="@/assets/img/zoom-preview.png"
                alt=""
                @click="
                  handleOpenModalsImagePriview(
                    selectedWorker.photos[selectedPhotoIndex] &&
                      selectedWorker.photos[selectedPhotoIndex].before.image
                  )
                "
              />
            </div>
          </div>
          <!-- Photo Before -->

          <div style="max-height: 500px !important" class="divider-vertical-line-1"></div>

          <!-- Photo After -->
          <div
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-2"
          >
            <p class="averta-bold black-color label-15 m-0">Foto After</p>
            <div class="card-image-before-After">
              <img
                :src="
                  selectedWorker &&
                  selectedWorker.photos &&
                  selectedWorker.photos[selectedPhotoIndex] &&
                  selectedWorker.photos[selectedPhotoIndex].after.image
                "
                alt="After Image"
                v-if="
                  selectedWorker &&
                  selectedWorker.photos &&
                  selectedWorker.photos[selectedPhotoIndex] &&
                  selectedWorker.photos[selectedPhotoIndex].after.image
                "
              />
              <!-- <img v-else src="@/assets/img/placeholder.png" alt="Placeholder Image" /> -->
              <img
                class="zoom-preview"
                src="@/assets/img/zoom-preview.png"
                alt=""
                @click="
                  handleOpenModalsImagePriview(
                    selectedWorker.photos[selectedPhotoIndex] &&
                      selectedWorker.photos[selectedPhotoIndex].after.image
                  )
                "
              />
            </div>
          </div>
          <!-- Photo After -->
        </div>
        <!-- Show Image Banner Before After -->

        <!-- Show Card Image Before After -->
        <div
          class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 gap-3"
        >
          <div
            v-for="(photoPair, index) in selectedWorker && selectedWorker.photos"
            :key="index"
            :class="{ 'selected-card': selectedPhotoIndex === index }"
            class="image-card-before-after is-clickable"
            @click="handleImageClick(index)"
          >
            <div class="image-card-before">
              <img :src="photoPair && photoPair.before.image" alt="Before Image Thumbnail" />
            </div>
            <div class="image-card-after">
              <img :src="photoPair && photoPair.after.image" alt="After Image Thumbnail" />
            </div>
          </div>
        </div>
        <!-- Show Card Image Before After -->
      </div>
      <!-- Body -->
    </div>

    <modalsViewZoomImage
      :photoDetail="selectedPhotoDetail"
      :isHandleOpenModalsImagePriview.sync="isHandleOpenModalsImagePriview"
    />
  </b-sidebar>
</template>

<script>
import modalsViewZoomImage from './modalsViewZoomImage.vue'
import { ArrowLeftIcon } from '@/components/icons'

export default {
  name: 'SidebarDetailImageRating',
  props: {
    isHandleOpenSidebarDetailImageRating: {
      type: Boolean,
      default: false
    },
    selectedWorker: {
      type: Object,
      default: () => ({})
    },
    selectedWorkerPhotos: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ArrowLeftIcon,
    modalsViewZoomImage
  },
  created () {},
  data () {
    return {
      overlay: true,
      fullheight: true,
      fullwidth: true,
      right: true,
      selectedPhotoIndex: 0,
      selectedPhotoIndexDetail: 0,
      isHandleOpenModalsImagePriview: false,
      selectedPhotoDetail: null,
      photoPair: []
    }
  },
  methods: {
    handleOpenModalsImagePriview (photoDetail) {
      if (!photoDetail) {
        console.error('Photo detail is empty or invalid:', photoDetail)
        return
      }
      this.selectedPhotoDetail = photoDetail
      this.isHandleOpenModalsImagePriview = true
    },
    closeSidebar () {
      this.$emit('closeSidebar')
    },
    handleImageClick (index) {
      this.selectedPhotoIndex = index
    }
  }
}
</script>

<style scoped>
.avatar-users {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  mex-height: 50px;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-users > img {
  object-fit: cover;
  width: 100%;
  height: auto;
}
.card-image-before-After {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 55vh;
  max-width: 45vw;
  margin: 0;
  padding: 0;
  background: transparent;
}
.card-image-before-After > img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.zoom-preview {
  position: absolute;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
}
.image-card-before-after {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  background: #ffffff;
  overflow: hidden !important;
}
.image-card-before {
  position: absolute;
  top: 0;
  left: 0;
  width: 48.5%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #868686;
  justify-content: center;
  align-items: center;
}
.image-card-after {
  position: absolute;
  top: 0;
  right: 0;
  width: 48.5%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #868686;
  justify-content: center;
  align-items: center;
}
.image-card-before > img,
.image-card-after > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.selected-card {
  border: 2px solid #e10009;
  box-shadow: 0 0 10px rgb(225, 0, 9, 0.5);
}
</style>
