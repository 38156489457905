<template>
  <div v-if="isLoading" class="loading-wrapper">
    <SpinnerIcon />
  </div>
  <div v-else-if="dataRecapProject.length < 1" class="columns is-centered">
    <div class="column has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">Rekap Hasil Proyek tidak ditemukan</p>
    </div>
  </div>
  <div v-else>
    <div class="body-wrapper mb-4" v-for="(itemWorker, indexWorker) in dataRecapProject" :key="`${indexWorker}_worker`">
      <div class="header-detail p-5 flex-sm-column align-items-sm-start">
        <div class="is-flex mb-sm-1 cursor-pointer"
          @click="itemWorker && itemWorker.certificates && itemWorker.certificates.length > 0 ?
          handleOpenModalsPreviewSertifikat(itemWorker.worker_id, itemWorker) : '-' ">
          <div class="tukang-img mr-3">
            <img v-if="itemWorker.worker_image && itemWorker.worker_image !== ''" :src="itemWorker.worker_image" alt="worker_img" class="is-clickable" @click="openModalPreviewImage({ singleImage: itemWorker.worker_image })" />
            <img v-else :src="require('@/assets/img/default-picture.jpg')" alt="default_pic" />
          </div>
          <div>
            <div class="is-flex is-flex-direction-row is-justify-content-center is-align-item-center">
              <p class="averta-bold">{{ itemWorker.worker_name }}</p>
              <div class="badge-new-comment ml-3 gap-1" v-if="itemWorker && itemWorker.certificates && itemWorker.certificates.length > 0">
                <SertifikatBadgeIcon />
                <p class="m-0 label-10 averta-bold-italic color-green" style="white-space: nowrap">
                  Bersertifikat
                </p>
              </div>
            </div>

            <p class="label-cs">ID{{ itemWorker.worker_id }}</p>
          </div>
        </div>
        <div class="is-flex">
          <div class="jenis-tukang-img mr-3">
            <img :src="itemWorker.skill_image || require('@/assets/img/default-picture.jpg')" alt="default_pic" />
          </div>
          <div>
            <p class="label-cs">Jenis Tukang</p>
            <p class="averta-bold">{{ itemWorker.skill_name }}</p>
          </div>
        </div>
      </div>
      <div class="p-4">
        <p class="averta-bold mb-4">Rekap Hasil Proyek</p>
        <div v-for="(itemWorking, indexWorking) in itemWorker.item_working" :key="`${indexWorking}_note_item_working`" class="detail-wrapper mb-4">
          <div class="foto-wrapper">
            <div class="align-self-sm-start">
              <p>Detail Pekerjaan</p>
              <p class="averta-bold mb-2">{{ itemWorking.note_item_working }}</p>
              <div v-if="itemWorking.pictures && itemWorking.pictures.length > 0" class="is-flex">
                <div v-for="(itemPicture, indexPicture) in itemWorking.pictures" :key="indexPicture" @click="openModalPreviewImage({ multipleImage: itemWorking.pictures, index: indexPicture })" class="wrapper-preview-image cursor-pointer is-relative mr-3">
                  <img :src="itemPicture" alt="" />
                </div>
              </div>
            </div>
            <div class="is-flex w-sm-100 mt-sm-2 overflow-sm-auto">
              <div class="is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-flex-end c-w-15 gap-4">
                <div v-if="itemWorking.photo_afters.length > 0 || itemWorking.photo_befores.length > 0" class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 gap-1">
                  <p class="averta-reguler label-12 black-color">Sebelum | Sesudah</p>
                  <div class="divider-vertical-line-1" style="min-height: 1.5rem"></div>
                  <p @click="handleShowSidebar({worker: itemWorker, dataNoteItemWorking: itemWorking})"
                    class="averta-bold label-12 color-red cursor-pointer">
                    Lihat Foto
                  </p>
                </div>

                <!-- Foto Content -->
                <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 gap-4"
                  v-if="itemWorking.photo_afters.length > 0 || itemWorking.photo_befores.length > 0">
                  <div v-for="(photoSet, index) in combinedPhotos(itemWorking)" :key="index" class="image-card-before-after">
                    <!-- Before Image -->
                    <div class="image-card-before">
                      <img
                        :src="photoSet.before ? photoSet.before.image_url : require('@/assets/img/empty-before-after.png')"
                        alt="Before Image"
                        :class="photoSet.before && photoSet.before.image_url ? 'cursor-pointer' : ''"
                        @click="
                        photoSet && photoSet.before && photoSet.before.image_url ?
                        openModalPreviewImage({ singleImage: photoSet.before ? photoSet.before.image_url : require('@/assets/img/empty-before-after.png') }) : '-' "
                      />
                    </div>

                    <!-- After Image -->
                    <div class="image-card-after">
                      <img
                        :src="photoSet.after ? photoSet.after.image_url : require('@/assets/img/empty-before-after.png')"
                        alt="After Image"
                        :class="photoSet.after && photoSet.after.image_url ? 'cursor-pointer' : ''"
                        @click="
                        photoSet && photoSet.after && photoSet.after.image_url ?
                        openModalPreviewImage({ singleImage: photoSet.after ? photoSet.after.image_url : require('@/assets/img/empty-before-after.png') }) : '-' "
                      />
                    </div>
                  </div>
                </div>
                <!-- Foto Content -->

                <!-- Validation Empty Image -->
                <div
                  v-else
                  style="background: #f9fcff"
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 p-4 border-radius-10 gap-2"
                >
                  <ImageEmptyIcon width="26" color="#276ACA" />
                  <p
                    style="white-space: nowrap !important"
                    class="averta-reguler-italic label-18 gray-color m-0"
                  >
                    Foto belum ada
                  </p>
                </div>
                <!-- Validation Empty Image -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalPreviewImage
      :isActive="modalPreviewImageActive"
      :dataImage="imagePreviewCarousel"
      :urlOneImage="urlImagePreview"
      :indexImg="indexModalPreview"
      @closeModal="closeModalPreviewImage"
    />

    <SidebarBeforeAfter
      :showSidebar="sidebarBeforeAfter"
      :data="selectedDataWorker"
      type="detail"
      @closeSidebar="handleShowSidebar"
    />

    <ModalsPreviewSertifikat
      :isHandleOpenModalsPreviewSertifikat="isHandleOpenModalsPreviewSertifikat"
      :selectWorkers="selectWorkers"
      @closeModalPreviewSertifikat="closeModalPreviewSertifikat"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import { ProyekEmptyIcon, SpinnerIcon, SertifikatBadgeIcon, ImageEmptyIcon } from '@/components/icons'
import { alertErrorMessage } from '@/helpers'
import SidebarBeforeAfter from '@/components/SidebarBeforeAfter.vue'
import ModalsPreviewSertifikat from '@/components/modal/ModalsPreviewSertifikat.vue'

export default {
  name: 'TabRekapHasilProyek',
  components: {
    ProyekEmptyIcon,
    SpinnerIcon,
    ModalPreviewImage,
    SidebarBeforeAfter,
    SertifikatBadgeIcon,
    ModalsPreviewSertifikat,
    ImageEmptyIcon
  },
  data () {
    return {
      isLoading: false,
      imagePreviewCarousel: [],
      modalPreviewImageActive: false,
      dataRecapProject: [],
      sidebarBeforeAfter: false,
      selectedDataWorker: null,
      urlImagePreview: null,
      indexModalPreview: 0,

      // Preview Sertifikat
      isHandleOpenModalsPreviewSertifikat: false,
      selectWorkers: {},
      photo_befores: [], // Harus diinisialisasi sebagai array kosong
      photo_afters: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    combinedPhotos () {
      return (itemWorking) => {
        const beforePhotos = itemWorking.photo_befores || []
        const afterPhotos = itemWorking.photo_afters || []
        const maxLength = Math.max(beforePhotos.length, afterPhotos.length)

        return Array.from({ length: maxLength }, (_, index) => ({
          before: beforePhotos[index] || null,
          after: afterPhotos[index] || null
        }))
      }
    }
  },
  methods: {
    closeModalPreviewImage () {
      this.imagePreviewCarousel = null
      this.modalPreviewImageActive = false
    },
    openModalPreviewImage (props = {}) {
      const { multipleImage, singleImage } = props

      this.modalPreviewImageActive = true

      if (singleImage) {
        this.urlImagePreview = singleImage
        this.imagePreviewCarousel = []
      } else {
        this.imagePreviewCarousel = multipleImage
        this.urlImagePreview = null
        this.indexModalPreview = props.index || 0
      }
    },
    getRecapProject () {
      this.isLoading = true
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        path_name: 'recap-attendance'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          this.dataRecapProject = response.data.data
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    // Sidebar before-after
    handleShowSidebar (props = {}) {
      const { worker, dataNoteItemWorking } = props
      this.sidebarBeforeAfter = !this.sidebarBeforeAfter
      if (worker) {
        this.selectedDataWorker = {
          ...worker,
          note_item: dataNoteItemWorking.note_item_working,
          before: dataNoteItemWorking.photo_befores,
          after: dataNoteItemWorking.photo_afters
        }
      } else {
        this.selectedDataWorker = null
      }
    },
    handleOpenModalsPreviewSertifikat (workersId, workers, item) {
      this.selectWorkers = { workersId, ...workers, ...item }
      this.isHandleOpenModalsPreviewSertifikat = true
    },
    closeModalPreviewSertifikat () {
      this.isHandleOpenModalsPreviewSertifikat = false
    }
  },
  created () {
    this.getRecapProject()
  }
}
</script>

<style scoped>
.body-wrapper {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  overflow: hidden;
}
.header-detail {
  background: linear-gradient(90deg, #ffdddd 0%, #ffefdb 99.97%);
  border-radius: 12px 12px 0px 0px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.label-cs {
  font-size: 14px;
  color: #868686;
}
.flex-1 {
  flex: 1;
}
.jenis-tukang-img {
  width: 40px !important;
  height: 40px !important;
}
.jenis-tukang-img,
.before-after-img,
.tukang-img {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.before-after-img,
.tukang-img {
  width: 35px !important;
  height: 35px !important;
  border-radius: 35px;
}
.before-after-img {
  border-radius: 5px !important;
}
.jenis-tukang-img img,
.before-after-img img {
  width: 100%;
  height: 100%;
}
.foto-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-wrapper {
  background: #fafafa;
  border-radius: 20px;
  padding: 20px;
}
.text-red {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.before-after-wrapper {
  min-width: 10em;
}
.modal-wrapper {
  overflow-x: hidden;
}
.header-modal {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}
.body-modal {
  background: white;
  border-radius: 0px 0px 12px 12px;
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.before-after {
  width: 380px;
  height: 380px;
  border-radius: 24px;
}
@media only screen and (max-width: 600px) {
  .foto-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .flex-sm-column {
    flex-direction: column;
  }

  .align-self-sm-start {
    align-self: flex-start;
  }

  .w-sm-100 {
    width: 100%;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mt-sm-2 {
    margin-top: 1rem;
  }

  .header-detail {
    padding: 1rem !important;
  }
}
.badge-new-comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: auto;
  max-width: auto;
  height: auto;
  margin: 0;
  padding: 0.25rem 0.5rem;
  background: #ECFAF0;
  border-radius: 1000px;
}

.image-card-before-after {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  background: #ffffff;
  overflow: hidden !important;
}
.image-card-before {
  position: absolute;
  top: 0;
  left: 0;
  width: 48.5%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #868686;
  justify-content: center;
  align-items: center;
}
.image-card-after {
  position: absolute;
  top: 0;
  right: 0;
  width: 48.5%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #868686;
  justify-content: center;
  align-items: center;
}
.image-card-before > img,
.image-card-after > img,
.avatar-users-comment > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
