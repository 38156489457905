<template>
  <div v-if="dataProps && dataProps.length > 0" :class="isDetailOrder ? '' : 'wrap'">
    <p class="averta-bold label-20 mb-3">Berkas Lampiran</p>

    <div v-if="dataProps && dataProps.length > 0" class="card-lampiran-wrapper">
      <div v-for="(item, index) in dataProps" :key="index" class="card-lampiran mb-3">
        <div class="is-flex is-align-items-center is-justify-content-space-between">
          <div class="is-flex is-align-items-center">
            <PaperIcon class="mr-2" />
            <div>
              <p class="label-14 averta-bold" :class="isDetailOrder ? 'title-lampiran-detail-order' : 'title-lampiran'">
                {{ item.name }}
              </p>
              <p class="label-12 text-grey">upload pada : {{ item.updated_at }}</p>
            </div>
          </div>
          <div class="wrap-dropdown">
            <div class="dot is-clickable" ref="threeDotsSIK" @click="showDropdown(index)"></div>
            <div
              class="dropdown-sik"
              v-if="dropdownID === index"
            >
              <!-- v-closable="{
                exclude: ['threeDotsSIK'],
                handler: 'showDropdown'
              }" -->
              <!-- Lihat Lampiran -->
              <div class="is-flex is-align-items-center is-justify-content-space-between is-clickable"
                @click="previewAttachment(item.filename)">
                <div class="is-flex is-align-items-center">
                  <div class="wrap-icon is-flex is-align-items-center is-justify-content-center mr-3">
                    <EyeIcon />
                  </div>
                  <p class="unduh label-14 averta-bold">Lihat Lampiran</p>
                </div>
                <div class="is-flex is-align-items-center">
                  <ArrowFoward color="#868686" />
                </div>
              </div>

              <div class="divider"></div>

              <!-- Download Lampiran -->
              <div class="is-flex is-align-items-center is-justify-content-space-between is-clickable"
                @click="download(item.filename, item.name)">
                <div class="is-flex is-align-items-center">
                  <div class="wrap-icon is-flex is-align-items-center is-justify-content-center mr-3">
                    <DownloadIcon />
                  </div>
                  <p class="unduh label-14 averta-bold" target="_blank">Unduh Lampiran</p>
                </div>
                <div class="is-flex is-align-items-center">
                  <ArrowFoward color="#868686" />
                </div>
              </div>

              <div v-if="!isSMRC" class="divider"></div>

              <!-- Ubah Lampiran -->
              <div
                v-if="!isSMRC"
                class="is-flex is-align-items-center is-justify-content-space-between is-clickable option-wrapper"
                @click="editAttachment(item)"
              >
                <div class="is-flex is-align-items-center">
                  <div
                    class="wrap-icon is-flex is-align-items-center is-justify-content-center mr-3"
                  >
                    <EditIcon />
                  </div>
                  <p class="unduh label-14 averta-bold" target="_blank">
                    Ubah Lampiran
                  </p>
                </div>
                <div class="is-flex is-align-items-center">
                  <ArrowFoward color="#868686" />
                </div>
              </div>

              <div class="divider" v-if="dataHistoryAttachment && dataHistoryAttachment.length > 0"></div>

              <!-- Lihat Riwayat Perubahan -->
              <div
                class="is-flex is-align-items-center is-justify-content-space-between is-clickable option-wrapper"
                @click="showHistoryAttachment"
                v-if="dataHistoryAttachment && dataHistoryAttachment.length > 0"
              >
                <div class="is-flex is-align-items-center">
                  <div
                    class="wrap-icon is-flex is-align-items-center is-justify-content-center mr-3"
                  >
                    <HistoryLineIcon />
                  </div>
                  <p class="unduh label-14 averta-bold white-space-nowrap" target="_blank">
                    Lihat Riwayat Perubahan
                  </p>
                </div>
                <div class="is-flex is-align-items-center">
                  <ArrowFoward color="#868686" />
                </div>
              </div>
              <!-- Lihat Riwayat Perubahan -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Preview Image -->
    <ModalPreviewImage :isActive="modalPreviewImageActive" :dataImage="imagePreview" :title="titlePreviewImage"
      @closeModal="closeModalPreviewImage()" />

    <!-- ModalEditAttachment -->
    <ModalEditAttachment
      :type="dataEdit.type"
      :data="dataEdit"
      :surveyId="idSurvey"
      :showModal="showModalEdit"
      @closeModal="closeModalEdit"
      :refreshData="refreshData"
    />

    <!-- Modal History -->
    <ModalHistoryAttachment
      :showModalHistory="showModalHistory"
      :dataHistory="dataHistoryAttachment"
      @closeModal="closeModalHistory"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  PaperIcon,
  EyeIcon,
  ArrowFoward,
  DownloadIcon,
  EditIcon,
  HistoryLineIcon
} from '@/components/icons'
import { alertErrorMessage } from '@/helpers'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import ModalEditAttachment from '@/components/modal/ModalEditAttachment'
import ModalHistoryAttachment from '@/components/modal/ModalHistoryAttachment.vue'

export default {
  name: 'BerkasLampiran',
  props: {
    isDetailOrder: {
      type: Boolean,
      default: false
    },
    dataProps: {
      type: Array,
      default: () => []
    },
    idSurvey: {
      type: Number,
      default: null
    },
    isSMRC: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    PaperIcon,
    EyeIcon,
    ArrowFoward,
    DownloadIcon,
    ModalPreviewImage,
    EditIcon,
    HistoryLineIcon,
    ModalEditAttachment,
    ModalHistoryAttachment
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      dropdownID: null,
      modalPreviewImageActive: false,
      imagePreview: null,
      titlePreviewImage: null,
      dataEdit: {
        id: null,
        filename: '',
        type: '',
        created_at: '',
        updated_at: '',
        name: ''
      },
      dataHistoryAttachment: [],
      showModalEdit: false,
      showModalHistory: false
    }
  },
  methods: {
    showDropdown (id) {
      this.dropdownID = this.dropdownID || this.dropdownID === 0 ? false : id
    },
    previewAttachment (filename) {
      this.imagePreview = [filename]
      this.titlePreviewImage = 'Berkas Lampiran'
      this.modalPreviewImageActive = true
      this.dropdownID = null
    },
    closeModalPreviewImage () {
      this.modalPreviewImageActive = false
    },
    download (url, name) {
      const sliceExtension = url.slice(url.lastIndexOf('/') + 1)
      let finalExtension = sliceExtension.split('.')[1]

      if (finalExtension.split('_').length > 1) {
        finalExtension = '.' + finalExtension.split('_')[0]
      }

      if (finalExtension.split('?').length > 1) {
        finalExtension = '.' + finalExtension.split('?')[0]
      }

      fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
          const linkURL = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = linkURL

          a.download = `${name}${finalExtension}`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => alert('Maaf ada masalah, silahkan coba lagi'))

      this.dropdownID = null
    },
    editAttachment (item) {
      this.dropdownID = null
      this.dataEdit = item
      this.showModalEdit = true
    },
    closeModalEdit () {
      this.showModalEdit = false
      this.dataEdit = {
        id: null,
        filename: '',
        type: '',
        created_at: '',
        updated_at: '',
        name: ''
      }
    },
    showHistoryAttachment () {
      this.dropdownID = null
      this.showModalHistory = true
    },
    closeModalHistory () {
      this.showModalHistory = false
    },
    getHistoryAttachment () {
      const payload = {
        url: this.url,
        survey_id: this.idSurvey
      }

      this.$store
        .dispatch('project/getHistoryAttachment', payload)
        .then(res => {
          this.dataHistoryAttachment = res.data.data
        })
        .catch(error => {
          alertErrorMessage(error)
        })
    },
    refreshData () {
      this.$emit('refreshData')
    }
  },
  mounted () {
    this.getHistoryAttachment()
  }
}
</script>

<style scoped>
.wrap {
  border: 1px solid #D8D8D8;
  padding: 18px;
  border-radius: 12px;
  margin-bottom: 14px;
}

.card-lampiran {
  background: #FAFAFA;
  border: none;
  padding: 12px;
  border-radius: 20px;
}

.title-lampiran {
  white-space: nowrap;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-lampiran-detail-order {
  white-space: nowrap;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dot::after {
  content: '\2807';
  font-size: 20px;
  color: #868686;
}

.wrap-dropdown {
  position: relative;
}

.dropdown-sik {
  position: absolute;
  right: 0px;
  top: 30px;
  width: 245px;
  background: white;
  padding: 10px 15px;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  z-index: 2;
}

.dropdown-spk {
  position: absolute;
  right: -20px;
  top: 30px;
  width: 300px;
  background: white;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  z-index: 2;
}

.wrap-icon {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #F5F5F5;
}

.divider {
  border-top: 1px solid #E1E1E1;
  margin: 14px 0px;
}

.unduh {
  color: black !important;
}

.max-height-attachment {
  max-height: 16.5em;
  overflow-y: auto;
}

.card-lampiran-wrapper {
  max-height: 14em;
  overflow: auto;
}
</style>
