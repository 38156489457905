<template>
  <div>
    <div v-if="isSMRC" class="columns">
      <!-- <div class="column card col-1 p-3 m-2" v-for="(store, index) in getListDaftarStoreData" :key="index">
        <img :src="require(`@/assets/img/${store.image}`)"  alt="wave" class="wave" />
          <p class="is-size-5 averta-bold">{{ store.total }}</p>
          <p class="is-size-4 averta-black my-2">{{ store.listStore }}</p>
      </div> -->
    </div>
    <div v-if="listStore.list.length > 0" class="columns is-flex is-flex-wrap-wrap">
      <div class="column is-3" v-for="(store, index) in listStore.list" :key="index">
        <div class="card border-radius-20 overflow-hidden border-1-grey-2 p-4">
          <img v-if="listStore.branch.logo" :src="listStore.branch.logo" class="logo" />
          <div class="mb-4 my-3 store-title-wrapper">
            <div class="ellipsis-overflow store-name-wrapper">
              <p class="averta-bold">{{ store.name }}</p>
            </div>
            <div class="ellipsis-overflow address-wrapper">
              <p class="color-grey">{{ store.address }}</p>
            </div>
          </div>
          <!-- Default -->
           <div class="is-flex is-justify-content-space-around wrapper-vendor p-3">
            <div class="is-flex is-align-items-center is-justify-content-center">
              <div
                v-for="(pic, indexImg) in store.pic_image.slice(0, 4)"
                :key="`img-${indexImg}`"
                class="pic_image img-shadow-1"
              >
                <img v-if="pic.image_url && pic.image_url !== '-'" :src="pic.image_url" alt="" />
                <img v-else :src="require('@/assets/img/default-picture.jpg')" />
              </div>
            </div>
            <div>
              <p class="has-text-centered">Jumlah PIC</p>
              <p class="averta-bold color-red">{{ store.total_pic }}</p>
            </div>
          </div>
          <!-- Default -->

          <!-- SMRCN -->
            <!-- <div  v-if="listStore.vendor.code === 'SMRCN'" class="is-flex c-w-100 is-flex-direction-column is-justify-content-center wrapper-vendor p-3 is-align-items-center">
             <div class="is-flex c-w-100 is-flex-direction-row-reverse is-justify-content-space-between is-align-items-center px-2" >
                <div class="is-flex is-align-items-center is-justify-content-flex-end">
                  <div v-for="(pic, indexImg) in store.pic_image.slice(0, 4)" :key="`img-${indexImg}`" class="pic_image img-shadow-1" >
                    <img v-if="pic.image_url && pic.image_url !== '-'" :src="pic.image_url" alt="" />
                    <img v-else :src="require('@/assets/img/default-picture.jpg')" />
                  </div>
                </div>
                <div class="is-flex-direction-column is-align-items-flex-start">
                  <p class="has-text-centered">Jumlah PIC</p>
                  <p class="averta-bold color-red">{{ store.total_pic }}</p>
                </div>
             </div>

             <hr class="dashed" />

             <div class="is-flex c-w-100 is-flex-direction-row is-justify-content-space-between is-align-items-center">
                <div style="gap: 0.5rem" class="is-flex is-align-items-center is-justify-content-flex-end">
                <SaldoCoinIcon size="35" />
                <p class="has-text-centered averta-bold">Saldo</p>
                </div>
                <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-start">
                  <p class="has-text-centered averta-bold label-16">Rp</p>
                  <p class="has-text-centered averta-bold label-16">0</p>
                </div>
             </div>
          </div> -->
          <!-- SMRCN -->

          <div class="mt-5 is-flex">
            <ButtonApp
              width="100%"
              class="mr-3"
              :isSecondary="true"
              @click="editStore(store)"
            >
              <p class="averta-bold py-2">Edit</p>
            </ButtonApp>
            <ButtonApp
              width="100%"
              @click="detailStore(store.id)"
            >
              <p class="averta-bold py-2">Lihat Detail</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="column c-w-100 has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Data Tidak Ditemukan
      </p>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { encryptData, isSMRC } from '@/helpers'
import { ProyekEmptyIcon, SaldoCoinIcon } from '@/components/icons'
import { ListDaftarStoreData } from '@/data/views/liststoreData'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'TabListStore',
  props: {
    listStore: {
      type: Object
    },
    edit: {
      type: Function
    }
  },
  data () {
    return {
      ListDaftarStoreData
    }
  },
  components: {
    ProyekEmptyIcon,
    ButtonApp,
    // eslint-disable-next-line vue/no-unused-components
    SaldoCoinIcon
  },
  methods: {
    detailStore (storeId) {
      // const encrypt = encryptData(this.$route.params.branchId)
      this.$router.push({ path: `/am/vendors/${storeId}/store-detail`, query: { source: this.$route.params.branchId } })
    },
    editStore (data) {
      this.$emit('edit', data)
    }
  },
  computed: {
    getListDaftarStoreData () {
      return ListDaftarStoreData(this.listStore)
    },
    isSMRC () {
      return isSMRC(this.listStore.vendor.code)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.col-1 {
  background: linear-gradient(90deg, #ED4949 0%, #A40707 100%);
  border-radius: 20px;
  color: white;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.wrapper-vendor {
  background: #F7F7F7;
  border-radius: 8px;
}

.border-right {
  border-right: 2px #EAEAEA solid;
}

.btn-detail {
  border-radius: 20px;
  color: $color-red-1;
  background: white;
  border: 1px solid #EB4600;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: 'Averta-Bold';
  font-size: 16px;
  width: 100%;
}

.logo {
  object-fit: contain;
  height: 27px;
  max-width: 43%;
}

.pic_image {
  width: 40px;
  height: 40px;
  margin: 0px -7px;
  border-radius: 100px;
  overflow: hidden;
}

.pic_image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ellipsis-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.address-wrapper {
  -webkit-line-clamp: 2;
}

.store-name-wrapper {
  -webkit-line-clamp: 1;
}

.store-title-wrapper {
  height: 60px;
}

.dashed{
  width: 100%;
  height: 0;
  max-height: 0;
  border: 1px dashed #E1E1E1;
}

</style>
