<template>
  <button
    :style="buttonStyle"
    :class="[isBoldFont ? 'averta-bold' : 'averta-reguler']"
    @click="handleClick"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonApp',
  data () {
    return {
      isHovered: false
    }
  },
  props: {
    width: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: 'auto'
    },
    isBold: {
      type: Boolean,
      default: false
    },
    isSecondary: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
    isBorderNone: {
      type: Boolean,
      default: false
    },
    isBorderWhite: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonStyle () {
      return {
        width: this.width,
        height: this.height,
        cursor: this.isDisabled ? 'no-drop' : 'pointer',
        background: this.isTransparent ? 'transparent' : this.isSecondary ? '#fff' : (this.isDisabled ? '#9a9a9a' : '#E10009'),
        border: this.isBorderNone ? 'none' : this.isBorderWhite ? '2px solid #fff' : this.isSecondary ? (this.isDisabled ? '1px solid #9a9a9a' : '1px solid #E10009') : 'none',
        color: this.isSecondary ? (this.isDisabled ? '#9a9a9a' : '#E10009') : '#fff',
        transition: '0.2s all ease-in-out'
      }
    },
    isBoldFont () {
      return this.isBold
    },
    isHoveredEnabled () {
      return this.hoverEnabled
    }
  },
  methods: {
    handleClick () {
      if (!this.isDisabled) {
        this.$emit('click')
      }
    },
    handleMouseEnter () {
      this.isHovered = true
    },
    handleMouseLeave () {
      this.isHovered = false
    }
  },
  mounted () {},
  created () {}
}
</script>

<style lang="css" scoped>
button {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: calc(0.4em - 1px);
  padding-top: calc(0.4em - 1px);
  border-radius: 100px;
  border: 1px solid #E10009;
  text-align: center;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.026rem;
  cursor: pointer;
  transition: all 0.5s ease;
}
</style>
