/* eslint-disable no-unused-vars */
import axios from 'axios'

const state = () => ({
  projectList: [],
  comment: null
})

const getters = {
  projectList: (state) => state.projectList,
  getDataComment: (state) => state.comment
}

const actions = {
  getProjectList ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let concatUrl = ''

      if (payload.start_date) {
        concatUrl += `?start_date=${payload.start_date}`
      }

      if (payload.end_date) {
        concatUrl += concatUrl ? `&end_date=${payload.end_date}` : `?end_date=${payload.end_date}`
      }

      if (payload.search) {
        concatUrl += concatUrl ? `&search=${payload.search}` : `?search=${payload.search}`
      }

      axios
        .get(`${payload.url}${process.env.VUE_APP_RATING}/project-list${concatUrl}`)
        .then((response) => {
          commit('SET_PROJECT_LIST', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  getProjectListById ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_RATING}/project/${payload.project_id}`)
        .then(response => {
          commit('SET_PROJECT_LIST', response.data.data)
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  getRatingTags ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_RATING_TAGS}/rating-tags`)
        .then(response => {
          commit('SET_PROJECT_LIST', response.data.data)
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  postRating ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_RATING_TAGS}`, {
        project_id: payload.payload.project_id,
        worker_id: payload.payload.worker_id,
        work_item_id: payload.payload.work_item_id,
        skill_id: payload.payload.skill_id,
        date: payload.payload.date,
        rating: payload.payload.rating,
        tags: payload.payload.tags,
        description: payload.payload.description
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  postComplaint ({ commit }, { url, payload }) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      axios.post(`${url}${process.env.VUE_APP_RATING_TAGS}/complaint`, payload, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  postComment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_RATING_TAGS}/comment`, {
        project_id: payload.payload.project_id,
        worker_id: payload.payload.worker_id,
        work_item_id: payload.payload.work_item_id,
        skill_id: payload.payload.skill_id,
        date: payload.payload.date,
        comment: payload.payload.comment
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  }
}

const mutations = {
  SET_PROJECT_LIST (state, data) {
    state.projectList = data
  },
  setDataComment (state, data) {
    state.comment = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
