<template>
  <div class="min-h-80vh c-w-90 m-auto">
    <!-- HEADER -->
    <div class="is-sticky bg-white py-2 z-index-5 header-progress" :class="{'border-bottom-1': isLittleScroll}" :style="{ top: navbarPosition, marginBottom: '-4px' }">
      <HeaderProgress :loadAPI="getReportProgress" :isLittleScrollProps="isLittleScroll" />
    </div>

    <!-- RINGKASAN -->
    <div class="mt-2">
      <SummaryProgress :data="dataSummary" :isLoading="isLoading" />
    </div>

    <div v-if="isLoading" class="loading-wrapper">
      <SpinnerIcon />
    </div>
    <div v-else-if="!isLoading && dataProgress.length > 0" class="is-relative">
      <div ref="scrollTableContainer" class="table-container border-radius-top-10 mb-4" @scroll="handleScrollTableContainer">
        <div
          :style="{ width: `${(widthColumn * 18) + widthColumnNav}px`}"
          class="is-relative"
        >

          <!-- NAVBAR SECTION -->
          <div class="sticky-top z-index-4">
            <NavbarTableProgress
              :heightColumnNavProps="heightColumnNav"
              :widthColumnNavProps="widthColumnNav"
              :widthColumnProps="widthColumn"
            />
          </div>

          <div
            v-for="(itemWeek, indexWeek) in dataProgress"
            :key="`item-week-${indexWeek}`"
            class="weekly-wrap"
          >
            <!-- WEEKLY SECTION -->
            <AccordionWeeklyProgress
              :itemWeekProps="itemWeek"
              :indexWeekProps="indexWeek"
              :heightColumnDailyProps="heightColumnDaily"
              :heightColumnNavProps="heightColumnNav"
              :widthWeekAccordionProps="widthWeekAccordion"
              :indexAccordionWeekProps="indexAccordionWeek"
              :handleAccordionProps="handleAccordion"
            />

            <!-- SHOW TOTAL MINGGUAN ON CLOSE ACCORDION -->
            <div v-if="indexAccordionWeek.includes(indexWeek)">
              <TotalWeeklyColumnProgress
                :showHeader="true"
                :totalWeekProps="itemWeek.total_week"
                :heightColumnWeeklyProps="heightColumnWeekly"
                :widthColumnProps="widthColumn"
                :widthColumnNavProps="widthColumnNav"
              />
            </div>

            <div v-if="itemWeek.data.length > 0" class="per-section" :class="{'close': indexAccordionWeek.includes(indexWeek)}">
              <div v-for="(itemDaily, indexDaily) in itemWeek.data" :key="`item-daily-${indexDaily}`" class="is-flex">
                <!-- LEFT SECTION -->
                <div class="sticky-left z-index-2">
                  <div
                    :id="`columnDate${indexWeek}${indexDaily}`"
                    :style="{
                      width: `${widthColumnNav}px`,
                      height: `${itemDaily.project.length < 1 ? heightColumn : heightColumn * itemDaily.project.length}px`
                    }"
                    class="column-date border-right-5 averta-bold"
                    :class="{
                      'center': isOverlapBody(`columnDate${indexWeek}${indexDaily}`, `columnDateChild${indexWeek}${indexDaily}`),
                      'today': itemDaily.today
                    }"
                  >
                    <div :id="`columnDateChild${indexWeek}${indexDaily}`">
                      <p class="has-text-centered label-14">{{formatDate(itemDaily.date, 'dddd')}}</p>
                      <p class="has-text-centered label-14">{{formatDate(itemDaily.date, 'DD MMM YYYY')}}</p>
                      <div v-if="itemDaily.today" class="border-radius-100 flex-center pl-2 pr-3 py-1 mt-2 bg-light-blue-2">
                        <div class="bg-blue border-radius-100 ellipse mr-1"></div>
                        <p class="averta-bold color-blue-1 has-text-centered label-14">Hari Ini</p>
                      </div>
                    </div>
                  </div>

                  <!-- TOTAL DIALY KESELURUHAN -->
                  <div
                    :style="{ width: `${widthColumnNav}px`, height: `${heightColumnDaily}px` }"
                    class="daily-column side flex-center border-right-5"
                  >
                    <p class="averta-bold label-14">Total Harian : {{countValueInsideArray(itemDaily.total_day)}}</p>
                  </div>

                  <!-- TOTAL WEEKLY SIDE -->
                  <div v-if="indexDaily + 1 === itemWeek.data.length">
                    <TotalWeeklyColumnProgress
                      :showHeader="true"
                      :showColumn="false"
                      :totalWeekProps="itemWeek.total_week"
                      :heightColumnWeeklyProps="heightColumnWeekly"
                      :widthColumnNavProps="widthColumnNav"
                    />
                  </div>

                </div>
                <div>
                  <div v-if="itemDaily.project.length > 0">
                    <div
                      v-for="(itemStore, indexStore) in itemDaily.project"
                      :key="`item-store-${indexStore}`"
                      class="is-flex"
                      :class="{'bg-grey-11': isEmptyRow(itemStore.list)}"
                    >
                      <!-- DATA COLUMN -->
                      <div v-if="itemStore.list.length > 0" class="is-flex">
                        <div
                          v-for="(itemColumn, indexColumn) in itemStore.list"
                          :key="`item-column-${indexColumn}`"
                          :style="{ width: `${widthColumn}px`, height: `${heightColumn}px` }"
                          class="column-status px-2 pb-2 pt-1 flex-center-vertical"
                          :class="{
                            'border-right-5': indexColumn === 1,
                            'bg-grey-3': indexColumn === 8,
                            'bg-pink-2': itemColumn && itemColumn.background === 'danger',
                            'bg-light-orange-1': itemColumn && itemColumn.background === 'warning',
                          }"
                        >
                          <div v-if="itemColumn && Object.keys(itemColumn).length > 0" class="c-w-100 c-h-100 is-relative">
                            <div
                              class="c-w-100 c-h-100 overflow-auto is-flex is-flex-direction-column py-3"
                              :class="{
                                'is-justify-content-center': [
                                    itemColumn.start_due_date,
                                    itemColumn.status_late,
                                    itemColumn.late
                                  ].filter(field => field).length < 2
                              }"
                            >
                              <div
                                class="flex-center-vertical is-justify-content-space-between c-w-100"
                                :class="{
                                  'm-auto': [
                                    itemColumn.start_due_date,
                                    itemColumn.status_late,
                                    itemColumn.late
                                  ].filter(field => field).length >= 2,
                                }"
                              >
                                <div class="is-flex c-w-90">
                                  <p class="color-gray label-14 mr-1">ID{{ itemStore.survey_id }}</p>
                                  <div class="ellipsis-content">
                                    <p class="averta-bold label-14">| {{ itemStore.store_name }}</p>
                                  </div>
                                </div>
                                <div
                                  class="is-clickable"
                                  @click="handleTooltip({
                                    groupIndex: `${indexWeek}${indexDaily}${indexStore}${indexColumn}`,
                                    surveyId: itemStore.survey_id,
                                    status: itemColumn.status_name
                                  })">
                                  <div v-if="itemColumn.background === 'danger'">
                                    <AlertIcon size="18" color="#E10009"/>
                                  </div>
                                  <div v-else>
                                    <img :src="require('@/assets/img/info-blue.png')" style="height: 19px" alt="">
                                  </div>
                                </div>
                              </div>

                              <!-- JATUH TEMPO -->
                              <div
                                v-if="itemColumn.start_due_date"
                                class="flex-center-vertical border-radius-100 py-1 px-2 mt-1 bg-grey-11 flex-shrink-0"
                              >
                                <p class="label-11">Jatuh Tempo : {{formatDate(itemColumn.start_due_date, 'DD/MM/YY')}} - {{formatDate(itemColumn.end_due_date, 'DD/MM/YY')}}</p>
                              </div>

                              <!-- PENAWARAN DITOLAK -->
                              <div
                                v-if="itemColumn.status_late === 'Penawaran Ditolak'"
                                class="is-flex"
                              >
                                <div class="border-radius-100 py-1 px-2 mt-1 bg-red-3 flex-shrink-0  is-inline-block">
                                  <p class="label-11 color-white">Penawaran Ditolak</p>
                                </div>
                              </div>

                              <!-- TELAT HARI KE-N -->
                              <div
                                v-if="itemColumn.late"
                                class="flex-center-vertical border-radius-100 py-1 px-2 mt-1 color-red flex-shrink-0"
                                :class="{'bg-pink-2': !itemColumn.background, 'bg-white': itemColumn.background === 'danger'}"
                              >
                                <p class="label-11">Telat {{ itemColumn.late }} Hari</p>
                                <p class="ml-1 averta-bold label-11">({{formatDate(itemColumn.date_late, 'DD/MM/YYYY')}})</p>
                              </div>

                              <!-- EXTEND PROYEK -->
                              <div
                                v-if="itemColumn.extend"
                                class="flex-center-vertical border-radius-100 py-1 px-2 mt-1 color-red flex-shrink-0 bg-white"
                              >
                                <InfoOutlineIcon />
                                <p class="label-11">Extend Proyek : {{ itemColumn.extend }} Hari</p>
                              </div>
                            </div>

                            <!-- TOOLTIP -->
                            <div
                              v-if="`${indexWeek}${indexDaily}${indexStore}${indexColumn}` === selectedTooltip"
                              v-click-outside="closeTooltip"
                              class="tooltip-selected-wrapper"
                              :style="{
                                right: indexColumn === 0 ? '-9em' : '1em',
                                top: (indexWeek === dataProgress.length && indexDaily === itemWeek.data.length - 1 && indexStore === itemDaily.project.length) ? '-13em' : indexColumn === 0 ? '3em' : '1.7em'
                              }"
                            >
                              <AdditionalHeaderTooltipProgress
                                v-if="dataTooltip"
                                :indexColumnProps="indexColumn"
                                :dataTooltipFlagingProps="dataTooltip.flaging"
                                :handleConfirmTooltipProps="handleConfirmTooltip"
                                :itemStoreProps="itemStore"
                                :itemColumnProps="itemColumn"
                              />

                              <!-- HEADER -->
                              <div class="header-tooltip">
                                <div v-if="!isLoadingTooltip && dataTooltip" class="flex-center-vertical is-justify-content-space-between c-w-100">
                                  <div>
                                    <p class="averta-bold">{{dataTooltip.store_name}}</p>
                                    <p class="color-grey averta-reguler-italic label-14">ID{{dataTooltip.survey_id}}</p>
                                  </div>
                                  <div class="status-survey px-2" :class="getClassName(dataTooltip.status_name)">
                                    <p class="label-12 has-text-centered">{{titleStatus(dataTooltip.status_name)}}</p>
                                  </div>
                                </div>
                                <div v-else class="c-w-100">
                                  <b-skeleton width="50%" :animated="true"></b-skeleton>
                                  <b-skeleton width="30%" :animated="true"></b-skeleton>
                                </div>
                              </div>

                              <!-- BODY -->
                              <div v-if="!isLoadingTooltip && dataTooltip" class="body-tooltip">
                                <div class="is-flex is-justify-content-space-between">

                                  <!-- CATATAN PROYEK -->
                                  <div class="flex-1 note-tooltip" :class="{'width': dataTooltip.history_late.length > 0}">
                                    <div class="flex-center-vertical is-justify-content-space-between">
                                      <p class="averta-bold mb-1">Catatan Proyek</p>
                                      <p v-if="dataTooltip.date_note" class="color-grey label-10">~ {{formatDate(dataTooltip.date_note, 'DD/MM/YY')}}</p>
                                    </div>
                                    <p class="color-grey label-14 line-height-15">{{dataTooltip.note || '-'}}</p>
                                  </div>

                                  <!-- CATATAN KETERLAMBATAN -->
                                  <div v-if="dataTooltip.history_late.length > 0" class="flex-1">
                                    <div class="note-tooltip ml-3 width">
                                      <p class="averta-bold mb-1">Catatan Keterlambatan</p>

                                      <div class="list-late-wrapper">
                                        <div
                                          v-for="item, index in dataTooltip.history_late"
                                          :key="`late-${index}`"
                                          class="mb-1"
                                          :class="{'mb-1': index + 1 !== dataTooltip.history_late.length}"
                                        >
                                          <p class="averta-black label-14 line-height-15">
                                            • {{titleStatus(item.status_name)}}
                                          </p>
                                          <p class="color-gray label-12 ml-2">
                                            {{formatDate(item.date_late, 'DD/MM/YYYY')}} • Terlambat {{item.late}} Hari
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="mt-3 flex-center-vertical">
                                  <div @click="closeTooltip" class="cancel-tooltip flex-center is-clickable">
                                    <XMarkIcon size="10" color="#E10009" />
                                  </div>
                                  <div class="status-survey status-survey-red flex-1 ml-3 button-detail-tooltip is-clickable" @click="handleNavigateDetail(itemStore)">
                                    <p class="label-14">{{ dataTooltip.flaging.type === 'rejected' || dataTooltip.flaging.type === 'can-reject' ? 'Lihat Detail & Revisi Penawaran' : 'Lihat Detail' }}</p>
                                  </div>
                                </div>
                              </div>

                              <div v-else class="c-w-100 px-4 pt-3">
                                <b-skeleton width="100%" height="100px" :animated="true"></b-skeleton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- EMPTY COLUMN (18) -->
                      <div v-else>
                        <EmptyColumnProgress
                          :heightColumnProps="heightColumn"
                          :heightColumnDailyProps="heightColumnDaily"
                          :widthColumnProps="widthColumn"
                          :hideDaily="true"
                        />
                      </div>
                    </div>

                    <!-- TOTAL DIALY -->
                    <div>
                      <div class="flex-center">
                        <div
                          v-for="daily, index in itemDaily.total_day"
                          :key="`daily-${index}`"
                          :style="{ width: `${widthColumn}px`, height: `${heightColumnDaily}px` }"
                          :class="{'border-right-5': index === 1, 'bg-grey-2': index === 8}"
                          class="daily-column flex-center"
                        >
                          <!-- index === 8 | Penawaran Ditolak (dataNavbar) -->
                          <p class="averta-bold label-14">{{daily}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- EMPTY COLUMN (18) -->
                  <div v-else>
                    <EmptyColumnProgress
                      :heightColumnProps="heightColumn"
                      :heightColumnDailyProps="heightColumnDaily"
                      :widthColumnProps="widthColumn"
                    />
                  </div>

                  <!-- TOTAL WEEKLY COLUMN -->
                  <div v-if="indexDaily + 1 === itemWeek.data.length">
                    <TotalWeeklyColumnProgress
                      :totalWeekProps="itemWeek.total_week"
                      :heightColumnWeeklyProps="heightColumnWeekly"
                      :widthColumnProps="widthColumn"
                      :widthColumnNavProps="widthColumnNav"
                    />
                  </div>

                </div>
              </div>
            </div>
            <div v-else-if="!indexAccordionWeek.includes(indexWeek)">
              <TotalWeeklyColumnProgress
                :showHeader="true"
                :totalWeekProps="itemWeek.total_week"
                :heightColumnWeeklyProps="heightColumnWeekly"
                :widthColumnProps="widthColumn"
                :widthColumnNavProps="widthColumnNav"
              />
            </div>
          </div>

        </div>
      </div>

      <!-- Scroll Ke Hari Ini -->
      <div v-if="showScrollToday && !isLoading && dataProgress.length > 0" class="scroll-today flex-center border-radius-100 px-3 py-2 bg-red color-white is-clickable tf-scale-09" @click="handleScrollToday">
        <p class="averta-bold">{{showBackToTop ? 'Kembali ke Atas' : 'Ke hari ini' }}</p>
        <div class="icon-today-wrapper" :class="{'reverse': showBackToTop}">
          <img :src="require('@/assets/img/arrow-upward.png')" height="15" alt="" >
        </div>
      </div>
    </div>

    <!-- EMPTY -->
    <div v-else>
      <h1>Empty</h1>
    </div>

    <!-- Modal Tagihan -->
    <b-modal v-model="isModalInvoice">
      <div class="flex-center">
        <div class="modal-tagihan-wrapper overflow-auto">
          <img :src="require('@/assets/img/forgot-password.png')" alt="">
          <p class="label-16 averta-bold mb-1 mt-5">Tagihan Sudah Terbayar</p>
          <p class="has-text-centered mb-5">Periksa dan pastikan seluruh informasi pembayaran telah benar dan <br>sesuai</p>

          <div v-if="isLoadingInvoice" class="c-w-100">
            <b-skeleton width="100%" height="100px" :animated="true"></b-skeleton>
          </div>

          <div v-else-if="!isLoadingInvoice && dataInvoice" class="form-modal-invoice bg-lightgray">
            <div class="border-bottom-dashed pb-4 mb-3">
              <p class="averta-bold label-14 mb-2">Pilih Tanggal Pembayaran</p>
              <b-datepicker v-model="selectedDatePaymentInvoice" :mobile-native="false" :min-date="minDatePaymentInvoice">
                <template v-slot:trigger>
                  <div
                    class="input-search-1 bg-white is-clickable px-3 flex-center-vertical"
                  >
                    <ScheduleIcon width="15" height="15" />
                    <p class="ml-2 label-14 averta-bold">{{selectedDatePaymentInvoiceString}}</p>
                  </div>
                </template>
              </b-datepicker>
            </div>

            <div>
              <p class="averta-bold label-14 mb-2">Informasi Tagihan</p>
              <div class="border-radius-12 overflow-hidden bg-white flex-center-vertical py-3">
                <div class="px-4 flex-1 border-right-2">
                  <p class="color-grey label-14 mb-1">ID/No. Invoice</p>
                  <p class="averta-bold label-14">ID{{tempDataProject.survey_id}}/{{dataInvoice.invoice}}</p>
                </div>
                <div class="px-4 flex-1">
                  <p class="color-grey label-14">Grand Total Tagihan</p>
                  <p class="color-orange-2 averta-bold">{{formatIdr(dataInvoice.price.grand_total)}}</p>
                </div>
              </div>
            </div>
          </div>

          <div v-if="isLoadingInvoice" class="c-w-100 mt-3">
            <b-skeleton width="100%" height="40px" :animated="true"></b-skeleton>
          </div>

          <div v-else-if="!isLoadingInvoice && dataInvoice" class="flex-center c-w-100 mt-3">
            <ButtonApp :isSecondary="true" class="flex-1 mr-2" @click="handleCloseModalInvoice">
              <p class="py-2 averta-bold">Kembali</p>
            </ButtonApp>
            <ButtonApp class="flex-1" @click="onClickFinishPayment">
              <p class="py-2 averta-bold">Sudah Terbayar</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Helpers
import { mapGetters } from 'vuex'
import moment from 'moment'
import {
  formatDateHelper,
  formatCurrency,
  getStatusClassName,
  alertErrorMessage,
  compareBetweenDates,
  titleReportProgress
} from '@/helpers'

// Assets
import {
  SpinnerIcon,
  AlertIcon,
  ScheduleIcon,
  XMarkIcon,
  InfoOutlineIcon
} from '@/components/icons'
import confirmImage from '@/assets/img/delete-area-component.png'

// Components
import ButtonApp from '@/components/button/ButtonApp.vue'
import SummaryProgress from './components/SummaryProgress.vue'
import HeaderProgress from './components/HeaderProgress.vue'
import NavbarTableProgress from './components/NavbarTableProgress.vue'
import AccordionWeeklyProgress from './components/AccordionWeeklyProgress.vue'
import TotalWeeklyColumnProgress from './components/TotalWeeklyColumnProgress.vue'
import EmptyColumnProgress from './components/EmptyColumnProgress.vue'
import AdditionalHeaderTooltipProgress from './components/AdditionalHeaderTooltipProgress.vue'

export default {
  name: 'ReportProgress',
  components: {
    // Icons
    SpinnerIcon,
    AlertIcon,
    ScheduleIcon,
    XMarkIcon,
    InfoOutlineIcon,

    // Components
    ButtonApp,
    SummaryProgress,
    HeaderProgress,
    NavbarTableProgress,
    AccordionWeeklyProgress,
    TotalWeeklyColumnProgress,
    EmptyColumnProgress,
    AdditionalHeaderTooltipProgress
  },
  data () {
    return {
      widthColumn: 250,
      widthColumnNav: 153,
      widthWeekAccordion: 0,
      heightColumn: 90,
      heightColumnNav: 47,
      heightColumnDaily: 45,
      heightColumnWeekly: 65,
      elementPointToday: '',
      showScrollToday: false,
      showBackToTop: false,
      navbarPosition: 0,
      isLittleScroll: false,

      isLoading: false,
      isLoadingTooltip: false,
      isLoadingInvoice: false,
      dataSummary: null,
      dataProgress: [],

      // filter
      storeName: null,
      selectedDate: null,

      // for Tooltip/Information API purposes
      fromDate: null,
      toDate: null,
      dataTooltip: null,
      selectedTooltip: null,

      // Invoice
      dataInvoice: null,
      isModalInvoice: false,
      selectedDatePaymentInvoice: null,
      tempDataProject: null,
      minDatePaymentInvoice: null,

      indexAccordionWeek: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      heightNavbar: 'dashboard/getNavbarHeight'
    }),
    selectedDatePaymentInvoiceString () {
      return this.selectedDatePaymentInvoice ? moment(this.selectedDatePaymentInvoice).format('DD/MM/YYYY') : ''
    }
  },
  methods: {
    titleStatus (status) {
      return titleReportProgress(status)
    },
    adjustStickyPosition () {
      setTimeout(() => {
        if (this.heightNavbar) {
          this.navbarPosition = this.heightNavbar + 'px'
        }
      }, 500)
    },
    formatDate (date, format) {
      if (!date) return ''
      return formatDateHelper({ date, format })
    },
    formatIdr (value) {
      if (!value) return '-'
      return formatCurrency(value)
    },
    formatMonth (props = {}) {
      const { start, end } = props
      if (!start && !end) return '-'
      const formatStart = moment(start).format('MMMM YYYY')
      const formatEnd = moment(end).format('MMMM YYYY')

      if (formatStart === formatEnd) return formatStart

      return `${formatStart} - ${formatEnd}`
    },
    formatTitleSummary (title) {
      if (!title) return '-'
      const checkString = ['SM', 'BS', 'SPK', 'SIK']

      let result = title.replace(/-/g, ' ')

      checkString.forEach(str => {
        const pattern = new RegExp(str.toLowerCase(), 'g')
        result = result.replace(pattern, str)
      })

      result = result.replace(/\b\w/g, function (char) {
        return char.toUpperCase()
      })

      return result
    },
    formatTitleFlaging (type, isButton) {
      switch (type) {
        case 'rejected':
          return 'Penawaran Ditolak'
        case 'payment-success':
          return 'Tagihan Ditandai Sudah Dibayar'
        case 'can-reject':
          return isButton ? 'Penawaran Ditolak' : 'Penawaran Telah Ditolak?'
        case 'can-paid':
          return isButton ? 'Konfirmasi Terbayar' : 'Tagihan Sudah Dibayarkan?'

        default:
          return type
      }
    },
    countValueInsideArray (arrayDaily) {
      if (!arrayDaily || arrayDaily.length < 1) {
        return 0
      }

      let total = 0
      for (let i = 0; i < arrayDaily.length; i++) {
        total += arrayDaily[i]
      }

      return total
    },
    isEmptyRow (data) {
      return data.every(obj => !obj ? true : Object.keys(obj).length === 0)
    },
    isOverlapBody (idElement, idElementChild) {
      setTimeout(() => {
        const element = document.querySelector(`#${idElement}`)
        const elementChild = document.querySelector(`#${idElementChild}`)
        const tableContainer = document.querySelector('.table-container')

        if (element && elementChild && tableContainer) {
          const countHeight = tableContainer.clientHeight - this.heightColumnNav - this.heightColumnDaily
          if (element.clientHeight >= countHeight) {
            elementChild.style.marginTop = '30px'
            elementChild.style.marginBottom = '30px'
            elementChild.style.position = 'sticky'
            elementChild.style.top = `${(countHeight + 100) / 2}px`

            return true
          }
          element.style.justifyContent = 'center'
        }
        return false
      }, 100)
    },
    getClassName (status) {
      return getStatusClassName(status)
    },
    getReportProgress (props = {}) {
      // keep data store & date
      const { store = this.storeName, date = this.selectedDate } = props
      this.isLoading = true
      this.indexAccordionWeek = [] // open all acordion

      this.storeName = store
      this.selectedDate = date

      const payload = {
        url: this.url
      }

      if (this.storeName) {
        payload.storeName = this.storeName
      }
      if (this.selectedDate) {
        payload.startDate = this.selectedDate.start
        payload.endDate = this.selectedDate.end
      }
      // payload.startDate = '2024-07-29'
      // payload.endDate = '2024-07-31'

      this.$store
        .dispatch('report/getListReportProgress', payload)
        .then(response => {
          const res = response.data.data
          this.dataSummary = res.summary
          this.dataProgress = res.table
          this.isLoading = false

          this.fromDate = res.summary.from_date
          this.toDate = res.summary.to_date

          this.showScrollToday = compareBetweenDates({ start: this.fromDate, end: this.toDate })

          setTimeout(() => {
            const tableContainer = document.querySelector('.table-container')
            if (tableContainer) {
              this.widthWeekAccordion = tableContainer.clientWidth - 50
            }
          }, 100)
        })
        .catch(err => {
          this.isLoading = false
          alertErrorMessage(err)
        })
    },
    getTooltipInformation (props = {}) {
      const { surveyId, status } = props
      this.isLoadingTooltip = true
      this.dataTooltip = null

      const payload = {
        url: this.url,
        surveyId,
        status,
        startDate: this.fromDate,
        endDate: this.toDate
      }

      this.$store
        .dispatch('report/getTooltipInformation', payload)
        .then(res => {
          this.isLoadingTooltip = false
          this.dataTooltip = res.data.data
        })
        .catch(err => {
          this.isLoadingTooltip = false
          alertErrorMessage(err)
        })
    },
    handleNavigateDetail (data) {
      let routeData

      if (data.project_id) {
        routeData = this.$router.resolve({ path: `/am/project/${data.project_id}/detail` })
      } else {
        routeData = this.$router.resolve({ path: `/am/project/survey/${data.survey_id}/detail` })
      }

      window.open(routeData.href, '_blank')
    },
    handleAccordion (index) {
      const findArray = this.indexAccordionWeek.includes(index)
      if (findArray) {
        const findIndex = this.indexAccordionWeek.indexOf(index)
        this.indexAccordionWeek.splice(findIndex, 1)
      } else {
        this.indexAccordionWeek.push(index)
      }
    },
    handleTooltip (props = {}) {
      const { groupIndex, surveyId, status } = props

      if (this.selectedTooltip === groupIndex) {
        this.selectedTooltip = null
      } else {
        this.selectedTooltip = groupIndex

        if (!this.isLoadingTooltip) {
          this.getTooltipInformation({ surveyId, status })
        }
      }
    },
    closeTooltip () {
      this.selectedTooltip = null
    },
    handleConfirmTooltip (type, dataProject, dataColumn) {
      const minDate = new Date(dataColumn.start_due_date)

      switch (type) {
        case 'can-reject':
          this.handleConfirmReject(dataProject)
          break
        case 'can-paid':
          this.tempDataProject = dataProject
          minDate.setDate(minDate.getDate() - 1)
          this.minDatePaymentInvoice = minDate
          this.selectedDatePaymentInvoice = new Date(dataColumn.start_due_date)

          setTimeout(() => {
            this.handleConfirmInvoice()
          }, 50)
          break
        default:
          return type
      }
    },
    handleConfirmReject (data) {
      this.$swal({
        imageUrl: confirmImage,
        width: '400px',
        padding: '10px 10px 20px',
        title: '<p class="averta-bold label-18">Konfirmasi Penawaran Ditolak</p>',
        html: '<p class="averta-regular">Pastikan seluruh informasi penawaran telah<br/>sesuai</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Penawaran Ditolak',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component flex-1',
          cancelButton: 'btn-batal-detail-component flex-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.closeTooltip()

          const payload = {
            url: this.url,
            surveyId: data.survey_id
          }
          this.$store
            .dispatch('report/postRejectConfirm', payload)
            .then(() => {
              this.getReportProgress()

              this.$buefy.toast.open({
                message: `<p class="averta-regular"><span class="is-italic">ID${data.survey_id}</span> ${data.store_name}<span class="averta-bold ml-4">Penawaran Ditolak</span></p>`,
                type: 'is-black',
                duration: 3500
              })
            }).catch((err) => {
              alertErrorMessage(err)
            })
        }
      })
    },
    handleConfirmInvoice () {
      this.isLoadingInvoice = true
      this.isModalInvoice = !this.isModalInvoice

      this.$store
        .dispatch('project/getDataInvoice', { url: this.url, project_id: this.tempDataProject.project_id })
        .then(res => {
          this.dataInvoice = res.data.data
          this.isLoadingInvoice = false
        })
        .catch(err => {
          this.isLoadingInvoice = false
          alertErrorMessage(err)
        })
    },
    handleCloseModalInvoice () {
      this.isModalInvoice = false
    },
    handleResetStateInvoice () {
      this.dataInvoice = null
      this.selectedDatePaymentInvoice = null
      this.tempDataProject = null
      this.minDate = null
    },
    onSelectDatePaymentInvoice () {
      this.isOpenDatePaymentInvoice = !this.isOpenDatePaymentInvoice
    },
    onClickFinishPayment () {
      if (!this.selectedDatePaymentInvoice) {
        return alertErrorMessage('Mohon Pilih Tanggal Pembayaran')
      }
      this.$swal({
        imageUrl: confirmImage,
        width: '400px',
        padding: '10px 10px 20px',
        title: '<p class="averta-bold label-18">Konfirmasi Tagihan Sudah Dibayar</p>',
        html: '<p class="averta-regular">Pastikan seluruh informasi tagihan <br/>dan pembayaran telah sesuai</p>',
        showCancelButton: true,
        confirmButtonText: 'Ya, Konfirmasi',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component flex-1',
          cancelButton: 'btn-batal-detail-component flex-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            project_id: this.tempDataProject.project_id,
            paid_date: moment(this.selectedDatePaymentInvoice).format('YYYY-MM-DD')
          }
          this.$store
            .dispatch('project/putConfirmPayment', payload)
            .then(() => {
              this.getReportProgress()

              this.$buefy.toast.open({
                message: `<p class="averta-regular"><span class="is-italic">ID${this.tempDataProject.survey_id}</span> ${this.tempDataProject.store_name}<span class="averta-bold ml-4">Ditandai Sudah dibayar</span></p>`,
                type: 'is-black',
                duration: 3500
              })
              this.handleCloseModalInvoice()
              this.handleResetStateInvoice()
            }).catch((err) => {
              alertErrorMessage(err)
              this.handleCloseModalInvoice()
              this.handleResetStateInvoice()
            })
        }
      })
    },
    handleScroll () {
      const scrollY = window.scrollY || window.pageYOffset

      if (!this.isLoading) {
        if (scrollY > 20) {
          this.isLittleScroll = true
        } else {
          this.isLittleScroll = false
        }
      }
    },
    handleScrollToday () {
      this.indexAccordionWeek = []
      setTimeout(() => {
        if (this.showBackToTop) {
          const tableContainer = document.querySelector('.table-container')

          if (tableContainer) {
            tableContainer.scrollTo({ top: 0, behavior: 'smooth' })
          }
        } else {
          const todayContainer = document.querySelector('.today')

          if (todayContainer) {
            todayContainer.scrollIntoView({
              behavior: 'smooth'
            })
          }
        }
      }, 100)
    },
    handleScrollTableContainer () {
      // Get the reference to the scrollable container
      const container = this.$refs.scrollTableContainer

      // Get the reference to the specific list item with the class 'item4'
      const todayElement = this.$refs.scrollTableContainer.querySelector('.today')

      // Check if the fourth list item is not visible
      if (todayElement) {
        const containerRect = container.getBoundingClientRect()
        const listItemRect = todayElement.getBoundingClientRect()

        const offset = container.clientHeight - this.heightColumn

        if (listItemRect.bottom < containerRect.top || listItemRect.top > containerRect.bottom - offset) {
          this.showBackToTop = false
        } else {
          this.showBackToTop = true
        }
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.getReportProgress()
    this.adjustStickyPosition()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    heightNavbar (val) {
      if (val) {
        this.adjustStickyPosition()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.table-container {
  max-height: 73.5vh;
  overflow: auto;
  position: relative;
}

.weekly-wrap {
  position: relative;
  background: $color-white;
}

.per-section {
  // max-height: 900vh; // try another way
  transition: 0.4s ease-in-out;
}

.per-section.close {
  overflow: clip;
  max-height: 0px; // heightColumnDaily
}

.column-date {
  border: 1px solid $color-grey-10;
  border-top-width: 0;
  background: $color-grey-6;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.is-flex {
  display: flex;
  position: relative;
}

.column-status {
  border: 1px solid $color-grey-10;
  border-top-width: 0;
  border-left-width: 0;
}

.daily-column {
  border: 1px solid $color-grey-10;
  border-top-width: 0;
  border-left-width: 0;
  background: $color-light-blue-2;
}

.daily-column.side {
  border-left-width: 1px;
}

// TOOLTIP

.tooltip-selected-wrapper {
  min-width: 378px;
  min-height: 230px;
  overflow: hidden;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 6px 24px 0px #2525251A;
  position: absolute;
  top: 3em;
  right: -0.5em;
  z-index: 4;
}

.header-tooltip {
  position: relative;
  z-index: 1;
  background: $color-grey-6;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.body-tooltip {
  padding: 15px;
}

.note-tooltip {
  background: #FFF4F4;
  padding: 10px;
  min-height: 80px;
  border-radius: 15px;
}

.note-tooltip.width {
  width: 210px;
}

.cancel-tooltip {
  height: 38px;
  width: 38px;
  border-radius: 35px;
  border: 1px solid $color-red-1;
  overflow: hidden;
}

.button-detail-tooltip {
  padding: 20px 15px;
}

.list-late-wrapper {
  max-height: 80px;
  overflow-y: auto;
}

// TOOLTIP

.scroll-today {
  position: absolute;
  z-index: 7;
  right: 1em;
  bottom: 1.5em;
  opacity: 0.6;
}

.scroll-today:hover {
  opacity: 1;
}

.icon-today-wrapper {
  transform: rotate(180deg) scale(0.7);
  transition: 0.2s all ease-in-out;
}

.reverse {
  transform: rotate(0deg) scale(0.7);
}

.icon-today-wrapper img {
  height: 20px;
  transform: scale(1.7);
  margin-right: 5px;
}

.ellipse {
  width: 6px;
  height: 6px;
  margin-top: 1px;
}

</style>

<style>
.date-report-progress-wrapper .datepicker .dropdown-content {
  box-shadow: none !important;
}
</style>
