<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-modal v-model="isHandleOpenModalSelectConsultant" :can-cancel="false">
    <div class="wrapper-modal">
      <!-- Header Modals -->
      <div class="modal-header">
        <div class="flex-row-start gap-3">
          <UserBlackIcon colorBackground="transparent" colorUser="#ffffff" />
          <p class="averta-bold label-18 color-white">
            {{ isAnySelected ? 'Ubah' : 'Pilih' }} Konsultan Survey
          </p>
        </div>
        <div class="flex-row-end cursor-pointer" @click="closeModal">
          <XMarkIcon />
        </div>
      </div>
      <!-- Header Modals -->

      <!-- Body Modals -->
      <div class="modal-body gap-6">
        <div style="z-index: 99999" class="flex-row-center c-w-100 is-pl-6 is-pr-6 is-sticky">
          <span class="is-relative c-w-100">
            <input
              v-model="searchQuery"
              style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)"
              type="text"
              class="input-search-2 input-none c-w-100 is-p-3 is-pl-10 flex-row-start"
              placeholder="Cari Tukang Jagoan"
            />
            <span class="is-absolute left-position line-height-0">
              <SearchIcon width="18px" color="#000000" />
            </span>
          </span>
        </div>

        <div class="c-w-100 gap-2 flex-column-center">
          <!-- List Consultants -->
          <div
            v-for="consultant in filteredConsultants"
            :key="consultant.id"
            class="flex-column-start c-w-100 is-pl-6 is-pr-6 is-pt-4 is-pb-4 is-hoverable-consultant cursor-pointer"
            :class="{
              'is-selected-consultant': consultant.isSelected,
              'is-unselectable-consultant': isAnySelected && !consultant.isSelected
            }"
            @click="toggleSelect(consultant)"
          >
            <div style="max-height: 3rem; min-height: 3rem" class="flex-row-space-between c-w-100">
              <div class="flex-row-start c-w-50 gap-4">
                <figure class="circle-avatar" v-if="consultant.image === '-'">
                  <EmptyAvatarIcon />
                </figure>
                <figure class="circle-avatar" v-else>
                  <img :src="consultant.image" />
                </figure>
                <div class="flex-column-start">
                  <div style="min-height: 2rem; max-height: 2rem" class="flex-row-start gap-2">
                    <p class="averta-bold">{{ consultant.name }}</p>
                    <code v-if="consultant.isSelected" class="badge-selected border-radius-20">
                      <p class="averta-regular label-12">Terpilih</p>
                    </code>
                  </div>
                  <p class="averta-regular">{{ consultant.id }}</p>
                </div>
              </div>
              <div class="flex-row-end c-w-50">
                <ButtonAppEvent
                  height="30px"
                  :isSecondary="true"
                  :isDisabled="isAnySelected && !consultant.isSelected"
                  @click="toggleSelect(consultant, $event)"
                  :isTransparent="consultant.isSelected ? true : false"
                >
                  <p class="averta-semibold">
                    {{ consultant.isSelected ? 'Batalkan' : 'Pilih' }}
                  </p>
                </ButtonAppEvent>
              </div>
            </div>
          </div>
          <!-- List Consultants -->

          <!-- Logic Search Null -->
          <div
            v-if="filteredConsultants && filteredConsultants.length === 0 && consultants && consultants.length > 0"
            class="flex-row-center p-2 border-radius-20 red-light-color"
          >
            <div class="flex-row-start gap-2">
              <AlertIcon />
              <p class="averta-semibold label-13 color-red">
                Consultant Yang Anda Cari, Tidak Ditemukan
              </p>
            </div>
          </div>
          <!-- Logic Search Null -->

          <!-- Logic Data Null -->
          <div
            v-if="consultants && consultants.length === 0"
            class="flex-row-center p-2 border-radius-20 red-light-color"
          >
            <div class="flex-row-start gap-2">
              <AlertIcon />
              <p class="averta-semibold label-13 color-red">Consultant Anda Tidak Ditemukan</p>
            </div>
          </div>
          <!-- Logic Data Null -->
        </div>
      </div>
      <!-- Body Modals -->

      <!-- Footer Modals -->
      <div class="modal-footer">
        <ButtonAppEvent
          height="40px"
          :isDisabled="!isDisabledButtonApp"
          @click="submitSelectedConsultant"
        >
          <p class="averta-bold">Lanjutkan</p>
        </ButtonAppEvent>
      </div>
      <!-- Footer Modals -->
    </div>
  </b-modal>
</template>

<script>
import {
  XMarkIcon,
  UserBlackIcon,
  SearchIcon,
  AlertIcon,
  EmptyAvatarIcon
} from '@/components/icons'
import { mapGetters } from 'vuex'
import ButtonAppEvent from '@/components/button/ButtonAppEvent.vue'
import moment from 'moment'

export default {
  name: 'ModalAssignConsultant',
  data () {
    return {
      searchQuery: '',
      selectedConsultant: null
    }
  },
  components: {
    SearchIcon,
    UserBlackIcon,
    XMarkIcon,
    ButtonAppEvent,
    AlertIcon,
    EmptyAvatarIcon
  },
  props: {
    isHandleOpenModalSelectConsultant: {
      type: Boolean,
      default: false
    },
    dataSurvey: {
      type: Object,
      default: null
    },
    consultants: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isDisabledButtonApp () {
      return this.consultants && this.consultants.some((consultant) => consultant.isSelected)
    },
    isAnySelected () {
      return this.consultants && this.consultants.some((consultant) => consultant.isSelected)
    },
    filteredConsultants () {
      const query = this.searchQuery.trim().toLowerCase()
      return this.consultants && this.consultants.filter((consultant) => consultant.name.toLowerCase().includes(query))
    }
  },
  methods: {
    resetConsultantSelected () {
      this.selectedConsultant = null
      if (this.consultants && this.consultants.length > 0) {
        this.consultants.forEach((consultant) => {
          this.$set(consultant, 'isSelected', false)
        })
      }
    },
    closeModal () {
      this.$emit('closeModal')
    },
    formatDate (date) {
      return date ? moment(date).format('DD MMMM YYYY') : 'Tanggal Tidak Tersedia'
    },
    toggleSelect (consultant, event) {
      if (event) {
        event.stopPropagation()
      }
      if (consultant.isSelected) {
        this.$set(consultant, 'isSelected', false)
        this.selectedConsultant = null
      } else {
        this.consultants.forEach((c) => this.$set(c, 'isSelected', false))
        this.$set(consultant, 'isSelected', true)
        this.selectedConsultant = consultant
      }
    },
    submitSelectedConsultant () {
      if (this.selectedConsultant) {
        this.$emit('selectconsultant', this.selectedConsultant)
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped>
.wrapper-modal {
  display: flex;
  flex-direction: column;
  width: 70%;
  min-width: 70%;
  max-width: 70%;
  height: auto;
  min-height: auto;
  max-height: 650px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  background: transparent;
}
.modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 1.5rem;
  background: #8b080d;
  border-radius: 10px 10px 0 0;
}
.modal-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 520px !important;
  max-height: 520px !important;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 1.5rem 0;
  background: #ffffff;
  overflow: auto;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65px;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  padding: 0 1.5rem;
  background: #ffffff;
  box-shadow: 0px -3px 30px 0px #00000012;
  border-radius: 0 0 10px 10px;
}
.blue-light-color {
  background: #3185fc1a;
}
.gray-light-color {
  background: #fafafa;
}
.red-light-color {
  background: #ff00001a;
}
.circle-avatar {
  display: flex;
  flex-direction: row;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
}
.circle-avatar > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.is-selected-consultant {
  background: #10bd411a;
  transition: all 0.5s ease;
}
.is-hoverable-consultant:hover {
  background: #10bd411a;
  transition: all 0.5s ease;
}
.is-unselectable-consultant {
  opacity: 0.1;
  pointer-events: none;
  transition: all 0.5s ease;
}
.is-sticky {
  top: 0;
  left: 0;
  right: 0;
}
.badge-selected {
  background: #ffffff !important;
  border: 1px solid #10bd4193 !important;
  color: #10bd41 !important;
  padding: 0.25rem 0.5rem !important;
  transition: all 0.5s ease;
}
</style>
