<template>
  <div class="hide-while-download">
    <ButtonApp height="30px" :isSecondary="true" :isTransparent="true" @click="handleModalEdit">
      <div class="is-flex is-align-items-center">
        <EditIcon size="11" />
        <p class="averta-bold mx-1">Edit</p>
      </div>
    </ButtonApp>

    <b-modal v-model="isModalActive">
      <div class="flex-center color-black">
        <div class="modal-edit overflow-auto">
          <img :src="require('@/assets/img/pin-location.png')" alt="" />
          <p class="label-16 averta-bold mb-1 my-5">Atur Alamat Invoice</p>

          <div class="border-radius-20 overflow-hidden bg-lightgray p-4 mb-5 c-w-100">
            <textarea v-model="inputAddress" class="textarea averta-regular mt-1 label-14 resize-none" rows="3" @keydown="disableEnter"></textarea>
          </div>

          <div class="flex-center c-w-100">
            <ButtonApp :isSecondary="true" class="flex-1 mr-2" @click="handleModalEdit">
              <p class="py-2 averta-bold">Kembali</p>
            </ButtonApp>
            <ButtonApp class="flex-1" :isDisabled="checkRecipient" @click="handleConfirmEdit">
              <p class="py-2 averta-bold">Simpan</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { EditIcon } from '@/components/icons'
import confirmImage from '../../../../../assets/img/delete-area-component.png'
import { alertErrorMessage } from '@/helpers'

export default {
  name: 'ModalEditAddress',
  components: {
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    EditIcon
  },
  props: {
    refreshData: {
      type: Function
    },
    address: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      url: process.env.VUE_APP_SHELL_URL_API,
      isLoading: false,
      isModalActive: false,
      inputAddress: ''
    }
  },
  computed: {
    checkRecipient () {
      if (!this.inputAddress || this.inputAddress.trim() === '') return true
      else if (this.isLoading) return true
      return false
    }
  },
  methods: {
    disableEnter (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    },
    handleModalEdit () {
      this.isModalActive = !this.isModalActive
      this.inputAddress = this.address
    },
    handleConfirmEdit () {
      this.$swal({
        imageUrl: confirmImage,
        width: '500px',
        padding: '10px 10px 20px',
        title: '<p class="averta-bold label-18">Simpan Informasi</p>',
        html: '<p class="averta-regular">Pastikan seluruh informasi alamat telah sesuai</p>',
        showCancelButton: true,
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component flex-1',
          cancelButton: 'btn-batal-detail-component flex-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.postDataInvoice()
        }
      })
    },
    postDataInvoice () {
      this.isLoading = true
      const payload = {
        url: this.url,
        project_id: this.$route.params.projectId,
        address: this.inputAddress
      }

      this.$store
        .dispatch('project/updateAddressInvoice', payload)
        .then(() => {
          this.refreshData({ hideLoading: true })
          setTimeout(() => {
            this.isLoading = false
            this.isModalActive = false
          }, 500)

          this.$buefy.toast.open({
            duration: 3000,
            message: '<div class="flex-center is-flex-direction-column"><p class="averta-regular">Perubahan alamat berhasil disimpan</p</div>'
          })
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.modal-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  background: $color-white;
  padding: 30px;
  border-radius: 20px;
}
</style>
