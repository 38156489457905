<template>
  <svg :width="width" :height="height" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7695 10.1453C16.039 9.29229 15.7071 8.55305 15.7071 7.29716V6.87013C15.7071 5.23354 15.3304 4.17907 14.5115 3.12459C13.2493 1.48699 11.1244 0.5 9.04423 0.5H8.95577C6.91935 0.5 4.86106 1.44167 3.577 3.0128C2.71333 4.08842 2.29293 5.18822 2.29293 6.87013V7.29716C2.29293 8.55305 1.98284 9.29229 1.23049 10.1453C0.676907 10.7738 0.5 11.5815 0.5 12.4557C0.5 13.3309 0.787226 14.1598 1.36367 14.8336C2.11602 15.6413 3.17846 16.1569 4.26375 16.2466C5.83505 16.4258 7.40634 16.4933 9.0005 16.4933C10.5937 16.4933 12.165 16.3805 13.7372 16.2466C14.8215 16.1569 15.884 15.6413 16.6363 14.8336C17.2118 14.1598 17.5 13.3309 17.5 12.4557C17.5 11.5815 17.3231 10.7738 16.7695 10.1453Z"
      fill="#E10009"
    />
    <path
      opacity="0.4"
      d="M11.0088 17.7285C10.5088 17.6217 7.46266 17.6217 6.96275 17.7285C6.53539 17.8272 6.07324 18.0568 6.07324 18.5604C6.09809 19.0408 6.37935 19.4648 6.76895 19.7337L6.76795 19.7347C7.27184 20.1275 7.86319 20.3773 8.48236 20.4669C8.81232 20.5122 9.14825 20.5102 9.49014 20.4669C10.1083 20.3773 10.6997 20.1275 11.2036 19.7347L11.2026 19.7337C11.5922 19.4648 11.8734 19.0408 11.8983 18.5604C11.8983 18.0568 11.4361 17.8272 11.0088 17.7285Z"
      fill="#E10009"
    />
  </svg>
</template>

<script>
export default {
  name: 'RedNotifikasiIcon',
  props: {
    width: {
      type: String,
      default: '18'
    },
    height: {
      type: String,
      default: 'auto'
    },
    viewBox: {
      type: String,
      default: '0 0 18 21'
    },
    fill: {
      type: String,
      default: 'none'
    }
  }
}
</script>
