<template>
  <div>
    <ModalTemplate :showModal="showModal" width="770px" @closeModal="closeModal">
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="flex-center-vertical">
            <img :src="require('@/assets/icons/plus-rounded-white-used.svg')" alt="">
            <p class="averta-bold label-18 ml-3">
              {{ title }}
            </p>
          </div>
          <div class="is-clickable" @click="closeModal">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="wrap-body-modal">

          <div class="c-h-80">
            <div class="mx-5 my-4 border-radius-20 flex-center-vertical is-justify-content-space-between" :class="[isWorkerFees ? 'bg-white py-3' : 'bg-lightgray p-4']">
              <div class="c-w-100">
                <p class="mb-1">{{titleInputName}}</p>

                <div v-if="isMaterial" class="averta-bold-italic label-16">
                  <p class="color-red" v-if="!titleMaterials">Belum ditentukan</p>
                  <div v-else class="material-name-wrapper mr-3">
                    <p class="color-blue-1 mr-1">
                      {{ titleMaterials }}
                    </p>
                  </div>
                </div>

                <div v-if="isWorkTool || isAuxiliaryTool">
                  <input
                    v-model="name"
                    type="text"
                    class="c-w-100 input-search-1 outline-none"
                    :placeholder="`Masukan nama alat ${isWorkTool ? 'kerja' : 'bantu'}`"
                  >
                </div>

                <div v-if="isWorkerFees" class="is-flex radio-worker-fees">
                  <label class="flex-center-vertical flex-1 border-radius-10 border-1-grey-2 p-3 bg-lightgray is-clickable mr-3">
                    <input type="radio" value="1" v-model="feeType" class="mr-2">
                    <p class="averta-bold">Upah Tukang</p>
                  </label>
                  <label class="flex-center-vertical flex-1 border-radius-10 border-1-grey-2 p-3 bg-lightgray is-clickable">
                    <input type="radio" value="2" v-model="feeType" class="mr-2">
                    <p class="averta-bold">Upah Kenek</p>
                  </label>
                </div>
              </div>
              <ModalSelectMaterial v-if="isMaterial" />
            </div>

            <div class="divider-section"></div>

            <!-- Form -->
            <div class="mx-5 my-4">
              <div>
                <p class="mb-2">Harga <span class="color-red">*</span></p>
                <InputPrice
                  :value="price"
                  :disabled="disabledPrice"
                  className="pr-4"
                  @change="$event => handleChangeInput($event, 'price')"
                />
              </div>
              <div class="is-flex mt-4">
                <div class="flex-1 mr-4">
                  <p class="mb-1">Volume <span class="color-red">*</span></p>
                  <input
                    v-model="volume"
                    type="number"
                    class="input-search-1 c-w-100 outline-none"
                    placeholder="Masukan Volume"
                    :disabled="disabledVolume"
                  >
                </div>
                <div class="flex-1 cs-autocomplete-satuan-material">
                  <p class="mb-1">Satuan <span class="color-red">*</span></p>
                  <InputAutocompleteSatuan
                    :value="unit"
                    :disabled="disabledSatuan"
                    @selectedUnit="$event => handleSelectUnit($event, 'satuan')"
                  />
                </div>
              </div>

              <ErrorComponent v-if="showErrorMsg" class="mt-4" :title="titleErrorMsg" />
            </div>
          </div>

          <div class="footer-modal">
            <ButtonApp
              height="45px"
              :isDisabled="disabledSubmit"
              @click="handleSubmit"
            >
              <p class="averta-bold px-4 py-2">{{ title }}</p>
            </ButtonApp>
          </div>

        </div>
      </template>
    </ModalTemplate>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency, removeComma, alertErrorMessage } from '@/helpers'

export default {
  name: 'ModalComponentItem',
  components: {
    ModalTemplate: () => import('@/components/modal/ModalTemplate.vue'),
    ModalSelectMaterial: () => import('@/components/modal/ModalSelectMaterial.vue'),
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    InputPrice: () => import('@/components/input/InputPrice.vue'),
    InputAutocompleteSatuan: () => import('@/components/input/InputAutocompleteSatuan.vue'),
    ErrorComponent: () => import('@/components/ErrorComponent.vue')
  },
  props: {
    code: {
      type: String
    },
    showModalProps: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      showModal: this.showModalProps,
      title: '',
      titleMaterials: '',

      name: '',
      price: '',
      volume: null,
      unit: null,
      feeType: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      selectedMaterials: 'unitPrice/getSelectedMaterials',
      formDetail: 'unitPrice/getFormDetailUnitPrice'
    }),
    emptyMaterials () {
      return this.selectedMaterials.length < 1
    },
    disabledPrice () {
      if (this.isMaterial) return this.selectedMaterials.length < 1
      if (this.isWorkerFees) return !this.feeType
      return false
    },
    disabledVolume () {
      if (this.isMaterial) return this.selectedMaterials.length < 1
      if (this.isWorkerFees) return !this.feeType
      return false
    },
    disabledSatuan () {
      if (this.isMaterial) return this.selectedMaterials.length < 1
      if (this.isWorkerFees) return !this.feeType
      return false
    },
    disabledSubmit () {
      return !this.price || this.price === '0' ||
      (!this.isWorkerFees && !this.volume) ||
      !this.unit ||
      (this.isMaterial && this.selectedMaterials.length < 1) ||
      (this.isWorkTool && !this.name) ||
      (this.isWorkerFees && !this.feeType)
    },
    showErrorMsg () {
      if (this.isMaterial) return this.selectedMaterials.length < 1
      if (this.isWorkerFees) return !this.feeType
      return false
    },
    titleErrorMsg () {
      if (this.isWorkerFees) return 'Tentukan jenis upah terlebih dahulu untuk menentukan Harga dan Satuan'
      else return 'Tentukan nama material terlebih dahulu untuk menentukan Harga dan Satuan'
    },
    isMaterial () {
      return this.code === 'add_materials' || this.code === 'edit_materials'
    },
    isWorkTool () {
      return this.code === 'add_work_tools' || this.code === 'edit_work_tools'
    },
    isAuxiliaryTool () {
      return this.code === 'add_auxiliary_tools' || this.code === 'edit_auxiliary_tools'
    },
    isWorkerFees () {
      return this.code === 'add_worker_fees' || this.code === 'edit_worker_fees'
    },
    isNote () {
      return this.code === 'note'
    },
    titleInputName () {
      switch (this.code) {
        case 'add_materials':
        case 'edit_materials':
          return 'Nama Material'

        case 'add_work_tools':
        case 'edit_work_tools':
          return 'Nama Alat Kerja'

        case 'add_auxiliary_tools':
        case 'edit_auxiliary_tools':
          return 'Nama Alat Bantu'

        case 'add_worker_fees':
        case 'edit_worker_fees':
          return 'Upah Kerja'

        default:
          return 'Nama'
      }
    }
  },
  methods: {
    formatThousand (value) {
      return formatCurrency(value, true) === '-' ? 0 : formatCurrency(value, true)
    },
    closeModal () {
      this.$emit('closeModal')
    },
    resetStore () {
      this.name = null
      this.price = null
      this.volume = null
      this.unit = null
      this.$store.commit('unitPrice/setSelectedMaterials', [])
      this.$store.commit('unitPrice/setFormDetailUnitPrice', 'reset')
    },
    handleChangeInput (value, type) {
      const temp = value.toString().replace(/\D/g, '')
      if (type === 'price') {
        this.price = this.formatThousand(temp)
      }
    },
    handleSelectUnit (option) {
      if (option === 'empty') {
        this.unit = null
      } else {
        this.unit = option
      }
      // this.$store.commit('unitPrice/setUnitPrice', { unit: option })
    },
    handleSubmit () {
      const body = {
        ...this.formDetail,
        index: this.formDetail ? this.formDetail.index : null,
        volume: this.volume,
        price: removeComma(this.price),
        unit_id: this.unit.id,
        unit_name: this.unit.name,
        code: this.code,
        note: ''
      }

      if (this.isWorkerFees) {
        body.worker_price = this.volume * removeComma(this.price)
      } else {
        body.material_price = this.volume * removeComma(this.price)
      }

      let payload = null
      if (this.isMaterial) {
        payload = {
          ...body,
          name: this.titleMaterials,
          selected: this.selectedMaterials,
          isEdit: this.code === 'edit_materials'
        }
      } else if (this.isWorkTool) {
        payload = {
          ...body,
          name: this.name,
          isEdit: this.code === 'edit_work_tools'
        }
      } else if (this.isAuxiliaryTool) {
        payload = {
          ...body,
          name: this.name,
          isEdit: this.code === 'edit_auxiliary_tools'
        }
      } else if (this.isWorkerFees) {
        payload = {
          ...body,
          name: this.feeType === 1 || this.feeType === '1' ? 'Upah Tukang' : 'Upah Kenek',
          fee_type: this.feeType,
          isEdit: this.code === 'edit_worker_fees'
        }
      }

      this.$store.commit('unitPrice/setDetailUnitPrice', payload)
      this.closeModal()
      setTimeout(() => {
        this.resetStore()
      }, 300)
    },
    getSelectedMaterial (title) {
      this.isLoading = true

      const payload = {
        url: this.url,
        // disable_paginate: true
        limit: 1000,
        page: this.page
      }
      this.$store
        .dispatch('product/getListProducts', payload)
        .then((response) => {
          const res = response.data.data
          this.isLoading = false

          const result = res
            .filter(item => title.includes(item.name))
            .map(item => ({ ...item, checked: true }))

          this.$store.commit('unitPrice/setSelectedMaterials', result)
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    }
  },
  mounted () {
  },
  watch: {
    showModalProps (val) {
      this.showModal = val
      if (val) {
        // Edit
        if (this.code === 'edit_materials') {
          this.price = this.formatThousand(this.formDetail.price)
          this.volume = this.formDetail.volume
          this.unit = {
            id: this.formDetail.unit_id,
            name: this.formDetail.unit_name
          }
          this.titleMaterials = this.formDetail.name

          // TEMPORARY SOLUTION, becouse response not include selected
          if (!this.formDetail.selected || this.formDetail.selected.length < 1) {
            this.getSelectedMaterial(this.formDetail.name)
          } else {
            this.$store.commit('unitPrice/setSelectedMaterials', this.formDetail.selected)
          }
        } else if (this.code === 'edit_work_tools') {
          this.price = this.formatThousand(this.formDetail.price)
          this.volume = this.formDetail.volume
          this.unit = {
            id: this.formDetail.unit_id,
            name: this.formDetail.unit_name
          }
          this.name = this.formDetail.name
        } else if (this.code === 'edit_auxiliary_tools') {
          this.price = this.formatThousand(this.formDetail.price)
          this.volume = this.formDetail.volume
          this.unit = {
            id: this.formDetail.unit_id,
            name: this.formDetail.unit_name
          }
          this.name = this.formDetail.name
        } else if (this.code === 'edit_worker_fees') {
          this.price = this.formatThousand(this.formDetail.price)
          this.volume = this.formDetail.volume
          this.unit = {
            id: this.formDetail.unit_id,
            name: this.formDetail.unit_name
          }
          this.feeType = this.formDetail.fee_type
        } else {
          this.resetStore()
        }
      }

      if (this.code === 'add_materials') {
        this.title = 'Tambah Material'
      } else if (this.code === 'add_work_tools') {
        this.title = 'Tambah Alat Kerja'
      } else if (this.code === 'add_auxiliary_tools') {
        this.title = 'Tambah Alat Bantu'
      } else if (this.code === 'add_worker_fees') {
        this.title = 'Tambah Upah Kerja'
      } else if (this.code === 'note') { // NOT READY
        this.title = 'Tambah Catatan'
      } else {
        this.title = 'Simpan'
      }
    },
    selectedMaterials: {
      handler (val) {
        if (val) {
          let temp = ''
          if (this.selectedMaterials && this.selectedMaterials.length > 0) {
            this.selectedMaterials.forEach((item, index) => {
              temp += `${item.name}${index === this.selectedMaterials.length - 1 ? '' : ', '}`
            })
          }
          this.titleMaterials = temp
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.wrap-body-modal {
  background: white;
  height: 500px;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.divider-section {
  width: 100%;
  height: 6px;
  background: $color-grey-2;
}

.footer-modal {
  box-shadow: 0px -3px 30px 0px #00000012;
  padding: 0px 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 17%;
}

.material-name-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-height: 80px;
  overflow: auto;
}

.radio-worker-fees input[type="radio"] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}
</style>

<style>
.cs-autocomplete-satuan-material .autocomplete .dropdown-content {
  max-height: 150px !important;
}

.cs-autocomplete-satuan-material .autocomplete input {
  font-size: 14px !important;
  padding: 19px 10px !important;
}
</style>
