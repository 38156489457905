<template>
  <main>
    <div class="gradient-background"></div>

    <div v-if="!isLoading && isSM" class="c-w-100">
      <!-- Back Navigation -->
      <div
        class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 p-5"
      >
        <div
          @click="goBackNavigation"
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-50 cursor-pointer"
          style="gap: 0.5rem"
        >
          <ArrowLeftIcon />
          <p class="averta-bold label-18">Kembali ke Daftar Proyek</p>
        </div>
      </div>
      <!-- Back Navigation -->

      <!-- Infomation Rating By ID -->
      <div
        class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 p-5"
      >
        <div
          class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
        >
          <div
            style="gap: 3rem"
            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
          >
            <!-- Image Content -->
            <div
              class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
            >
              <img
                :src="getDataBranch.logo"
                alt="logo-kanggo"
                width="100"
                v-if="getDataBranch.logo"
              />
              <img src="@/assets/img/no_empty_image.png" alt="logo-kanggo" width="100" v-else />
            </div>
            <!-- Image Content -->

            <div
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start"
              style="gap: 3rem"
            >
              <!-- ID Rating Content -->
              <div
                style="gap: 0.25rem"
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
              >
                <p class="averta-regular label-14">ID & Building</p>
                <p class="averta-bold label-14 color-red">ID {{ getDataBranch.id }}</p>
                <p class="averta-bold label-14">
                  {{ getDataBranch.name }} - {{ getDataBranch.branch_unit_name }}
                </p>
              </div>
              <!-- ID Rating Content -->

              <!-- Address Rating Content -->
              <div
                style="gap: 0.25rem; max-width: 450px"
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
              >
                <p class="averta-regular label-14">Alamat</p>
                <p class="averta-bold label-14" style="line-height: 1.65">
                  {{ getDataBranch.address }}
                </p>
              </div>
              <!-- Address Rating Content -->
            </div>
          </div>
        </div>
        <div
          style="gap: 1rem"
          class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center c-w-50"
        >
          <div class="card-rating-count">
            <div
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            >
              <p class="averta-regular label-12 color-white">Proyek Belum Di-rating</p>
              <p class="averta-bold label-24 color-white">
                {{ getDataSummaryById.has_no_rate }} Proyek
              </p>
            </div>
            <div class="divider-vertical" style="min-height: 70px; background-color: #ffffff"></div>
            <div
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            >
              <p class="averta-regular label-12 color-white">Proyek Sudah Di-rating</p>
              <p class="averta-bold label-24 color-white">
                {{ getDataSummaryById.has_been_rated }} Proyek
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Infomation Rating By ID -->

      <!-- Looping Section (Card Rating) -->
      <div
        v-for="(workerGroup, index) in getDataWorkers"
        :key="index"
        class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100"
      >
        <!-- Date Section -->
        <div
          style="background-color: #e1e1e1; gap: 0.5rem"
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 px-5 py-3"
        >
          <CalendarNoRounded />
          <p class="averta-bold label-16 m-0 p-0">{{ workerGroup.date }}</p>
        </div>
        <!-- Date Section -->

        <!-- Card Content Rating -->
        <div
          v-for="(worker, workerIndex) in workerGroup.workers"
          :key="workerIndex"
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 py-3 px-5 my-3"
        >
          <div
            class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center c-w-100 border-radius-20 overflow-hidden border-color-1"
          >
            <!-- Header Card -->
            <div
              class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center p-5 light-blue-color c-w-100 gap-6"
            >
              <div
                class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
              >
                <div
                  @click="
                    worker && worker.certificates && worker.certificates.length > 0
                      ? handleOpenModalsPreviewSertifikat(worker.worker_id, worker)
                      : ''
                  "
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3"
                >
                  <div class="avatar-circle-users">
                    <img :src="worker.image" :alt="worker.name" />
                  </div>
                  <div
                    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                  >
                    <div
                      class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                    >
                      <p class="averta-bold label-18 m-0">{{ worker.name }}</p>
                      <div
                        class="badge-new-comment ml-3 gap-1"
                        v-if="worker && worker.certificates && worker.certificates.length > 0"
                      >
                        <SertifikatBadgeIcon />
                        <p
                          class="m-0 label-10 averta-bold-italic color-green"
                          style="white-space: nowrap"
                        >
                          Bersertifikat
                        </p>
                      </div>
                    </div>
                    <p class="averta-light label-14 m-0">{{ worker.workItemId }}</p>
                  </div>
                </div>

                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3"
                >
                  <div class="avatar-circle-users">
                    <img :src="worker.skillImage" :alt="worker.skill_name" />
                  </div>
                  <div
                    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                  >
                    <p class="averta-light label-14 m-0">Jenis Tukang</p>
                    <p class="averta-bold label-18 m-0">{{ worker.skillName }}</p>
                  </div>
                </div>
              </div>
              <div
                class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 px-5 py-3 border-radius-100 light-blue-color-1"
              >
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-50"
                >
                  <p class="averta-reguler label-14">Item Pekerjaan:</p>
                </div>
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center c-w-50"
                >
                  <p class="averta-bold label-14">{{ worker.workItemName }}</p>
                </div>
              </div>
            </div>
            <!-- Header Card -->

            <!-- Body Card -->
            <div
              class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center p-5 c-w-100"
            >
              <div
                class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center light-gray-color c-w-100 p-4 border-radius-20"
              >
                <!-- Rating Content -->
                <div class="card-content-comment-rating">
                  <div class="card-rating">
                    <div class="card-rating-users c-w-100">
                      <div
                        style="gap: 10px"
                        class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
                      >
                        <div class="avatar-users">
                          <ChecklistGreenIcon width="29" v-if="worker.ratingFromClient.rating" />
                          <RedNotifikasiIcon width="25" v-else />
                        </div>
                        <div
                          style="gap: 5px"
                          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                        >
                          <p class="averta-bold black-color label-14 m-0">
                            Rating dari {{ worker.branchName }}
                          </p>

                          <div
                            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-2"
                          >
                            <p
                              class="label-14 m-0"
                              :class="
                                worker.ratingFromClient.rating
                                  ? 'averta-bold color-red cursor-pointer'
                                  : 'averta-reguler-italic'
                              "
                              @click="
                                worker.ratingFromClient.rating
                                  ? handleOpenModalsPreviewRatingClient(worker.work_item_id, worker)
                                  : ''
                              "
                            >
                              {{
                                worker.ratingFromClient.rating
                                  ? 'Lihat Detail Penilaian'
                                  : 'Belum Rating'
                              }}
                            </p>
                            <ArrowRightIcon
                              color="#FF0000"
                              size="13"
                              v-if="worker.ratingFromClient.rating"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="is-flex is-flex-direction-column is-justify-content-flex-end star-color"
                      >
                        <b-rate
                          size="is-medium"
                          :spaced="true"
                          :value="worker.ratingFromClient.rating"
                          :disabled="true"
                        ></b-rate>
                      </div>
                    </div>
                    <div class="divider-line-1 px-6"></div>
                    <div class="card-rating-users c-w-100">
                      <div
                        style="gap: 10px; min-width: 50%; max-width: 50%; width: 50%"
                        class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
                      >
                        <div class="avatar-users">
                          <ChecklistGreenIcon width="29" v-if="worker.ratingFromInternal.rating" />
                          <RedNotifikasiIcon width="25" v-else />
                        </div>
                        <div
                          style="gap: 5px"
                          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                        >
                          <p class="averta-bold black-color label-14 m-0">Rating dari Kanggo</p>

                          <div
                            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-2"
                          >
                            <p
                              class="label-14 m-0"
                              :class="
                                worker.ratingFromInternal.rating
                                  ? 'averta-bold color-red cursor-pointer'
                                  : 'averta-reguler-italic'
                              "
                              @click="
                                worker.ratingFromInternal.rating
                                  ? handleOpenModalsPreviewRating(worker.work_item_id, worker)
                                  : ''
                              "
                            >
                              {{
                                worker.ratingFromInternal.rating
                                  ? 'Lihat Detail Penilaian'
                                  : 'Belum Rating'
                              }}
                            </p>
                            <ArrowRightIcon
                              color="#FF0000"
                              size="13"
                              v-if="worker.ratingFromInternal.rating"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style="min-width: 50%; max-width: 50%; width: 50%"
                        class="is-flex is-flex-direction-row is-justify-content-flex-end c-w-100"
                      >
                        <ButtonApp
                          v-if="
                            !worker.ratingFromInternal ||
                            Object.keys(worker.ratingFromInternal).length === 0
                          "
                          height="45px"
                          width="75%"
                          @click="
                            handleOpenModalsRating(worker.work_item_id, worker, getDataWorkers)
                          "
                        >
                          <div
                            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                          >
                            <p class="averta-bold label-13 color-white">Rating Tukang</p>
                          </div>
                        </ButtonApp>
                        <b-rate
                          v-else
                          size="is-medium"
                          v-model="worker.ratingFromInternal.rating"
                          :spaced="true"
                          :value="worker.ratingFromInternal.rating"
                          :disabled="true"
                        ></b-rate>
                      </div>
                    </div>
                  </div>
                  <div class="card-comment">
                    <div
                      class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
                    >
                      <div class="is-flex c-w-50 is-justify-content-flex-start">
                        <div
                          class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-2"
                        >
                          <p class="averta-bold label-12 gray-color">Komentar Rating</p>
                          <p class="averta-bold label-12 gray-color">-</p>
                          <p class="averta-bold label-12 black-color">
                            {{ worker.comments.length > 0 ? worker.comments.length : 0 }}
                          </p>
                        </div>
                      </div>
                      <div class="is-flex c-w-50 is-justify-content-flex-end">
                        <p
                          style="pointer-events: none"
                          class="averta-semibold label-12 color-red is-clickable"
                          @click="
                            worker.has_been_rated === true ?
                            handleOpenModalsComment(worker.work_item_id, worker) :
                            worker.has_been_commented === true
                              ? handleOpenModalsComment(worker.work_item_id, worker)
                              : '-'
                          "
                        >
                          {{
                            worker.comments.find((comment) => comment.comment_from === 2) &&
                            worker.has_been_commented === true
                              ? 'Lihat Komentar'
                              : '+ Beri Komentar'
                          }}
                        </p>
                      </div>
                    </div>

                    <div
                      v-if="worker.comments.length > 0"
                      style="position: relative"
                      class="is-flex c-w-100 is-flex-direction-column is-justify-content-space-between is-align-items-center gap-8"
                    >
                      <div
                        style="gap: 10px"
                        class="is-flex is-flex-direction-row c-w-100"
                        v-for="(comment, commentIndex) in worker.comments"
                        :key="commentIndex"
                      >
                        <div class="avatar-users-comment c-w-100">
                          <img
                            v-if="comment.created_by_image"
                            :src="comment.created_by_image"
                            alt="kanggo.id"
                          />
                          <img v-else src="@/assets/img/user-default.svg" alt="kanggo.id" />
                        </div>
                        <div
                          class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start c-w-100"
                        >
                          <div
                            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 gap-2"
                          >
                            <p class="m-0 label-12 averta-bold">{{ comment.created_by_name }}</p>
                            <div class="divider-vertical-line-1"></div>
                            <p class="m-0 label-12 averta-reguler-italic">
                              {{ comment.comment_from === 2 ? 'Site Manager' : 'Admin' }}
                            </p>
                            <div class="badge-new-comment ml-3 gap-1">
                              <NewMessageIcon />
                              <p class="m-0 label-10 averta-bold-italic red-color">Komentar Baru</p>
                            </div>
                            <div class="card-datetime-content ml-3 gap-1">
                              <p class="m-0 label-10 averta-bold-italic red-color">
                                {{ comment.created_at }}
                              </p>
                            </div>
                          </div>
                          <p
                            class="m-0 label-13 averta-reguler mt-1"
                            style="line-height: 1.4; word-break: break-all !important"
                          >
                            {{ comment.comment }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 c-h-100"
                    >
                      <p class="m-0 label-12 averta-bold">Belum ada komentar</p>
                    </div>
                  </div>
                </div>
                <!-- Rating Content -->

                <!-- Foto Content -->
                <div
                  class="is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-flex-end c-w-15 gap-4"
                >
                  <!-- Foto Header -->
                  <div
                    class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 gap-1"
                  >
                    <p class="averta-reguler label-12 black-color">Sebelum | Sesudah</p>
                    <div class="divider-vertical-line-1" style="min-height: 1.5rem"></div>
                    <p
                      @click="
                        worker.photos.length > 0
                          ? handleOpenSidebarDetailImageRating(worker.work_item_id, worker)
                          : '-'
                      "
                      class="averta-bold label-12 color-blue-1"
                      :class="worker.photos.length > 0 ? 'cursor-pointer' : 'pointer-events-none'"
                    >
                      Lihat Foto
                    </p>
                  </div>

                  <div
                    v-if="worker.photos.length > 0"
                    class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 gap-3"
                  >
                    <!-- Loop through each photo group (before-after) -->
                    <div
                      v-for="(photo, photoIndex) in worker.photos"
                      :key="photoIndex"
                      class="image-card-before-after"
                    >
                      <!-- Before Image -->
                      <div class="image-card-before">
                        <img
                          :src="photo.before.image"
                          alt="Before Image"
                          v-if="photo.before.image"
                        />
                        <span
                          v-else
                          class="c-w-100 c-h-100 is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                        >
                          <ImageEmptyIcon />
                        </span>
                      </div>

                      <!-- After Image -->
                      <div class="image-card-after">
                        <img :src="photo.after.image" alt="After Image" v-if="photo.after.image" />
                        <span
                          v-else
                          class="c-w-100 c-h-100 is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                        >
                          <ImageEmptyIcon />
                        </span>
                      </div>
                    </div>
                    <!-- Loop through each photo group (before-after) -->
                  </div>

                  <!-- Validation Empty Image -->
                  <div
                    v-else
                    style="background: #f9fcff"
                    class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 p-4 border-radius-10 gap-2"
                  >
                    <ImageEmptyIcon width="26" color="#276ACA" />
                    <p
                      style="white-space: nowrap !important"
                      class="averta-reguler-italic label-18 gray-color m-0"
                    >
                      Foto belum ada
                    </p>
                  </div>
                  <!-- Validation Empty Image -->
                </div>
                <!-- Foto Content -->
              </div>
            </div>
            <!-- Body Card -->
          </div>
        </div>
        <!-- Card Rating Section -->
      </div>

      <modalsRating
        :isHandleOpenModalsRating="isHandleOpenModalsRating"
        :selectedWorker="selectedWorker"
        :maxRating5="maxRating5"
        :maxRatingUnder4="maxRatingUnder4"
        :maxRatingUnder2="maxRatingUnder2"
        :getProjectId="getProjectId"
        :getDataBranch="getDataBranch"
        :getDataClient="getDataClient"
        :getDateCreateAt="getDateCreateAt"
        @closeModalsRating="closeModalsRating"
      />

      <modalsPreviewDetailRating
        :isHandleOpenModalsPreviewRating="isHandleOpenModalsPreviewRating"
        :selectedWorker="selectedWorker"
        @closeModalsPreviewRating="closeModalsPreviewRating"
      />

      <modalsPreviewDetailRatingClient
        :isHandleOpenModalsPreviewRatingClient="isHandleOpenModalsPreviewRatingClient"
        :selectedWorker="selectedWorker"
        @closeModalsPreviewRating="closeModalsPreviewRating"
      />

      <modalsComment
        :isHandleOpenModalsComments="isHandleOpenModalsComments"
        :selectedWorker="selectedWorker"
        :getProjectId="getProjectId"
        @closeModalsComments="closeModalsComments"
      />

      <!-- Sidebar Detail Image Rating -->
      <sidebarDetailImageRating
        :isHandleOpenSidebarDetailImageRating="isHandleOpenSidebarDetailImageRating"
        :selectedWorker="selectedWorker"
        @closeSidebar="handleCloseSidebar"
      />
      <!-- Sidebar Detail Image Rating -->

      <ModalsPreviewSertifikat
        :isHandleOpenModalsPreviewSertifikat="isHandleOpenModalsPreviewSertifikat"
        :selectWorkers="selectWorkers"
        @closeModalPreviewSertifikat="closeModalPreviewSertifikat"
      />
    </div>
    <!-- Looping Section (Card Rating) -->

    <!-- Loading -->
    <div v-else>
      <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
    <!-- Loading -->
  </main>
</template>

<script>
import moment from 'moment'
import {
  ArrowLeftIcon,
  CalendarNoRounded,
  ChecklistGreenIcon,
  NewMessageIcon,
  ArrowRightIcon,
  RedNotifikasiIcon,
  ImageEmptyIcon,
  SpinnerIcon,
  SertifikatBadgeIcon
} from '@/components/icons'
import { alertErrorMessage } from '@/helpers'
import { mapGetters } from 'vuex'
import ButtonApp from '@/components/button/ButtonApp'
import modalsRating from './components/modalsRating'
import modalsPreviewDetailRating from './components/modalsPreviewDetailRating'
import modalsPreviewDetailRatingClient from './components/modalsPreviewDetailRatingClient'
import modalsComment from './components/modalsComment'
import sidebarDetailImageRating from './components/sidebarDetailImageRating'
import ModalsPreviewSertifikat from '@/components/modal/ModalsPreviewSertifikat.vue'

export default {
  name: 'DetailsViewsRating',
  components: {
    ArrowLeftIcon,
    CalendarNoRounded,
    ChecklistGreenIcon,
    NewMessageIcon,
    ButtonApp,
    ArrowRightIcon,
    RedNotifikasiIcon,
    ImageEmptyIcon,
    modalsRating,
    modalsPreviewDetailRating,
    modalsPreviewDetailRatingClient,
    modalsComment,
    SpinnerIcon,
    sidebarDetailImageRating,
    SertifikatBadgeIcon,
    ModalsPreviewSertifikat
  },
  data () {
    return {
      clearDate: null,
      isLoading: false,
      projectDetails: null,
      getDataWorkers: [],
      getDataSummaryById: {},
      getDataBranch: {},
      getDataClient: {},
      isHandleOpenModalsRating: false,
      selectedWorker: {},
      isHandleOpenModalsPreviewRating: false,
      isHandleOpenModalsPreviewRatingClient: false,
      maxRating5: [],
      maxRatingUnder4: [],
      maxRatingUnder2: [],
      getProjectId: null,
      moment: moment,
      isHandleOpenModalsComments: false,
      isFullPage: true,
      isHandleOpenSidebarDetailImageRating: false,
      isHandleOpenModalsPreviewSertifikat: false,
      selectWorkers: {}
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    }),
    isSM () {
      if (this.user.roles[0].name === 'Site Manager') {
        return true
      }
      return false
    }
  },
  methods: {
    handleOpenModalsPreviewSertifikat (workersId, workers, item) {
      this.selectWorkers = { workersId, ...workers, ...item }
      this.isHandleOpenModalsPreviewSertifikat = true
    },
    closeModalPreviewSertifikat () {
      this.isHandleOpenModalsPreviewSertifikat = false
    },
    handleOpenSidebarDetailImageRating (workItemId, worker) {
      this.selectedWorker = { workItemId, ...worker }
      this.selectedWorkerPhotos = worker.photos.map((photo) => [photo.before, photo.after])
      this.isHandleOpenSidebarDetailImageRating = true
    },
    handleCloseSidebar () {
      this.isHandleOpenSidebarDetailImageRating = false
      this.selectedWorker = null
    },
    goBackNavigation () {
      this.$router.go(-1)
    },
    handleOpenModalsComment (workItemId, worker) {
      this.selectedWorker = { workItemId, ...worker }
      this.isHandleOpenModalsComments = true
    },
    closeModalsComments () {
      this.isHandleOpenModalsComments = false
    },
    handleOpenModalsPreviewRating (workItemId, worker) {
      this.selectedWorker = { workItemId, ...worker }
      this.isHandleOpenModalsPreviewRating = true
    },
    handleOpenModalsPreviewRatingClient (workItemId, worker) {
      this.selectedWorker = { workItemId, ...worker }
      this.isHandleOpenModalsPreviewRatingClient = true
    },
    closeModalsPreviewRating () {
      this.isHandleOpenModalsPreviewRating = false
      this.isHandleOpenModalsPreviewRatingClient = false
    },
    handleOpenModalsRating (workItemId, worker) {
      this.selectedWorker = { workItemId, ...worker }
      this.isHandleOpenModalsRating = true
    },
    closeModalsRating (modalType) {
      if (modalType === 'Rating Under Three') {
        this.isHandleOpenModalsRating = false
      } else if (modalType === 'Rating Over Three') {
        this.isRatingOverThree = false
      }
    },
    getProjectList () {
      this.isLoading = true
      this.$store
        .dispatch('rating/getProjectList', { url: this.url })
        .then((response) => {
          this.getDataSummary = response.data.data.summary || {}
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
        .finally(() => {
          this.isLoading = true
        })
    },
    getRatingTags () {
      this.isLoading = true
      this.$store
        .dispatch('rating/getRatingTags', { url: this.url })
        .then((response) => {
          if (response.data?.code === 1) {
            this.ratingTags = response.data.data
            this.maxRating5 = this.ratingTags[0].rating_tags.map((item) => ({
              name: item.name,
              id: item.id
            }))
            this.maxRatingUnder4 = this.ratingTags[1].rating_tags.map((item) => ({
              name: item.name,
              id: item.id
            }))
            this.maxRatingUnder2 = this.ratingTags[2].rating_tags.map((item) => ({
              name: item.name,
              id: item.id
            }))
          } else {
            console.warn('Unexpected Response:', response)
          }
        })
        .catch((error) => {
          if (error.response) {
            console.error('Error Response:', error.response)
          } else if (error.request) {
            console.error('No Response Received:', error.request)
          } else {
            console.error('Unexpected Error:', error.message)
          }
        })
        .finally(() => {
          this.isLoading = true
        })
    },
    getProjectListById (id) {
      this.isLoading = true
      this.$store
        .dispatch('rating/getProjectListById', { url: this.url, project_id: id })
        .then((response) => {
          this.projectDetails = response.data.data
          this.getDataSummaryById = response.data.data.summary
          this.getDataBranch = response.data.data.branch
          this.getDataClient = response.data.data.client
          this.getDateCreateAt = response.data.data.created_at
          this.getProjectId = response.data.data.project_id

          this.getDataWorkers = this.projectDetails.data.map((item) => {
            const workersByDate = item.worker.map((worker) => {
              return {
                id: worker.id,
                name: worker.name,
                image: worker.image,
                skillId: worker.skill_id,
                skillName: worker.skill_name,
                skillImage: worker.skill_image,
                workItemId: worker.work_item_id,
                workItemName: worker.work_item_name,
                attendance: worker.attendance,
                has_been_commented: worker.has_been_commented,
                has_been_rated: worker.has_been_rated,
                photos: worker.photos,
                branchName: worker.branch_name,
                ratingFromClient: worker.rating_from_client,
                ratingFromInternal: worker.rating_from_internal,
                comments: worker.comments.map((comment) => {
                  return {
                    id: comment.id,
                    comment: comment.comment,
                    comment_from: comment.comment_from,
                    created_at: moment(comment.created_at).format('DD/MM/YYYY - HH:mm'),
                    created_by_id: comment.created_by_id,
                    created_by_name: comment.created_by_name,
                    created_by_image: comment.created_by_image
                  }
                }),
                hasBeenRated: worker.has_been_rated,
                hasBeenCommented: worker.has_been_commented,
                date: item.date
              }
            })
            return {
              date: item.date,
              workers: workersByDate
            }
          })
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  created () {
    this.getProjectList()
    this.getRatingTags()
    const projectId = this.$route.params.id
    if (projectId) {
      this.getProjectListById(projectId)
    } else {
      console.error('Project ID is missing in route params!')
    }
  }
}
</script>

<style scoped>
main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0;
}
.gradient-background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 230px;
  background: linear-gradient(0deg, #fffff7 0%, #fff5ea 100%);
  z-index: -1;
}
.card-rating-count {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: auto;
  background: linear-gradient(90deg, #d9272d 0%, #f6bf6c 100%);
  border-radius: 20px;
  margin: 0;
  padding: 1rem;
  gap: 1rem;
}
.divider-vertical {
  width: 1px;
  height: 100%;
  margin: 0 1rem;
}

.light-blue-color {
  background: #e1eeff;
}
.light-blue-color-1 {
  background: #f0f7ff;
}
.light-gray-color {
  background: #f5f5f5;
}
.border-color-1 {
  border-width: 1px;
  border-style: solid;
  border-color: #e1e1e1;
}
.avatar-circle-users {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  mex-height: 40px;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-circle-users > img {
  object-fit: cover;
  width: 100%;
  height: auto;
}
.card-content-comment-rating {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  min-width: 75%;
  max-width: 75%;
  height: 180px;
  min-height: 180px;
  max-height: 180px;
  margin: 0;
  gap: 10px;
  padding: 10px;
  border-radius: 20px;
  background: #ffffff;
  border: 1px solid #e10009;
}
.card-rating {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  min-width: auto;
  max-width: 50%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  gap: 0rem;
  margin: 0;
  padding: 0;
  background: transparent !important;
}
.card-comment {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  min-width: auto;
  max-width: 50%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 10px;
  gap: 15px;
  background: #fafafa;
  border-radius: 10px;
  overflow: auto !important;
}
.card-rating-users {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 100%;
  margin: 0;
  padding: 5px;
  background: transparent !important;
}
.avatar-users {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 50%;
}
.avatar-users-comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25px;
  min-width: 25px;
  max-width: 25px;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  margin: 0;
  padding: 0;
  background: #868686;
  border-radius: 50%;
  overflow: hidden !important;
}
.card-datetime-content {
  margin-left: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
}
.image-card-before-after {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  background: #ffffff;
  overflow: hidden !important;
}
.image-card-before {
  position: absolute;
  top: 0;
  left: 0;
  width: 48.5%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #868686;
  justify-content: center;
  align-items: center;
}
.image-card-after {
  position: absolute;
  top: 0;
  right: 0;
  width: 48.5%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #868686;
  justify-content: center;
  align-items: center;
}
.image-card-before > img,
.image-card-after > img,
.avatar-users-comment > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.badge-new-comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: auto;
  max-width: auto;
  height: auto;
  margin: 0;
  padding: 0.25rem 0.5rem;
  background: #fce5e6;
  border-radius: 1000px;
}
</style>
