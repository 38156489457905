<template>
  <svg width="25" height="auto" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_2019_141462"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="3"
      width="15"
      height="12"
    >
      <path
        d="M4.24531 9.02824L2.2759 9.02824C1.95825 9.02824 1.80296 9.40942 2.02884 9.62824L4.70413 12.3106C4.84531 12.4518 5.06413 12.4518 5.20531 12.3106L7.8806 9.6353C8.09943 9.40942 7.94413 9.02824 7.62649 9.02824L5.65708 9.02824C5.65708 6.3953 8.07119 4.31295 10.803 4.89177C12.4053 5.22354 13.6971 6.52236 14.0288 8.11765C14.5583 10.6377 12.8288 12.8824 10.4924 13.2212C10.1535 13.2706 9.89237 13.5671 9.89237 13.9129C9.89237 14.3365 10.2665 14.6753 10.69 14.6188C13.7888 14.1812 16.083 11.2306 15.4335 7.89177C15.003 5.68942 13.2312 3.91765 11.0288 3.48707C7.40766 2.78824 4.24531 5.54118 4.24531 9.02824Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_2019_141462)">
      <rect
        x="0.265625"
        y="17.4688"
        width="16.9412"
        height="16.9412"
        transform="rotate(-90 0.265625 17.4688)"
        fill="#252525"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArrowRotateLeftIcon'
}
</script>
