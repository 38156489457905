<template>
  <section>
    <b-sidebar
      v-model="open"
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right"
      :delay="delay"
      :can-cancel="false"
    >
      <div v-if="dataPhoto && Object.keys(dataPhoto).length > 0" class="p-1">
        <div class="is-flex is-justify-content-start px-4 py-4 border-bottom">
          <div class="is-flex is-align-items-center is-clickable" @click="goBack">
            <ArrowLeftIcon class="mr-2" />
          </div>

          <!-- HEADER DETAIL PAGE -->
          <div v-if="type === 'detail'" class="is-flex is-justify-content-start px-3 cursor-pointer"
          @click="dataPhoto && dataPhoto.certificates && dataPhoto.certificates.length > 0 ?
          handleOpenModalsPreviewSertifikat(dataPhoto.worker_id, dataPhoto) : '-' ">
            <div class="tukang-img mr-3">
              <img
                v-if="dataPhoto && dataPhoto.worker_image"
                :src="dataPhoto && dataPhoto.worker_image"
                class="is-clickable"
                alt="worker-img"
                @click="openModalPreviewImage(dataPhoto && dataPhoto.worker_image)"
              />
              <img v-else :src="require('@/assets/img/default-picture.jpg')" alt="default_pic" />
            </div>
            <div class="is-flex is-flex-direction-column is-justify-content-center">
              <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-4">
                <p class="averta-bold">{{dataPhoto.worker_name}}</p>
                <div class="badge-new-comment ml-3 gap-1" v-if="dataPhoto && dataPhoto.certificates && dataPhoto.certificates.length > 0">
                  <SertifikatBadgeIcon />
                  <p class="m-0 label-10 averta-bold-italic color-green">
                    Bersertifikat
                  </p>
                </div>
              </div>
              <p class="mt-1 averta-regular color-gray label-12">ID{{dataPhoto.worker_id}}</p>
            </div>
          </div>

          <!-- HEADER LIST PAGE -->
          <div v-if="type === 'list'" class="is-flex is-justify-content-start px-3">
            <div class="tukang-img mr-3">
              <img
                v-if="dataPhoto.skill_image"
                :src="dataPhoto.skill_image"
                class="is-clickable"
                alt="worker-img"
                @click="openModalPreviewImage(dataPhoto.skill_image)"
              />
              <img v-else :src="require('@/assets/img/default-picture.jpg')" alt="default_pic" />
            </div>
            <div class="is-flex is-flex-direction-column is-justify-content-center">
              <p class="averta-regular color-gray label-12">Jenis Keahlian</p>
              <p class="averta-bold">{{dataPhoto.skill_name}}</p>
            </div>
          </div>
        </div>

        <!-- BODY -->
        <div class="wrapper-content p-sm-2">
          <div v-if="type === 'detail'" class="wrapper-notif">
            <p class="averta-bold label-14 color-black-primary">Item Pekerjaan</p>
            <p class="averta-regular label-14 color-black-primary">
              {{dataPhoto.note_item || '-'}}
            </p>
          </div>

          <div class="carousel-content flex-sm-column mt-sm-2">
            <div class="is-flex is-justify-content-end is-align-items-center flex-1 px-5 border-right-2 c-w-sm-100 p-sm-0 border-sm-none">
              <div class="side-content mb-sm-2">
                <p class="averta-bold label-16 mb-5 mb-sm-0 has-text-centered">Foto Before</p>
                <div class="cover-img before p-sm-0 is-flex">
                  <div class="wrap-images is-flex is-align-items-center is-justify-content-center">
                    <img v-if="photoBeforeCover" class="images" :src="typeof photoBeforeCover === 'object' ? photoBeforeCover.image_url : photoBeforeCover" alt="">
                    <img v-else :src="require('@/assets/img/empty-before-after.png')" alt="">
                    <img
                      v-if="photoBeforeCover"
                      class="zoom-preview is-clickable"
                      :src="require('@/assets/img/zoom-preview.png')"
                      @click="openModalPreviewImage(typeof photoBeforeCover === 'object' ? photoBeforeCover.image_url : photoBeforeCover)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="is-flex is-justify-content-start is-align-items-center flex-1 px-5 c-w-sm-100 p-sm-0 ">
              <div class="side-content">
                <p class="averta-bold label-16 mb-5 mb-sm-0 has-text-centered">Foto After</p>
                <div class="cover-img after p-sm-0 is-flex">
                  <div class="wrap-images is-flex is-align-items-center is-justify-content-center">
                    <img v-if="photoAfterCover" class="images" :src="typeof photoAfterCover === 'object' ? photoAfterCover.image_url : photoAfterCover" alt="">
                    <img v-else :src="require('@/assets/img/empty-before-after.png')" alt="">

                    <img
                      v-if="photoAfterCover"
                      class="zoom-preview is-clickable"
                      :src="require('@/assets/img/zoom-preview.png')"
                      @click="openModalPreviewImage(typeof photoAfterCover === 'object' ? photoAfterCover.image_url : photoAfterCover)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- FOOTER CAROUSEL -->
          <div class="is-flex is-align-items-center is-justify-content-center mt-6 mb-6 mt-sm-2 overflow-auto">
            <div
              v-for="item, index in previewPhotoCarousel"
              :key="`item-carousel-${index}`"
              class="item-carousel is-clickable"
              :class="{'selected': selectedPhoto === index}"
              @click="handleSelectedPhoto(index)"
            >
              <div class="img-carousel" :class="{'bg-grey-9': !item.pictureBefore}">
                <img v-if="item.pictureBefore" :src="typeof item.pictureBefore === 'object' ? item.pictureBefore.image_url : item.pictureBefore" alt="">
                <img v-else :src="require('@/assets/img/empty-img-2.png')" class="empty-preview" alt="">
              </div>
              <div class="img-carousel" :class="{'bg-grey-9': !item.pictureAfter}">
                <img v-if="item.pictureAfter" :src="typeof item.pictureAfter === 'object' ? item.pictureAfter.image_url : item.pictureAfter" alt="">
                <img v-else :src="require('@/assets/img/empty-img-2.png')" class="empty-preview" alt="">
              </div>
            </div>
          </div>

        </div>

        <!-- Modal Preview -->
        <ModalPreviewImage
          :isActive="modalPreview"
          :urlOneImage="urlImagePreview"
          @closeModal="closeModalPreviewImage"
        />

        <ModalsPreviewSertifikat
          :isHandleOpenModalsPreviewSertifikat="isHandleOpenModalsPreviewSertifikat"
          :selectWorkers="selectWorkers"
          @closeModalPreviewSertifikat="closeModalPreviewSertifikat"
        />
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import { ArrowLeftIcon, SertifikatBadgeIcon } from '@/components/icons'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import ModalsPreviewSertifikat from '@/components/modal/ModalsPreviewSertifikat.vue'

export default {
  name: 'SidebarBeforeAfter',
  data () {
    return {
      open: false,
      overlay: true,
      fullheight: true,
      fullwidth: true,
      right: true,
      expandHover: true,
      delay: 2000,

      dataPhoto: null,
      photoBeforeCover: null,
      photoAfterCover: null,
      selectedPhoto: 0,

      // Preview Modal
      modalPreview: false,
      urlImagePreview: null,
      previewPhotoCarousel: null,
      isHandleOpenModalsPreviewSertifikat: false,
      selectWorkers: {}
    }
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    type: {
      type: String
    }
  },
  watch: {
    showSidebar (val) {
      this.open = val
    },
    data (val) {
      if (val) {
        if (this.type === 'detail') {
          this.dataPhoto = val
        } else if (this.type === 'list') {
          const newData = {
            ...val,
            photo_befores: val.photo_befores.map(item => item.image_url),
            photo_afters: val.photo_afters.map(item => item.image_url),
            before: val.before.map(item => item.image_url),
            after: val.after.map(item => item.image_url)
          }
          this.dataPhoto = newData
        }

        const arrPhotoBefore = this.dataPhoto.image_photo_before || this.dataPhoto.before || this.dataPhoto.photo_befores
        const arrPhotoAfter = this.dataPhoto.image_photo_after || this.dataPhoto.after || this.dataPhoto.photo_afters

        this.photoBeforeCover = this.getValidPhoto(arrPhotoBefore[0])
        this.photoAfterCover = this.getValidPhoto(arrPhotoAfter[0])

        const maxLength = Math.max(arrPhotoBefore.length, arrPhotoAfter.length)
        const combinePhoto = []

        const hasBeforeId = arrPhotoAfter.some(obj => obj && typeof obj === 'object' && 'image_before_id' in obj) // new data
        if (hasBeforeId) {
          for (let i = 0; i < arrPhotoBefore.length; i++) {
            let matchingAfter = null
            const before = this.getValidPhoto(arrPhotoBefore[i])

            if (before) {
              matchingAfter = arrPhotoAfter.find(after => after.image_before_id === before.id) || null
            }

            const obj = {
              pictureBefore: before,
              pictureAfter: matchingAfter
            }
            combinePhoto.push(obj)
          }
        } else {
          for (let i = 0; i < maxLength; i++) {
            const obj = {
              pictureBefore: i < arrPhotoBefore.length ? arrPhotoBefore[i] : null,
              pictureAfter: i < arrPhotoAfter.length ? arrPhotoAfter[i] : null
            }
            combinePhoto.push(obj)
          }
        }
        this.previewPhotoCarousel = combinePhoto
      }
    }
  },
  components: {
    ArrowLeftIcon,
    ModalPreviewImage,
    SertifikatBadgeIcon,
    ModalsPreviewSertifikat
  },
  computed: {},
  methods: {
    goBack () {
      this.selectedPhoto = 0
      this.$emit('closeSidebar')
    },
    openModalPreviewImage (image) {
      this.modalPreview = true
      this.urlImagePreview = image
    },
    closeModalPreviewImage () {
      this.modalPreview = false
      this.urlImagePreview = null
    },
    handleSelectedPhoto (index) {
      this.selectedPhoto = index
      const arrPhoto = this.previewPhotoCarousel[index]

      this.photoBeforeCover = this.getValidPhoto(arrPhoto.pictureBefore)
      this.photoAfterCover = this.getValidPhoto(arrPhoto.pictureAfter)
    },
    handleOpenModalsPreviewSertifikat (workersId, workers) {
      this.selectWorkers = { workersId, ...workers }
      this.isHandleOpenModalsPreviewSertifikat = true
    },
    closeModalPreviewSertifikat () {
      this.isHandleOpenModalsPreviewSertifikat = false
    },
    getValidPhoto (photo) {
      if (!photo || Object.keys(photo).length === 0) {
        return null
      }
      return photo
    }
  },
  created () {}
}
</script>

<style scoped>
.wrapper-content {
  width: 100%;
  height: auto;
  top: 158px;
  padding: 30px;
}

.wrapper-notif {
  width: 100%;
  height: 62px;
  padding: 12px;
  border-radius: 12px;
  gap: 4px;
  background: #FAFAFA;
}

.border-bottom {
  border-bottom: 2px solid #E1E1E1;
}

.tukang-img {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 35px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
}

.tukang-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-content {
  display: flex;
  align-items: center;
  margin-top: 2em;
}

.side-content {
  /* width: 493px; */
}

.cover-img {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 380.31px;
  max-width: 493px;
  position: relative;
  border-radius: 15px;
}

.wrap-images {
  position: relative;
  height: 100%;
  width: auto;
}

.cover-img .wrap-images .images {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.cover-img.before {
  justify-content: end;
  align-items: center;
}

.cover-img.after {
  justify-content: start;
  align-items: center;
}

.zoom-preview {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.item-carousel {
  width: 80px;
  height: 80px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.item-carousel.selected {
  border: 3px solid #911A1E;
}

.item-carousel:last-child {
  margin-right: 0 !important;
}

.img-carousel {
  height: 100%;
  flex: 1;
}

.img-carousel:last-child{
  border-left: 4px solid white;
}

.img-carousel img {
  object-fit: cover;
  height: 100%;
}

.empty-preview {
  filter: grayscale(1);
  opacity: 0.4;
  transform: scale(0.7);
}

@media only screen and (max-width: 600px) {
  .item-carousel:first-child {
    margin-left: 0;
  }

  .cover-img.before {
    justify-content: center;
    align-items: flex-end;
  }
  .cover-img.after {
    justify-content: center;
    align-items: center;
  }
  /* .wrap-images {

  } */

  /* .cover-img .wrap-images .images {

  } */
}
.badge-new-comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: auto;
  max-width: auto;
  height: auto;
  margin: 0;
  padding: 0.25rem 0.5rem;
  background: #ECFAF0;
  border-radius: 1000px;
}
</style>
