<template>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1647_105079)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.00016 0.836354C2.69891 0.836354 0.833497 2.70177 0.833497 5.00302C0.833144 5.61693 0.968453 6.22333 1.22975 6.77885L0.842247 8.66927C0.828451 8.73677 0.831606 8.80663 0.851431 8.87261C0.871255 8.93859 0.907131 8.99862 0.955845 9.04734C1.00456 9.09605 1.06459 9.13193 1.13057 9.15175C1.19655 9.17158 1.26642 9.17473 1.33391 9.16094L3.22433 8.77344C3.7635 9.0276 4.366 9.16927 5.00016 9.16927C7.30141 9.16927 9.16683 7.30427 9.16683 5.0026C9.16683 2.70177 7.30141 0.835938 5.00016 0.835938"
        fill="#E10009"
      />
    </g>
    <defs>
      <clipPath id="clip0_1647_105079">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'NewMessageIcon'
}
</script>
