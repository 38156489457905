<template>
  <svg width="25" height="auto" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_2019_141464"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="3"
      width="15"
      height="12"
    >
      <path
        d="M13.7547 9.02824L15.7241 9.02824C16.0417 9.02824 16.197 9.40942 15.9712 9.62824L13.2959 12.3106C13.1547 12.4518 12.9359 12.4518 12.7947 12.3106L10.1194 9.6353C9.90057 9.40942 10.0559 9.02824 10.3735 9.02824L12.3429 9.02824C12.3429 6.3953 9.92881 4.31295 7.19704 4.89177C5.59469 5.22354 4.30292 6.52236 3.97116 8.11765C3.44175 10.6377 5.17116 12.8824 7.50763 13.2212C7.84645 13.2706 8.10763 13.5671 8.10763 13.9129C8.10763 14.3365 7.73351 14.6753 7.30998 14.6188C4.21116 14.1812 1.91704 11.2306 2.56645 7.89177C2.99704 5.68942 4.76881 3.91765 6.97116 3.48707C10.5923 2.78824 13.7547 5.54118 13.7547 9.02824Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_2019_141464)">
      <rect
        width="16.9412"
        height="16.9412"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 17.7344 17.4688)"
        fill="#252525"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArrowRotateRightIcon'
}
</script>
