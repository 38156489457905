<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-modal v-model="isHandleOpenModalPageRating" :can-cancel="false">
    <div class="modal-content">
      <div class="modal-body gap-8">
        <div
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100"
        >
          <HasNoRateIcon />
        </div>
        <div
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-2 c-w-100"
        >
          <p class="averta-bold color-black label-20 m-0">
            Masih Ada Jagoan Kanggo yang Belum Dirating
          </p>
          <p class="averta-reguler color-black label-15 m-0">
            Ulasan kamu membantu kami menjadi lebih baik
          </p>
        </div>
        <div
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-2 c-w-100"
        >
          <p class="averta-reguler color-black label-15 m-0">
            Butuh bantuan? Hubungi
            <span class="averta-reguler color-red label-15 m-0 is-clickable">Business Support</span>
          </p>
        </div>
        <div
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-2 c-w-100"
        >
          <ButtonApp width="100%" height="45px" :isSecondary="true" @click="isRefreshPage()">
            <p class="averta-bold color-red label-15 m-0">Rating Lagi Nanti</p>
          </ButtonApp>
          <ButtonApp
            width="100%"
            height="45px"
            :hoverEnabled="false"
            @click="isToPagesDetailRating()"
          >
            <p class="averta-bold white-color label-15 m-0">Rating Tukang Sekarang</p>
          </ButtonApp>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { HasNoRateIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp'
export default {
  name: 'modalsHasNoRate',
  components: {
    HasNoRateIcon,
    ButtonApp
  },
  props: {
    isHandleOpenModalPageRating: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isRefreshPage () {
      window.location.reload()
    },
    isToPagesDetailRating () {
      this.$router
        .push({
          name: 'ViewsRating',
          params: { id: this.$route.params.id }
        })
        .then(() => {
          window.location.reload()
        })
    }
  }
}
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: auto;
  min-height: auto;
  max-height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
  z-index: 9999;
}
.modal-body {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 3rem;
  background: #ffffff;
  border-radius: 20px;
}
</style>
