<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-modal v-model="ishandleOpenModalsConfirmRating" :can-cancel="false">
    <div class="modal-content">
      <div class="modal-body gap-8">
        <div
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100"
        >
          <ConfirmRatingIcon />
        </div>
        <div
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-2 c-w-100"
        >
          <p class="averta-black color-red label-20 m-0">Pastikan Rating dan Ulasan Sudah Benar!</p>
          <p class="averta-reguler color-black label-15 m-0 c-w-65" style="text-align: center">
            Rating yang kamu berikan akan ditampilkan di Client dan menjadi penilaian mutlak untuk
            tukang.
          </p>
        </div>
        <div
          class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-2 c-w-100"
        >
          <ButtonApp
            width="100%"
            height="45px"
            :hoverEnabled="true"
            :isSecondary="true"
            @click="closeModal"
          >
            <p class="averta-bold white-color label-15 m-0">Cek Lagi</p>
          </ButtonApp>
          <ButtonApp width="100%" height="45px" :hoverEnabled="false" @click="submitDataRating">
            <p class="averta-bold white-color label-15 m-0">Kirim Rating</p>
          </ButtonApp>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { ConfirmRatingIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
export default {
  name: 'modalsConfirmRating',
  components: {
    ConfirmRatingIcon,
    ButtonApp
  },
  props: {
    ishandleOpenModalsConfirmRating: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModalConfirmRating')
    },
    submitDataRating () {
      this.$emit('submitRating')
    }
  }
}
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: auto;
  min-height: auto;
  max-height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
  z-index: 9999;
}
.modal-body {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 3rem;
  background: #ffffff;
  border-radius: 20px;
}
</style>
