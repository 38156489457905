<template>
  <div class="input-search-wrapper">
    <title-bar :title-stack="title || ''" />
    <div class="is-flex is-align-items-center flex-sm-column c-w-sm-100">
      <b-field class="c-w-sm-100 input-wrapper mr-3 mx-sm-0">
        <b-input placeholder="ID/Nama Toko/No. Invoice" type="search" icon="magnify" v-model="keyword" @keyup.native.enter="handleSearch" class="search-wrapper"> </b-input>
      </b-field>
      <div class="is-flex is-align-items-center">
        <InputAutocomplete header="Pilih Client" :isVendor="true" :isNew="true" :haveImg="true" imgKey="logo" :onSelectItem="handleSelectVendor" :onClearSearch="handleSelectVendor" class="mr-2" />
        <div class="c-w-sm-100 mt-sm-2 mb-sm-2">
          <b-dropdown aria-role="list" v-model="sort" class="dropdown-list c-w-100">
            <template #trigger="{ active }">
              <b-button :icon-right="active ? 'menu-up' : 'menu-down'" class="btn-dropdown">
                <div class="is-flex is-align-items-center pr-6">
                  <AscDscIcon class="mr-3" />
                  <p class="averta-regular">{{ sort ? (sort === 'asc' ? 'Terlama' : 'Terbaru') : 'Pilih Urutan' }}</p>
                </div>
              </b-button>
            </template>
            <b-dropdown-item aria-role="listitem" value="desc" @click="onClickItem">Terbaru</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" value="asc" @click="onClickItem">Terlama</b-dropdown-item>
          </b-dropdown>
        </div>
        <ButtonApp class="ml-3" :isSecondary="true" @click="handleSpecificSearch">
          <div class="flex-center-vertical" style="padding: 7px 0;">
            <SearchIcon color="#E10009" size="17" />
            <p class="averta-bold ml-1">Cari Lebih Spesifik</p>
          </div>
        </ButtonApp>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'

export default {
  name: 'InputSearchGlobal',
  props: {
    title: {
      type: Array
    }
  },
  components: {
    AscDscIcon: () => import('@/components/icons'),
    TitleBar: () => import('@/components/TitleBar'),
    InputAutocomplete: () => import('@/components/input/InputAutocomplete.vue'),
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    SearchIcon: () => import('@/components/icons/SearchIcon')
  },
  data () {
    return {
      keyword: '',
      sort: 'desc',
      vendor: null,
      isCurrentPage: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      listPersist: 'list/getPersistDataList'
    })
  },
  methods: {
    handleSelectVendor (props = {}) {
      const { dataVendor } = props
      if (dataVendor) {
        this.vendor = dataVendor.name
      } else {
        this.vendor = null
      }
      this.handleQuery()
    },
    onClickItem () {
      this.isCurrentPage = true
    },
    handleSearch () {
      if (!this.keyword) {
        return alertErrorMessage('Masukan Kata Kunci')
      }

      const body = {
        keyword: this.keyword,
        client: null,
        area: null,
        unit: null,
        building: null,
        start_date: null,
        end_date: null,
        type: null,
        status: null
      }

      if (this.vendor) {
        body.client = {
          name: this.vendor,
          code: this.vendor.toLowerCase().includes('summervil') ? 'SMRCN' : ''
        }
      }

      this.$store.commit('projectV2/setSpecificSearch', body)
      this.handleSpecificSearch()

      // const currentRole = this.user.roles[0].name
      // let routerPath = ''
      // switch (currentRole) {
      //   case 'Site Manager':
      //     routerPath = '/sm/search'
      //     break
      //   case 'Account Manager':
      //   case 'Business Support':
      //     routerPath = '/am/search'
      //     break
      //   case 'Purchasing':
      //     routerPath = '/purchasing/search'
      //     break
      //   default:
      //     routerPath = '/search'
      //     break
      // }

      // const payloadQuery = {
      //   keyword: this.keyword
      // }

      // if (this.sort) {
      //   payloadQuery.sort = this.sort
      // }
      // if (this.vendor) {
      //   payloadQuery.vendor = this.vendor
      // }

      // this.$router.push({ path: routerPath, query: payloadQuery })
    },
    handleQuery () {
      const payloadQuery = {}

      if (this.sort) {
        payloadQuery.sort = this.sort
      }
      if (this.vendor) {
        payloadQuery.vendor = this.vendor
      }

      this.$store.commit('project/setDataListFilter', payloadQuery)
      this.$router.replace({ query: payloadQuery })
    },
    handleSpecificSearch () {
      const currentRole = this.user.roles[0].name

      let path = ''
      switch (currentRole) {
        case 'Site Manager':
          path = '/sm/specific-search'
          break
        case 'Account Manager':
        case 'Business Support':
          path = '/am/specific-search'
          break
        case 'Purchasing':
          path = '/purchasing/specific-search'
          break
        default:
          path = '/specific-search'
          break
      }
      this.$router.push({ path })
    }
  },
  watch: {
    sort () {
      if (!this.listPersist.detailPage || this.isCurrentPage) {
        const queryParams = this.$route.query
        if (queryParams.vendor) {
          this.vendor = queryParams.vendor
        }
        this.handleQuery()
      }
    },
    '$route.query': {
      immediate: true,
      handler (newQuery, oldQuery) {
        if (!oldQuery) {
          if (JSON.stringify(newQuery) !== JSON.stringify(oldQuery)) {
            this.sort = newQuery.sort || 'desc'
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.input-search-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: auto;
  margin-top: 0.5em;
  margin-bottom: 2.5em;
}

.search-wrapper {
  width: 27em;
}

.input-wrapper {
  border: 1px solid #E1E1E1;
  border-radius: 5px;
  margin-bottom: 0;
}

.dropdown-list {
  border: 1px solid #E1E1E1;
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .input-search-wrapper {
    flex-direction: column;
    margin-bottom: 0.5em;
  }

  .search-wrapper {
    width: 100%;
  }
}
</style>
