<template>
  <div>
    <div v-for="(itemArea, indexArea) in areas" :key="itemArea.area_id">
      <div class="tabs tabs-area-wrapper thin-scroll user-select-text" v-if="selectedAreaId === itemArea.area_id">
        <!-- tabs component areas -->
        <div class="overflow-x-auto overflow-y-hidden thin-scroll tabs-component-wrapper">
          <ul v-if="sliceComponent1.length > 0">
            <li v-for="(itemComponent, indexComponent) in sliceComponent1" :key="itemComponent.component_name" :class="{'is-active averta-bold': currentClick === itemComponent.component_id}">
              <a @click="handleClick(itemComponent.component_id)">
                <div class="is-flex is-align-items-center">
                  <span>{{ itemComponent.component_name }}</span>
                  <div
                    v-if="handleValidateForm(indexArea, indexComponent) && showError"
                    class="ml-2"
                  >
                    <AlertIcon />
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <ul v-if="sliceComponent2.length > 0">
            <li v-for="(itemComponent, indexComponent) in sliceComponent2" :key="itemComponent.component_name" :class="{'is-active averta-bold': currentClick === itemComponent.component_id}">
              <a @click="handleClick(itemComponent.component_id)">
                <div class="is-flex is-align-items-center">
                  <span>{{ itemComponent.component_name }}</span>
                  <div
                    v-if="handleValidateForm(indexArea, indexComponent) && showError"
                    class="ml-2"
                  >
                    <AlertIcon />
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <ul v-if="sliceComponent3.length > 0">
            <li v-for="(itemComponent, indexComponent) in sliceComponent3" :key="itemComponent.component_name" :class="{'is-active averta-bold': currentClick === itemComponent.component_id}">
              <a @click="handleClick(itemComponent.component_id)">
                <div class="is-flex is-align-items-center">
                  <span>{{ itemComponent.component_name }}</span>
                  <div
                    v-if="handleValidateForm(indexArea, indexComponent) && showError"
                    class="ml-2"
                  >
                    <AlertIcon />
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <!-- end tabs component areas -->

        <!-- content -->
        <div v-for="(itemComponent, indexComponent) in itemArea.components" :key="`${itemComponent.component_name}-content`">
          <div v-if="currentClick === itemComponent.component_id && selectedAreaId === itemArea.area_id">
            <div v-for="(itemDetail, indexDetail) in itemComponent.detail_components" :key="itemDetail.id">
              <p class="averta-bold is-uppercase white-space-initial" :class="{ 'mt-3': indexDetail === 0, 'mt-5': indexDetail > 0 }">
                · {{ itemDetail.detail_component_name }}
              </p>
              <div class="is-flex mt-2 mb-5 note-wrapper p-4">
                <p class="mr-2 white-space-no-wrap">Catatan :</p>
                <p class="white-space-initial">{{ itemDetail.note }} </p>
              </div>
              <div
                class="mb-4"
                v-for="(itemItem, indexItem) in itemDetail.item"
                :key="indexItem"
              >
                <div class="p-5 item-job-wrapper">
                  <div class="item-job">
                    <div class="align-self-sm-start">
                      <p>Item Pekerjaan</p>
                      <p class="averta-bold white-space-normal ellipsis-3">{{ itemItem.note_item_working }}</p>
                      <p v-if="itemItem.random">{{itemItem.random}}</p>
                    </div>
                  </div>
                  <!-- compress foto here -->
                  <div class="border-top-dotted mt-4">
                    <p class="pt-3 mb-2">Foto Survey <i>(Max.{{maxPhotoSizeMB}}MB/Foto)</i><span class="text-merah"> *</span></p>
                    <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start">
                      <div class="is-relative mr-3"
                        v-for="(itemPhoto, indexPhoto) in itemItem[`photo_area_${itemArea.area_id}_component_${itemComponent.component_id}_detail_${itemDetail.id}_item_${indexItem + 1}`]"
                        :key="indexPhoto"
                      >
                        <div
                          v-if="itemPhoto.can_delete"
                          class="x-mark-rounded cursor-pointer"
                          @click="handleDeletePhoto(`photo_area_${itemArea.area_id}_component_${itemComponent.component_id}_detail_${itemDetail.id}_item_${indexItem + 1}`, indexArea, indexComponent, indexDetail, indexItem, indexPhoto, itemPhoto, itemItem.note_item_working)"
                        >
                          <img :src="require('@/assets/img/close-circle.png')" alt=""/>
                        </div>
                        <div v-if="uniqueUpload === `${indexArea}${indexComponent}${indexDetail}${indexItem}${indexPhoto}`">
                          <div
                            v-if="isLoadingProgress"
                            class="wrap-bar"
                          >
                            <div class="bar">
                              <div class="progress"></div>
                            </div>
                            <div class="text">
                              <p>Loading...
                                <span class="mask"/>
                              </p>
                            </div>
                          </div>
                        </div>
                        <label
                          v-if="Object.keys(itemPhoto).length === 0 || itemPhoto.preview === null"
                          class="cursor-pointer"
                          :for="'input-' + `${indexArea}${indexComponent}${indexDetail}${indexItem}${indexPhoto}`"
                        >
                          <input
                            type="file"
                            :id="'input-' + `${indexArea}${indexComponent}${indexDetail}${indexItem}${indexPhoto}`"
                            @change="e =>
                              handleFileUpload(
                                e,
                                indexArea,
                                indexComponent,
                                indexDetail,
                                indexItem,
                                indexPhoto,
                                itemArea.area_id,
                                itemComponent.component_id,
                                itemDetail.id,
                                indexItem + 1,
                                itemItem.note_item_working
                              )"
                            accept="image/*"
                            hidden
                          >
                          <img :src="require('@/assets/img/upload-photo.png')" alt="">
                        </label>
                        <div
                          class="wrapper-add-file cursor-pointer"
                          v-else-if="itemPhoto.preview"
                          @click="openModalPreviewImage(itemItem[`photo_area_${itemArea.area_id}_component_${itemComponent.component_id}_detail_${itemDetail.id}_item_${indexItem + 1}`])"
                        >
                          <img :src="itemPhoto.preview" alt="">

                        </div>
                        <div v-else class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-2">
                          <div  @click="openModalPreviewImage(itemItem[`photo_area_${itemArea.area_id}_component_${itemComponent.component_id}_detail_${itemDetail.id}_item_${indexItem + 1}`])" class="wrapper-add-file cursor-pointer">
                            <img :src="itemPhoto.picture_url" alt="">
                          </div>
                          <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100"
                          @click="handleOpenModalChangeImage(itemPhoto.picture_url, itemPhoto)">
                            <p class="averta-bold color-red cursor-pointer label-12">Ganti Foto</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalPreviewImage
      :isActive="modalPreviewImageActive"
      :dataImage="imagePreviewCarousel"
      title=""
      @closeModal="closeModalPreviewImage()"
    />
    <ModalChangePhotoSurvey
      ref="ModalChangePhotoSurvey"
      :isHandleOpenModalChangePhotos="isHandleOpenModalChangePhotos"
      :selectImage="selectImage"
      @closeModalChangePhotosSurvey="closeModalChangePhotosSurvey"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Compressor from 'compressorjs'
import { AlertIcon } from '@/components/icons'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import ModalChangePhotoSurvey from '@/components/modal/ModalChangePhotoSurvey'

export default {
  name: 'TabComponentPhoto',
  data () {
    return {
      areas: this.dataAreas,
      isError: false,
      isChange: false,
      validateForm: [],
      isLoading: false,
      imagePreviewCarousel: [],
      modalPreviewImageActive: false,
      maxPhotoSizeMB: 5,
      isFullPage: false,
      uniqueUpload: null,
      isLoadingProgress: false,
      currentClick: this.selectedComponentId,
      sliceComponent1: this.dataComponents.sliceComponent1 || [],
      sliceComponent2: this.dataComponents.sliceComponent2 || [],
      sliceComponent3: this.dataComponents.sliceComponent3 || [],
      isHandleOpenModalChangePhotos: false,
      selectImage: {}
    }
  },
  components: {
    AlertIcon,
    ModalPreviewImage,
    ModalChangePhotoSurvey
  },
  props: ['dataAreas', 'dataComponents', 'selectedAreaId', 'selectedComponentId', 'indexArea', 'showError'],
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataUpdateItemPekerjaan: 'project/getDataUpdateItemPekerjaanBS'
    })
  },
  watch: {
    areas: {
      handler: function (newVal) {
        if (newVal || newVal > 0) {
          this.handleIsChange()
          this.validateAreas(newVal)
        }
      },
      deep: true
    },
    dataComponents (newVal) {
      this.sliceComponent1 = newVal.sliceComponent1 || []
      this.sliceComponent2 = newVal.sliceComponent2 || []
      this.sliceComponent3 = newVal.sliceComponent3 || []
    },
    indexArea () {
      // use IndexArea because cant detect selectedComponentId with selectedComponentId watch
      this.currentClick = this.selectedComponentId
    }
  },
  methods: {
    handleValidateForm (indexArea, indexComp) {
      if (
        this.validateForm.find(
          (e) => e.area === indexArea && e.component === indexComp
        )
      ) {
        return true
      } else {
        return false
      }
    },
    async handleFileUpload (event, indexArea, indexComponent, indexDetail, indexItem, indexPhoto, idArea, idComponent, idDetail, idItem, note) {
      this.uniqueUpload = `${indexArea}${indexComponent}${indexDetail}${indexItem}${indexPhoto}`
      this.isLoadingProgress = true
      const selectedFile = event.target.files[0]

      if (selectedFile) {
        const fileSizeInMB = selectedFile.size / (1024 * 1024)
        const allowedTypes = [
          'image/jpeg',
          'image/png',
          'image/jpg',
          'image/JPEG',
          'image/PNG',
          'image/JPG'
        ]

        // VALIDASI
        if (!allowedTypes.includes(selectedFile.type)) {
          this.$buefy.toast.open({
            message: 'Hanya file gambar yang diperbolehkan (JPEG, JPG, PNG)',
            type: 'is-danger'
          })
          this.isLoadingProgress = false
          return
        }

        // VALIDASI
        if (fileSizeInMB.toFixed(2) > this.maxPhotoSizeMB) {
          this.$buefy.toast.open({
            message: 'Gambar melebihi ukuran maksimum yang diizinkan',
            type: 'is-danger'
          })
          this.isLoadingProgress = false
          return
        }

        const payload = {
          survey_id: this.$route.params.surveyId,
          image: selectedFile,
          key_photo: `photo_area_${idArea}_component_${idComponent}_detail_${idDetail}_item_${indexItem + 1}`
        }

        await this.handleCompressImage(payload)
          .then(results => {
            if (results.status === 201) {
              this.isLoadingProgress = false
              this.areas[indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem][`photo_area_${idArea}_component_${idComponent}_detail_${idDetail}_item_${idItem}`][indexPhoto] = {
                preview: results.data.data.image_url,
                picture: results.data.data.image_url,
                can_delete: true,
                id: results.data.data.id
              }
              this.areas[indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem].note_item_working = `${note} ` // add space to update image
              this.$emit('handleChangeStep', true)
            }
          }).catch(err => {
            this.isLoadingProgress = false
            console.log(err)
            this.$buefy.toast.open({
              message: 'gagal memuat data!',
              type: 'is-danger'
            })
          })
      }
    },
    handleCompressImage (data) {
      return new Promise((resolve, reject) => {
        const compressor = new Compressor(data.image, {
          quality: 0.6,
          mimeType: 'image/jpeg',
          success: async (result) => {
            const payload = {
              url: this.url,
              survey_id: data.survey_id,
              image: result,
              key_photo: data.key_photo
            }

            try {
              const response = await this.$store.dispatch('projectV2/uploadPhotoStep2', payload)
              resolve(response)
            } catch (error) {
              this.isLoadingProgress = false
              this.$buefy.toast.open({
                message: error.message,
                type: 'is-danger'
              })
            }
          },
          error (err) {
            console.error(err.message)
            reject(err)
          }
        })

        return compressor
      })
    },
    handleDeletePhoto (key, indexArea, indexComponent, indexDetail, indexItem, indexPhoto, itemPhoto, note) {
      // const payload = {
      //   url: this.url,
      //   survey_id: this.$route.params.surveyId,
      //   key,
      //   index: indexPhoto
      // }
      try {
        const payload = {
          url: this.url,
          id: itemPhoto.id
        }
        this.$store
          .dispatch('projectV2/deletePhotoStep2', payload)
          .then(response => {
            if (response.status === 201) {
              if (itemPhoto.preview || itemPhoto.picture_url) {
                this.areas[indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem][key][indexPhoto].can_delete = false
                this.areas[indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem][key][indexPhoto].preview = null
                this.areas[indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem][key][indexPhoto].picture = null
                this.areas[indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem].note_item_working = `${note} ` // add space to update image
                // return

                if (itemPhoto.picture_url) {
                  this.areas[indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem][key][indexPhoto].picture_url = null
                }
              }
              this.$emit('refreshData', true)
            }
          })
          .catch(error => {
            console.log(error)
            this.$buefy.toast.open({
              message:
                'Gagal delete Foto Survey, silahkan coba lagi',
              type: 'is-danger'
            })
          })
      } catch (error) {
        console.log(error)
      }
    },
    handleIsChange () {
      this.$emit('handleIsChange', true)
    },
    closeModalPreviewImage () {
      this.imagePreviewCarousel = null
      this.modalPreviewImageActive = false
    },
    openModalPreviewImage (data) {
      const arr = []
      for (const item of data) {
        if (item.preview) {
          arr.push(item.preview)
        } else if (item.picture_url) {
          arr.push(item.picture_url)
        }
      }
      this.imagePreviewCarousel = arr
      this.modalPreviewImageActive = true
    },
    isEmptyItem (obj) {
      let value = false

      for (const element of obj) {
        if (element) {
          if (Object.keys(element).length < 1 || ('preview' in element ? !element.preview : false)) {
            value = true
            break
          } else if (element.preview || element.picture_url) {
            value = false
          }
          // if (element.preview || element.picture_url) {
          //   value = false
          //   break
          // } else {
          //   value = true
          // }
        }
      }
      return value
    },
    handleClick (value) {
      this.currentClick = value
    },
    async validateAreas (arr) {
      if (!arr || arr.length < 1) {
        return
      }
      await arr.map((itemArea, indexArea) => {
        itemArea.components.map((itemComp, indexComp) => {
          itemComp.detail_components.map((itemDetail, indexDetail) => {
            itemDetail.item.map((itemItem, indexItem) => {
              const newObj = {
                area: indexArea,
                component: indexComp,
                detail: indexDetail,
                item: indexItem
              }
              const found = this.validateForm.some(
                (o) =>
                  o.area === indexArea &&
                    o.component === indexComp &&
                    o.detail === indexDetail &&
                    o.item === indexItem
              )
              const idx = this.validateForm.findIndex(
                (x) =>
                  x.area === indexArea &&
                    x.component === indexComp &&
                    x.detail === indexDetail &&
                    x.item === indexItem
              )

              const isEmpty = this.isEmptyItem(itemItem[`photo_area_${itemArea.area_id}_component_${itemComp.component_id}_detail_${itemDetail.id}_item_${indexItem + 1}`])
              if (isEmpty) {
                if (found) {
                  this.validateForm[idx] = newObj
                } else {
                  this.validateForm.push(newObj)
                }
              } else {
                if (found) {
                  this.validateForm.splice(idx, 1)
                }
              }
            })
          })
        })
      })
      this.$emit('handleValidateForm', this.validateForm)
    },
    handleOpenModalChangeImage (id, dataImage) {
      this.isHandleOpenModalChangePhotos = true
      this.selectImage = { id, ...dataImage, fromStepOffering: true }
      this.triggerFileInput()
    },
    triggerFileInput () {
      setTimeout(() => {
        this.$refs.ModalChangePhotoSurvey.triggerFileInput()
      }, 500)
    },
    closeModalChangePhotosSurvey () {
      this.isHandleOpenModalChangePhotos = false
    }
  },
  created () {
    this.validateAreas(this.areas)
  }
}
</script>

<style scoped>
.white-space-no-wrap {
  white-space: nowrap;
}

.note-wrapper {
  background: #fafafa;
  border-radius: 100px;
}

.item-job-wrapper {
  background: #fafafa;
  border-radius: 12px 12px 0 0;
}

.item-job {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border-top-dotted {
  border-top: 1px dashed #e1e1e1;
}

.wrapper-add-file {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 14px;
  width: 86px;
  height: 86px;
  position: relative;
}

.wrapper-add-file img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* Loading Bar */
.wrap-bar {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  top: 2.2rem;
}
.bar {
  position: relative;
  height: 15px;
  width: 83px;
  border-radius: 5px;
  box-shadow: 2px 3px 6px 0 rgba(0,0,0,0.2);
}
.progress {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0%;
  animation: progressing 3s infinite;
  background-color: #7abf70;
}
@keyframes progressing {
  100% { width: 100%; }
}
.text {
  position: relative;
  overflow: hidden;
}
.text p {
  margin-bottom: 0;
}
.mask {
  height: 100%;
  width: 20px;
  position: absolute;
  z-index: 1;
  left: 80%;
  animation: masking 1s linear infinite;
}
@keyframes masking {
  100% { left: 100%; }
}

</style>
