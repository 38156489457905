<template>
  <div>
    <ButtonApp :isBold="true" @click="handlePublishInvoice">
      <div class="is-flex is-align-items-center py-2">
        <SendIcon size="20" />
        <p class="ml-1">Terbitkan Invoice ke Client</p>
      </div>
    </ButtonApp>

    <b-modal v-model="isModalActive">
      <div class="flex-center color-black">
        <div class="modal-publish-invoice">
          <div class="body-invoice-wrapper">
            <div class="flex-center is-flex-direction-column">
              <img :src="require(`@/assets/img/${alreadyConfirm ? 'reset-password' : 'forgot-password'}.png`)" alt="" />
              <p class="label-16 averta-bold mb-1 mt-5 label-16">{{alreadyConfirm ? 'Invoice berhasil diterbitkan' : 'Terbitkan Invoice ke Client'}}</p>
              <p v-if="alreadyConfirm" class="has-text-centered mb-5">Client sudah dapat mengakses invoice melalui Kanggo Dashboard</p>
              <p v-else class="has-text-centered mb-5">Periksa dan pastikan seluruh informasi invoice telah benar dan sesuai. <span class="color-red averta-bold">Invoice hanya dapat diterbitkan 1 kali.</span></p>
            </div>

            <div class="border-radius-20 overflow-hidden bg-lightgray p-4 c-w-100 mb-4">
              <p class="averta-bold mb-3">Detail Invoice</p>
              <div class="p-3 border-radius-20 overflow-hidden bg-white">
                <p class="color-grey">Kepada Yth:</p>
                <p class="averta-bold mb-2">{{dataInvoice.recipient}}</p>
                <div class="c-w-100 m-auto columns border-radius-20 overflow-hidden bg-lightgray px-2">
                  <div class="column is-3">
                    <div class="border-right-2">
                      <p class="color-gray mb-1">ID</p>
                      <p class="averta-bold">ID{{dataInvoice.survey_id}}</p>
                    </div>
                  </div>
                  <div class="column is-5">
                    <div class="border-right-2">
                      <p class="color-gray mb-1">Grand Total</p>
                      <p class="color-red averta-bold">Rp{{ formatThousand(dataInvoice.price.grand_total) }}</p>
                    </div>
                  </div>
                  <div class="column is-4">
                    <p class="color-gray mb-1">Tanggal Terbit</p>
                    <p class="averta-bold">{{formatTglTerbit()}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="c-w-100 bg-white pt-3 border-top-1 mr-4">
            <div v-if="!alreadyConfirm" class="c-w-100 mb-4">
              <label class="checkbox flex-center-vertical">
                <input class="tf-scale-2 accent-color-red tf-origin-left mr-4" v-model="isChecked" type="checkbox" :disabled="isLoading" />
                <p>Invoice telah benar dan sesuai untuk diterbitkan ke client</p>
              </label>
            </div>

            <div v-if="alreadyConfirm" class="c-w-100" @click="handleCloseModal">
              <ButtonApp class="c-w-100">
                <p class="py-2 averta-bold">Tutup</p>
              </ButtonApp>
            </div>
            <div v-else class="flex-center c-w-100">
              <ButtonApp :isSecondary="true" class="flex-1 mr-2" :isDisabled="isLoading" @click="handleCloseModal">
                <p class="py-2 averta-bold">Batalkan</p>
              </ButtonApp>
              <ButtonApp class="flex-1" :isDisabled="!isChecked || isLoading" @click="putPublishInvoice">
                <div class="flex-center">
                  <p class="py-2 averta-bold">Terbitkan Invoice</p>
                  <div v-if="isLoading" class="ml-2">
                    <SpinnerLoading />
                  </div>
                </div>
              </ButtonApp>
            </div>
          </div>

        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { alertErrorMessage, formatCurrency } from '@/helpers'
import { SendIcon } from '@/components/icons'
import sunsetImage from '../../../../../assets/img/sunset.png'
import SpinnerLoading from '@/components/loading/SpinnerLoading.vue'

export default {
  name: 'PublishInvoice',
  components: {
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    SendIcon,
    SpinnerLoading
  },
  props: {
    refreshData: {
      type: Function
    },
    emptyRecipientFunction: {
      type: Function
    },
    emptyInvoiceFunction: {
      type: Function
    },
    recipient: {
      type: String,
      default: ''
    },
    invoice: {
      type: String,
      default: ''
    },
    dataInvoice: {
      type: Object
    }
  },
  data () {
    return {
      url: process.env.VUE_APP_SHELL_URL_API,
      isLoading: false,
      isModalActive: false,
      isChecked: false,
      alreadyConfirm: false
    }
  },
  methods: {
    formatTglTerbit (value) {
      return moment().format('DD/MM/YYYY')
    },
    formatThousand (value) {
      return formatCurrency(value, true)
    },
    handlePublishInvoice () {
      if (!this.recipient) {
        this.emptyRecipientFunction()
        return
      }
      if (!this.invoice) {
        this.emptyInvoiceFunction()
        return
      }

      this.alreadyConfirm = false
      this.isChecked = false
      this.isLoading = false

      const payload = {
        url: this.url,
        project_id: this.$route.params.projectId
      }

      this.$store
        .dispatch('project/checkPublishInvoice', payload)
        .then(() => {
          this.isModalActive = !this.isModalActive
        })
        .catch((error) => {
          const msg = error.response.data.message
          switch (msg) {
            case 'Status Konfirmasi Proyek Belum Terpenuhi':
              this.$swal({
                width: '450px',
                padding: '10px 10px 30px 10px',
                html: `
                  <img src="${sunsetImage}" class="mb-4" />
                  <p class="averta-bold mb-4 label-22">${msg}</p>
                  <p class="averta-regular">Periksa dan pastikan proyek yang akan dikonfirmasi telah selesai</p>
                `,
                confirmButtonText: 'Kembali',
                customClass: {
                  confirmButton: 'btn-hapus-detail-component'
                }
              })
              break

            default:
              alertErrorMessage(error)
              break
          }
        })
    },
    handleCloseModal () {
      if (this.isLoading) return
      this.isModalActive = false
      this.refreshData()
    },
    putPublishInvoice () {
      if (!this.isChecked || this.isLoading) return

      this.isLoading = true
      // eslint-disable-next-line no-unused-vars
      const payload = {
        url: this.url,
        project_id: this.$route.params.projectId
      }

      this.$store
        .dispatch('project/putPublishInvoice', payload)
        .then(() => {
          this.isLoading = false
          this.isModalActive = false

          setTimeout(() => {
            this.alreadyConfirm = true
            this.isModalActive = true
          }, 300)
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.modal-publish-invoice {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  background: $color-white;
  padding: 20px;
  padding-right:  0;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 20px;
}

.body-invoice-wrapper {
  max-height: 490px;
  width: 100%;
  overflow: auto;
  padding-right: 18px;
}
</style>
