<template>
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.16439 0.625C0.563138 0.625 0.0810547 1.10708 0.0810547 1.70833V7.125C0.0810547 7.41232 0.195191 7.68787 0.398356 7.89103C0.60152 8.0942 0.87707 8.20833 1.16439 8.20833H5.49772V10.9167L7.12272 9.29167L8.74772 10.9167V8.20833H9.83105C10.1184 8.20833 10.3939 8.0942 10.5971 7.89103C10.8003 7.68787 10.9144 7.41232 10.9144 7.125V1.70833C10.9144 1.42102 10.8003 1.14547 10.5971 0.942301C10.3939 0.739137 10.1184 0.625 9.83105 0.625H1.16439ZM5.49772 1.70833L7.12272 2.79167L8.74772 1.70833V3.60417L10.3727 4.41667L8.74772 5.22917V7.125L7.12272 6.04167L5.49772 7.125V5.22917L3.87272 4.41667L5.49772 3.60417V1.70833ZM1.16439 1.70833H3.87272V2.79167H1.16439V1.70833ZM1.16439 3.875H2.78939V4.95833H1.16439V3.875ZM1.16439 6.04167H3.87272V7.125H1.16439V6.04167Z"
      fill="#10BD41"
    />
  </svg>
</template>

<script>
export default {
  name: 'SertifikatBadgeIcon'
}
</script>
