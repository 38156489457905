<template>
  <ModalTemplate :showModal="showModal" width="650px" @closeModal="closeModal">
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div class="flex-center-vertical">
          <img :src="require('@/assets/icons/note-white-used.svg')" alt="" />
          <p class="averta-bold label-18 ml-3">
            {{ title }}
          </p>
        </div>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>
    <template v-slot:bodyModal>
      <div class="wrap-body-modal">
        <div class="body-form-modal">
          <textarea
            class="textarea input-search-1 averta-regular mt-1 label-14 c-w-100 bg-white"
            cols="30"
            placeholder="Masukan Catatan"
            v-model="note"
            :disabled="readOnly"
          ></textarea>
        </div>

        <div class="footer-modal" v-if="!readOnly">
          <ButtonApp height="45px" @click="handleSubmit">
            <p class="averta-bold px-4 py-2">Tambah Catatan</p>
          </ButtonApp>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
export default {
  name: 'ModalDescription',
  components: {
    ModalTemplate: () => import('@/components/modal/ModalTemplate'),
    ButtonApp: () => import('@/components/button/ButtonApp')
  },
  props: {
    dataProps: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      note: '',
      title: '',
      showModal: false
    }
  },
  computed: {
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    handleSubmit () {
      this.$emit('handleSubmit', { ...this.dataProps, note: this.note })
    }
  },
  watch: {
    dataProps: {
      handler (val) {
        if (val) {
          this.title = 'Lihat Catatan'
          this.showModal = val.showModal
          this.note = val.note
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.wrap-body-modal {
  background: white;
  height: 500px;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.body-form-modal {
  height: 83%;
  padding: 20px;
}

.body-form-modal textarea {
  height: 15em !important;
}

.footer-modal {
  box-shadow: 0px -3px 30px 0px #00000012;
  padding: 0px 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 17%;
}
</style>
