<template>
  <svg :width="size" height="auto" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_4876_19159"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="6"
      y="3"
      width="4"
      height="11"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99966 5.77276C8.71705 5.77276 9.30401 5.1858 9.30401 4.46841C9.30401 3.75102 8.71705 3.16406 7.99966 3.16406C7.28227 3.16406 6.69531 3.75102 6.69531 4.46841C6.69531 5.1858 7.28227 5.77276 7.99966 5.77276ZM7.99966 7.07711C7.28227 7.07711 6.69531 7.66406 6.69531 8.38145C6.69531 9.09885 7.28227 9.6858 7.99966 9.6858C8.71705 9.6858 9.30401 9.09885 9.30401 8.38145C9.30401 7.66406 8.71705 7.07711 7.99966 7.07711ZM6.69531 12.2945C6.69531 11.5771 7.28227 10.9901 7.99966 10.9901C8.71705 10.9901 9.30401 11.5771 9.30401 12.2945C9.30401 13.0119 8.71705 13.5988 7.99966 13.5988C7.28227 13.5988 6.69531 13.0119 6.69531 12.2945Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_4876_19159)">
      <rect x="0.173828" y="0.554688" width="15.6522" height="15.6522" fill="#E10009" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OptionThreeBulletsIcon',
  props: {
    size: {
      type: String,
      default: '25'
    },
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>
