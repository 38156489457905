
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-modal v-model="isHandleOpenModalCancelSurvey" :can-cancel="false">
      <div class="modal-content">
        <div class="modal-body">
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-10"
          >
            <AlertConfirmModalsIcon />
            <div
              class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center gap-1 c-w-85"
            >
              <p class="averta-bold label-24" style="text-align: center">Batalkan Survey Ini?</p>
              <p class="averta-regular label-18" style="text-align: center">
                Jika proyek ini di batalkan, maka konsultan tidak datang ke lokasi proyek
              </p>
            </div>
            <div
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-cente c-w-100 gap-4"
            >
              <p class="averta-regular label-20">
                Alasan Pembatalan <span class="averta-regular label-20 color-red">*</span>
              </p>
              <textarea
                class="textarea"
                rows="6"
                placeholder="Masukan Catatan"
                v-model="noteCancelSurvey"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div
            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 gap-2"
          >
            <ButtonApp height="45px" width="100%" :isSecondary="true" @click="closeModal">
              <p class="averta-bold">Cek Kembali</p>
            </ButtonApp>
            <ButtonApp
              height="45px"
              width="100%"
              :isSecondary="false"
              :isDisabled="!isDisabled"
              @click="submitCancelSurvey"
            >
              <p class="averta-bold">Batalkan Survey</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </b-modal>
    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AlertConfirmModalsIcon, SpinnerIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
export default {
  name: 'ModalCancelSurvey',
  props: {
    isHandleOpenModalCancelSurvey: {
      type: Boolean,
      default: false
    },
    dataSurvey: {
      type: Object,
      default: null
    }
  },
  components: {
    ButtonApp,
    AlertConfirmModalsIcon,
    SpinnerIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isDisabled () {
      return this.noteCancelSurvey
    }
  },
  data () {
    return {
      noteCancelSurvey: '',
      isLoading: false,
      isFullPage: true
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModalCancelSurvey')
    },
    async submitCancelSurvey () {
      this.isLoading = true

      try {
        const payload = {
          survey_id: this.dataSurvey.id,
          description: this.noteCancelSurvey
        }

        console.log('payload', payload)

        const res = await this.$store.dispatch('project/postCancelSurvey', {
          url: this.url,
          payload
        })

        if (res?.data?.code === 1) {
          await this.$swal({
            icon: 'success',
            title: 'Success',
            text: res.data.message
          })

          this.$buefy.toast.open({
            message: 'Survey Berhasil Dibatalkan'
          })

          window.location.reload()

          this.isLoading = false
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text:
              res?.data?.message ||
              'An error occurred while submitting the Survey Berhasil Dibatalkan.'
          })
        }
      } catch (error) {
        console.error(
          'Error submitting Survey Berhasil Dibatalkan:',
          error.response?.data || error.message
        )

        this.$swal({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'An unexpected error occurred. Please try again.'
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  width: 65%;
  height: auto;
  min-height: auto;
  max-height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.modal-body {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1.5rem 1.5rem;
  background: #ffffff;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #ffffff;
}
.textarea {
  border-radius: 20px;
  resize: none;
}

@media screen and (max-width: 1440px) {
  .modal-body{
    height: 70%;
    min-height: 70%;
    max-height: 70%;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
  }
}
</style>
