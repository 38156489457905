<template>
  <section>
    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
      <template #trigger="props">
        <div class="card-header header is-flex is-align-items-center p-3" role="button" aria-controls="contentIdForA11y3"
          :aria-expanded="props.open">
          <div class="card-header-title">
            <div class="is-flex is-align-items-center">
              <img src="../../assets/img/penawaran-pending.png" alt="survey-process" class="mr-3">
              <div>
                <p class="averta-bold is-size-5 has-text-white">Penawaran Ditolak</p>
                <p class="has-text-white">Client me-reject penawaran yang telah dikirimkan. Kamu dapat mengupdate dan
                  mengirim ulang penawaran ini ke client. </p>
              </div>
            </div>
          </div>
          <div class="mr-4 btn-tutup is-flex is-align-items-center">
            <span>Tutup Detail</span>
            <b-icon :icon="props.open ? 'chevron-down' : 'chevron-up'"></b-icon>
          </div>
        </div>
      </template>

      <div class="card-content">
        <div class="content">
          <div class="is-flex is-align-items-center mb-3">
            <ChatIcon class="mr-3" />
            <div>
              <p class="mb-0 averta-bold">{{ dataPenawaran ? dataPenawaran && dataPenawaran.rejected && dataPenawaran.rejected.reason : '' }}</p>
              <p class="text-grey">{{ dataPenawaran ? dataPenawaran && dataPenawaran.rejected && dataPenawaran.rejected.note : '' }}</p>
            </div>
          </div>
          <!-- <div class="is-flex is-align-items-center">
            <ChatIcon class="mr-3" />
            <div>
              <p class="mb-0 averta-bold">Keterangan Reject</p>
              <p class="text-grey">Harga per tukang dan material yang digunakan terlalu mahal tidak sesuai budget dan
                kebutuhan kita. Tolong diupdate dulu ya!</p>
            </div>
          </div> -->
          <div class="divider my-5"></div>
          <div class="is-flex is-justify-content-end" v-if="user.roles[0].name === 'Account Manager'">
            <button class="btn-update-harga is-clickable" @click="showModalChoosePenawaran()">Update Harga
              Penawaran</button>
          </div>
          <!-- <div class="is-flex is-justify-content-end">
            <button class="btn-update is-clickable">Update Penawaran</button>
          </div> -->
        </div>
      </div>
    </b-collapse>
    <b-modal v-model="modalChooseUpdate" :can-cancel="false">
      <div class="modal-wrapper">
        <div class="header-modal is-flex is-justify-content-space-between">
          <div class="is-flex is-align-items-center">
            <p class="is-size-5">Update Penawaran</p>
          </div>
          <div class="is-clickable" @click="modalChooseUpdate = false">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
        <div class="body-modal">
          <p class="my-5 has-text-grey">Silakan pilih untuk update penawaran yang ingin diubah</p>
          <div class="columns mb-5">
            <div class="column">
              <div class="card is-flex is-justify-content-center pb-6 is-clickable" @click="goToUpdateSatuan()">
                <div class="has-text-centered">
                  <img class="mt-5" src="../../assets/img/harga-satuan.png" alt="harga-satuan">
                  <p class="is-size-5 averta-bold my-3">Ubah Harga Satuan</p>
                  <p>Kamu dapat ubah harga satuan <br> pertukang dan Material</p>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="card is-flex is-justify-content-center pb-6 is-clickable" @click="showModalUpdatePenawaran()">
                <div class="has-text-centered">
                  <img class="mt-5" src="../../assets/img/ubah-diskon.png" alt="diskon">
                  <p class="is-size-5 averta-bold my-3">Ubah Harga Grand Total</p>
                  <p>Kamu dapat ubah harga total <br> harga keseluruhan tukang & material</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="modalUpdatePenawaran" :can-cancel="false">
      <div class="modal-wrapper">
        <div class="header-modal is-flex is-justify-content-space-between">
          <div class="is-flex is-align-items-center">
            <BagIcon class="mr-3 icon-bag" />
            <p class="averta-bold label-20">Update Penawaran</p>
          </div>
          <div class="is-clickable" @click="modalUpdatePenawaran = false">
            <b-icon icon="close"></b-icon>
          </div>
        </div>
        <div class="body-modal diskon" v-if="dataPenawaran">
          <div class="is-flex is-justify-content-space-between mb-4 is-align-items-center">
            <div>
              <p class="averta-bold is-size-5">Update Penawaran ?</p>
              <p>Penawaran baru yang telah di-update akan dikirimkan ke klien langsung untuk direview</p>
            </div>
            <img src="../../assets/img/diskon.png" alt="image-modal" class="image-modal">
          </div>
          <p class="averta-bold is-size-5 mb-3">Detail Penawaran</p>
          <div class="card p-4 mb-4">
            <div class="columns">
              <div class="column">
                <p class="has-text-grey">ID Penawaran</p>
                <p class="averta-bold is-size-5">ID #{{ dataPenawaran.id }}</p>
              </div>
              <div class="column">
                <p class="has-text-grey">Tanggal Pembuatan</p>
                <p class="averta-bold is-size-5">{{ convertDate(dataPenawaran.created_at) }}</p>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <p class="has-text-grey">Jumlah Tukang</p>
                <p class="averta-bold is-size-5">{{ dataPenawaran && dataPenawaran.rejected && dataPenawaran.rejected.total_worker }}</p>
              </div>
              <div class="column">
                <p class="has-text-grey">Grand Total</p>
                <p class="averta-bold is-size-5">{{ formatThousand(dataPenawaran && dataPenawaran.rejected && dataPenawaran.rejected.grand_total) }}</p>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <p class="has-text-grey">Total Durasi Proyek</p>
                <p class="averta-bold is-size-5">{{ dataPenawaran && dataPenawaran.rejected && dataPenawaran.rejected.duration }} Hari</p>
              </div>
            </div>
          </div>
          <p class="mb-3"><span class="averta-bold">Total Diskon</span> <i class="has-text-grey">(Potongan Harga)</i>
            <span class="has-text-danger"> *</span></p>
          <div class="is-flex wrapper-diskon mb-3">
            <p class="wrapper-rp p-4 averta-bold">Rp</p>
            <input type="number" class="form-control p-4 averta-bold" min="1" oninput="validity.valid||(value='');" placeholder="0" v-model="discount">
          </div>
          <div v-if="message !== ''" class="notification is-light is-danger">
            <p>{{ message }}</p>
          </div>
          <button class="btn-lanjut p-4 is-size-5 mt-3 mb-5 averta-bold is-clickable" :class="{'active' : discount !== null}" @click="updateGrandTotal()">Lanjutkan</button>
        </div>
      </div>
    </b-modal>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </section>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { BagIcon, ChatIcon, SpinnerIcon } from '../icons'
import { formatCurrency } from '@/helpers'

export default {
  name: 'CardRejected',
  props: ['dataPenawaran'],
  computed: {
    ...mapGetters({
      user: 'auth/user',
      url: 'setup/getUrl'
    })
  },
  components: {
    ChatIcon,
    BagIcon,
    SpinnerIcon
  },
  data () {
    return {
      modalUpdatePenawaran: false,
      modalChooseUpdate: false,
      discount: null,
      message: '',
      isLoading: false
    }
  },
  methods: {
    convertDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    showModalChoosePenawaran () {
      if (this.dataPenawaran.rejected.reject_type === 'default') {
        this.modalChooseUpdate = true
      } else if (this.dataPenawaran.rejected.reject_type === 'unit') {
        this.goToUpdateSatuan()
      } else if (this.dataPenawaran.rejected.reject_type === 'grand') {
        this.modalUpdatePenawaran = true
      }
    },
    showModalUpdatePenawaran () {
      this.modalUpdatePenawaran = true
      this.modalChooseUpdate = false
    },
    goToUpdateSatuan () {
      this.$router.push(`/am/project/offering/${this.dataPenawaran.id}/update-price`)
    },
    updateGrandTotal () {
      if (this.discount === null || this.discount === '' || this.discount <= 1) {
        this.message = 'Silahkan masukkan discount dengan benar'
        return
      }

      this.isLoading = true

      const payload = {
        url: this.url,
        offering_id: this.dataPenawaran.id,
        discount: this.discount
      }

      this.$store
        .dispatch('project/updateDiscountPenawaran', payload)
        .then(response => {
          this.modalUpdatePenawaran = false
          this.$emit('updateData')
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: 'Uups ada kesalahan, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    formatThousand (x) {
      return formatCurrency(x)
    }
  }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.header {
  background: linear-gradient(90deg, #891313 0%, #D92727 99.97%);
  border-radius: 12px 12px 0px 0px;
  color: white;
}

.btn-tutup {
  border: 1px solid white;
  padding: 12px 24px;
  border-radius: 24px;
}

.text-grey {
  color: #868686;
}

.divider {
  border-bottom: 2px dashed #e1e1e1;
}

.btn-update {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
  border-radius: 90px;
  color: white;
  font-size: 15px;
  font-family: 'Averta-Bold';
  padding: 14px 24px;
  border: none;
}

.btn-update-harga {
  background: white;
  ;
  border-radius: 90px;
  color: #EB4600;
  font-size: 15px;
  font-family: 'Averta-Bold';
  padding: 14px 24px;
  border: 1px solid #EB4600;
}

.header-modal {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.body-modal {
  background: white;
  padding: 12px 24px;
  border-radius: 0px 0px 12px 12px;
}

.body-modal.diskon {
  height: 70vh;
  overflow-y: auto;
}

.image-modal {
  width: 10%;
}

.icon-bag {
  width: 26px;
}

.wrapper-rp {
  background: #e1e1e1;
  border-radius: 10px 0px 0px 10px;
}

.form-control {
  width: 100%;
  border: solid 1px #e1e1e1;
  border-radius: 0px 10px 10px 0px;
}

.btn-lanjut {
  background: #9A9A9A;
  color: white;
  width: 100%;
  border: none;
  border-radius: 100px;
}
.btn-lanjut.active {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
}

</style>
