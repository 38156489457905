<template>
  <div v-if="isLogin" :style="{marginTop: marginTopWrapper + 'px'}">
    <!-- Navbar Top Desktop -->
    <nav v-show="isNavBarVisible" id="navbar-main" class="navbar navbar-dekstop is-fixed-top is-hidden-mobile">
      <img src="../assets/img/logo-kanggo-2.png" alt="logo-kanggo"
        class="img-kanggo ml-6 py-2 is-hidden-touch is-clickable" @click="handleClickIcon">
      <div class="navbar-brand">
        <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
          <b-icon :icon="menuToggleMobileIcon" />
        </a>
      </div>
      <div class="navbar-brand is-right">
        <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
          <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
        </a>
      </div>
      <div class="navbar-menu fadeIn animated faster" :class="{ 'is-active': isMenuNavBarActive }">
        <div class="navbar-end is-flex is-align-items-center">
          <a class="mr-5 is-relative" ref="button-notif" @click="show">
            <span class="tag is-danger is-rounded notif-count" v-if="totalUnread > 0">{{ totalUnread }}</span>
            <div class="card-notif">
              <NotificationIcon color="#252525" />
            </div>
          </a>
          <nav-bar-menu class="has-divider has-user-avatar">
            <user-avatar />
            <div class="is-flex is-flex-direction-row is-flex-justify-center is-align-items-center gap-2">
              <span class="averta-bold has-text-dark">{{ user.name }}</span>
              <span class="averta-bold has-text-dark">-</span>
              <span class="averta-bold-italic color-red">{{ userRolesTag(user.roles[0].name) }}</span>
            </div>
            <div slot="dropdown" class="navbar-dropdown is-right"
              :class="{ 'multiple-role-dropdown': user.roles.length > 1 }">
              <div v-if="canSwitchRole" class="p-3">
                <div class="is-flex is-align-items-center mb-3">
                  <div class="img-profile multirole">
                    <img v-if="user.profile_picture" :src="user.profile_picture" :alt="user.name">
                    <img v-else src="../assets/img/default-picture.jpg" :alt="user.name">
                  </div>
                  <div>
                    <p class="averta-bold has-text-dark">{{ user.name }}</p>
                    <p class="has-text-dark mb-1">{{ user.email }}</p>
                    <div class="role small px-2 py-1">
                      {{ userRoles(user.roles[0].name) }}
                    </div>
                  </div>
                </div>
                <div style="width: 100%;">
                  <button v-for="role in this.user.roles" :key="role.id" class="btn-role mt-2"
                    :class="{ 'is-hidden': currentRole === role.name }" @click="handleSwitchRole(role.id)">
                    <span class="py-2 averta-bold">{{ userRoles(role.name) }}</span>
                  </button>
                </div>
              </div>
              <div v-else class="has-text-centered mb-5" style="width: 20em">
                <img v-if="user.profile_picture" :src="user.profile_picture" :alt="user.name" class="img-profile">
                <img v-else src="../assets/img/default-picture.jpg" :alt="user.name" class="img-profile">
                <p class="averta-bold has-text-dark">{{ user.name }}</p>
                <p class="has-text-dark mb-4">{{ user.email }}</p>
                <div class="role mx-5 p-2">{{ userRoles(user.roles[0].name) }}</div>
              </div>
              <router-link to="/profile" class="navbar-item" exact-active-class="is-active">
                <AccountIcon class="mr-3" />
                <span class="averta-bold has-text-dark">Akun Profile</span>
              </router-link>
              <hr class="navbar-divider" v-if="user.roles[0].name === 'Account Manager'">
              <router-link to="/am/vendors" class="navbar-item" exact-active-class="is-active"
                v-if="user.roles[0].name === 'Account Manager'">
                <DaftarClient class="mr-3" />
                <span class="averta-bold has-text-dark">Daftar Client</span>
              </router-link>
              <hr class="navbar-divider">
              <a class="navbar-item" @click="showModalLogout()">
                <LogoutIcon class="mr-3" />
                <span class="averta-bold has-text-dark">Logout</span>
              </a>
            </div>
          </nav-bar-menu>
        </div>
      </div>
    </nav>
    <!-- Navbar Top Desktop -->

    <!-- Navbar Top Mobile -->
    <div class="is-flex is-justify-content-space-between is-align-items-center navbar-mobile is-hidden-desktop" :class="{'page-detail': isPageDetail}" v-if="visibleNavbarTopMobile">
      <div @click="showMenuMobile">
        <b-icon icon="menu" size="is-medium"></b-icon>
      </div>
      <div>
        <section class="is-title-bar is-flex is-align-items-center">
          <img :src="require('@/assets/img/dashboard.png')" v-if="titleTopBar === '/' || titleTopBar === '/am/dashboard' || titleTopBar === '/sm/dashboard' || titleTopBar === '/purchasing/dashboard'" />
          <ProyekIcon
            v-if="titleTopBar === '/project' || titleTopBar === '/purchasing/project' || titleTopBar === '/am/project' || titleTopBar === '/sm/project'" />
          <PengaturanIcon v-if="titleTopBar === '/am/setting'" />
          <ScheduleIcon v-if="titleTopBar === '/am/schedule'" />
          <CatalogIcon v-if="titleTopBar === '/purchasing/catalog'" />
          <div class="ml-4 level">
            <div class="level-left">
              <div class="level-item">
                <ul>
                  <li class="averta-bold label-16" v-for="(title, index) in titleStack" :key="index">{{ title }}</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      <a class="mr-5 is-relative" @click="goToNotification()">
        <span class="tag is-danger is-rounded notif-count" v-if="totalUnread > 0">{{ totalUnread }}</span>
        <div class="card-notif">
          <NotificationIcon color="#252525" />
        </div>
      </a>

      <!-- Menu Mobile Sidebar -->
      <b-sidebar type="is-light" :fullheight="true" :fullwidth="true" :overlay="true" v-model="showMobileMenu">
        <div class="wrap-mobile-menu">
          <!-- Header Mobile Menu -->
          <div class="p-5 is-flex is-align-items-center">
            <div @click="showMenuMobile" class="is-flex is-align-items-center mr-3 is-clickable">
              <b-icon icon="close"></b-icon>
            </div>
            <p class="averta-black label-18">Menu Lainnya</p>
          </div>
          <!-- Header Mobile Menu -->

          <!-- User Information -->
          <div class="user-information px-5">
            <div class="is-flex is-align-items-start is-justify-content-space-between">
              <div class="is-flex is-align-items-center">
                <div class="wrap-profile flex-center mr-4">
                  <img v-if="user.profile_picture" :src="user.profile_picture" :alt="user.name">
                  <img v-else src="../assets/img/default-picture.jpg" :alt="user.name">
                </div>
                <div>
                  <p class="label-16 averta-bold">{{ user.name }}</p>
                  <p class="averta-regular">{{ user.email }}</p>
                  <div class="role small px-2 py-1">
                    {{ userRoles(user.roles[0].name) }}
                  </div>
                </div>
              </div>
              <div>
                <router-link to="/profile" @click.native="showMenuMobile">
                  <PengaturanIcon />
                </router-link>
              </div>
            </div>
          </div>
          <!-- User Information -->

          <!-- Menu -->
          <div class="wrap-menu">
            <div class="wrap-menu-list">
              <!-- <div v-for="menu in menuRoles" :key="`menu-${menu.path}`" class="wrap-menu-item is-flex is-align-items-center mb-2" @click="goToMenu(menu.path)"> -->
              <div v-for="menu in menuRoles" :key="`menu-${menu.path}`">
                <router-link :to="menu.path" class="wrap-menu-item is-flex is-align-items-center mb-2"
                  @click.native="goToMenu" exact>
                  <DashboardIcon class="mr-3" v-if="menu.name === 'Dashboard'" />
                  <ProyekIcon class="mr-3" v-if="menu.name === 'Proyek'" />
                  <ScheduleIcon class="mr-3" v-if="menu.name === 'Jadwal Survey'" />
                  <PengaturanIcon class="mr-3" v-if="menu.name === 'Pengaturan'" />
                  <CatalogIcon class="mr-3" v-if="menu.name === 'Katalog'" />
                  <p class="label-16 averta-regular">{{ menu.name }}</p>
                </router-link>
              </div>
            </div>
          </div>
          <!-- Menu -->
        </div>
      </b-sidebar>
      <!-- Menu Mobile Sidebar -->
    </div>
    <!-- Navbar Top Mobile -->

    <!-- Navbar Bottom -->
    <!-- <div class="navbar-bottom is-hidden-desktop" v-if="currentPath">
      <ul class="is-flex">
        <router-link class="flex-1" :to="menuRoles[0]" exact>
          <li class="has-text-centered wrapper-bottom-menu">
            <div class="icon-wrapper">
              <DashboardIcon />
            </div>
            <p>Dashboard</p>
          </li>
        </router-link>
        <router-link class="flex-1" :to="menuRoles[1]">
          <li class="has-text-centered wrapper-bottom-menu">
            <div class="icon-wrapper">
              <ProyekIcon />
            </div>
            <p>Proyek</p>
          </li>
        </router-link>
        <router-link class="flex-1" v-if="menuRoles.length > 2" :to="menuRoles[2]">
          <li class="has-text-centered wrapper-bottom-menu">
            <div class="icon-wrapper">
              <LaporanIcon />
            </div>
            <p>Laporan</p>
          </li>
        </router-link>
        <router-link class="flex-1" to="/profile">
          <li class="has-text-centered wrapper-bottom-menu">
            <div class="icon-wrapper">
              <img v-if="user.profile_picture" :src="user.profile_picture" :alt="user.name" class="img-profile-bottom">
              <img v-else src="../assets/img/default-picture.jpg" :alt="user.name" class="img-profile-bottom">
            </div>
            <p>Profile</p>
          </li>
        </router-link>
      </ul>
    </div> -->
    <!-- Navbar Bottom -->

    <!-- Modal Logout -->
    <b-modal v-model="modalLogout" width="30vw" :can-cancel="false">
      <div class="modal-logout is-flex is-justify-content-center">
        <div>
          <img src="../assets/img/logout-image.png" alt="" class="my-5">
          <p class="averta-bold has-text-centered is-size-5 mb-5">Keluar dari Dashboard</p>
          <div class="wrapper-btn-logout is-flex is-justify-content-space-between">
            <button class="btn-batalkan" @click="modalLogout = false">Batalkan</button>
            <button class="btn-logout" @click="logout()">Keluar</button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Modal Logout -->

    <!-- Notif -->
    <div v-if="showNotif" v-closable="{
      exclude: ['button-notif'],
      handler: 'hide'
    }" class="notif-wrapper">
      <div class="notif-top is-flex is-justify-content-space-between">
        <div class="is-flex is-align-items-center">
          <NotificationIcon color="#EB4600" class="mr-2" />
          <p class="averta-bold">Notifikasi</p>
        </div>
        <div class="has-text-grey-light">
          <strong class="has-text-grey-light">{{ totalNewNotif }}</strong> Notifikasi Baru
        </div>
      </div>
      <!-- change for enhancement -->
      <div v-if="!isLoading" class="notif-midle">
        <div
          @click="handleNavigate(item.tag, item.entity_id, item.id)"
          v-for="item in previewNotif" :key="item.id"
          class="is-clickable is-flex is-align-items-center"
          :class="[formatNotification(item.detail_tag, item.is_read)]"
        >
          <div
            class="mr-4"
            :class="{
              'wrapper-notif-icon-read': item.is_read === 1 && !(['spk-offer', 'request-survey', 'approved-offer', 'rejected-offer'].includes(item.detail_tag)),
              'wrapper-notif-icon-unread': item.is_read === 0 && !(['spk-offer', 'request-survey', 'approved-offer', 'rejected-offer'].includes(item.detail_tag))
            }"
          >
            <img v-if="item.detail_tag === 'spk-offer'" style="width: 32px; height: 32px;" :src="require(`@/assets/ion_notifications_sik.png`)"/>
            <img v-else-if="item.detail_tag === 'request-survey'" style="width: 32px; height: 32px;" :src="require(`@/assets/ion_notifications_project.png`)"/>
            <img v-else-if="item.detail_tag === 'approved-offer'" style="width: 34px; height: 31px;" :src="require(`@/assets/ion_notifications_approved.png`)"/>
            <img v-else-if="item.detail_tag === 'rejected-offer'" style="width: 32px; height: 32px;" :src="require(`@/assets/ion_notifications_rejected.png`)"/>
            <NotificationIcon v-else :color="item.is_read === 1 ? '#9A9A9A' : '#D9272D' " />
          </div>
          <div class="is-flex-direction-row c-w-100">
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <p class="averta-bold">{{ item.title }}</p>
              <p class="white-space-nowrap">
                {{ formatDateStatus(item.created_at) }} &#8226; {{ item.type }} - {{ formatTimeStatus(item.created_at) }}
              </p>
            </div>
            <div v-html="item.message_raw"></div>
          </div>
        </div>
      </div>
      <div class="notif-bottom">
        <div class="wrapper-button-notif averta-bold is-flex is-justify-content-space-around">
          <div @click="handleReadNotif(null, null, null, true)" class="is-clickable">
            <p>Tandai sudah dibaca</p>
          </div>
          <div class="divider-notif"></div>
          <div class="is-clickable" @click="goToNotification()">
            <p>Lihat Selengkapnya</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Notif -->
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu'
import UserAvatar from '@/components/UserAvatar'
import NotificationIcon from '../components/icons/NotificationIcon.vue'
import { AccountIcon, LogoutIcon, DashboardIcon, ProyekIcon, DaftarClient, PengaturanIcon, ScheduleIcon, CatalogIcon } from '../components/icons'
import SocketioService from '@/services/socketio.services'
import { navigateTo } from '@/helpers'

let prevScrollPosition = window.pageYOffset

window.addEventListener('scroll', function () {
  const currentScrollPosition = window.pageYOffset
  const navbar = document.querySelector('.navbar-bottom')

  if (navbar) {
    if (prevScrollPosition > currentScrollPosition) {
      navbar.classList.remove('navbar-hidden')
    } else {
      navbar.classList.add('navbar-hidden')
    }
  }

  prevScrollPosition = currentScrollPosition
})

export default {
  name: 'NavBar',
  props: ['userInteracted'],
  components: {
    UserAvatar,
    NavBarMenu,
    NotificationIcon,
    AccountIcon,
    LogoutIcon,
    DashboardIcon,
    ProyekIcon,
    DaftarClient,
    PengaturanIcon,
    ScheduleIcon,
    CatalogIcon
  },
  data () {
    return {
      isMenuNavBarActive: false,
      roleSwitchName: null,
      currentRole: null,
      modalLogout: false,
      showNotif: false,
      isLoading: true,
      previewNotif: null,
      showMobileMenu: false,
      marginTopWrapper: 0
    }
  },
  computed: {
    menuNavBarToggleIcon () {
      return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    darkModeToggleIcon () {
      return this.isDarkModeActive ? 'white-balance-sunny' : 'weather-night'
    },
    ...mapState([
      'isNavBarVisible',
      'isAsideMobileExpanded',
      'isDarkModeActive',
      'userName'
    ]),
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath',
      isLogin: 'auth/isLogin',
      email: 'auth/email',
      user: 'auth/user',
      totalUnread: 'dashboard/totalNotifUnread'
    }),
    currentPath () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      if (lastElement === 'detail' || lastElement === 'checkin-pemeriksaan' || lastElement === 'new-offering' || lastElement === 'notification') return false
      return true
    },
    titleTopBar () {
      return this.$route.path
    },
    isPageDetail () {
      const path = this.$route.path.split('/')
      switch (path[path.length - 1]) {
        case 'detail':
          return true
        default:
          return false
      }
    },
    visibleNavbarTopMobile () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      if (lastElement === 'notification') return false
      return true
    },
    menuRoles () {
      const menuKonsultan = [
        {
          name: 'Dashboard',
          path: '/'
        },
        {
          name: 'Proyek',
          path: '/project'
        }
      ]

      const menuAM = [
        {
          name: 'Dashboard',
          path: '/am/dashboard'
        },
        {
          name: 'Proyek',
          path: '/am/project'
        },
        {
          name: 'Jadwal Survey',
          path: '/am/schedule'
        },
        {
          name: 'Pengaturan',
          path: '/am/setting'
        }
      ]

      const menuSM = [
        {
          name: 'Dashboard',
          path: '/sm/dashboard'
        },
        {
          name: 'Proyek',
          path: '/sm/project'
        }
      ]

      const menuPurchasing = [
        {
          name: 'Dashboard',
          path: '/purchasing/dashboard'
        },
        {
          name: 'Proyek',
          path: '/purchasing/project'
        },
        {
          name: 'Katalog',
          path: '/purchasing/catalog'
        }
      ]

      if (this.user.roles[0].name === 'Purchasing') {
        return menuPurchasing
      } else if (this.user.roles[0].name === 'Account Manager') {
        return menuAM
      } else if (this.user.roles[0].name === 'Site Manager') {
        return menuSM
      }

      return menuKonsultan
    },
    canSwitchRole () {
      if (this.user.roles.length > 1) {
        return true
      }
      return false
    },
    totalNewNotif () {
      const condition = (element) => element.is_read === 0
      const count = this.previewNotif.filter(condition).length
      return count
    },
    titleStack () {
      if (this.$route.path.includes('/dashboard')) {
        return ['Dashboard']
      } else if (this.$route.path.includes('/project')) {
        return ['Proyek']
      } else if (this.$route.path.includes('/schedule')) {
        return ['Jadwal']
      } else if (this.$route.path.includes('/setting')) {
        return ['Pengaturan']
      } else if (this.$route.path.includes('/catalog')) {
        return ['Katalog']
      } else if (this.$route.path.includes('/search')) {
        return ['Pencarian']
      } else if (this.$route.path.includes('/profile')) {
        return ['Profile']
      } else if (this.$route.path.includes('/')) {
        return ['Dashboard']
      } else {
        return false
      }
    }
  },
  methods: {
    goToMenu () {
      this.showMobileMenu = false
    },
    showMenuMobile () {
      this.showMobileMenu = !this.showMobileMenu
    },
    goToDashboard () {
      const currentRole = this.user.roles[0].name
      switch (currentRole) {
        case 'Konsultan Survey':
          this.$router.push({ path: '/' })
          break
        case 'Site Manager':
          this.$router.push({ path: '/sm/dashboard' })
          break
        case 'Account Manager':
          this.$router.push({ path: '/am/dashboard' })
          break
        case 'Purchasing':
          this.$router.push({ path: '/purchasing/dashboard' })
          break
        default:
          this.$router.push({ path: '/' })
          break
      }
    },
    goToNotification () {
      this.showNotif = false
      const currentRole = this.user.roles[0].name
      switch (currentRole) {
        case 'Konsultan Survey':
          this.$router.push({ path: '/notification' })
          break
        case 'Site Manager':
          this.$router.push({ path: '/sm/notification' })
          break
        case 'Account Manager':
          this.$router.push({ path: '/am/notification' })
          break
        case 'Purchasing':
          this.$router.push({ path: '/purchasing/notification' })
          break
        default:
          this.$router.push({ path: '/notification' })
          break
      }
    },
    menuToggleMobile () {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = (!this.isMenuNavBarActive)
    },
    darkModeToggle () {
      this.$store.commit('darkModeToggle')
    },
    showModalLogout () {
      this.modalLogout = true
    },
    logout () {
      this.modalLogout = false
      this.$store.dispatch('auth/logout')
      this.$store.dispatch('penawaran/resetState')
      this.$store.commit('list/resetSelectedTab')
      this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
        clearData: true
      })
      this.$store.commit('list/resetPersistTabProject')
      this.$store.commit('list/resetPersistDataList')

      this.$router.push({ name: 'Login' })
    },
    handleSwitchRole (id) {
      const payload = {
        url: this.url,
        role_id: id
      }
      this.$store
        .dispatch('auth/switchRole', payload)
        .then(async () => {
          // clear tab to prevent duplicate tab selected
          this.$store.commit('list/resetSelectedTab')
          this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
            clearData: true
          })
          this.$store.commit('list/resetPersistTabProject')
          this.$store.commit('list/resetPersistDataList')

          const currentRole = await this.user.roles[0].name
          this.handleCheckSwitchRole(this.user.roles)
          // this.$buefy.toast.open({
          //   message:
          //     '<p class="averta-regular">Success switch role</p>',
          //   type: 'is-success'
          // })
          switch (currentRole) {
            case 'Konsultan Survey':
              this.$router.push({ path: '/' })
              break
            case 'Site Manager':
              this.$router.push({ path: '/sm/dashboard' })
              break
            case 'Account Manager':
              this.$router.push({ path: '/am/dashboard' })
              break
            case 'Purchasing':
              this.$router.push({ path: '/purchasing/dashboard' })
              break
            default:
              this.$router.push({ path: '/' })
              break
          }
        })
        .catch(error => {
          console.log(error)
          this.$buefy.toast.open({
            message:
              '<p class="averta-regular">Gagal switch role, silahkan coba lagi<p>',
            type: 'is-danger'
          })
        })
    },
    handleCheckSwitchRole (roles) {
      if (roles.length > 1) {
        this.currentRole = this.user.roles[0].name
      }
    },
    handleClickIcon () {
      switch (this.user.roles[0].name) {
        case 'Konsultan Survey':
          this.$router.push('/')
          break
        case 'Site Manager':
          this.$router.push('/sm/dashboard')
          break
        case 'Purchasing':
          this.$router.push('/purchasing/dashboard')
          break
        case 'Account Manager':
          this.$router.push('/am/dashboard')
          break
        default:
          this.$router.push('/')
          break
      }
    },
    show () {
      this.showNotif = !this.showNotif
      this.getPreviewNotif()
    },
    hide () {
      this.showNotif = false
    },
    playAudio () {
      const audio = new Audio(require('@/assets/audio/Notifikasi-Kanggo.mp3'))
      audio.play()
    },
    getPreviewNotif () {
      const rs = JSON.parse(localStorage.getItem('webapp_shell_kanggo'))
      if (rs) {
        this.$store
          .dispatch('dashboard/getPreviewNotif', { url: this.url })
          .then(response => {
            this.previewNotif = response.data.data.list
            this.$store.commit('dashboard/setNotifUnread', response.data.data.total_unread)
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
          })
      }
    },
    formatDateStatus (date) {
      if (!date) {
        return ''
      }

      return moment(date).format('DD MMM YYYY')
    },
    formatTimeStatus (date) {
      if (!date) {
        return ''
      }

      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
    },
    handleNavigate (tag, id, idNotif) {
      this.handleReadNotif(tag, id, idNotif)
    },
    handleReadNotif (tag, id, idNotif, preview) {
      const obj = {
        url: this.url
      }

      if (preview) {
        obj.preview = preview
      }

      if (idNotif) {
        obj.id = idNotif
      }

      this.$store
        .dispatch('dashboard/readNotif', obj)
        .then(() => {
          this.getPreviewNotif()
          if (this.user.roles[0].name === 'Purchasing' && tag === 'project') {

          } else if (!preview) {
            this.showNotif = false

            const url = navigateTo(tag, id, this.user.roles[0].name)
            this.$router.push(url).catch({})
            window.scrollTo(0, 0)
            window.location.reload()
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    userRoles (roles) {
      if (!roles) {
        return '-'
      } else if (roles === 'Account Manager' || roles === 'Business Support') {
        return 'Business Support'
      } else {
        return roles
      }
    },
    userRolesTag (roleName) {
      switch (roleName) {
        case 'Business Support':
          return 'BS'
        case 'Account Manager':
          return 'BS'
        case 'Site Manager':
          return 'SM'
        case 'Konsultan Pengawas':
          return 'Konsultan'
        case 'Konsultan Survey':
          return 'Konsultan'
        case 'Purchasing':
          return 'Purchasing'
        default:
          return roleName
      }
    },
    adjustNavbarPosition () {
      setTimeout(() => {
        const navbarContainer = document.querySelector('#navbar-main')
        const navHeight = navbarContainer.clientHeight

        this.$store.commit('dashboard/setHeightNavbar', navHeight)
        this.marginTopWrapper = navHeight
      }, 600)
    },
    formatNotification (title, isRead) {
      // sudah dibaca = 1 , belum dibaca = 0
      const titleToResult = {
        'spk-offer': 'confirm-client',
        'request-survey': 'create-survey-project',
        'approved-offer': 'approve-offer',
        'rejected-offer': 'offer-rejected'
      }

      let result
      if (isRead === 0 && titleToResult[title]) {
        result = titleToResult[title]
      } else if (isRead === 1) {
        result = 'notif-read'
      } else {
        result = 'notif-default'
      }

      return result
    }
  },
  mounted () {
    this.getPreviewNotif()
    if (this.user) {
      this.handleCheckSwitchRole(this.user.roles)
    }
    this.$router.afterEach(() => {
      this.isMenuNavBarActive = false
    })
    SocketioService.setupSocketConnection()
    SocketioService.getPreviewNotif((data) => {
      this.previewNotif = data.preview.list
      this.$store.commit('dashboard/setNotifUnread', data.preview.total_unread)
      this.getPreviewNotif()
      if (this.userInteracted) {
        this.playAudio()
      }
    })

    if (document.querySelector('#navbar-main')) {
      this.adjustNavbarPosition()
    }
  },
  watch: {
    user (newVal) {
      if (newVal) {
        this.handleCheckSwitchRole(newVal.roles)
        this.getPreviewNotif()
      }
    },
    isNavBarVisible (val) {
      if (val) {
        if (document.querySelector('#navbar-main')) {
          this.adjustNavbarPosition()
        }
      }
    }
  },
  events: {
    closeEvent () {
      this.hide()
    }
  }
}
</script>

<style scoped>
.img-kanggo {
  width: 10%;
  object-fit: contain;
}

.card-notif {
  width: 34px;
  height: 34px;
  background: #F8F7FA;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 12px;
  margin-bottom: 11px; */
}

.notif-count {
  position: absolute;
  top: -10px;
  right: -20px;
  z-index: 1;
}

.img-profile {
  object-fit: cover;
  height: 50px;
  width: 30px;
  border-radius: 100px;
  margin-right: 12px;
}

.img-profile.multirole {
  height: 4em !important;
  width: 4em !important;
  border-radius: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.img-profile.multirole img {
  width: 100%;
  max-height: none !important;
  height: 100%;
  object-fit: cover;
}

.role {
  background: linear-gradient(90deg, #891313 0%, #D92727 99.97%);
  border-radius: 100px;
  color: white;
  font-family: 'Averta-Bold';
  white-space: nowrap;
}

.role.small {
  font-size: 10px;
  display: inline-block;
}

.navbar-dekstop {
  height: 65px;
}

.navbar-mobile,
.navbar-bottom {
  display: none;
}

.navbar-hidden {
  transform: translateY(100%);
}

.btn-role {
  background: white;
  border-radius: 100px;
  text-align: center;
  border: 1px solid #EB4600;
  width: 100%;
  display: flex;
  margin: auto;
  cursor: pointer;
  justify-content: center;
}

.btn-role span {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.multiple-role-dropdown {
  width: 25em;
}

.flex-1 {
  flex: 1;
}

.icon-wrapper {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-logout {
  background: white;
  border-radius: 22px;
  padding: 32px 0px;
}

.btn-batalkan {
  background: white;
  border-radius: 100px;
  border: 1px solid #D9272D;
  color: #D9272D;
  width: 100%;
  margin-right: 10px;
  padding: 12px;
  font-family: 'Averta-Bold';
  cursor: pointer;
}

.btn-logout {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  border-radius: 100px;
  border: none;
  color: white;
  width: 100%;
  padding: 12px;
  font-family: 'Averta-Bold';
  cursor: pointer;
}

.notif-wrapper {
  width: 55%;
  z-index: 31;
  position: fixed;
  top: 11%;
  right: 15%;
  border-radius: 20px;
  box-shadow: 6px 7px 50px 0px rgba(0, 0, 0, 0.08);
  color: #252525;
}

.notif-top {
  background: #ffffff;
  padding: 22px 20px;
  border-radius: 20px 20px 0px 0px;
}

.notif-midle {
  background: #ffffff;
  overflow-y: auto;
  height: 350px;
}

.notif-bottom {
  background: #ffffff;
  border-radius: 0px 0px 20px 20px;
  overflow-y: auto;
}

.notif-unread {
  background: #fff;
  padding: 10px 20px;
  border: 1px solid #e1e1e1;
}

.notif-read {
  background: #fafafa;
  border: 1px solid #e1e1e1;
  padding: 10px 10px;
}

.wrapper-button-notif {
  padding: 20px;
  color: #D9272D;
  border: 1px solid #e1e1e1;
  border-radius: 0px 0px 20px 20px;
}

.divider-notif {
  border-right: 2px solid #E1E1E1;
}

.wrapper-notif-icon-read {
  background: #F0F0F0;
  padding: 9px;
  border-radius: 100px;
  width: 34px;
  height: 34px;
}

.wrapper-notif-icon-unread {
  background: #FFEFF0;
  padding: 9px;
  border-radius: 100px;
  width: 34px;
  height: 34px;
}

.notif-default {
  background: #fff;
  padding: 10px 10px;
  border: 1px solid #e1e1e1;
  cursor: pointer;
}

.confirm-client {
  background: #FFF3E6;
  padding: 10px 20px;
  cursor: pointer;
}

.create-survey-project {
  background: #E1EEFF;
  padding: 10px 20px;
  cursor: pointer;
}

.approve-offer {
  background: #E7F8EC;
  padding: 10px 20px;
  cursor: pointer;
}

.offer-rejected {
  background: #FCE5E6;
  padding: 10px 20px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .wrapper-navbar {
    margin-top: 0px;
  }

  .navbar-mobile {
    background: #ffffff;
    padding: 14px 12px;
    border-bottom: 1px solid #E1E1E1;
    position: fixed;
    width: 100%;
    z-index: 10;
    top: -3px;
  }

  .navbar-mobile.page-detail {
    position: relative;
    margin-bottom: -4rem;
  }

  .navbar-mobile .img-kanggo {
    height: 35%;
    width: 35%;
    object-fit: cover;
  }

  .navbar-bottom {
    display: block !important;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 3;
    margin: 0;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-bottom ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .navbar-bottom li {
    margin: 0 16px;
  }

  .navbar-bottom a {
    color: #868686;
    text-decoration: none;
  }

  .navbar-bottom svg {
    width: 100%;
  }

  .wrapper-bottom-menu {
    padding: 10px 0px 10px 0px;
  }

  .img-profile-bottom {
    border-radius: 100px;
    height: 30px;
    width: 30px;
    object-fit: cover;
  }

  .notif-wrapper {
    width: 100%;
    z-index: 31;
    position: absolute;
    top: 11%;
    right: 0%;
  }

  .wrap-profile {
    width: 57px;
    height: 57px;
    overflow: hidden;
    border-radius: 100px;
  }

  .wrap-profile img {
    height: 100%;
    widows: 100%;
    object-fit: cover;
  }

  .wrap-menu {
    margin-top: 24px;
    border-top: 1px solid #9A9A9A;
  }

  .wrap-menu-item {
    padding: 14px 24px;
    cursor: pointer;
  }

  .wrap-menu .wrap-menu-item svg {
    fill: #868686
  }

  .wrap-menu .wrap-menu-item p {
    color: #868686
  }

  .wrap-menu-item.active {
    background: rgba(214, 15, 39, 0.1);
    border-left: 7px solid #d9272d;
    padding: 14px 17px;
  }

  .wrap-menu-item.active svg {
    fill: #d9272d;
  }

  .is-title-bar svg {
    fill: #d9272d;
  }

  .wrapper-notif-icon-read {
    margin-top: -55px;
  }

  .wrapper-notif-icon-unread {
    margin-top: -55px;
  }

}
</style>
