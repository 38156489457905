<template>
  <svg
    width="252"
    height="240"
    viewBox="0 0 252 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.71">
      <path
        opacity="0.51"
        d="M126.987 5.79748L127.518 8.33645C127.556 8.51699 127.68 8.66712 127.85 8.73871L130.243 9.74086C130.671 9.92013 130.708 10.5131 130.305 10.7436L128.054 12.0334C127.894 12.1253 127.789 12.2893 127.774 12.473L127.561 15.0582C127.523 15.5207 126.97 15.7392 126.626 15.4269L124.704 13.6849C124.567 13.5613 124.379 13.5126 124.199 13.555L121.674 14.1505C121.223 14.2569 120.844 13.7995 121.035 13.3757L122.097 11.0091C122.173 10.8406 122.161 10.6461 122.065 10.489L120.718 8.27184C120.477 7.87528 120.796 7.3742 121.257 7.42425L123.836 7.70361C124.019 7.72325 124.201 7.65166 124.322 7.5123L126.014 5.546C126.318 5.19505 126.892 5.34328 126.987 5.79748Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M218.253 118.31L219.541 119.484C219.632 119.568 219.759 119.601 219.879 119.573L221.577 119.178C221.881 119.107 222.134 119.415 222.005 119.7L221.286 121.288C221.235 121.4 221.243 121.532 221.307 121.637L222.208 123.13C222.369 123.397 222.154 123.733 221.845 123.699L220.112 123.506C219.989 123.492 219.867 123.54 219.786 123.634L218.645 124.952C218.441 125.187 218.054 125.087 217.992 124.781L217.64 123.074C217.615 122.952 217.531 122.852 217.417 122.804L215.811 122.126C215.524 122.004 215.5 121.606 215.771 121.452L217.286 120.59C217.394 120.528 217.464 120.418 217.475 120.294L217.623 118.557C217.65 118.245 218.022 118.099 218.253 118.31Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M114.844 113.56L116.132 114.734C116.224 114.818 116.351 114.851 116.471 114.823L118.169 114.428C118.473 114.357 118.726 114.665 118.597 114.95L117.878 116.538C117.827 116.65 117.835 116.782 117.899 116.887L118.8 118.38C118.961 118.647 118.746 118.983 118.436 118.949L116.704 118.756C116.581 118.742 116.458 118.79 116.377 118.884L115.237 120.202C115.033 120.437 114.646 120.337 114.583 120.031L114.231 118.324C114.207 118.202 114.123 118.102 114.009 118.054L112.403 117.376C112.115 117.254 112.092 116.856 112.363 116.702L113.878 115.84C113.985 115.778 114.056 115.668 114.067 115.544L114.215 113.807C114.242 113.495 114.614 113.349 114.844 113.56Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M28.6308 108.067L29.9186 109.242C30.0105 109.325 30.1372 109.358 30.2575 109.33L31.9553 108.935C32.2593 108.864 32.5121 109.173 32.3835 109.457L31.6645 111.045C31.6132 111.158 31.6214 111.289 31.6854 111.395L32.5862 112.887C32.7471 113.155 32.5323 113.49 32.2226 113.456L30.49 113.264C30.3671 113.25 30.2449 113.298 30.1638 113.392L29.0229 114.709C28.8189 114.945 28.4325 114.844 28.3698 114.539L28.0176 112.832C27.9929 112.71 27.9093 112.609 27.7952 112.561L26.1894 111.883C25.9018 111.762 25.8783 111.364 26.1495 111.209L27.6641 110.347C27.7718 110.286 27.8421 110.175 27.8529 110.052L28.0011 108.315C28.0283 108.003 28.4002 107.858 28.6308 108.067Z"
        fill="white"
      />
      <path
        opacity="0.51"
        d="M185.825 39.9928L186.356 42.5318C186.394 42.7123 186.518 42.8624 186.688 42.934L189.08 43.9362C189.509 44.1154 189.545 44.7084 189.142 44.939L186.892 46.2287C186.731 46.3206 186.627 46.4846 186.612 46.6683L186.398 49.2536C186.36 49.716 185.808 49.9345 185.464 49.6222L183.542 47.8802C183.405 47.7567 183.216 47.7079 183.037 47.7503L180.512 48.3458C180.06 48.4522 179.682 47.9948 179.872 47.571L180.935 45.2044C181.011 45.0359 180.998 44.8414 180.903 44.6843L179.556 42.4672C179.315 42.0706 179.633 41.5695 180.095 41.6196L182.674 41.8989C182.857 41.9186 183.039 41.847 183.159 41.7076L184.852 39.7413C185.155 39.3904 185.73 39.5386 185.825 39.9928Z"
        fill="white"
      />
    </g>
    <path
      d="M72.6886 80.0322H10.2104C4.69791 80.0322 0.22876 75.5631 0.22876 70.0506V67.8645C0.22876 62.352 4.69791 57.8828 10.2104 57.8828H72.688C78.2005 57.8828 82.6696 62.352 82.6696 67.8645V70.0506C82.6703 75.5637 78.2011 80.0322 72.6886 80.0322Z"
      fill="#F1D900"
    />
    <path
      d="M13.2903 63.5125L14.3958 65.7524C14.5541 66.0736 14.8607 66.296 15.2148 66.3473L17.6866 66.7065C18.5786 66.8363 18.9352 67.9322 18.2897 68.5619L16.5008 70.3052C16.2442 70.5548 16.1277 70.9153 16.1878 71.2681L16.6104 73.7298C16.763 74.6185 15.8299 75.2957 15.0324 74.8764L12.8216 73.7139C12.5048 73.5473 12.126 73.5473 11.8093 73.7139L9.59846 74.8764C8.80028 75.2957 7.86781 74.6185 8.02047 73.7298L8.443 71.2681C8.50381 70.9153 8.38662 70.5554 8.13007 70.3052L6.34114 68.5619C5.69563 67.9329 6.05164 66.8363 6.9442 66.7065L9.41602 66.3473C9.77013 66.296 10.0767 66.073 10.2351 65.7524L11.3405 63.5125C11.7383 62.7042 12.8913 62.7042 13.2903 63.5125Z"
      fill="white"
    />
    <path
      d="M27.723 63.5125L28.8284 65.7524C28.9867 66.0736 29.2933 66.296 29.6474 66.3473L32.1193 66.7065C33.0112 66.8363 33.3678 67.9322 32.7223 68.5619L30.9334 70.3052C30.6768 70.5548 30.5603 70.9153 30.6205 71.2681L31.043 73.7298C31.1957 74.6185 30.2626 75.2957 29.465 74.8764L27.2542 73.7139C26.9374 73.5473 26.5586 73.5473 26.2419 73.7139L24.0311 74.8764C23.2329 75.2957 22.3004 74.6185 22.4531 73.7298L22.8756 71.2681C22.9364 70.9153 22.8192 70.5554 22.5627 70.3052L20.7738 68.5619C20.1282 67.9329 20.4843 66.8363 21.3768 66.7065L23.8486 66.3473C24.2027 66.296 24.5093 66.073 24.6677 65.7524L25.7731 63.5125C26.1709 62.7042 27.3239 62.7042 27.723 63.5125Z"
      fill="white"
    />
    <path
      d="M42.4251 63.5125L43.5305 65.7524C43.6889 66.0736 43.9955 66.296 44.3496 66.3473L46.8214 66.7065C47.7133 66.8363 48.07 67.9322 47.4245 68.5619L45.6355 70.3052C45.379 70.5548 45.2624 70.9153 45.3226 71.2681L45.7451 73.7298C45.8978 74.6185 44.9647 75.2957 44.1672 74.8764L41.9563 73.7139C41.6396 73.5473 41.2608 73.5473 40.944 73.7139L38.7332 74.8764C37.935 75.2957 37.0026 74.6185 37.1552 73.7298L37.5778 71.2681C37.6386 70.9153 37.5214 70.5554 37.2648 70.3052L35.4759 68.5619C34.8304 67.9329 35.1864 66.8363 36.079 66.7065L38.5508 66.3473C38.9049 66.296 39.2115 66.073 39.3699 65.7524L40.4753 63.5125C40.8731 62.7042 42.026 62.7042 42.4251 63.5125Z"
      fill="white"
    />
    <path
      d="M57.1275 63.5125L58.2329 65.7524C58.3913 66.0736 58.6979 66.296 59.052 66.3473L61.5238 66.7065C62.4157 66.8363 62.7724 67.9322 62.1269 68.5619L60.3379 70.3052C60.0814 70.5548 59.9648 70.9153 60.025 71.2681L60.4475 73.7298C60.6002 74.6185 59.6671 75.2957 58.8696 74.8764L56.6587 73.7139C56.342 73.5473 55.9632 73.5473 55.6464 73.7139L53.4356 74.8764C52.6374 75.2957 51.705 74.6185 51.8576 73.7298L52.2802 71.2681C52.341 70.9153 52.2238 70.5554 51.9672 70.3052L50.1783 68.5619C49.5328 67.9329 49.8888 66.8363 50.7814 66.7065L53.2532 66.3473C53.6073 66.296 53.9139 66.073 54.0723 65.7524L55.1777 63.5125C55.5755 62.7042 56.7284 62.7042 57.1275 63.5125Z"
      fill="white"
    />
    <path
      d="M71.56 63.5125L72.6654 65.7524C72.8238 66.0736 73.1304 66.296 73.4845 66.3473L75.9563 66.7065C76.8482 66.8363 77.2049 67.9322 76.5594 68.5619L74.7704 70.3052C74.5139 70.5548 74.3973 70.9153 74.4575 71.2681L74.88 73.7298C75.0327 74.6185 74.0996 75.2957 73.302 74.8764L71.0912 73.7139C70.7745 73.5473 70.3957 73.5473 70.0789 73.7139L67.8681 74.8764C67.0699 75.2957 66.1375 74.6185 66.2901 73.7298L66.7127 71.2681C66.7735 70.9153 66.6563 70.5554 66.3997 70.3052L64.6108 68.5619C63.9653 67.9329 64.3213 66.8363 65.2139 66.7065L67.6857 66.3473C68.0398 66.296 68.3464 66.073 68.5047 65.7524L69.6102 63.5125C70.0086 62.7042 71.1615 62.7042 71.56 63.5125Z"
      fill="white"
    />
    <path
      d="M38.4714 7.58076V41.3227C38.4714 45.3637 41.749 48.6457 45.7944 48.6457H86.0909C88.1111 51.6528 91.5654 55.3586 96.9702 57.0576C100.448 58.1516 103.604 58.0629 105.857 57.7329C106.36 57.6632 106.434 56.974 105.96 56.7928C104.535 56.2531 102.654 55.2009 101.211 53.1801C100.127 51.6623 99.6333 50.0704 99.4566 48.6457H103.926C107.971 48.6457 111.253 45.3637 111.253 41.3227V7.58076C111.253 3.5354 107.971 0.257812 103.926 0.257812H45.795C41.7484 0.257812 38.4714 3.53477 38.4714 7.58076Z"
      fill="#F5F5F5"
    />
    <path
      opacity="0.21"
      d="M100.569 15.1007H49.1544C48.163 15.1007 47.3591 14.2968 47.3591 13.3054V12.8968C47.3591 11.9054 48.163 11.1016 49.1544 11.1016H100.569C101.56 11.1016 102.364 11.9054 102.364 12.8968V13.3054C102.364 14.2968 101.56 15.1007 100.569 15.1007Z"
      fill="#23383B"
    />
    <path
      opacity="0.21"
      d="M100.569 23.0538H49.1544C48.163 23.0538 47.3591 22.2499 47.3591 21.2585V20.85C47.3591 19.8586 48.163 19.0547 49.1544 19.0547H100.569C101.56 19.0547 102.364 19.8586 102.364 20.85V21.2585C102.364 22.2499 101.56 23.0538 100.569 23.0538Z"
      fill="#23383B"
    />
    <path
      opacity="0.21"
      d="M77.0773 32.1397H49.1544C48.163 32.1397 47.3591 31.3359 47.3591 30.3445V29.9359C47.3591 28.9445 48.163 28.1406 49.1544 28.1406H77.0773C78.0687 28.1406 78.8725 28.9445 78.8725 29.9359V30.3445C78.8725 31.3359 78.0687 32.1397 77.0773 32.1397Z"
      fill="#23383B"
    />
    <path
      d="M251.771 43.6233C251.771 56.4125 235.972 66.7805 216.484 66.7805C214.702 66.7805 212.956 66.695 211.245 66.524C209.005 69.1263 205.789 71.7634 201.365 73.1526C197.605 74.3353 194.192 74.2397 191.757 73.8824C191.213 73.807 191.133 73.062 191.646 72.8657C193.186 72.2816 195.219 71.1445 196.78 68.9597C198.058 67.1676 198.576 65.2804 198.727 63.6347C188.243 59.6235 181.202 52.1644 181.202 43.6233C181.202 30.829 196.996 20.4609 216.484 20.4609C235.972 20.4609 251.771 30.829 251.771 43.6233Z"
      fill="#F5F5F5"
    />
    <path
      d="M213.363 57.1481C213.188 57.2422 213.009 57.3352 212.831 57.4245C212.721 57.2587 212.611 57.0921 212.507 56.9232C204.896 44.9389 201.992 34.5295 205.827 30.5651C207.746 28.5878 212.419 27.0966 215.502 29.2192C218.346 31.1763 218.488 34.8929 218.5 35.3937C218.91 34.8184 221.157 31.778 224.62 31.6623C228.155 31.5439 231.54 34.5556 232.321 37.6642C233.665 43.0164 225.949 50.5803 213.363 57.1481Z"
      fill="#E10009"
    />
    <path
      opacity="0.08"
      d="M145.611 236.753C203.862 236.753 251.084 226.117 251.084 212.997C251.084 199.878 203.862 189.242 145.611 189.242C87.3592 189.242 40.1372 199.878 40.1372 212.997C40.1372 226.117 87.3592 236.753 145.611 236.753Z"
      fill="#4F4F4F"
    />
    <path
      d="M82.1874 113.222C83.6406 109.451 85.9648 103.916 89.4159 97.4966C93.8484 89.2513 96.1757 85.0146 100.409 81.642C104.861 78.0939 110.369 76.3417 120.799 75.8071C141.484 74.746 157.381 73.9307 171.579 85.2426C186.264 96.9416 189.747 114.135 190.683 120.332C189.126 148.116 186.6 162.13 183.104 162.376C180.119 162.587 176.428 152.757 172.03 132.89C171.434 147.851 170.838 162.812 170.241 177.773C144.999 175.914 119.757 174.055 94.5154 172.196C97.3014 156.887 100.087 141.577 102.873 126.268C96.2144 138.105 89.5553 149.943 82.8969 161.781C82.6612 145.594 82.4243 129.408 82.1874 113.222Z"
      fill="#FFE3D5"
    />
    <mask
      id="mask0_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="82"
      y="75"
      width="109"
      height="103"
    >
      <path
        d="M82.1881 113.214C83.6413 109.444 85.9655 103.908 89.4167 97.4887C93.8491 89.2434 96.1765 85.0068 100.409 81.6342C104.862 78.0861 110.369 76.3339 120.8 75.7992C141.485 74.7382 157.381 73.9229 171.58 85.2348C186.265 96.9338 189.748 114.127 190.684 120.324C189.127 148.108 186.6 162.122 183.105 162.368C180.12 162.579 176.429 152.749 172.03 132.882C171.434 147.844 170.838 162.804 170.242 177.766C145 175.906 119.758 174.047 94.5161 172.188C97.3022 156.879 100.088 141.57 102.874 126.26C96.2151 138.098 89.556 149.935 82.8976 161.773C82.6619 145.586 82.425 129.4 82.1881 113.214Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1305_97159)">
      <path
        d="M177.448 177.925L90.9518 176.089C94.0203 161.668 97.0895 147.247 100.158 132.826C97.0205 125.916 93.2082 115.42 91.8253 102.144C90.5692 90.0877 91.755 79.9401 93.1436 72.8516C121.976 73.4635 150.807 74.0761 179.639 74.688C182.366 80.4038 190.291 98.919 183.996 120.398C181.418 129.196 177.228 135.851 173.761 140.334C174.99 152.864 176.219 165.394 177.448 177.925Z"
        fill="#E3E5E5"
      />
    </g>
    <mask
      id="mask1_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="82"
      y="75"
      width="109"
      height="103"
    >
      <path
        d="M82.1881 113.214C83.6413 109.444 85.9655 103.908 89.4167 97.4887C93.8491 89.2434 96.1765 85.0068 100.409 81.6342C104.862 78.0861 110.369 76.3339 120.8 75.7992C141.485 74.7382 157.381 73.9229 171.58 85.2348C186.265 96.9338 189.748 114.127 190.684 120.324C189.127 148.108 186.6 162.122 183.105 162.368C180.12 162.579 176.429 152.749 172.03 132.882C171.434 147.844 170.838 162.804 170.242 177.766C145 175.906 119.758 174.047 94.5161 172.188C97.3022 156.879 100.088 141.57 102.874 126.26C96.2151 138.098 89.556 149.935 82.8976 161.773C82.6619 145.586 82.425 129.4 82.1881 113.214Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_1305_97159)">
      <path
        opacity="0.04"
        d="M174.734 194.16L94.131 192.449L95.1154 146.086C100.171 149.601 117.104 160.426 140.354 158.538C156.244 157.247 167.707 150.617 173.195 146.908C173.709 162.659 174.221 178.41 174.734 194.16Z"
        fill="#1F0801"
      />
    </g>
    <mask
      id="mask2_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="82"
      y="75"
      width="109"
      height="103"
    >
      <path
        d="M82.1881 113.214C83.6413 109.444 85.9655 103.908 89.4167 97.4887C93.8491 89.2434 96.1765 85.0068 100.409 81.6342C104.862 78.0861 110.369 76.3339 120.8 75.7992C141.485 74.7382 157.381 73.9229 171.58 85.2348C186.265 96.9338 189.748 114.127 190.684 120.324C189.127 148.108 186.6 162.122 183.105 162.368C180.12 162.579 176.429 152.749 172.03 132.882C171.434 147.844 170.838 162.804 170.242 177.766C145 175.906 119.758 174.047 94.5161 172.188C97.3022 156.879 100.088 141.57 102.874 126.26C96.2151 138.098 89.556 149.935 82.8976 161.773C82.6619 145.586 82.425 129.4 82.1881 113.214Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_1305_97159)">
      <path
        opacity="0.04"
        d="M85.6356 165.281C82.085 165.912 78.5344 166.543 74.9832 167.175L76.2039 109.674C85.6895 99.1734 95.1758 88.6723 104.661 78.1719C105.288 79.6871 108.356 87.5352 104.507 95.3016C103.058 98.2245 101.084 100.217 99.5702 101.465C101.249 106.657 102.738 113.437 102.7 121.38C102.589 143.785 90.4013 159.68 85.6356 165.281Z"
        fill="#1F0801"
      />
    </g>
    <mask
      id="mask3_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="82"
      y="75"
      width="109"
      height="103"
    >
      <path
        d="M82.1881 113.214C83.6413 109.444 85.9655 103.908 89.4167 97.4887C93.8491 89.2434 96.1765 85.0068 100.409 81.6342C104.862 78.0861 110.369 76.3339 120.8 75.7992C141.485 74.7382 157.381 73.9229 171.58 85.2348C186.265 96.9338 189.748 114.127 190.684 120.324C189.127 148.108 186.6 162.122 183.105 162.368C180.12 162.579 176.429 152.749 172.03 132.882C171.434 147.844 170.838 162.804 170.242 177.766C145 175.906 119.758 174.047 94.5161 172.188C97.3022 156.879 100.088 141.57 102.874 126.26C96.2151 138.098 89.556 149.935 82.8976 161.773C82.6619 145.586 82.425 129.4 82.1881 113.214Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_1305_97159)">
      <path
        opacity="0.04"
        d="M187.536 165.264L174.066 164.977C173.81 150.807 173.554 136.636 173.297 122.466C170.656 121.846 165.851 120.28 163.681 116.133C161.765 112.471 162.885 108.669 164.228 103.469C165.596 98.1764 167.444 90.4721 169.311 80.5938C174.862 84.5378 180.415 88.4818 185.967 92.4251C186.356 92.7742 186.7 93.1365 187.005 93.5052C191.02 98.3481 189.461 105.624 188.746 108.307C188.342 127.293 187.939 146.279 187.536 165.264Z"
        fill="#1F0801"
      />
    </g>
    <path
      d="M197.885 164.157C185.929 168.414 164.763 174.226 138.109 172.423C110.17 170.533 89.2021 161.154 77.8705 155.062C98.1106 172.718 118.351 190.374 138.591 208.028C158.355 193.405 178.12 178.781 197.885 164.157Z"
      fill="#E10009"
    />
    <mask
      id="mask4_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="77"
      y="155"
      width="121"
      height="54"
    >
      <path
        d="M197.884 164.149C185.928 168.406 164.763 174.219 138.108 172.415C110.169 170.525 89.2013 161.146 77.8698 155.055C98.1099 172.71 118.35 190.366 138.59 208.021C158.354 193.397 178.119 178.773 197.884 164.149Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask4_1305_97159)">
      <path
        opacity="0.06"
        d="M178.721 201.443L102.081 199.816L102.854 163.461C116.893 172.437 130.931 181.414 144.97 190.391C156.478 181.956 167.986 173.522 179.494 165.088C179.236 177.207 178.979 189.325 178.721 201.443Z"
        fill="#1F0801"
      />
    </g>
    <path
      d="M156.447 52.268C156.995 49.9793 158.651 48.3056 160.576 48.0377C162.75 47.7349 164.584 49.3173 165.396 50.6469C167.622 54.2932 165.126 60.8978 160.535 63.0801C158.624 63.9885 156.751 63.9404 155.645 63.8118C155.912 59.9641 156.18 56.1157 156.447 52.268Z"
      fill="#FFE3D5"
    />
    <path
      d="M155.194 72.7411C154.909 74.5453 154.535 77.3078 154.335 80.7039C153.98 86.6947 154.573 87.7652 153.926 90.0977C152.699 94.5212 148.269 98.953 142.853 99.8918C134.892 101.272 127.786 94.5858 125.959 88.6686C125.173 86.1245 125.699 84.8101 126.412 77.1868C126.974 71.1688 127.183 66.2442 127.272 63.0078C136.579 66.2518 145.887 69.4965 155.194 72.7411Z"
      fill="#FFE3D5"
    />
    <mask
      id="mask5_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="125"
      y="63"
      width="31"
      height="38"
    >
      <path
        d="M155.194 72.7333C154.909 74.5375 154.535 77.3 154.335 80.6961C153.981 86.6868 154.573 87.7574 153.926 90.0899C152.699 94.5134 148.269 98.9452 142.854 99.884C134.892 101.264 127.786 94.578 125.959 88.6607C125.173 86.1167 125.699 84.8023 126.412 77.179C126.974 71.161 127.183 66.2364 127.272 63C136.579 66.244 145.887 69.4887 155.194 72.7333Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask5_1305_97159)">
      <path
        opacity="0.05"
        d="M155.776 76.1292C154.549 78.0334 149.849 84.8863 143.799 84.868C136.218 84.8452 127.378 74.0368 127.459 57.3594C138.51 58.4996 149.562 59.6392 160.613 60.7795C159.001 65.8954 157.388 71.0126 155.776 76.1292Z"
        fill="#1F0801"
      />
    </g>
    <path
      d="M146.933 25.8035C139.947 24.6785 134.034 30.6984 133.016 31.7759C120.79 44.7247 125.866 74.3586 138.479 79.5297C142.912 81.3471 147.3 79.7425 147.882 79.5202C157.036 76.0234 159.05 62.4088 160.096 55.3341C160.942 49.6113 161.613 44.65 159.402 38.8132C158.679 36.9064 154.963 27.0964 146.933 25.8035Z"
      fill="#FFE3D5"
    />
    <path
      d="M123.399 50.5078C121.283 45.6418 120.983 41.5148 121.002 39.0743C121.029 35.6126 121.096 27.2075 127.12 23.294C129.693 21.6231 132.462 21.3486 134.141 21.3337C135.103 19.894 136.736 17.8315 139.308 16.0599C147.114 10.6839 157.751 11.9953 164.409 16.1102C166.67 17.5074 170.364 19.7925 170.192 23.0912C169.995 26.8643 164.794 29.0671 164.444 29.2104C168.054 28.6558 171.357 30.2955 172.543 32.9643C173.492 35.1005 172.862 37.602 171.605 39.1828C167.952 43.7765 158.423 41.3389 157.049 40.9878C148.163 38.7148 143.167 31.7565 141.354 28.8898C140.329 31.9842 138.259 36.9027 134.013 41.8382C130.22 46.2482 126.166 48.9546 123.399 50.5078Z"
      fill="#23383B"
    />
    <path
      d="M128.571 50.9037C128.121 48.5934 126.538 46.8513 124.625 46.5016C122.466 46.107 120.567 47.6102 119.699 48.9044C117.321 52.4531 119.534 59.1578 124.028 61.5327C125.898 62.5209 127.772 62.5526 128.883 62.4715C128.779 58.6156 128.675 54.7596 128.571 50.9037Z"
      fill="#FFE3D5"
    />
    <path
      d="M182.709 120.082C177.621 119.042 172.535 118.002 167.447 116.962C166.039 116.674 165.11 115.325 165.342 113.907C167.085 103.246 168.828 92.5857 170.571 81.925C170.812 80.4515 172.212 79.4595 173.683 79.7217C178.73 80.6219 183.778 81.5214 188.825 82.4216C190.26 82.6775 191.227 84.0344 190.999 85.4737C189.296 96.2744 187.592 107.075 185.889 117.876C185.651 119.384 184.204 120.388 182.709 120.082Z"
      fill="#23383B"
    />
    <path
      d="M158.273 207.14C152.699 203.765 147.124 200.391 141.551 197.016C138.608 201.049 135.666 205.082 132.724 209.116C139.525 212.759 146.327 216.401 153.128 220.043C154.843 215.742 156.558 211.442 158.273 207.14Z"
      fill="#FFE3D5"
    />
    <path
      d="M151.673 204.552C151.917 204.268 152.271 204.103 152.645 204.093C155.036 204.026 157.005 203.92 158.405 203.831C164.131 203.468 166.994 203.286 168.327 203.034C168.62 202.978 169.698 202.76 171.146 202.802C171.146 202.802 171.444 202.811 171.742 202.834C175.358 203.111 179.235 206.345 179.235 206.345C184.546 210.773 188.107 208.179 191.979 212.37C196.518 217.284 195.37 224.907 194.974 227.531C194.771 228.875 193.851 234.984 190.958 235.602C190.244 235.754 189.631 235.525 189.323 235.405C185.646 233.981 182.752 232.768 182.7 232.737C180.904 231.688 178.268 230.303 174.588 228.85C170.046 226.626 166.208 225.781 163.578 225.416C158.092 224.655 150.199 222.88 146.001 222.558C144.53 222.446 143.472 221.095 143.727 219.643C143.792 219.275 143.863 218.887 143.941 218.481C144.967 213.172 145.579 210.192 147.472 209.518C147.753 209.418 148.177 209.328 148.77 209.42C149.551 209.542 150.252 208.927 150.277 208.137C150.293 207.629 150.377 207.059 150.581 206.46C150.867 205.618 151.301 204.986 151.673 204.552Z"
      fill="#E3E5E5"
    />
    <mask
      id="mask6_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="143"
      y="202"
      width="53"
      height="34"
    >
      <path
        d="M151.673 204.552C151.917 204.268 152.271 204.103 152.645 204.093C155.037 204.026 157.005 203.92 158.405 203.831C164.131 203.468 166.994 203.286 168.327 203.034C168.62 202.978 169.698 202.76 171.146 202.802C171.146 202.802 171.444 202.811 171.742 202.834C175.358 203.111 179.235 206.345 179.235 206.345C184.546 210.773 188.107 208.179 191.979 212.37C196.518 217.284 195.37 224.907 194.974 227.531C194.771 228.875 193.851 234.984 190.958 235.602C190.244 235.754 189.631 235.525 189.323 235.405C185.646 233.981 182.752 232.768 182.7 232.737C180.904 231.688 178.269 230.303 174.588 228.85C170.046 226.626 166.209 225.781 163.578 225.416C158.092 224.655 150.199 222.88 146.001 222.558C144.53 222.446 143.472 221.095 143.727 219.643C143.792 219.275 143.863 218.887 143.941 218.481C144.967 213.172 145.579 210.192 147.472 209.518C147.754 209.418 148.177 209.328 148.77 209.42C149.551 209.542 150.252 208.927 150.277 208.137C150.293 207.629 150.377 207.059 150.581 206.46C150.867 205.618 151.302 204.986 151.673 204.552Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask6_1305_97159)">
      <path
        opacity="0.15"
        d="M135.726 225.397C137.515 226.202 167.307 239.375 173.942 230.634C175.458 228.637 175.296 226.117 175.266 225.739C174.529 216.236 154.417 211.073 150.534 210.077C145.551 208.798 141.349 208.338 138.651 208.148C137.677 213.898 136.701 219.648 135.726 225.397Z"
        fill="#252525"
      />
    </g>
    <mask
      id="mask7_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="143"
      y="202"
      width="53"
      height="34"
    >
      <path
        d="M151.673 204.552C151.917 204.268 152.271 204.103 152.645 204.093C155.037 204.026 157.005 203.92 158.405 203.831C164.131 203.468 166.994 203.286 168.327 203.034C168.62 202.978 169.698 202.76 171.146 202.802C171.146 202.802 171.444 202.811 171.742 202.834C175.358 203.111 179.235 206.345 179.235 206.345C184.546 210.773 188.107 208.179 191.979 212.37C196.518 217.284 195.37 224.907 194.974 227.531C194.771 228.875 193.851 234.984 190.958 235.602C190.244 235.754 189.631 235.525 189.323 235.405C185.646 233.981 182.752 232.768 182.7 232.737C180.904 231.688 178.269 230.303 174.588 228.85C170.046 226.626 166.209 225.781 163.578 225.416C158.092 224.655 150.199 222.88 146.001 222.558C144.53 222.446 143.472 221.095 143.727 219.643C143.792 219.275 143.863 218.887 143.941 218.481C144.967 213.172 145.579 210.192 147.472 209.518C147.754 209.418 148.177 209.328 148.77 209.42C149.551 209.542 150.252 208.927 150.277 208.137C150.293 207.629 150.377 207.059 150.581 206.46C150.867 205.618 151.302 204.986 151.673 204.552Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask7_1305_97159)">
      <path
        opacity="0.38"
        d="M189.861 238.335C191.903 237.879 193.944 237.423 195.986 236.967L198.326 211.195C188.367 207.404 178.408 203.612 168.45 199.82C169.514 200.933 171.144 202.516 173.32 204.168C180.061 209.289 184.774 209.48 187.745 211.762C191.091 214.334 193.819 220.764 189.861 238.335Z"
        fill="#252525"
      />
    </g>
    <mask
      id="mask8_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="143"
      y="202"
      width="53"
      height="34"
    >
      <path
        d="M151.673 204.552C151.917 204.268 152.271 204.103 152.645 204.093C155.037 204.026 157.005 203.92 158.405 203.831C164.131 203.468 166.994 203.286 168.327 203.034C168.62 202.978 169.698 202.76 171.146 202.802C171.146 202.802 171.444 202.811 171.742 202.834C175.358 203.111 179.235 206.345 179.235 206.345C184.546 210.773 188.107 208.179 191.979 212.37C196.518 217.284 195.37 224.907 194.974 227.531C194.771 228.875 193.851 234.984 190.958 235.602C190.244 235.754 189.631 235.525 189.323 235.405C185.646 233.981 182.752 232.768 182.7 232.737C180.904 231.688 178.269 230.303 174.588 228.85C170.046 226.626 166.209 225.781 163.578 225.416C158.092 224.655 150.199 222.88 146.001 222.558C144.53 222.446 143.472 221.095 143.727 219.643C143.792 219.275 143.863 218.887 143.941 218.481C144.967 213.172 145.579 210.192 147.472 209.518C147.754 209.418 148.177 209.328 148.77 209.42C149.551 209.542 150.252 208.927 150.277 208.137C150.293 207.629 150.377 207.059 150.581 206.46C150.867 205.618 151.302 204.986 151.673 204.552Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask8_1305_97159)">
      <path
        opacity="0.06"
        d="M182.794 205.28C168.393 212.53 153.991 219.779 139.59 227.029C141.07 219.357 142.55 211.685 144.029 204.013C153.886 201.561 163.743 199.108 173.601 196.656C176.665 199.531 179.729 202.405 182.794 205.28Z"
        fill="#1F0801"
      />
    </g>
    <path
      opacity="0.35"
      d="M170.043 202.852C172.709 206.042 175.332 207.86 177.302 208.946C179.845 210.346 181.618 210.694 184.423 212.819C185.882 213.925 187.294 214.995 188.481 216.754C190.829 220.236 190.55 224.189 190.274 228.806C190.104 231.642 189.664 233.933 189.323 235.408"
      stroke="#252525"
      stroke-miterlimit="10"
    />
    <g opacity="0.45">
      <path
        d="M154.65 215.031C154.765 215.79 154.8 216.563 154.783 217.337C154.753 218.111 154.66 218.885 154.506 219.65C154.429 220.033 154.34 220.414 154.242 220.794C154.137 221.172 153.996 221.54 153.82 221.891C153.461 222.587 153.015 223.244 152.423 223.775L152.358 223.733C152.587 222.978 152.863 222.285 153.112 221.583C153.355 220.882 153.689 220.215 153.907 219.498C154.129 218.784 154.294 218.05 154.399 217.304C154.517 216.558 154.565 215.8 154.573 215.038L154.65 215.031Z"
        fill="#252525"
      />
    </g>
    <g opacity="0.45">
      <path
        d="M149.87 214.062C149.985 214.821 150.02 215.594 150.003 216.368C149.973 217.142 149.88 217.916 149.726 218.681C149.649 219.064 149.56 219.445 149.461 219.825C149.357 220.203 149.216 220.571 149.039 220.922C148.681 221.619 148.234 222.275 147.643 222.806L147.577 222.765C147.807 222.009 148.083 221.317 148.331 220.614C148.575 219.913 148.909 219.246 149.127 218.529C149.349 217.815 149.513 217.081 149.618 216.335C149.737 215.589 149.785 214.832 149.793 214.069L149.87 214.062Z"
        fill="#252525"
      />
    </g>
    <g opacity="0.45">
      <path
        d="M160.07 215.789C160.188 216.526 160.223 217.278 160.207 218.032C160.184 218.785 160.092 219.539 159.932 220.282C159.855 220.653 159.767 221.024 159.668 221.394C159.564 221.761 159.423 222.118 159.248 222.457C158.89 223.131 158.446 223.764 157.859 224.269L157.794 224.227C158.022 223.493 158.295 222.823 158.542 222.142C158.785 221.464 159.117 220.82 159.334 220.126C159.548 219.433 159.712 218.721 159.823 217.997C159.941 217.274 159.987 216.537 159.993 215.795L160.07 215.789Z"
        fill="#252525"
      />
    </g>
    <path
      d="M148.348 199.892C148.268 201.636 147.817 206.173 144.426 210.201C142.835 212.092 141.094 213.277 139.794 213.998C119.705 206.013 104.584 200.695 93.7315 197.099C90.8942 196.159 78.5516 192.09 67.4829 182.233C62.8801 178.135 59.6202 174.035 59.0476 168.697C58.8613 166.96 58.6966 162.647 61.3015 158.499C61.3015 158.499 63.7353 154.623 68.3628 152.466C77.8566 148.041 94.8781 159.231 108.172 168.645C119.005 176.318 132.825 186.68 148.348 199.892Z"
      fill="#E10009"
    />
    <mask
      id="mask9_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="58"
      y="151"
      width="91"
      height="63"
    >
      <path
        d="M148.347 199.892C148.267 201.636 147.816 206.173 144.426 210.201C142.834 212.092 141.094 213.277 139.793 213.998C119.704 206.013 104.583 200.695 93.7306 197.099C90.8933 196.159 78.5507 192.09 67.482 182.233C62.8792 178.135 59.6194 174.035 59.0467 168.697C58.8605 166.96 58.6958 162.647 61.3006 158.499C61.3006 158.499 63.7344 154.623 68.3619 152.466C77.8558 148.041 94.8772 159.231 108.171 168.645C119.004 176.318 132.824 186.68 148.347 199.892Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask9_1305_97159)">
      <path
        opacity="0.06"
        d="M139.889 220.658L105.619 219.93C103.509 214.362 101.398 208.793 99.2882 203.224C105.897 203.354 116.075 202.737 127.593 198.602C136.448 195.423 143.223 191.196 147.881 187.75C148.664 190.232 149.304 193.278 149.361 196.766C149.561 208.953 142.502 217.713 139.889 220.658Z"
        fill="#1F0801"
      />
    </g>
    <mask
      id="mask10_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="58"
      y="151"
      width="91"
      height="63"
    >
      <path
        d="M148.347 199.892C148.267 201.636 147.816 206.173 144.426 210.201C142.834 212.092 141.094 213.277 139.793 213.998C119.704 206.013 104.583 200.695 93.7306 197.099C90.8933 196.159 78.5507 192.09 67.482 182.233C62.8792 178.135 59.6194 174.035 59.0467 168.697C58.8605 166.96 58.6958 162.647 61.3006 158.499C61.3006 158.499 63.7344 154.623 68.3619 152.466C77.8558 148.041 94.8772 159.231 108.171 168.645C119.004 176.318 132.824 186.68 148.347 199.892Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask10_1305_97159)">
      <path
        opacity="0.06"
        d="M91.9775 153.901C91.6056 160.377 86.8001 165.4 81.4143 165.934C74.882 166.582 68.1019 160.566 68.3616 152.468C68.8291 151.727 72.0034 146.862 77.9435 146.081C83.4693 145.355 89.1262 148.459 91.9775 153.901Z"
        fill="#1F0801"
      />
    </g>
    <path
      d="M129.839 209.805C135.255 206.183 140.672 202.56 146.088 198.938C149.209 202.834 152.33 206.731 155.451 210.628C148.821 214.573 142.19 218.518 135.56 222.463C133.653 218.244 131.746 214.025 129.839 209.805Z"
      fill="#FFE3D5"
    />
    <path
      d="M136.315 206.913C136.058 206.64 135.697 206.492 135.323 206.499C132.931 206.54 130.96 206.522 129.558 206.497C123.821 206.392 120.953 206.34 119.609 206.147C119.314 206.105 118.228 205.935 116.783 206.043C116.783 206.043 116.485 206.065 116.189 206.101C112.588 206.542 108.862 209.947 108.862 209.947C103.756 214.61 100.081 212.179 96.4024 216.54C92.0891 221.654 93.5803 229.217 94.0934 231.821C94.3563 233.155 95.551 239.216 98.4694 239.702C99.1891 239.822 99.7909 239.566 100.094 239.432C103.703 237.843 106.539 236.501 106.59 236.468C108.337 235.339 110.908 233.837 114.519 232.219C118.957 229.793 122.752 228.776 125.364 228.293C130.81 227.285 138.615 225.156 142.794 224.646C144.259 224.467 145.255 223.07 144.934 221.63C144.853 221.266 144.764 220.882 144.668 220.48C143.404 215.223 142.659 212.273 140.737 211.685C140.451 211.597 140.025 211.527 139.436 211.646C138.662 211.802 137.934 211.219 137.873 210.432C137.835 209.925 137.725 209.359 137.495 208.77C137.168 207.942 136.706 207.33 136.315 206.913Z"
      fill="#E3E5E5"
    />
    <mask
      id="mask11_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="93"
      y="206"
      width="52"
      height="34"
    >
      <path
        d="M136.314 206.913C136.058 206.64 135.697 206.492 135.322 206.499C132.93 206.54 130.959 206.522 129.557 206.497C123.82 206.392 120.952 206.34 119.608 206.147C119.313 206.105 118.227 205.935 116.783 206.043C116.783 206.043 116.485 206.065 116.188 206.101C112.588 206.542 108.861 209.947 108.861 209.947C103.756 214.61 100.08 212.179 96.4018 216.54C92.0885 221.654 93.5797 229.217 94.0928 231.821C94.3557 233.155 95.5504 239.216 98.4688 239.702C99.1885 239.822 99.7903 239.566 100.094 239.432C103.703 237.843 106.539 236.501 106.589 236.468C108.336 235.339 110.907 233.837 114.519 232.219C118.956 229.793 122.751 228.776 125.363 228.293C130.809 227.285 138.614 225.156 142.794 224.646C144.258 224.467 145.254 223.07 144.933 221.63C144.852 221.266 144.764 220.882 144.667 220.48C143.404 215.223 142.658 212.273 140.736 211.685C140.45 211.597 140.024 211.527 139.436 211.646C138.661 211.802 137.933 211.219 137.873 210.432C137.834 209.925 137.725 209.359 137.494 208.77C137.167 207.942 136.705 207.33 136.314 206.913Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask11_1305_97159)">
      <path
        opacity="0.15"
        d="M153.184 227.021C151.433 227.906 122.265 242.408 115.243 233.974C113.639 232.048 113.687 229.523 113.699 229.145C114.007 219.618 133.866 213.554 137.7 212.384C142.621 210.881 146.798 210.233 149.484 209.922C150.718 215.622 151.951 221.321 153.184 227.021Z"
        fill="#252525"
      />
    </g>
    <mask
      id="mask12_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="93"
      y="206"
      width="52"
      height="34"
    >
      <path
        d="M136.314 206.913C136.058 206.64 135.697 206.492 135.322 206.499C132.93 206.54 130.959 206.522 129.557 206.497C123.82 206.392 120.952 206.34 119.608 206.147C119.313 206.105 118.227 205.935 116.783 206.043C116.783 206.043 116.485 206.065 116.188 206.101C112.588 206.542 108.861 209.947 108.861 209.947C103.756 214.61 100.08 212.179 96.4018 216.54C92.0885 221.654 93.5797 229.217 94.0928 231.821C94.3557 233.155 95.5504 239.216 98.4688 239.702C99.1885 239.822 99.7903 239.566 100.094 239.432C103.703 237.843 106.539 236.501 106.589 236.468C108.336 235.339 110.907 233.837 114.519 232.219C118.956 229.793 122.751 228.776 125.363 228.293C130.809 227.285 138.614 225.156 142.794 224.646C144.258 224.467 145.254 223.07 144.933 221.63C144.852 221.266 144.764 220.882 144.667 220.48C143.404 215.223 142.658 212.273 140.736 211.685C140.45 211.597 140.024 211.527 139.436 211.646C138.661 211.802 137.933 211.219 137.873 210.432C137.834 209.925 137.725 209.359 137.494 208.77C137.167 207.942 136.705 207.33 136.314 206.913Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask12_1305_97159)">
      <path
        opacity="0.38"
        d="M99.6875 242.379C97.6275 242.015 95.5674 241.652 93.5074 241.287L90.0081 215.647C99.7864 211.41 109.564 207.174 119.342 202.938C118.329 204.097 116.772 205.752 114.672 207.501C108.169 212.92 103.469 213.323 100.604 215.737C97.3773 218.456 94.9422 225.003 99.6875 242.379Z"
        fill="#252525"
      />
    </g>
    <path
      opacity="0.35"
      d="M117.887 206.047C115.368 209.354 112.83 211.289 110.91 212.461C108.433 213.974 106.677 214.402 103.971 216.652C102.564 217.822 101.2 218.955 100.094 220.766C97.9051 224.349 98.3625 228.286 98.8464 232.885C99.1435 235.711 99.6871 237.98 100.094 239.439"
      stroke="#252525"
      stroke-miterlimit="10"
    />
    <g opacity="0.45">
      <path
        d="M133.891 217.518C133.934 218.28 134.016 219.034 134.168 219.773C134.306 220.514 134.504 221.24 134.757 221.944C135.008 222.65 135.371 223.302 135.646 223.99C135.926 224.681 136.233 225.361 136.496 226.105L136.433 226.149C135.818 225.646 135.343 225.01 134.953 224.33C134.761 223.988 134.603 223.627 134.482 223.254C134.366 222.879 134.261 222.502 134.166 222.123C133.977 221.366 133.85 220.596 133.785 219.825C133.733 219.052 133.733 218.279 133.814 217.516L133.891 217.518Z"
        fill="#252525"
      />
    </g>
    <g opacity="0.45">
      <path
        d="M138.622 216.346C138.665 217.108 138.747 217.862 138.899 218.601C139.037 219.342 139.235 220.069 139.488 220.772C139.739 221.478 140.102 222.13 140.377 222.818C140.657 223.51 140.964 224.189 141.227 224.933L141.164 224.977C140.549 224.474 140.074 223.838 139.684 223.158C139.492 222.816 139.334 222.455 139.213 222.082C139.097 221.707 138.992 221.33 138.897 220.951C138.708 220.194 138.581 219.424 138.516 218.653C138.464 217.881 138.464 217.107 138.545 216.344L138.622 216.346Z"
        fill="#252525"
      />
    </g>
    <g opacity="0.45">
      <path
        d="M128.509 218.525C128.549 219.265 128.629 219.999 128.779 220.717C128.922 221.435 129.118 222.138 129.363 222.821C129.611 223.505 129.972 224.133 130.245 224.8C130.522 225.468 130.826 226.126 131.087 226.849L131.024 226.895C130.415 226.417 129.943 225.805 129.556 225.147C129.364 224.816 129.208 224.467 129.087 224.104C128.972 223.739 128.868 223.373 128.774 223.005C128.58 222.27 128.454 221.521 128.397 220.77C128.347 220.018 128.349 219.265 128.432 218.523L128.509 218.525Z"
        fill="#252525"
      />
    </g>
    <path
      d="M139.428 202.116C139.586 203.854 140.241 208.367 143.81 212.238C145.485 214.055 147.277 215.16 148.609 215.822C168.318 206.94 183.183 200.946 193.863 196.864C196.655 195.797 208.801 191.176 219.415 180.831C223.828 176.529 226.9 172.287 227.232 166.929C227.339 165.186 227.31 160.869 224.521 156.842C224.521 156.842 221.915 153.079 217.195 151.133C207.511 147.141 191.012 159.086 178.156 169.09C167.678 177.244 154.339 188.218 139.428 202.116Z"
      fill="#E10009"
    />
    <mask
      id="mask13_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="139"
      y="150"
      width="89"
      height="66"
    >
      <path
        d="M139.427 202.116C139.586 203.854 140.241 208.367 143.809 212.238C145.485 214.055 147.276 215.16 148.608 215.822C168.317 206.94 183.183 200.946 193.862 196.864C196.655 195.797 208.801 191.176 219.414 180.831C223.827 176.529 226.899 172.287 227.231 166.929C227.339 165.186 227.309 160.869 224.52 156.842C224.52 156.842 221.914 153.079 217.194 151.133C207.51 147.141 191.011 159.086 178.155 169.09C167.678 177.244 154.339 188.218 139.427 202.116Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask13_1305_97159)">
      <path
        opacity="0.06"
        d="M202.106 162.868C201.871 163.465 198.603 171.427 191.879 171.33C186.772 171.256 182.507 166.573 182.022 162.442C181.329 156.543 188.026 149.085 199.367 147.531C199.733 148.001 205.132 155.169 202.106 162.868Z"
        fill="#1F0801"
      />
    </g>
    <path
      d="M97.5611 100.94C98.7299 100.203 100.461 98.9085 101.903 96.8143C104.809 92.5947 104.571 87.995 104.323 84.229C103.925 78.1756 101.836 71.3303 100.416 71.4709C99.7586 71.5355 99.0054 73.1179 98.5689 79.7193C97.7784 79.4482 87.1056 75.9058 83.3612 80.3414C81.163 82.945 81.8262 87.7499 83.1515 97.3609C83.3301 98.6558 83.5081 99.7365 83.6418 100.506C82.314 103.457 80.2964 107.915 77.798 113.335C71.4639 127.076 70.9007 127.773 70.0405 131C68.7045 136.009 66.0939 145.801 71.0724 154.562C71.5323 155.371 76.0053 163.242 80.3655 162.977C85.6322 162.657 92.0024 150.385 97.5611 100.94Z"
      fill="#FFE3D5"
    />
    <path
      d="M178.272 124.244C175.713 122.333 173.153 120.421 170.594 118.51C168.888 117.236 167.002 115.033 167.484 114.125C168.08 113.001 172.267 114.145 173.604 114.534C171.274 112.673 163.85 106.625 164.749 105.153C165.589 103.777 173.303 107.034 176.243 108.322C169.596 102.711 168.643 100.866 169.032 100.23C169.612 99.2818 173.532 100.421 175.756 101.317C179.019 102.634 181.072 104.414 181.204 104.249C181.459 103.932 173.366 98.0022 174.124 96.5769C174.538 95.7984 177.643 96.2703 186.321 99.5587C186.107 98.2905 185.913 95.9529 187.004 93.5077C187.934 91.4236 189.832 89.1817 190.709 89.5473C191.478 89.8672 190.922 91.8126 191.009 95.125C191.158 100.851 192.976 101.085 193.012 105.753C193.03 108.13 192.351 110.62 190.992 115.6C190.372 117.871 189.775 119.727 189.345 120.997C194.738 135.848 197.477 142.922 198.938 146.4C199.007 146.562 199.179 146.972 199.367 147.536C199.79 148.805 201.965 155.325 198.245 161.023C196.468 163.745 192.313 167.863 188.366 167.082C181.063 165.637 179.071 148.335 178.317 141.796C177.478 134.508 177.818 128.398 178.272 124.244Z"
      fill="#FFE3D5"
    />
    <g opacity="0.47">
      <path
        d="M185.969 99.6172C186.242 99.7585 186.53 99.7927 186.832 99.8699C187.141 99.965 187.407 100.06 187.707 100.184C187.856 100.256 187.987 100.337 188.09 100.377C188.138 100.396 188.206 100.411 188.29 100.436C188.332 100.449 188.378 100.465 188.426 100.488L188.542 100.548C188.832 100.715 189.056 100.917 189.295 101.07C189.54 101.243 189.792 101.416 190.033 101.611C190.268 101.806 190.511 102.035 190.697 102.265C190.897 102.491 191.08 102.72 191.281 102.959C191.472 103.204 191.635 103.455 191.802 103.711C191.552 103.535 191.332 103.32 191.1 103.135C190.872 102.954 190.637 102.75 190.413 102.549C189.981 102.143 189.508 101.834 189.003 101.494C188.741 101.312 188.516 101.108 188.29 100.964L188.21 100.913C188.19 100.901 188.166 100.89 188.137 100.878C188.079 100.855 188 100.832 187.911 100.79C187.738 100.707 187.626 100.607 187.513 100.533C187.269 100.4 186.991 100.237 186.743 100.105C186.486 99.9707 186.174 99.8535 185.969 99.6172Z"
        fill="#AD4323"
      />
    </g>
    <g opacity="0.47">
      <path
        d="M180.794 103.898C181.035 103.986 181.259 104.109 181.478 104.238L181.791 104.439C181.887 104.493 181.992 104.546 182.104 104.598C182.593 104.815 182.975 105.171 183.366 105.401C183.791 105.65 184.225 105.929 184.611 106.27C184.816 106.438 184.97 106.649 185.139 106.795C185.217 106.866 185.342 106.947 185.429 107.04C185.523 107.132 185.599 107.237 185.666 107.346C185.559 107.275 185.453 107.209 185.345 107.156C185.232 107.099 185.133 107.068 184.999 107.008C184.752 106.876 184.569 106.711 184.368 106.591C183.959 106.328 183.532 106.1 183.1 105.843C182.634 105.562 182.304 105.195 181.906 104.947C181.803 104.875 181.691 104.804 181.587 104.708C181.485 104.609 181.411 104.527 181.321 104.436C181.151 104.254 180.974 104.081 180.794 103.898Z"
        fill="#AD4323"
      />
    </g>
    <g opacity="0.47">
      <path
        d="M175.274 108.031C175.452 108.038 175.618 108.131 175.77 108.211C175.924 108.299 176.061 108.345 176.234 108.408C176.399 108.475 176.559 108.532 176.728 108.609C176.896 108.692 177.035 108.788 177.17 108.836C177.234 108.86 177.293 108.869 177.378 108.881C177.455 108.891 177.573 108.915 177.658 108.943C177.835 108.997 178.005 109.064 178.169 109.136C178.496 109.284 178.796 109.458 179.097 109.598C179.704 109.886 180.369 110.174 180.934 110.621C181.212 110.817 181.482 111.02 181.759 111.232L182.165 111.558C182.233 111.608 182.308 111.658 182.376 111.717C182.436 111.774 182.523 111.851 182.504 111.945C182.494 111.856 182.404 111.818 182.332 111.781C182.257 111.744 182.177 111.716 182.094 111.682C181.923 111.601 181.783 111.526 181.628 111.449C181.323 111.289 181.009 111.124 180.704 110.95C180.136 110.601 179.515 110.355 178.873 110.06C178.552 109.899 178.255 109.725 177.959 109.573C177.812 109.495 177.665 109.426 177.514 109.369C177.438 109.337 177.385 109.325 177.293 109.301C177.209 109.28 177.1 109.249 177.006 109.199C176.826 109.105 176.688 108.99 176.558 108.897C176.425 108.808 176.27 108.724 176.127 108.634C175.989 108.548 175.812 108.447 175.69 108.326C175.558 108.214 175.439 108.092 175.274 108.031Z"
        fill="#AD4323"
      />
    </g>
    <g opacity="0.47">
      <path
        d="M173.331 114.25C173.636 114.24 173.924 114.409 174.175 114.549C174.301 114.622 174.423 114.692 174.546 114.738L174.955 114.901C175.094 114.957 175.231 115.011 175.368 115.075C175.511 115.149 175.641 115.232 175.764 115.311C175.888 115.389 176.001 115.47 176.116 115.531L176.501 115.724C177.004 115.986 177.533 116.283 178.031 116.591L178.397 116.82C178.516 116.896 178.649 116.967 178.777 117.057C179.057 117.24 179.15 117.553 179.459 117.584C179.313 117.636 179.142 117.568 179.018 117.487C178.883 117.404 178.781 117.323 178.651 117.277C178.525 117.221 178.39 117.177 178.247 117.123C178.1 117.063 177.971 117 177.837 116.943C177.569 116.828 177.312 116.697 177.049 116.575L176.262 116.179L175.869 115.968C175.729 115.886 175.603 115.799 175.487 115.71C175.371 115.622 175.26 115.538 175.148 115.468C175.025 115.401 174.898 115.33 174.773 115.258C174.647 115.184 174.526 115.119 174.395 115.037C174.256 114.951 174.139 114.852 174.028 114.753C173.81 114.553 173.612 114.356 173.331 114.25Z"
        fill="#AD4323"
      />
    </g>
    <g opacity="0.47">
      <path
        d="M189.706 120.453C189.686 120.649 189.587 120.831 189.482 120.996C189.375 121.163 189.247 121.314 189.121 121.462C188.882 121.731 188.707 122.094 188.346 122.363C188.188 122.483 188.027 122.592 187.875 122.698C187.799 122.752 187.726 122.806 187.657 122.863L187.435 123.048C187.274 123.177 187.131 123.272 186.985 123.399C186.8 123.546 186.611 123.627 186.474 123.717C186.176 123.9 185.88 124.175 185.481 124.357C185.108 124.496 184.75 124.632 184.38 124.755C184.204 124.82 183.995 124.88 183.803 124.909C183.609 124.941 183.423 124.967 183.244 125.008C183.148 125.027 183.079 125.051 182.967 125.082C182.864 125.106 182.767 125.117 182.672 125.127C182.485 125.149 182.294 125.169 182.151 125.307C182.249 125.131 182.46 125.046 182.641 124.988C182.733 124.955 182.824 124.929 182.904 124.893C182.974 124.859 183.079 124.802 183.168 124.767C183.355 124.692 183.545 124.64 183.725 124.581C183.907 124.524 184.065 124.467 184.242 124.379C184.584 124.215 184.94 124.069 185.277 123.915C185.577 123.767 185.853 123.513 186.201 123.282C186.389 123.168 186.558 123.097 186.675 123.004C186.823 122.884 186.997 122.762 187.144 122.661L187.375 122.49C187.458 122.431 187.543 122.376 187.627 122.324C187.795 122.218 187.964 122.128 188.123 122.032C188.407 121.867 188.636 121.547 188.946 121.283C189.095 121.164 189.237 121.04 189.371 120.908C189.502 120.773 189.632 120.634 189.706 120.453Z"
        fill="#AD4323"
      />
    </g>
    <g opacity="0.47">
      <path
        d="M87.0532 79.7816C89.062 79.328 91.1126 79.0106 93.196 79.0157C93.7168 79.0195 94.24 79.0461 94.7626 79.1227C95.2833 79.2032 95.811 79.3096 96.3127 79.5548C96.5649 79.6694 96.8024 79.8398 97.0108 80.0489C97.1077 80.1629 97.204 80.2782 97.2705 80.4169C97.3516 80.5474 97.3934 80.695 97.4289 80.8357C97.5543 81.4051 97.4828 81.9829 97.2496 82.508C97.007 83.037 96.5439 83.4494 96.0435 83.68C95.5424 83.9226 95.0116 84.0391 94.4909 84.1145C93.967 84.1886 93.4532 84.2178 92.9439 84.2678L91.4128 84.4205C89.3724 84.6454 87.3351 84.9209 85.2966 85.2212C87.2959 84.7252 89.3192 84.3153 91.3621 84.0227C91.8727 83.9473 92.3839 83.8789 92.897 83.8199C93.4108 83.7572 93.9239 83.7154 94.4174 83.6331C94.9102 83.552 95.398 83.4411 95.8224 83.2289C96.2519 83.0249 96.594 82.7038 96.7828 82.2933C96.9728 81.8828 97.0355 81.3861 96.9399 80.9503C96.9133 80.8426 96.886 80.7375 96.8284 80.6494C96.7853 80.5531 96.715 80.4714 96.6472 80.3878C96.4926 80.2339 96.3096 80.0951 96.0961 79.9906C95.6761 79.7708 95.1839 79.6542 94.6929 79.5681C94.1988 79.4864 93.6933 79.4408 93.1859 79.4167C92.1698 79.373 91.1449 79.4084 90.1218 79.4781C89.0968 79.537 88.0757 79.6549 87.0532 79.7816Z"
        fill="#AD4323"
      />
    </g>
    <g opacity="0.47">
      <path
        d="M93.4757 84.0348C94.1732 83.8321 94.977 83.8923 95.6365 84.2965C95.9621 84.5004 96.2377 84.8013 96.4043 85.1637C96.5709 85.5273 96.6101 85.9371 96.5645 86.3267C96.5151 86.7163 96.3897 87.1015 96.1559 87.4315C95.9317 87.766 95.6162 88.034 95.2735 88.2253C94.5767 88.6022 93.8108 88.7111 93.0817 88.7891C91.6285 88.8986 90.1981 89.0475 88.757 89.2242C87.3196 89.4054 85.8816 89.6031 84.4392 89.8032C85.8405 89.4073 87.2613 89.0766 88.6974 88.8271C89.4145 88.6953 90.1373 88.5946 90.8601 88.4983C91.5816 88.4071 92.3171 88.3425 93.0329 88.2772C93.7411 88.2132 94.4475 88.1049 95.0436 87.7977C95.6422 87.5031 96.0539 86.9349 96.1686 86.2691C96.2275 85.9416 96.2066 85.6008 96.0939 85.2923C95.9805 84.9831 95.767 84.7088 95.494 84.503C95.2241 84.2914 94.8947 84.1482 94.5488 84.0728C94.201 84.0013 93.8374 83.9892 93.4757 84.0348Z"
        fill="#AD4323"
      />
    </g>
    <g opacity="0.47">
      <path
        d="M93.3796 88.5661C94.0771 88.3634 94.881 88.4236 95.5404 88.8277C95.866 89.0317 96.1416 89.3326 96.3082 89.6949C96.4748 90.0585 96.5141 90.4684 96.4685 90.858C96.419 91.2476 96.2936 91.6327 96.0599 91.9628C95.8356 92.2972 95.5201 92.5652 95.1774 92.7565C94.4806 93.1334 93.7147 93.2424 92.9856 93.3203C91.5324 93.4299 90.102 93.5788 88.6609 93.7555C87.2235 93.9367 85.7856 94.1343 84.3431 94.3345C85.7444 93.9386 87.1653 93.6079 88.6013 93.3583C89.3184 93.2265 90.0412 93.1258 90.764 93.0295C91.4856 92.9383 92.221 92.8737 92.9368 92.8085C93.6451 92.7445 94.3514 92.6361 94.9475 92.3289C95.5461 92.0337 95.9579 91.4661 96.0725 90.8003C96.1315 90.4728 96.1105 90.132 95.9978 89.8235C95.8844 89.5144 95.6709 89.2401 95.3979 89.0342C95.128 88.8226 94.7986 88.6795 94.4527 88.6041C94.105 88.5325 93.7414 88.5205 93.3796 88.5661Z"
        fill="#AD4323"
      />
    </g>
    <g opacity="0.47">
      <path
        d="M93.2833 93.1051C93.9808 92.9024 94.7847 92.9626 95.4441 93.3668C95.7697 93.5707 96.0453 93.8716 96.2119 94.234C96.3785 94.5976 96.4177 95.0075 96.3721 95.397C96.3227 95.7866 96.1973 96.1718 95.9635 96.5018C95.7393 96.8363 95.4238 97.1043 95.0811 97.2956C94.3843 97.6725 93.6184 97.7814 92.8893 97.8594C91.4361 97.969 90.0057 98.1178 88.5646 98.2946C87.1272 98.4757 85.6892 98.6734 84.2468 98.8736C85.6481 98.4776 87.069 98.147 88.505 97.8974C89.2221 97.7656 89.9449 97.6649 90.6677 97.5686C91.3892 97.4774 92.1247 97.4128 92.8405 97.3475C93.5487 97.2835 94.2551 97.1752 94.8512 96.868C95.4498 96.5728 95.8616 96.0052 95.9762 95.3394C96.0351 95.0119 96.0142 94.6711 95.9015 94.3626C95.7881 94.0535 95.5746 93.7792 95.3016 93.5733C95.0317 93.3617 94.7023 93.2185 94.3564 93.1431C94.0087 93.0716 93.645 93.0595 93.2833 93.1051Z"
        fill="#AD4323"
      />
    </g>
    <g opacity="0.46">
      <path
        d="M147.893 46.2266C147.454 46.981 147.051 47.7526 146.722 48.5546C146.388 49.354 146.112 50.1744 145.892 51.008C145.674 51.8423 145.522 52.6912 145.423 53.5457C145.328 54.4003 145.298 55.2612 145.321 56.1195L145.074 55.8756C145.68 55.8801 146.32 56.0378 146.827 56.4407C147.339 56.8404 147.625 57.4669 147.704 58.0744C147.787 58.6895 147.691 59.3027 147.484 59.8697C147.275 60.436 146.915 60.9612 146.457 61.3488C146.002 61.7441 145.459 62.0279 144.881 62.185C144.302 62.3326 143.671 62.3364 143.105 62.1223C142.533 61.9259 142.074 61.4888 141.773 60.9935C141.472 60.4917 141.314 59.9223 141.276 59.354C141.377 59.9134 141.581 60.455 141.901 60.9105C142.222 61.3621 142.675 61.7137 143.194 61.8613C143.709 62.0267 144.267 61.9848 144.782 61.8341C145.296 61.6719 145.776 61.3951 146.167 61.0264C146.569 60.6666 146.862 60.2143 147.037 59.7094C147.204 59.2045 147.283 58.6597 147.208 58.1435C147.134 57.631 146.904 57.1489 146.507 56.8429C146.118 56.5294 145.593 56.3849 145.068 56.378L144.826 56.3748L144.82 56.1341C144.797 55.2504 144.848 54.3648 144.969 53.49C145.086 52.6145 145.282 51.7511 145.538 50.9079C145.802 50.0673 146.123 49.2457 146.522 48.4634C146.92 47.6804 147.37 46.9259 147.893 46.2266Z"
        fill="#AD4323"
      />
    </g>
    <path
      d="M134.949 44.101C132.776 43.7627 131.309 45.1646 130.967 44.6781C130.688 44.2828 131.494 43.126 132.372 42.4444C133.926 41.2383 135.793 41.4511 136.397 41.5202C136.937 41.5816 138.24 41.7476 139.296 42.7308C139.583 42.9975 140.366 43.7253 140.167 44.3512C139.973 44.9657 138.947 45.1221 138.722 45.1563C137.153 45.3958 136.608 44.3601 134.949 44.101Z"
      fill="#23383B"
    />
    <path
      d="M143.705 66.3259C140.29 66.6002 138.235 64.0226 137 65.0463C136.054 65.8292 136.63 67.8589 136.702 68.104C137.446 70.6208 140.289 72.1513 142.741 72.3648C145.84 72.6346 149.344 70.8894 150.261 68.0673C150.683 66.768 150.705 64.7815 149.739 64.2025C148.458 63.4347 146.972 66.0636 143.705 66.3259Z"
      fill="black"
    />
    <mask
      id="mask14_1305_97159"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="136"
      y="64"
      width="15"
      height="9"
    >
      <path
        d="M143.705 66.3181C140.29 66.5924 138.235 64.0148 137 65.0384C136.054 65.8214 136.63 67.8511 136.703 68.0962C137.447 70.613 140.29 72.1435 142.741 72.357C145.84 72.6268 149.344 70.8816 150.261 68.0595C150.683 66.7602 150.706 64.7737 149.74 64.1947C148.458 63.4269 146.973 66.0558 143.705 66.3181Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask14_1305_97159)">
      <path
        d="M148.06 71.1516C148.2 72.9513 145.759 74.7491 143.504 74.6996C141.247 74.6502 138.898 72.7447 139.102 70.9615C139.306 69.1834 141.99 68.0526 144.081 68.2585C145.758 68.4226 147.934 69.5318 148.06 71.1516Z"
        fill="#E10009"
      />
    </g>
    <g opacity="0.46">
      <path
        d="M120.975 52.2207C121.091 51.5454 121.388 50.8473 121.997 50.3855C122.295 50.1486 122.685 50.0314 123.061 49.9985C123.443 49.9693 123.817 50.0447 124.165 50.1651C124.859 50.4203 125.453 50.879 125.893 51.4383C126.338 51.9952 126.653 52.6426 126.802 53.3198C126.453 52.7218 126.059 52.1732 125.594 51.7063C125.483 51.5853 125.35 51.4871 125.233 51.3731C125.102 51.2768 124.981 51.1666 124.841 51.0849C124.578 50.8986 124.289 50.7523 123.992 50.649C123.404 50.4229 122.749 50.4089 122.219 50.7181C121.681 51.0329 121.289 51.6113 120.975 52.2207Z"
        fill="#AD4323"
      />
    </g>
    <path
      d="M153.832 44.5022C156.017 44.2564 157.423 45.7191 157.786 45.2478C158.081 44.8645 157.326 43.6749 156.477 42.9565C154.975 41.6857 153.101 41.8188 152.495 41.8618C151.953 41.9005 150.645 42.0114 149.547 42.9483C149.249 43.2029 148.437 43.8966 148.608 44.53C148.777 45.1521 149.795 45.3517 150.018 45.396C151.575 45.7026 152.163 44.6903 153.832 44.5022Z"
      fill="#23383B"
    />
    <g opacity="0.46">
      <path
        d="M164.337 55.9947C164.614 55.3676 164.816 54.698 164.723 54.0822C164.629 53.476 164.206 52.9756 163.66 52.6607C163.393 52.4948 163.098 52.363 162.787 52.2756C162.636 52.2186 162.474 52.1939 162.317 52.152C162.155 52.133 161.995 52.0919 161.831 52.0811C161.175 52.0133 160.501 52.052 159.817 52.1571C160.437 51.8473 161.138 51.6845 161.851 51.6801C162.563 51.67 163.293 51.8429 163.928 52.2217C164.24 52.4168 164.535 52.6601 164.752 52.9749C164.963 53.2879 165.118 53.6654 165.121 54.0455C165.145 54.8101 164.79 55.4797 164.337 55.9947Z"
        fill="#AD4323"
      />
    </g>
    <g opacity="0.18">
      <path
        d="M102.873 126.275C102.958 125.135 103.093 124.004 103.245 122.876C103.395 121.747 103.573 120.623 103.758 119.501C103.952 118.379 104.152 117.259 104.382 116.145C104.609 115.029 104.854 113.917 105.149 112.812C105.065 113.952 104.931 115.084 104.778 116.212C104.629 117.341 104.45 118.464 104.265 119.587C104.07 120.708 103.869 121.828 103.639 122.943C103.412 124.058 103.168 125.171 102.873 126.275Z"
        fill="#23383B"
      />
    </g>
    <g opacity="0.18">
      <path
        d="M171.742 122.055C171.873 122.955 171.952 123.857 172.014 124.759C172.079 125.661 172.114 126.564 172.143 127.467C172.162 128.37 172.175 129.274 172.158 130.178C172.144 131.083 172.112 131.988 172.029 132.893C171.898 131.993 171.818 131.091 171.757 130.189C171.692 129.287 171.657 128.384 171.629 127.48C171.61 126.577 171.597 125.673 171.614 124.769C171.628 123.866 171.66 122.961 171.742 122.055Z"
        fill="#23383B"
      />
    </g>
    <g opacity="0.13">
      <path
        d="M122.861 82.8203C123.266 84.9355 123.801 87.0069 124.687 88.9403C125.578 90.8642 126.709 92.6575 128.059 94.2634C129.421 95.8591 131.031 97.2306 132.801 98.3195C134.567 99.4116 136.508 100.227 138.535 100.616C142.593 101.501 146.886 100.303 150.153 97.7025C151.803 96.4235 153.246 94.8557 154.412 93.0889C155.614 91.3412 156.519 89.3913 157.217 87.3566C156.33 91.5496 154.178 95.6222 150.776 98.4703C149.11 99.9228 147.106 101 144.954 101.628C142.795 102.236 140.483 102.296 138.293 101.859C133.9 100.96 130.009 98.3398 127.298 94.8873C125.949 93.1485 124.892 91.2075 124.116 89.1721C123.402 87.1102 122.982 84.9684 122.861 82.8203Z"
        fill="#23383B"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'HasNoRateIcon'
}
</script>
