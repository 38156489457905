<template>
  <div class="container-table-unit-price">
    <div v-if="initialData.length > 0" class="is-flex unit-price-container">
      <div class="unit-price-section left">
        <div class="is-flex bg-dark-grey-5 color-white averta-bold br-note-item-6">
          <div
            class="item-price left"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_NO}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p>NO</p>
          </div>
          <div
            class="item-price left"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_ID}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }">
            <p>ID</p>
          </div>
          <div
            class="item-price left"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_NOTE_ITEM}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }">
            <p>NAMA ITEM PEKERJAAN</p>
          </div>
          <div
            class="item-price left"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p>SATUAN</p>
          </div>
        </div>
        <div class="item-column br-note-item-6" v-for="(item, index) in dataTable" :key="index">
          <div
            class="flex-center"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_NO}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>{{index+1}}</p>
          </div>
          <div
            class="flex-center"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_ID}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p class="averta-bold">{{item.id}}</p>
          </div>
          <div
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_NOTE_ITEM}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <div class="flex-center c-h-100">
              <div class="max-c-h-100 c-w-100">
                <p>{{item.note}}</p>
              </div>
            </div>
          </div>
          <div
            class="flex-center"
            :style="{
              width: `${S.TBL_WIDTH_PERCENT_UNIT_NAME}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>{{item.name}}</p>
          </div>
        </div>
      </div>
      <div class="unit-price-section middle">
        <div class="is-flex color-white averta-bold">
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_MATERIAL}px`,
              width: `${S.TBL_WIDTH_PERCENT_MATERIAL}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p class="has-text-centered">HARGA MATERIAL</p>
            <div @click="handleSort('material_price')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="">
            </div>
          </div>
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_JASA}px`,
              width: `${S.TBL_WIDTH_PERCENT_JASA}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p>HARGA JASA</p>
            <div @click="handleSort('service_price')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="">
            </div>
          </div>
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_SATUAN}px`,
              width: `${S.TBL_WIDTH_PERCENT_SATUAN}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p class="has-text-centered">HARGA SATUAN</p>
            <div @click="handleSort('unit_price')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="">
            </div>
          </div>

          <!-- <div class="item-price middle flex-center bg-dark-grey-5" :style="{width: '13em', height: `${S.HEIGHT_COLUMN_HEADER}px`}">
            <p>TANGGAL DIBUAT</p>
            <div @click="handleSort('created_at')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="">
            </div>
          </div> -->
          <div
            class="item-price middle flex-center bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_UPDATED_AT}px`,
              width: `${S.TBL_WIDTH_PERCENT_UPDATED_AT}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <p class="has-text-centered">TANGGAL <br> TER UPDATE</p>
            <div @click="handleSort('last_updated_at')" class="is-clickable ml-1">
              <img :src="require('@/assets/icons/arrow-top-bottom-used.svg')" alt="">
            </div>
          </div>
          <div
            class="item-price middle bg-dark-grey-5"
            :style="{
              minWidth: `${S.TBL_WIDTH_ACTION}px`,
              width: `${S.TBL_WIDTH_PERCENT_ACTION}`,
              height: `${S.HEIGHT_COLUMN_HEADER}px`
            }"
          >
            <!-- ACTION COLUMN -->
          </div>
        </div>
        <div class="is-flex" v-for="(item, index) in dataTable" :key="index">
          <div
            class="item-price middle is-justify-content-space-between averta-bold"
            :style="{
              minWidth: `${S.TBL_WIDTH_MATERIAL}px`,
              width: `${S.TBL_WIDTH_PERCENT_MATERIAL}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>Rp</p>
            <p>{{item.material_price ? formatThousand(item.material_price) : 0}}</p>
          </div>
          <div
            class="item-price middle is-justify-content-space-between averta-bold"
            :style="{
              minWidth: `${S.TBL_WIDTH_JASA}px`,
              width: `${S.TBL_WIDTH_PERCENT_JASA}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>Rp</p>
            <p>{{item.service_price ? formatThousand(item.service_price) : 0}}</p>
          </div>
          <div
            class="item-price middle is-justify-content-space-between averta-bold"
            :style="{
              minWidth: `${S.TBL_WIDTH_SATUAN}px`,
              width: `${S.TBL_WIDTH_PERCENT_SATUAN}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>Rp</p>
            <p>{{formatThousand(item.unit_price)}}</p>
          </div>
          <!-- <div class="item-price middle" :style="{width: '13em', height: `${S.TBL_HEIGHT_COLUMN_BODY}px`}">
            <p>{{formatDate(item.created_at)}}</p>
          </div> -->
          <div
            class="item-price middle"
            :style="{
              minWidth: `${S.TBL_WIDTH_UPDATED_AT}px`,
              width: `${S.TBL_WIDTH_PERCENT_UPDATED_AT}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <p>{{formatDate(item.last_updated_at || item.created_at)}}</p>
          </div>
          <div
            class="item-price middle flex-center"
            :style="{
              minWidth: `${S.TBL_WIDTH_ACTION}px`,
              width: `${S.TBL_WIDTH_PERCENT_ACTION}`,
              height: `${S.TBL_HEIGHT_COLUMN_BODY}px`
            }"
          >
            <div class="is-clickable mr-3" @click="() => handleEdit(item)">
              <EditIcon color="black" size="18" />
            </div>
            <div class="is-clickable" @click="() => handleDelete(item)">
              <TrashIcon size="18" />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="unit-price-section right">
        <div class="action-empty bg-dark-grey-5" :style="{ height: `${S.HEIGHT_COLUMN_HEADER}px` }">
        </div>
        <div
          :key="index"
          v-for="(item, index) in dataTable"
          class="flex-center column-action"
          :style="{ height: `${S.TBL_HEIGHT_COLUMN_BODY}px` }"
        >
          <div class="is-clickable mr-3" @click="() => handleEdit(item)">
            <EditIcon color="black" size="18" />
          </div>
          <div class="is-clickable" @click="() => handleDelete(item)">
            <TrashIcon size="18" />
          </div>
        </div>
      </div> -->

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
    <div v-else class="column c-w-100 has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">
        Data Tidak Ditemukan
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { formatCurrency, alertErrorMessage, toastMessage } from '@/helpers'
import { EditIcon, TrashIcon, SpinnerIcon, ProyekEmptyIcon } from '@/components/icons'
import discardImage from '@/assets/img/delete-area-component.png'

export default {
  name: 'TableUnitPrice',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    initialData: {
      type: Array,
      default: () => []
    },
    refreshData: {
      type: Function
    }
  },
  components: {
    EditIcon,
    TrashIcon,
    SpinnerIcon,
    ProyekEmptyIcon
  },
  data () {
    return {
      isLoading: false,
      sortBy: null,
      sortType: null,
      dataTable: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      S: 'unitPrice/staticUnitPrice'
    })
  },
  methods: {
    formatThousand (val) {
      return formatCurrency(val, true)
    },
    formatDate (val) {
      if (!val) return '-'
      return moment(val).format('DD MMM YYYY')
    },
    handleEdit (data) {
      this.$router.push({ path: `/am/form-unit-price/${this.$route.params.vendorId}`, query: { noteId: data.id } })
    },
    handleDelete (data) {
      this.$swal({
        imageUrl: discardImage,
        imageAlt: 'cancel offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Hapus Item Pekerjaan Ini?</p>',
        html: '<p class="averta-reguler">Seluruh informasi pada item pekerjaan ini akan terhapus.</p>',
        showCancelButton: true,
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true
          const payload = {
            url: this.url,
            id: data.id
          }
          this.$store
            .dispatch('unitPrice/deleteItemWorkingUnitPrice', payload)
            .then(() => {
              toastMessage('Data Berhasil Dihapus', 'is-dark')
              this.refreshData({ refresh: true })
              this.isLoading = false
            })
            .catch(err => {
              this.isLoading = false
              alertErrorMessage(err)
            })
        }
      })
    },
    handleSort (type) {
      if (type !== this.sortBy || !this.sortBy) {
        this.sortBy = type
        this.sortType = 'desc'
      } else {
        if (this.sortType === 'desc') {
          this.sortType = 'asc'
        } else if (this.sortType === 'asc') {
          this.sortBy = null
          this.sortType = null
        }
      }

      this.refreshData({
        refresh: true,
        sortBy: this.sortBy,
        sortType: this.sortType,
        hideLoading: true
      })
    }
  },
  created () {
    this.dataTable = this.initialData
  },
  watch: {
    initialData: {
      handler (val) {
        this.dataTable = val
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.container-table-unit-price {
  // max-height: 73vh;
  // overflow: auto;
  // border-radius: 10px;
  // border-bottom: 1px solid #C0C0C0;
  // position: relative;
}

.unit-price-container {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.unit-price-section {
}

.unit-price-section.left {
  width: 40%;
}

.unit-price-section.middle {
  width: 60%;
  overflow-x: auto;
  overflow-y: hidden;
  border-right: 1px solid #C0C0C0;
}

.unit-price-section.right {
  width: 8%;
}

.item-price.left {
  border: 1px solid #C0C0C0;
  height: 5em;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-price.middle {
  border: 1px solid #C0C0C0;
  height: 5em;
  min-width: 13%;
  flex-shrink: 0;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-column {
  display: flex;

  > div {
    border: 1px solid #C0C0C0;
    overflow: auto;
    padding: 6px 10px;
  }
}

.action-empty {
  // border-left: 6px solid #C0C0C0;
  border-left: 4px solid #C0C0C0;
}

.column-action {
  border: 1px solid #C0C0C0;
  // border-left: 6px solid #C0C0C0;
  border-left: 4px solid #C0C0C0;
}

.br-note-item-6 {
  // border-right: 6px solid #C0C0C0;
  border-right: 4px solid #C0C0C0;
}
</style>
