<template>
  <div class="bg-lightgray border-radius-12 overflow-visible p-4 mt-5 c-w-100">
    <p class="mb-2">
      <span class="averta-black label-18">Rincian Perhitungan Harga Penawaran</span>
      <span class="color-grey averta-reguler-italic"> (Opsional)</span>
    </p>

    <div class="table-unit-price border-1-grey-13" v-if="!isLoading">
      <div class="is-relative">
        <!-- HEADER -->
        <div class="sticky-top z-index-20">
          <div class="is-flex color-white" :style="{ minWidth: `${S.WIDTH_TABLE}px`, width: '100%' }">
            <div
              class="sticky-all column-header left bg-dark-grey-5"
              :style="{
                width: S.WIDTH_LAYOUT_LEFT,
                height: `${S.HEIGHT_COLUMN_HEADER}px`,
                borderLeft: 0
              }"
            >
                <!-- minWidth: `${S.WIDTH_STICKY_TITLE_LEFT}px`, -->
              <p class="averta-bold">PEKERJAAN</p>
            </div>
            <div class="is-relative is-flex bg-dark-grey-5" :style="{ width: S.WIDTH_LAYOUT_MIDDLE }">
              <div
                v-for="(item, index) in S.DATA_HEADER"
                :key="index"
                class="column-header"
                :style="{ width: item.width, height: `${S.HEIGHT_COLUMN_HEADER}px` }"
              >
                <p class="averta-bold has-text-centered">{{ item.title }}</p>
              </div>
            </div>
            <!-- `${S.WIDTH_STICKY_TITLE_RIGHT}px` -->
            <div
              class="sticky-all-right column-header right bg-dark-grey-5"
              :style="{
                width: S.WIDTH_LAYOUT_RIGHT,
                height: `${S.HEIGHT_COLUMN_HEADER}px`
              }"
            ></div>
          </div>
        </div>

        <!-- BODY -->
        <!-- :style="{ width: `${S.WIDTH_TABLE}px` }" -->
        <!-- :style="{ width: '75%' }" -->
        <div class="is-relative body-unit-price" :style="{ width: '100%', minWidth: `${S.WIDTH_TABLE}px` }">
          <ItemSection
            :dataProps="materials"
            :readOnly="readOnly"
            title="Material"
            code="materials"
            background="#FCE5E6"
            :zIndex="11"
            @addItem="$event => handleAddItem('add_materials')"
            @editItem="$event => handleEditItem($event, 'edit_materials')"
            @editDescription="$event => handleEditDescription($event, 'materials')"
            @removeItem="$event => handleRemoveItem($event, 'materials')"
          />
          <ItemSection
            :dataProps="work_tools"
            :readOnly="readOnly"
            title="Alat Kerja"
            code="work_tools"
            background="#E1EEFF"
            :zIndex="8"
            @addItem="$event => handleAddItem('add_work_tools')"
            @editItem="$event => handleEditItem($event, 'edit_work_tools')"
            @editDescription="$event => handleEditDescription($event, 'work_tools')"
            @removeItem="$event => handleRemoveItem($event, 'work_tools')"
          />
          <ItemSection
            :dataProps="auxiliary_tools"
            :readOnly="readOnly"
            title="Alat Bantu"
            code="auxiliary_tools"
            background="#E7F8EC"
            :zIndex="5"
            @addItem="$event => handleAddItem('add_auxiliary_tools')"
            @editItem="$event => handleEditItem($event, 'edit_auxiliary_tools')"
            @editDescription="$event => handleEditDescription($event, 'auxiliary_tools')"
            @removeItem="$event => handleRemoveItem($event, 'auxiliary_tools')"
          />
          <ItemSection
            :dataProps="worker_fees"
            :readOnly="readOnly"
            title="Upah Kerja"
            code="worker_fees"
            background="#FFF3E6"
            :zIndex="5"
            @addItem="$event => handleAddItem('add_worker_fees')"
            @editItem="$event => handleEditItem($event, 'edit_worker_fees')"
            @editDescription="$event => handleEditDescription($event, 'worker_fees')"
            @removeItem="$event => handleRemoveItem($event, 'worker_fees')"
          />
        </div>

        <!-- FOOTER -->
        <!-- :style="{ width: `${S.WIDTH_TABLE}px` }" -->
        <!-- :style="{ width: '5%' }" -->
        <!-- <div class="sticky-bottom z-index-5"> -->
        <div :style="{ width: '100%', minWidth: `${S.WIDTH_TABLE}px` }">
          <div
            v-for="(item, index) in dataFooter"
            :key="`parent-${index}`"
            class="is-flex"
            :class="{ 'border-top-5-grey-13': index === 0 }"
          >
            <div
              class="sticky-all column-footer left bg-white"
              :style="{
                width: S.WIDTH_LAYOUT_LEFT,
                height: `${S.HEIGHT_COLUMN_HEADER + (item.code === 'markup' ? 15 : 10)}px`,
                borderLeft: 0
              }"
            >
                <!-- minWidth: `${S.WIDTH_STICKY_TITLE_LEFT}px`, -->
              <p class="averta-bold">{{item.title}}</p>
            </div>
            <div v-if="item.code === 'markup'" class="is-relative is-flex" :style="{width: S.WIDTH_LAYOUT_MIDDLE}">
              <!-- <div
                class="column-footer bg-white border-right-1-grey-13"
                :style="{
                  width: `${S.WIDTH_KETERANGAN}px`,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
                <div
                  class="c-w-100"
                  :class="[{
                    'is-flex is-justify-content-space-between': item.note
                  }]"
                >
                  <div
                    v-if="item.note"
                    class="note-footer"
                    :style="{ height: `${S.HEIGHT_COLUMN_HEADER + 5}px` }"
                  >
                    <p>{{ item.note }}</p>
                  </div>
                  <div
                    class="flex-center c-h-100"
                    :class="[!readOnly ? 'is-clickable' : '', {'c-w-100': !item.note}]"
                    @click="handleEditDescription(item, item.code)"
                  >
                    <p
                      v-if="!item.note"
                      class="mr-2"
                      :class="[!readOnly ? 'averta-bold color-red' : 'averta-reguler-italic color-grey']"
                    >Belum diatur</p>
                    <EditIcon v-if="!readOnly" />
                  </div>
                </div>
              </div> -->
              <!-- <div
                class="column-footer bg-white border-right-1-grey-13"
                :style="{
                  width: `${adjustWidthMarkup}`,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
                <InputPrice
                  format="%"
                  :value="markupPercent"
                  :percent="true"
                  :disabled="readOnly"
                  parentClassName="c-w-100"
                  @change="$event => handleChangeMarkupPercent($event)"
                />
              </div> -->
              <div
                class="column-footer bg-white border-right-1-grey-13"
                :style="{
                  width: S.WIDTH_PERCENT_HARGA,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
              </div>
              <div
                class="column-footer bg-white border-right-1-grey-13"
                :style="{
                  width: S.WIDTH_PERCENT_VOL,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
                <div class="flex-center">
                  <!-- <InputPrice
                    :hideFormat="true"
                    :hideBorder="true"
                    :value="markupPercent"
                    :disabled="readOnly"
                    className="hover-border-1-grey-2 has-text-centered"
                    @change="$event => handleChangeMarkupPercent($event)"
                  /> -->
                  <input
                    type="number"
                    class="averta-bold has-text-centered outline-none c-w-100 input-volume-unit-price hover"
                    :class="{'border': !readOnly}"
                    v-model="markupPercent"
                    :disabled="readOnly"
                    @input="(e) => handleChangeMarkupPercent(e.target.value)"
                  >
                </div>
              </div>
              <div
                class="column-footer bg-white border-right-1-grey-13 flex-center"
                :style="{
                  width: S.WIDTH_PERCENT_SAT,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
                <p class="averta-bold">%</p>
              </div>
              <div
                class="column-footer bg-white border-right-1-grey-13"
                :style="{
                  width: S.WIDTH_PERCENT_MATERIAL,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
                <div v-if="item.markup_item" class="c-w-100">
                  <div class="flex-center-vertical is-justify-content-space-between averta-bold c-w-100">
                    <p class="flex-shrink-0 mr-3">Rp</p>
                    <div class="flex-shrink-0 has-text-right">
                      <!-- <p>{{ formatThousand(item.markup_item) }}</p> -->
                      <p>{{ formatThousand((Number(markupPercent) ? (Number(markupPercent) / 100) : 0) * summary.subtotal_2_item) }}</p>
                    </div>
                  </div>
                  <!-- <div v-if="markupPercent && summary.subtotal_2_item" class="flex-center-vertical is-justify-content-space-between averta-reguler-italic c-w-100">
                    <p class="flex-shrink-0 mr-3">Rp</p>
                    <div class="flex-shrink-0 has-text-right">
                      <p>{{ formatThousand((Number(markupPercent) ? (Number(markupPercent) / 100) : 0) * summary.subtotal_2_item) }}</p>
                    </div>
                  </div> -->
                </div>
              </div>
              <div
                class="column-footer bg-white border-right-1-grey-13"
                :style="{
                  width: S.WIDTH_PERCENT_UPAH,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
                <div v-if="item.markup_service" class="c-w-100">
                  <div class="flex-center-vertical is-justify-content-space-between averta-bold c-w-100">
                    <p class="flex-shrink-0 mr-3">Rp</p>
                    <div class="flex-shrink-0 has-text-right">
                      <!-- <p>{{ formatThousand(item.markup_service) }}</p> -->
                      <p>{{ formatThousand((Number(markupPercent) ? (Number(markupPercent) / 100) : 0) * summary.subtotal_2_service) }}</p>
                    </div>
                  </div>
                  <!-- <div v-if="markupPercent && summary.subtotal_2_service" class="flex-center-vertical is-justify-content-space-between averta-reguler-italic c-w-100">
                    <p class="flex-shrink-0 mr-3">Rp</p>
                    <div class="flex-shrink-0 has-text-right">
                      <p>{{ formatThousand((Number(markupPercent) ? (Number(markupPercent) / 100) : 0) * summary.subtotal_2_service) }}</p>
                    </div>
                  </div> -->
                </div>
              </div>
              <!-- TOTAL PRICE -->
              <!-- <div
                class="column-footer bg-white border-right-1-grey-13"
                :style="{width: S.WIDTH_PERCENT_TOTAL_PRICE}"
              >
                <div v-if="item.markup_item || item.markup_service" class="flex-center-vertical is-justify-content-space-between averta-bold c-w-100">
                  <p class="flex-shrink-0 mr-3">Rp</p>
                  <div class="flex-shrink-0 has-text-right">
                    <p>{{ formatThousand(item.markup_item + item.markup_service) }}</p>
                  </div>
                </div>
              </div> -->
            </div>
            <div v-else class="is-relative is-flex" :style="{ width: S.WIDTH_LAYOUT_MIDDLE }">
              <div
                v-for="(column, indexColumn) in item.columns"
                :key="`column-${indexColumn}`"
                :style="{ width: column.width, height: `${S.HEIGHT_COLUMN_HEADER + 10}px` }"
                :class="[column.disabled ? 'bg-lightgray-8' : 'bg-white']"
                class="column-footer border-right-1-grey-13 overflow-auto"
              >
                <div
                  v-if="column.description"
                  class="c-w-100"
                  :class="[{
                    'is-flex is-justify-content-space-between': column.note
                  }]"
                >
                  <div
                    v-if="column.note"
                    class="note-footer"
                    :style="{ height: `${S.HEIGHT_COLUMN_HEADER}px` }"
                  >
                    <p>{{ column.note }}</p>
                  </div>
                  <div
                    class="flex-center c-h-100"
                    :class="[!readOnly ? 'is-clickable' : '', {'c-w-100': !column.note}]"
                    @click="handleEditDescription(column, column.code)"
                  >
                    <p
                      v-if="!column.note"
                      class="mr-2"
                      :class="[!readOnly ? 'averta-bold color-red' : 'averta-reguler-italic color-grey']"
                    >Belum diatur</p>
                    <EditIcon v-if="!readOnly" />
                  </div>
                </div>
                <div v-else-if="!column.disabled && column.value" class="flex-center-vertical averta-bold is-justify-content-space-between c-w-100">
                  <p class="mr-3">Rp</p>
                  <p>{{ formatThousand(column.value) }}</p>
                </div>
              </div>
            </div>
            <div
              class="sticky-all-right column-footer right bg-lightgray-8"
              :style="{
                width: S.WIDTH_LAYOUT_RIGHT,
                height: `${S.HEIGHT_COLUMN_HEADER + (item.code === 'markup' ? 15 : 10)}px`
              }"
            ></div>
                <!-- minWidth: `${S.WIDTH_STICKY_TITLE_RIGHT}px`, -->
          </div>
          <div class="is-relative is-flex">
            <div
              class="sticky-all column-footer left bg-pink-5"
              :style="{
                width: S.WIDTH_LAYOUT_LEFT,
                height: `${S.HEIGHT_COLUMN_HEADER + 15}px`,
                borderLeft: 0
              }"
            >
                <!-- minWidth: `${S.WIDTH_STICKY_TITLE_LEFT}px`, -->
              <p class="averta-bold">Jumlah</p>
            </div>
            <div
              class="is-relative is-flex bg-pink-5"
              :style="{ width: `${S.WIDTH_TABLE}px` }"
            >
              <div
                class="column-footer bg-pink-5"
                :style="{
                  width: S.WIDTH_PERCENT_HARGA,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
              </div>
              <div
                class="column-footer bg-pink-5"
                :style="{
                  width: S.WIDTH_PERCENT_VOL,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
              </div>
              <div
                class="column-footer bg-pink-5 border-right-1-grey-13"
                :style="{
                  width: S.WIDTH_PERCENT_SAT,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
              </div>
              <div
                class="column-footer bg-pink-5 border-right-1-grey-13"
                :style="{
                  width: S.WIDTH_PERCENT_MATERIAL,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
                <div class="flex-center-vertical is-justify-content-space-between averta-bold c-w-100">
                  <p class="flex-shrink-0 mr-3">Rp</p>
                  <div class="flex-shrink-0 has-text-right">
                    <p>{{ formatThousand(summary.markup_item) }}</p>
                  </div>
                </div>
              </div>
              <div
                class="column-footer bg-pink-5 border-right-1-grey-13"
                :style="{
                  width: S.WIDTH_PERCENT_UPAH,
                  height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
                }"
              >
                <div class="flex-center-vertical is-justify-content-space-between averta-bold c-w-100">
                  <p class="flex-shrink-0 mr-3">Rp</p>
                  <div class="flex-shrink-0 has-text-right">
                    <p>{{ formatThousand(summary.markup_service) }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="sticky-all-right column-footer right bg-pink-5 flex-center averta-black"
              :style="{
                width: S.WIDTH_LAYOUT_RIGHT,
                height: `${S.HEIGHT_COLUMN_HEADER + 15}px`
              }"
            >
              <p class="label-14" style="margin-right: 1px;">Rp</p>
              <p class="label-14">{{ formatThousand(summary.markup_item + summary.markup_service) }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <ModalComponentItem
      :code="modalType"
      :showModalProps="showModal"
      @closeModal="showModal = false"
    />

    <ModalDescription
      :dataProps="descriptionProps"
      :readOnly="readOnly"
      @closeModal="handleCloseDescription"
      @handleSubmit="$event => handleSubmitDescription($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency } from '@/helpers'

export default {
  name: 'CalculationPriceOffering',
  components: {
    ItemSection: () => import('./ItemSection.vue'),
    ModalComponentItem: () => import('./ModalComponentItem.vue'),
    ModalDescription: () => import('./ModalDescription.vue')
    // InputPrice: () => import('@/components/input/InputPrice.vue')
    // EditIcon: () => import('@/components/icons/EditIcon.vue'),
  },
  data () {
    return {
      isLoading: false,
      showModal: false,
      showModalDesc: false,
      modalType: '',
      markupPercent: '',
      descriptionProps: null,

      summary: {
        subtotal_1_item: 0,
        subtotal_1_service: 0,
        subtotal_1_note: '',
        overhead_item: 0,
        overhead_service: 0,
        overhead_note: '',
        subtotal_2_item: 0,
        subtotal_2_service: 0,
        subtotal_2_note: '',
        markup_item: 0,
        markup_service: 0,
        markup_note: '',
        markup_percentage: 0
      },
      materials: [],
      work_tools: [],
      auxiliary_tools: [],
      worker_fees: [],
      dataFooter: []
    }
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      S: 'unitPrice/staticUnitPrice',
      detailUnitPrice: 'unitPrice/getDetailUnitPrice',
      getUnitPrice: 'unitPrice/getUnitPrice'
    }),
    adjustWidthMarkup () {
      return (this.S.WIDTH_VOL + this.S.WIDTH_SAT + this.S.WIDTH_HARGA) + 'px'
    }
  },
  methods: {
    formatThousand (value) {
      return formatCurrency(value, true) === '-' ? 0 : formatCurrency(value, true)
    },
    handleAddItem (type) {
      // this.modalType = type
      // this.showModal = !this.showModal

      const temp = {
        name: '',
        volume: '',
        price: '',
        unit_id: '',
        unit_name: '',
        material_price: '',
        note: ''
      }
      if (type === 'add_materials') {
        this.materials.push({
          ...temp,
          code: JSON.parse(JSON.stringify(type.replace('add', 'edit'))),
          isAdd: true,
          index: this.materials.length,
          selected: []
        })
      } else if (type === 'add_work_tools') {
        this.work_tools.push({
          ...temp,
          code: JSON.parse(JSON.stringify(type.replace('add', 'edit'))),
          isAdd: true,
          index: this.work_tools.length
        })
      } else if (type === 'add_auxiliary_tools') {
        this.auxiliary_tools.push({
          ...temp,
          code: JSON.parse(JSON.stringify(type.replace('add', 'edit'))),
          isAdd: true,
          index: this.auxiliary_tools.length
        })
      } else if (type === 'add_worker_fees') {
        this.worker_fees.push({
          ...temp,
          code: JSON.parse(JSON.stringify(type.replace('add', 'edit'))),
          isAdd: true,
          fee_type: null,
          index: this.worker_fees.length
        })
      }
    },
    handleEditItem (data, type) {
      // this.modalType = type
      // this.showModal = !this.showModal
      this.$store.commit('unitPrice/setFormDetailUnitPrice', data)

      if (type === 'edit_materials') {
        const findIndex = this.materials.findIndex(item => item.index === data.index)
        this.materials.splice(findIndex, 1, data)
      } else if (type === 'edit_work_tools') {
        const findIndex = this.work_tools.findIndex(item => item.index === data.index)
        this.work_tools.splice(findIndex, 1, data)
      } else if (type === 'edit_auxiliary_tools') {
        const findIndex = this.auxiliary_tools.findIndex(item => item.index === data.index)
        this.auxiliary_tools.splice(findIndex, 1, data)
      } else if (type === 'edit_worker_fees') {
        const findIndex = this.worker_fees.findIndex(item => item.index === data.index)
        this.worker_fees.splice(findIndex, 1, data)
      }
    },
    handleEditDescription (data, type) {
      this.descriptionProps = {
        ...data,
        code: type,
        value: data.value || data.note || '',
        showModal: true
      }
    },
    handleCloseDescription () {
      this.descriptionProps = {
        code: '',
        value: '',
        showModal: false
      }
    },
    handleSubmitDescription (data) {
      if (data.code === 'materials') {
        this.materials[data.index].note = data.note
      } else if (data.code === 'work_tools') {
        this.work_tools[data.index].note = data.note
      } else if (data.code === 'auxiliary_tools') {
        this.auxiliary_tools[data.index].note = data.note
      } else if (data.code === 'worker_fees') {
        this.worker_fees[data.index].note = data.note
      } else if (data.code === 'subtotal_1') {
        this.dataFooter[0].columns[0].note = data.note
        this.summary.subtotal_1_note = data.note
      } else if (data.code === 'overhead') {
        this.dataFooter[1].columns[0].note = data.note
        this.summary.overhead_note = data.note
      } else if (data.code === 'subtotal_2') {
        this.dataFooter[2].columns[0].note = data.note
        this.summary.subtotal_2_note = data.note
      } else if (data.code === 'markup') {
        this.dataFooter[3].note = data.note
        this.summary.markup_note = data.note
      }

      this.handleCloseDescription()
      this.storeDetailUnitPrice()
    },
    handleRemoveItem (data, type) {
      if (type === 'materials') {
        this.materials = data
      } else if (type === 'work_tools') {
        this.work_tools = data
      } else if (type === 'auxiliary_tools') {
        this.auxiliary_tools = data
      } else if (type === 'worker_fees') {
        this.worker_fees = data
      }
      this.calculateSummary()
    },
    handleChangeMarkupPercent (value) {
      const temp = value.toString().replace(/\D/g, '')
      this.markupPercent = value > 100 ? 100 : temp

      // this.calculateSummary()

      this.summary.markup_percentage = Number(temp || 0)
      this.summary.markup_item = this.summary.subtotal_2_item + ((Number(this.markupPercent) ? (Number(this.markupPercent) / 100) : 0) * this.summary.subtotal_2_item)
      this.summary.markup_service = this.summary.subtotal_2_service + ((Number(this.markupPercent) ? (Number(this.markupPercent) / 100) : 0) * this.summary.subtotal_2_service)

      this.dataFooter.splice(3, 1, {
        ...this.dataFooter[3],
        markup_item: this.summary.markup_item,
        markup_service: this.summary.markup_service
      })
      this.storeDetailUnitPrice()
    },
    storeDetailUnitPrice () {
      this.$store.commit('unitPrice/setUnitPrice', {
        detail: {
          summary: this.summary,
          materials: this.materials,
          work_tools: this.work_tools,
          auxiliary_tools: this.auxiliary_tools,
          worker_fees: this.worker_fees
        }
      })
    },
    assignSummaryValue () {
      const subTotalI = [
        // {
        //   note: this.summary.subtotal_1_note || '',
        //   code: 'subtotal_1',
        //   description: true,
        //   width: this.S.WIDTH_KETERANGAN + 'px'
        // },
        {
          disabled: true,
          width: this.S.WIDTH_PERCENT_HARGA
          // width: this.S.WIDTH_HARGA + 'px'
        },
        {
          disabled: true,
          width: this.S.WIDTH_PERCENT_VOL
          // width: this.S.WIDTH_VOL + 'px'
        },
        {
          disabled: true,
          width: this.S.WIDTH_PERCENT_SAT
          // width: this.S.WIDTH_SAT + 'px'
        },
        {
          value: this.summary.subtotal_1_item,
          width: this.S.WIDTH_PERCENT_MATERIAL
          // width: this.S.WIDTH_MATERIAL + 'px'
        },
        {
          value: this.summary.subtotal_1_service,
          width: this.S.WIDTH_PERCENT_UPAH
          // width: this.S.WIDTH_UPAH + 'px'
        }
        // {
        //   value: (this.summary.subtotal_1_item || 0) + (this.summary.subtotal_1_service || 0),
        //   width: this.S.WIDTH_PERCENT_TOTAL_PRICE
        //   // width: this.S.WIDTH_TOTAL_PRICE + 'px'
        // }
      ]
      const overHead = [
        // {
        //   note: this.summary.overhead_note || '',
        //   code: 'overhead',
        //   description: true,
        //   width: this.S.WIDTH_KETERANGAN + 'px'
        // },
        {
          disabled: true,
          width: this.S.WIDTH_PERCENT_HARGA
          // width: this.S.WIDTH_HARGA + 'px'
        },
        {
          disabled: true,
          width: this.S.WIDTH_PERCENT_VOL
          // width: this.S.WIDTH_VOL + 'px'
        },
        {
          disabled: true,
          width: this.S.WIDTH_PERCENT_SAT
          // width: this.S.WIDTH_SAT + 'px'
        },
        {
          value: this.summary.overhead_item,
          width: this.S.WIDTH_PERCENT_MATERIAL
          // width: this.S.WIDTH_MATERIAL + 'px'
        },
        {
          value: this.summary.overhead_service,
          width: this.S.WIDTH_PERCENT_UPAH
          // width: this.S.WIDTH_UPAH + 'px'
        }
        // {
        //   value: '',
        //   width: this.S.WIDTH_PERCENT_TOTAL_PRICE
        // // width: this.S.WIDTH_TOTAL_PRICE + 'px'
        // }
      ]

      const subTotalII = [
        // {
        //   note: this.summary.subtotal_2_note || '',
        //   code: 'subtotal_2',
        //   description: true,
        //   width: this.S.WIDTH_KETERANGAN + 'px'
        // },
        {
          disabled: true,
          width: this.S.WIDTH_PERCENT_HARGA
          // width: this.S.WIDTH_HARGA + 'px'
        },
        {
          disabled: true,
          width: this.S.WIDTH_PERCENT_VOL
          // width: this.S.WIDTH_VOL + 'px'
        },
        {
          disabled: true,
          width: this.S.WIDTH_PERCENT_SAT
          // width: this.S.WIDTH_SAT + 'px'
        },
        {
          value: this.summary.subtotal_2_item,
          width: this.S.WIDTH_PERCENT_MATERIAL
          // width: this.S.WIDTH_MATERIAL + 'px'
        },
        {
          value: this.summary.subtotal_2_service,
          width: this.S.WIDTH_PERCENT_UPAH
          // width: this.S.WIDTH_UPAH + 'px'
        }
        // {
        //   value: (this.summary.subtotal_2_item || 0) + (this.summary.subtotal_2_service || 0),
        //   width: this.S.WIDTH_PERCENT_TOTAL_PRICE
        //   // width: this.S.WIDTH_TOTAL_PRICE + 'px'
        // }
      ]
      this.dataFooter = [
        {
          index: 0,
          title: 'SUB TOTAL I',
          columns: subTotalI
        },
        {
          index: 1,
          title: 'Over head / waste 5%',
          columns: overHead
        },
        {
          index: 2,
          title: 'SUB TOTAL II',
          columns: subTotalII
        },
        {
          index: 3,
          title: 'Markup',
          code: 'markup',
          height: this.S.HEIGHT_COLUMN_HEADER + 10,
          note: this.summary.markup_note,
          markup_item: this.summary.markup_item,
          markup_service: this.summary.markup_service,
          markup_percentage: this.summary.markup_percentage || 0
        }
      ]
    },
    calculateSummary () {
      let totalPriceMaterials = 0
      let totalPriceUpah = 0

      this.materials.forEach(item => {
        if (item.material_price) totalPriceMaterials += item.material_price
      })

      this.work_tools.forEach(item => {
        if (item.material_price) totalPriceMaterials += item.material_price
      })

      this.auxiliary_tools.forEach(item => {
        if (item.material_price) totalPriceMaterials += item.material_price
      })

      this.worker_fees.forEach(item => {
        if (item.worker_price) totalPriceUpah += item.worker_price
      })

      this.summary.subtotal_1_item = totalPriceMaterials
      this.summary.subtotal_1_service = totalPriceUpah

      this.summary.overhead_item = this.S.OVER_HEAD_PERCENT * totalPriceMaterials
      this.summary.overhead_service = this.S.OVER_HEAD_PERCENT * totalPriceUpah

      this.summary.subtotal_2_item = totalPriceMaterials + this.summary.overhead_item
      this.summary.subtotal_2_service = totalPriceUpah + this.summary.overhead_service

      this.summary.markup_item = this.summary.subtotal_2_item + ((Number(this.markupPercent) ? (Number(this.markupPercent) / 100) : 0) * this.summary.subtotal_2_item)
      this.summary.markup_service = this.summary.subtotal_2_service + ((Number(this.markupPercent) ? (Number(this.markupPercent) / 100) : 0) * this.summary.subtotal_2_service)

      this.storeDetailUnitPrice()
      this.assignSummaryValue()
    }
  },
  mounted () {},
  created () {
    if (this.$route.query.noteId || (this.getUnitPrice && this.getUnitPrice.detail)) {
      const res = this.getUnitPrice.detail
      this.materials = res.materials || []
      this.work_tools = res.work_tools || []
      this.auxiliary_tools = res.auxiliary_tools || []
      this.worker_fees = res.worker_fees || []
      this.summary = res.summary

      if (!res.summary.markup_percentage) {
        const percentItem = ((res.summary.markup_item - res.summary.subtotal_2_item) / res.summary.subtotal_2_item) * 100
        const percentService = ((res.summary.markup_service - res.summary.subtotal_2_service) / res.summary.subtotal_2_service) * 100

        this.markupPercent = (percentItem + percentService) / 2
        this.handleChangeMarkupPercent(this.markupPercent)
      } else {
        this.markupPercent = res.summary.markup_percentage
        this.handleChangeMarkupPercent(this.markupPercent)
      }
      this.calculateSummary()
    } else {
      this.calculateSummary()
    }
    // for Edit, get API Edit Summary -> assign variable this.summary from response and call assignSummaryValue()
  },
  watch: {
    detailUnitPrice: {
      handler (val) {
        if (val.code === 'add_materials' || val.code === 'edit_materials') {
          if (val.isEdit) {
            this.materials.splice(val.index, 1, val)
          } else {
            this.materials.push({ ...val, index: this.materials.length })
          }
        } else if (val.code === 'add_work_tools' || val.code === 'edit_work_tools') {
          if (val.isEdit) {
            this.work_tools.splice(val.index, 1, val)
          } else {
            this.work_tools.push({ ...val, index: this.work_tools.length })
          }
        } else if (val.code === 'add_auxiliary_tools' || val.code === 'edit_auxiliary_tools') {
          if (val.isEdit) {
            this.auxiliary_tools.splice(val.index, 1, val)
          } else {
            this.auxiliary_tools.push({ ...val, index: this.auxiliary_tools.length })
          }
        } else if (val.code === 'add_worker_fees' || val.code === 'edit_worker_fees') {
          if (val.isEdit) {
            this.worker_fees.splice(val.index, 1, val)
          } else {
            this.worker_fees.push({ ...val, index: this.worker_fees.length })
          }
        }

        this.calculateSummary()
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.column-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0 10px;
  border-left: 1px solid #fff;

  &.left {
    border-right: 6px solid #fff;
    z-index: 4;
  }

  &.right {
    border-left: 4px solid #fff;
    z-index: 4;
  }
}

.column-footer {
  flex-shrink: 0;
  padding: 0 10px;
  border-bottom: 1px solid #C0C0C0;
  border-top: 1px solid #C0C0C0;
  display: flex;
  align-items: center;

  &.left {
    border-right: 6px solid #C0C0C0;
    z-index: 4;
  }

  &.right {
    border-left: 4px solid #C0C0C0;
    z-index: 4;
  }
}

.body-unit-price {
  min-height: 5em;
}

.table-unit-price {
  position: relative;
  width: 100%;
  overflow: auto;
  border-radius: 10px;
  max-height: 77vh;
}

.note-footer {
  display: inline-block;
  width: 90%;
  overflow: auto;
}

.input-volume-unit-price {
  border: none;
  padding: 8px 0;
}

.input-volume-unit-price:disabled{
  background: white;
}

.input-volume-unit-price.hover:hover, .input-volume-unit-price.hover:focus, .input-volume-unit-price.border {
  border: 1px solid $color-grey-2;
}
</style>
