<template>
  <svg :width="size" height="auto" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 5.5L0 0.5H10L5 5.5Z" :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowDownDropdown',
  props: {
    size: {
      type: String,
      default: '15'
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>
