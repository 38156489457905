<template>
  <div>
    <p class="averta-black label-18">
      Masukan Nama Item Pekerjaan <span class="color-red">*</span>
    </p>
    <p class="color-grey mb-3">Pastikan nama yang dimasukan unik dan jelas</p>
    <textarea
      rows="3"
      class="mt-1 textarea style-textarea-wrapper label-14"
      v-model="noteItemWorking"
      @input="handleChange"
      placeholder="Isi disini"
    ></textarea>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FormItemWorking',
  data () {
    return {
      noteItemWorking: ''
    }
  },
  computed: {
    ...mapGetters({
      reset: 'unitPrice/getResetUnitPrice',
      dataAPIUnitPrice: 'unitPrice/getDataAPIUnitPrice'
    })
  },
  methods: {
    handleChange (e) {
      const value = e.target.value
      this.$store.commit('unitPrice/setUnitPrice', { note_item_working: value || 'empty' })
    }
  },
  watch: {
    reset (val) {
      this.noteItemWorking = ''
    }
  },
  mounted () {
    if (this.dataAPIUnitPrice) {
      this.noteItemWorking = this.dataAPIUnitPrice.note_item_working
    }
  }
}
</script>

<style scoped>
</style>
