<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-modal v-model="isHandleOpenModalsComments" :can-cancel="false">
    <div class="modal-content">
      <div class="modal-header">
        <div
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
        >
          <p class="averta-bold label-20 color-white">Komentar Rating</p>
        </div>
        <div
          @click="closeModals()"
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center is-clickable"
        >
          <XMarkIcon />
        </div>
      </div>
      <div class="modal-body" ref="modalBody">
        <!-- Content Card Comments -->
        <div
          class="card-comments"
          ref="cardComments"
          v-if="selectedWorker && selectedWorker.comments && selectedWorker.comments.length > 0"
        >
          <div
            class="buble-chat-comment gap-3"
            v-for="comment in selectedWorker && selectedWorker.comments"
            :key="comment.id"
          >
            <div class="avatar-circle-users">
              <img :src="comment.created_by_image" alt="" v-if="comment.created_by_image" />
              <img
                v-else
                class="is-rounded mr-1 image-cover"
                src="@/assets/img/user-default.svg"
                alt="kanggo.id"
              />
            </div>
            <div
              class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-content-flex-start gap-1"
            >
              <div
                class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3"
              >
                <p class="averta-bold is-font-14 black-color">
                  {{
                    comment.created_by_name === null
                      ? 'Anda'
                      : comment.created_by_name === getUsers.name
                      ? 'Anda'
                      : comment.created_by_name || 'Anda'
                  }}
                </p>
                <p class="averta-reguler-italic is-font-14 gray-color">
                  {{ comment.comment_from === 1 ? 'Site Manager' : 'Admin' }}
                </p>
                <p class="averta-reguler is-font-14 gray-color">
                  {{ comment.created_at }}
                </p>
              </div>
              <div
                style="white-space: pre-line; display: block; word-break: break-all !important"
                class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start gap-2"
              >
                <p class="averta-reguler label-14 black-color" style="line-height: 1.6">
                  {{ comment.comment }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 c-h-100 card-comments"
        >
          <p>Belum Ada Riwayat Komentar</p>
        </div>
        <!-- Content Card Comments -->
      </div>
      <div class="modal-footer z-index-9999 gap-3">
        <div class="avatar-circle-users">
          <img v-if="getUsers.profile_picture" :src="getUsers.profile_picture" alt="" />
          <img
            v-else
            class="is-rounded mr-1 image-cover"
            src="@/assets/img/user-default.svg"
            alt="kanggo.id"
          />
        </div>
        <div
          class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100"
        >
          <input
            class="input-comments-mitra"
            v-model="comment"
            placeholder="Tulis komentar disini"
            :disabled="isPermission"
            @keyup.enter="submitCommentRating"
            @input="maintainFocus"
            ref="commentInput"
          />
        </div>
        <ButtonApp :height="'45px'" :isDisabled="!comment.trim() || selectedWorker.has_been_commented === true" @click="submitCommentRating()">
          <p class="averta-reguler is-font-16">Kirim</p>
        </ButtonApp>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { XMarkIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
import moment from 'moment'

export default {
  name: 'modalsComment',
  components: {
    ButtonApp,
    XMarkIcon
  },
  props: {
    isHandleOpenModalsComments: {
      type: Boolean,
      default: false
    },
    isRatingOverThree: {
      type: Boolean,
      default: false
    },
    selectedWorker: {
      type: Object,
      default: null
    },
    getProjectId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      getUsers: 'auth/user'
    }),
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isPermission () {
      return (
        this.selectedWorker &&
        this.selectedWorker.comments &&
        this.selectedWorker.comments.some((comment) => comment.comment_from === 2)
      )
    }
  },
  data () {
    return {
      selectedOptions: [],
      comment: '',
      isLoading: false,
      isFullPage: true,
      moment: moment,
      profile_picture: null
    }
  },
  methods: {
    scrollToBottom () {
      this.$nextTick(() => {
        const cardComments = this.$refs.cardComments
        if (cardComments) {
          cardComments.scrollTop = cardComments.scrollHeight
        }
      })
    },
    playAudio () {
      const audio = new Audio(require('@/assets/audio/notification_B2B_message.mp3'))
      audio.play()
    },
    closeModals () {
      this.$emit('closeModalsComments')
      window.localStorage.removeItem('newCommentData')
    },
    loadCommentFromStorageDataComment () {
      const savedCommentData = localStorage.getItem('newCommentData')
      if (savedCommentData) {
        this.comment = savedCommentData
      }
    },
    async submitCommentRating () {
      if (!this.comment.trim()) return

      const newCommentData = {
        id: Date.now(),
        created_by_name:
          this.selectedWorker.created_by_name === this.getUsers.name
            ? 'Anda'
            : this.selectedWorker.created_by_name,
        created_by_image: this.getUsers.profile_picture,
        comment: this.comment,
        comment_from: 1,
        created_at: `${moment().format('DD/MM/YYYY')} - ${moment().format('HH:mm')}`
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.selectedWorker.comments = [...(this.selectedWorker.comments || []), newCommentData]

      const commentText = this.comment
      this.comment = ''

      try {
        const payload = {
          project_id: this.getProjectId,
          worker_id: this.selectedWorker.id || this.selectedWorker.worker_id,
          work_item_id:
            this.selectedWorker.workItemId || this.selectedWorker.item_component_id,
          skill_id: this.selectedWorker.skillId || this.selectedWorker.skill_id,
          date: this.selectedWorker.date,
          comment: commentText
        }

        const res = await this.$store.dispatch('rating/postComment', { url: this.url, payload })
        if (res.data.code === 1) {
          this.playAudio()
          this.$buefy.toast.open({
            message: 'Komentar berhasil dikirim'
          })
        } else {
          console.error(
            'Gagal mengirim komentar ke backend:',
            res.data.message || 'Kesalahan tidak diketahui.'
          )
        }
      } catch (error) {
        console.error('Kesalahan saat mengirim komentar:', error)
        this.$buefy.toast.open({
          message: 'Gagal Mengirim Komentar'
        })
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Terjadi kesalahan saat mengirim komentar. Harap coba lagi.'
        })
      } finally {
        this.isLoading = false
        localStorage.removeItem('newCommentData')
        this.scrollToBottom()
      }
      this.focusInput()
    },
    focusInput () {
      this.$nextTick(() => {
        if (this.$refs.commentInput) {
          this.$refs.commentInput.focus()
        }
      })
    },
    maintainFocus () {
      this.focusInput()
    }
  },
  mounted () {
    this.focusInput()
  },
  updated () {
    this.focusInput()
  },
  created () {
    this.loadCommentFromStorageDataComment()
  },
  watch: {
    comment: {
      handler (newValue) {
        if (newValue.trim()) {
          localStorage.setItem('newCommentData', newValue)
        }
      },
      deep: true
    },
    'selectedWorker.comments': {
      handler () {
        this.scrollToBottom()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  min-width: 80%;
  max-width: 80%;
  height: auto;
  min-height: auto;
  max-height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
}
.modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 2rem;
  background: #b40007;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.modal-body {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  background: linear-gradient(180deg, #defff9 0%, #ede4ff 100%);
}
.card-comments {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 500px;
  min-height: 500px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding: 1rem;
  background: #ffffff;
  border-radius: 20px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.buble-chat-comment {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding: 20px 0;
  border-bottom: 1px dotted #e1e1e1;
}
.buble-chat-comment:last-child {
  border-bottom: none;
}
.buble-chat-comment:last-child {
  padding-bottom: 0;
}
.buble-chat-comment:first-child {
  padding-top: 0;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #ffffff;
}
.avatar-circle-users {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 45px;
  min-width: 45px;
  max-width: 45px;
  height: 45px;
  min-height: 45px;
  max-height: 45px;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-circle-users > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.button-comments-mitra {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 35px;
  max-height: 100%;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(1em - 1px);
  padding-right: calc(1em - 1px);
  padding-top: calc(0.5em - 1px);
  border-radius: 100px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  gap: 0.5rem;
  border: 1px solid #e1e1e1;
  background: #ffffff;
}
.input-comments-mitra {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 10px 10px 10px 25px;
  border-radius: 100px;
  text-align: left;
  white-space: nowrap;
  gap: 0.5rem;
  border: 1px solid #e1e1e1;
  background: #f5f5f5;
  resize: none;
}
@media (max-width: 1600px) {
  .card-comments {
    height: 400px !important;
    min-height: 400px !important;
    max-height: 400px !important;
  }
}
</style>
