<template>
  <div class="container-wrapper min-h-90vh">
    <div v-if="isLoading" class="loading-wrapper">
      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
    <div v-else>
      <div
        class="indicator-wrapper"
        :style="{
          top: navbarPosition,
          padding: isPriceActual ? '0px' : '10px 30px'
        }"
      >
        <div v-if="!isPriceActual" class="flex-center-vertical">
          <InfoIcon color="blue" width="20" height="20" />
          <p class="color-blue-1 ml-2 averta-bold">
            Detail penawaran ini sama dengan format penawaran yang diterima oleh client
          </p>
        </div>
        <CardPriceTypeOffering
          v-else
          styleProps="position: relative; padding: 15px 30px !important; margin: 0 !important;"
        />
      </div>

      <div class="button-wrapper" :style="`${isPriceActual ? 'margin-top: 4em !important;' : ''}`">
        <ButtonApp
          width="195px"
          height="46px"
          :is-bold="true"
          :hover-enabled="false"
          @click="downloadPDF"
        >
          <b-icon icon="download" size="is-small" />
          {{ isLoadingSpinner ? "Mengunduh..." : "Unduh Penawaran" }}
        </ButtonApp>
      </div>
      <div
        class="invoice border-1 my-5"
        ref="document"
        v-for="(dataInvoice, indexInvoice) in paginatedList"
        :key="`dataInvoice-${indexInvoice}`"
      >
        <div :class="`padding-page-pdf invoice-${indexInvoice + 1}`">
          <div class="height-body-pdf">
            <!-- NEW HEADER -->
            <HeaderSection
              type="offering"
              :index="indexInvoice"
              :recipient="isPriceActual ? dataOffering.branch_name : dataOffering.company_name"
              :address="dataOffering.store_address"
              :storeName="dataOffering.store_name"
              :unitName="dataOffering.unit_name"
              :externalId="dataOffering.client_external_id"
              :surveyId="dataOffering.survey_id"
              :duration="dataOffering.duration"
              :createdDate="dataOffering.offering_created_date || dataOffering.created_date"
              :offeringNumber="dataOffering.offering_number"
              :isSMRC="isSMRC"
            />

            <div
              class="card pdf-table-body mb-3"
              v-if="dataInvoice.table || dataInvoice.section.totalSection"
            >
              <!-- TABLE INVOICE -->
              <div v-if="dataInvoice.table">
                <div class="columns mb-0 averta-bold">
                  <div class="column is-5 px-0 is-flex pl-5">
                    <div class="number-pdf">
                      <p>No.</p>
                    </div>
                    <p>Detail Pekerjaan</p>
                  </div>
                  <div class="column is-2 has-text-right">
                    <p class="mr-4">Volume Pekerjaan</p>
                  </div>
                  <div class="column is-1 px-0">
                    <p>Satuan</p>
                  </div>
                  <div class="column is-2 has-text-centered">
                    <p>Harga Satuan</p>
                  </div>
                  <div class="column is-2 has-text-right">
                    <p>Total Harga</p>
                  </div>
                </div>
                <div class="divider-invoice mb-5"></div>
                <div
                  v-for="(itemWorking, indexWorking) in dataInvoice.table"
                  :key="`itemWorking-${indexWorking}`"
                >
                  <div v-if="!itemWorking.hide" class="columns mb-1">
                    <div class="column is-10">
                      <p class="averta-black label-14">
                        {{ itemWorking.area_name }}
                      </p>
                    </div>
                    <div
                      class="column is-2 is-flex is-justify-content-space-between pl-1"
                    >
                      <p class="averta-black label-14">Rp</p>
                      <p class="averta-black label-14">
                        {{ formatThousand(itemWorking.total_price) }}
                      </p>
                    </div>
                  </div>

                  <div
                    v-for="(itemSubArea, indexSubArea) in itemWorking.components"
                    :key="`subarea-${indexSubArea}`"
                  >
                    <p
                      v-if="!itemSubArea.hide &&  itemSubArea.item_components.length > 0 "
                      class="averta-bold ml-3 pb-2"
                    >
                      {{ itemSubArea.component_name }}
                    </p>
                    <div
                      v-for="(itemListSub,
                      indexListSub) in itemSubArea.item_components"
                      :key="`list-sub-${indexListSub}`"
                      class="columns pb-2 m-0"
                    >
                      <div class="column py-0 pl-3 pr-0 is-5 is-flex">
                        <div class="number-pdf">
                          <p>
                            {{ numberList(itemListSub.no) }}
                          </p>
                        </div>
                        <p>
                          {{ itemListSub.note_item_working }}
                        </p>
                      </div>
                      <div class="column py-0 is-2 has-text-right">
                        <p class="mr-4">{{ itemListSub.volume }}</p>
                      </div>
                      <div class="column py-0 is-1">
                        <p class="has-text-centered">{{ itemListSub.unit_name }}</p>
                      </div>
                      <div
                        class="column py-0 is-2 is-flex is-justify-content-space-between"
                      >
                        <p>Rp</p>
                        <p>{{ formatThousand(itemListSub.unit_price) }}</p>
                      </div>
                      <div
                        class="column py-0 pr-0 is-2 is-flex is-justify-content-space-between"
                      >
                        <p>Rp</p>
                        <p>{{ formatThousand(itemListSub.total_price) }}</p>
                      </div>
                    </div>
                  </div>

                  <div v-if="dataInvoice.table.length !== indexWorking + 1 || dataInvoice.section.totalSection" class="divider-invoice my-4"></div>
                </div>
              </div>

              <!-- TOTAL SUMMARY SECTION -->
              <div v-if="dataInvoice.section.totalSection">
                <div v-if="dataOffering.client_type_code === 'company' || dataOffering.client_type_code === null || !dataOffering.client_type_code">
                  <div class="is-flex is-justify-content-end my-3 pl-3">
                    <p class="mr-5 averta-bold label-14" style="width: 183px;">{{ titleDPP }}</p>
                    <div class="is-flex is-justify-content-space-between averta-bold" style="width: 140px;">
                      <p>Rp</p>
                      <p>{{ formatThousand(dataOffering.prices.subtotal) }}</p>
                    </div>
                  </div>
                  <div class="is-flex is-justify-content-end mb-3 pl-3" v-if="dataOffering.prices.discount">
                    <p class="mr-5 averta-bold label-14" style="width: 183px;">Discount</p>
                    <div class="is-flex is-justify-content-space-between averta-bold" style="width: 140px;">
                      <p>Rp</p>
                      <p>({{ formatThousand(dataOffering.prices.discount) }})</p>
                    </div>
                  </div>
                  <div class="is-flex is-justify-content-end mb-3 pl-3">
                    <p class="mr-5 averta-bold label-14" style="width: 183px;">
                      <!-- PPN {{ dataOffering.prices.ppn_percent }}% -->
                      PPN
                    </p>
                    <div class="is-flex is-justify-content-space-between averta-bold" style="width: 140px;">
                      <p>Rp</p>
                      <p>{{ formatThousand(dataOffering.prices.ppn) }}</p>
                    </div>
                  </div>
                  <div class="is-flex is-justify-content-end mb-3 pl-3"
                   v-if="dataOffering.prices.pph && dataOffering.prices.pph_percent">
                    <p class="mr-5 averta-bold label-14" style="width: 183px;">
                      PPh Final 4 Ayat 2 ({{ dataOffering.prices.pph_percent }}%)
                    </p>
                    <div class="is-flex is-justify-content-space-between averta-bold" style="width: 140px;">
                      <p>Rp</p>
                      <p>({{ formatThousand(dataOffering.prices.pph) }})</p>
                    </div>
                  </div>
                  <div class="is-flex is-justify-content-end wrapper-grand-total mb-3 pl-3 py-2">
                    <p class="mr-5 averta-bold label-18" style="width: 183px;">Grand Total</p>
                    <div class="is-flex is-justify-content-space-between" style="width: 140px;">
                      <p class="averta-black text-grand-total label-18">Rp</p>
                      <p class="averta-black text-grand-total label-18">
                        {{ formatThousand(dataOffering.prices.total) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="dataOffering.client_type_code === 'personal'">
                  <div v-if="dataOffering.prices.discount">
                    <div class="is-flex is-justify-content-end my-3 pl-3">
                      <p class="mr-5 averta-bold label-14" style="width: 183px;">Total</p>
                      <div class="is-flex is-justify-content-space-between averta-bold" style="width: 140px;">
                        <p>Rp</p>
                        <p>{{ formatThousand(dataOffering.prices.subtotal) }}</p>
                      </div>
                    </div>
                    <div class="is-flex is-justify-content-end mb-3 pl-3">
                      <p class="mr-5 averta-bold label-14" style="width: 183px;">Discount</p>
                      <div class="is-flex is-justify-content-space-between averta-bold" style="width: 140px;">
                        <p>Rp</p>
                        <p>({{ formatThousand(dataOffering.prices.discount) }})</p>
                      </div>
                    </div>
                  </div>
                  <div class="is-flex is-justify-content-end wrapper-grand-total mb-3 pl-3 py-2">
                    <p class="mr-5 averta-bold label-18" style="width: 183px;">Grand Total</p>
                    <div class="is-flex is-justify-content-space-between" style="width: 140px;">
                      <p class="averta-black text-grand-total label-18">Rp</p>
                      <p class="averta-black text-grand-total label-18">
                        {{ formatThousand(dataOffering.prices.total) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="is-flex is-justify-content-end">
                  <p>(<span class="averta-bold">Terbilang:</span> <i>{{ formatSpellOut(dataOffering.prices && dataOffering.prices.total) }}</i>)</p>
                </div>
              </div>
            </div>

            <!-- INSTRUKSI PEMBAYARAN -->
            <div>
              <div v-if="dataInvoice.section.instruksi">
                <p class="averta-bold label-14 mb-3">Dengan kententuan sebagai berikut :</p>
                <ol class="label-14 ml-5">
                  <div v-for="terms, indexTerms in dataOffering.terms_offering" :key="indexTerms" >
                    <span v-if="indexTerms === 2 && dataOffering.client_type_code === 'personal'"></span>
                    <li v-else class="mb-2">
                      {{ terms.title }}
                      <div class="rekening-wrapper mt-2" v-if="terms.company_account">
                        <p class="averta-bold label-16 mb-1">
                          {{ terms.company_account.company_name }}
                        </p>
                        <p>{{ terms.company_account.bank }}</p>
                        <p>No Rekening: <span class="averta-bold">{{ terms.company_account.account_number }}</span></p>
                      </div>
                    </li>
                  </div>
                </ol>
                <p>Demikian surat penawaran ini kami sampaikan, mohon dapat diterima. Untuk informasi lebih lanjut dapat menghubungi saya, Nadia 0877-6277-1993. Terima kasih atas perhatian dan kerjasamanya.</p>
              </div>
              <div v-if="dataInvoice.section.ttd" class="pt-6 ml-5 is-relative">
                <p class="averta fs-14">Hormat kami,</p>
                <div class="height-signature flex-center-vertical">
                  <div class="signature-img flex-center-vertical" v-if="dataOffering.sales_profile.signature">
                    <img :src="dataOffering.sales_profile.signature" alt="" crossorigin="anonymous">
                  </div>
                </div>
                <p class="averta fs-14">
                  <u class="averta-bold">{{ dataOffering.sales_profile.name }}</u>
                  <br />
                  <span>({{ dataOffering.sales_profile.division }})</span>
                  <br />
                  <span>PT. Tenaga Kanggo Indonesia</span>
                  <br />
                  <span>T: {{ dataOffering.sales_profile.phone_number }}</span>
                  <br />
                  <span>E: {{ dataOffering.sales_profile.email }}</span>
                </p>
              </div>
            </div>
          </div>

          <!-- PAGE NUMBER -->
          <FooterSection :pagePosition="`${ indexInvoice + 1 }/${ paginatedList.length }`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { mapGetters } from 'vuex'
import { alertErrorMessage, formatCurrency, Terbilang, isSMRC } from '@/helpers'

import {
  SpinnerIcon,
  InfoIcon
} from '@/components/icons'
import FooterSection from './components/FooterSection.vue'
import HeaderSection from './components/HeaderSection.vue'
import CardPriceTypeOffering from '@/components/card/CardPriceTypeOffering.vue'

export default {
  name: 'PDFNewDesign',
  components: {
    SpinnerIcon,
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    InfoIcon,
    HeaderSection,
    FooterSection,
    CardPriceTypeOffering
  },
  data () {
    return {
      isLoading: false,
      isLoadingSpinner: false,
      dataOffering: null,
      dataList: null,
      url: process.env.VUE_APP_SHELL_URL_API,
      navbarPosition: null,
      typeClient: 'perusahaan'
    }
  },
  computed: {
    ...mapGetters({
      heightNavbar: 'dashboard/getNavbarHeight'
    }),
    paginatedList () {
      // 640 didapat ketika height recipient 99, tools yang digunakan Extension Chrome "Page Ruler"
      const INIT_RECIPIENT = 99
      const INIT_START = 655
      const INIT_MIDLE = 780
      const WIDTH_RECIPIENT = 736
      const WIDTH_TEXT_RECIPIENT = 336
      const WIDTH_TEXT_ADDRESS = 356
      const WIDTH_AREA = 588
      const WIDTH_SUB = 698
      const WIDTH_LIST = 249
      const HEIGHT_TOTAL_PRICE = 176
      const HEIGHT_TOTAL_PRICE_DISCOUNT = 209
      const HEIGHT_INSTRUCTION = 361
      const HEIGHT_TTD = 284

      const pages = []
      let currentPage = []
      let currentHeight = 0
      let isReset = false

      // RECIPIENT SECTION
      const divRecipient = document.createElement('div')
      const pRecipient = document.createElement('p')
      const pAddress = document.createElement('p')
      pRecipient.textContent = this.isPriceActual ? this.dataOffering.branch_name : this.dataOffering.company_name
      pRecipient.classList = 'averta-bold mt-1'
      pRecipient.style.width = `${WIDTH_TEXT_RECIPIENT}px`
      pAddress.classList = 'averta-regular'
      pAddress.style.width = `${WIDTH_TEXT_ADDRESS}px`
      pAddress.textContent = this.dataOffering.store_address
      divRecipient.className = 'p-4 is-flex is-justify-content-space-between border-radius-20 overflow-hidden'
      divRecipient.style.width = `${WIDTH_RECIPIENT}px`
      divRecipient.appendChild(pRecipient)
      divRecipient.appendChild(pAddress)
      document.body.appendChild(divRecipient)
      const heightRecipient = divRecipient.clientHeight + 25
      document.body.removeChild(divRecipient)

      // PAGE BODY HEIGHT
      let start = INIT_START
      const midle = INIT_MIDLE

      if (heightRecipient < INIT_RECIPIENT) {
        start += (INIT_RECIPIENT - heightRecipient)
      } else {
        start -= (heightRecipient - INIT_RECIPIENT)
      }

      let countNumber = 0
      if (this.dataList && this.dataList.length > 0) {
        this.dataList.forEach(itemArea => {
          currentPage.push({
            ...itemArea,
            components: []
          })

          const widthArea = `${WIDTH_AREA}px`
          const widthSub = `${WIDTH_SUB}px`
          const widthList = `${WIDTH_LIST}px`

          // CLONE AREA
          const divAreaColumns = document.createElement('div')
          const divArea = document.createElement('div')
          const pArea = document.createElement('p')

          pArea.className = 'averta-black label-14'
          pArea.textContent = itemArea.area_name

          divAreaColumns.className = 'columns mb-1'
          divArea.className = 'column'
          divArea.appendChild(pArea)
          divAreaColumns.style.width = widthArea
          divAreaColumns.appendChild(divArea)

          document.body.appendChild(divAreaColumns)
          currentHeight += divAreaColumns.clientHeight + 4 + 16 // 4 = margin | 16 divider-invoice
          document.body.removeChild(divAreaColumns)

          itemArea.components.forEach((itemSub, indexSub) => {
            const idxArea = currentPage.findIndex(
              item => item.area_id === itemArea.area_id
            )

            currentPage[idxArea].components.push({
              ...itemSub,
              item_components: []
            })

            const divSub = document.createElement('div')
            const pSub = document.createElement('p')

            // CLONE SUB AREA
            pSub.className = 'averta-bold pb-2'
            pSub.textContent = itemSub.component_name
            divSub.className = 'ml-5'
            divSub.style.width = widthSub
            divSub.appendChild(pSub)

            document.body.appendChild(divSub)
            currentHeight += divSub.clientHeight
            document.body.removeChild(divSub)

            itemSub.item_components.forEach((itemList, indexList) => {
              const limitPageHeight = pages.length === 0 ? start : midle

              countNumber++
              itemList.no = countNumber

              const idxArea = currentPage.findIndex(
                item => item.area_id === itemArea.area_id
              )
              const idxSub = currentPage[idxArea].components.findIndex(
                item => item.component_id === itemSub.component_id
              )

              // CLONE LIST ITEMS
              const divList = document.createElement('div')
              const pList = document.createElement('p')

              divList.className = 'columns pb-2 m-0'
              divList.style.width = widthList
              pList.className = 'averta-regular'
              pList.textContent = itemList.note_item_working

              divList.appendChild(pList)
              document.body.appendChild(divList)

              if (currentHeight + (divList.clientHeight) > limitPageHeight) {
                // Remove unused area, if area exceed the limit, dont push into currentPage / remove it from currentPage
                const areaComponents = currentPage[idxArea].components

                if (areaComponents.length === 1 && areaComponents[idxSub].item_components.length === 0) {
                  currentPage = currentPage.filter(item => item.area_id !== currentPage[idxArea].area_id)
                }

                pages.push({
                  table: currentPage,
                  section: {
                    totalSection: false,
                    instruksi: false,
                    ttd: false
                  }
                })
                currentHeight = 0
                isReset = true
              }

              if (isReset) {
                let hideArea = false
                let hideSub = false

                if (indexList > 0) {
                  hideArea = true
                  hideSub = true
                } else if (indexSub > 0 && indexList === 0) {
                  hideArea = true
                }

                currentPage = [
                  {
                    hide: hideArea,
                    ...itemArea,
                    components: [
                      {
                        hide: hideSub,
                        ...itemSub,
                        item_components: [itemList]
                      }
                    ]
                  }
                ]
                isReset = false
              } else {
                currentPage[idxArea].components[idxSub].item_components.push(
                  itemList
                )
              }

              currentHeight += divList.clientHeight
              document.body.removeChild(divList)
            })
          })
        })

        // LAST DATA PAGE
        if (currentPage.length) {
          pages.push({
            table: currentPage,
            section: {
              totalSection: false,
              instruksi: false,
              ttd: false
            }
          })
        }

        let totalHeight = 0
        let isBreak = false

        // HEIGHT BOTTOM SECTION
        const totalSection = this.dataOffering.prices.discount ? HEIGHT_TOTAL_PRICE_DISCOUNT : HEIGHT_TOTAL_PRICE
        const instruksi = HEIGHT_INSTRUCTION + 24
        const ttd = HEIGHT_TTD
        const limitBottomHeight = pages.length === 1 ? start : midle

        // IS EXCEED TOTAL PRICE
        const countTotal = currentHeight + totalSection
        if (countTotal < limitBottomHeight) {
          totalHeight = countTotal
          pages[pages.length - 1].section.totalSection = true
        } else {
          isBreak = true
          pages.push({
            section: {
              totalSection: true,
              instruksi: true,
              ttd: true
            }
          })
        }

        // IS EXCEED INSTRUKSI
        const countInstruksi = totalHeight + instruksi
        if (!isBreak && countInstruksi < limitBottomHeight) {
          totalHeight = countInstruksi

          pages[pages.length - 1].section.instruksi = true
        } else if (!isBreak) {
          isBreak = true
          pages.push({
            section: {
              totalSection: false,
              instruksi: true,
              ttd: true
            }
          })
        }

        // IS EXCEED TTD
        const countTTD = totalHeight + ttd
        if (!isBreak && countTTD < limitBottomHeight) {
          totalHeight = countTTD

          pages[pages.length - 1].section.ttd = true
        } else if (!isBreak) {
          isBreak = true
          pages.push({
            section: {
              totalSection: false,
              instruksi: false,
              ttd: true
            }
          })
        }

        // console.log(pages)
        return pages
      }

      return []
    },
    isPriceActual () {
      return this.$route.query.actual || false
    },
    isSMRC () {
      return isSMRC(this.dataOffering.vendor_code || null)
    },
    titleDPP () {
      if (this.dataOffering.offering_created_date) {
        const createdDate = moment(this.dataOffering.invoice_created_date || this.dataOffering.offering_created_date)
        const thresholdDate = moment('2025-01-01')

        if (createdDate.isSameOrAfter(thresholdDate)) {
          return 'Harga Jual'
        } else {
          return 'DPP'
        }
      } else {
        return 'DPP'
      }
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x, true)
    },
    convertDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    getDataDetailClient () {
      this.isLoading = true
      const payload = {
        url: this.url,
        offering_id: this.$route.params.offeringId
      }

      if (this.$route.query.preview === 'true') {
        payload.preview = true
      }

      this.$store
        .dispatch('project/getDataDetailClient', payload)
        .then(response => {
          const res = response.data.data

          this.dataList = res.item_components
          this.dataOffering = res
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    async downloadPDF () {
      if (this.isLoadingSpinner) return
      this.isLoadingSpinner = true
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', 'a4') // Create A4-sized PDF

      for (let index = 0; index < this.paginatedList.length; index++) {
        if (index > 0) {
          pdf.addPage() // Add a new page for each item after the first one
        }
        const selector = `.invoice-${index + 1}`

        // Capture content and add it to the PDF
        await this.captureContent(selector, pdf)
      }

      // Save the PDF
      const filename = `${this.dataOffering.offering_number || 'penawaran_' + moment().format('DD-MM-YYYY')}.pdf`
      pdf.save(filename)
      this.isLoadingSpinner = false
    },
    async captureContent (selector, pdf) {
      const element = document.querySelector(selector)
      return html2canvas(element, { scale: 2, allowTaint: true, useCORS: true })
        .then(canvas => {
          const imgWidth = 208
          const imgHeight = (canvas.height * imgWidth) / canvas.width

          const imageData = canvas.toDataURL('image/jpeg')
          pdf.addImage(
            imageData,
            'JPEG',
            1,
            0,
            imgWidth,
            imgHeight,
            '',
            'FAST'
          )
        })
        .catch(error => console.error('Error capturing content:', error))
    },
    adjustStickyPosition () {
      setTimeout(() => {
        if (this.heightNavbar) {
          this.navbarPosition = this.heightNavbar + 'px'
        }
      }, 600)
    },
    numberList (value) {
      return formatCurrency(value, true) + '.'
    },
    formatSpellOut (value) {
      return Terbilang(value)
    }
  },
  mounted () {
    this.adjustStickyPosition()
  },
  created () {
    this.getDataDetailClient()
  },
  watch: {
    heightNavbar (val) {
      if (val) {
        this.adjustStickyPosition()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';
.container-wrapper {
  margin-top: 5vh;
  width: 100%;
}

.button-wrapper {
  width: 210mm;
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-top: 2em;
}

.invoice {
  position: relative;
  width: 210mm;
  height: 297mm;
  padding: 0;
  margin: 0 auto;
  display: block;
}

.wrapper-alamat {
  width: 300px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.img-kanggo {
  width: 130px;
}

.wrapper-store {
  width: 50%;
  font-size: 14px;
}

.wrapper-address {
  width: 50%;
  font-size: 14px;
}

.divider-invoice {
  border-bottom: 1px solid $color-grey-14;
}

.wrapper-grand-total {
  background: linear-gradient(90deg, #fffff7 0%, #fff5ea 100%);
  border-radius: 8px;
  font-size: 17px;
  margin: 0px -10px;
  padding: 0px 10px;
}

.text-grand-total {
  color: #d9272d;
}

.btn-download {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 6px;
  color: white;
  font-family: "Averta-Bold";
  padding: 0px 24px;
}

.btn-download svg {
  fill: #ffffff;
}

.watermarkBelumBayar {
  background: url("../../../../assets/img/belum-bayar.png") center center
    no-repeat;
  top: 0rem;
  opacity: 0.35;
  position: absolute;
  width: 100%;
  height: 100%;
}

.watermarkLunas {
  background: url("../../../../assets/img/lunas.png") center center no-repeat;
  top: 0rem;
  opacity: 0.35;
  position: absolute;
  width: 100%;
  height: 100%;
}

.dot-list {
  list-style-type: disc;
}

.indicator-wrapper {
  background: #f4f9ff;
  width: 100%;
  position: fixed;
  top: 12vh;
  z-index: 2;
  display: flex;
  align-items: center;
}

.mb-ttd {
  margin-bottom: 110px;
}

.durasi-wrapper {
  border: 2px solid #3185FC;
  border-radius: 12px;
  background: rgba(49, 133, 252, 0.1);
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 18px;
}

.height-signature {
  height: 110px;
}

.signature-img {
  height: 90%;
  position: relative;
}

.signature-img img {
  height: 100%;
  object-fit: contain;
}
</style>
