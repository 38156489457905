<template>
  <div>
    <div class="mb-5">
      <p class="averta-black label-20">Informasi {{renderTitlePICStore}}</p>
      <p v-if="!typeContent">
        Masukkan informasi {{renderTitleStore}} dan {{renderTitlePICStore}} dari {{renderTitleBranch}} yang sudah dinput diatas.
      </p>
    </div>
    <div v-if="typeContent === 'add-pic'">
      <div class="is-flex is-justify-content-space-between mb-5">
        <p class="label-14 mr-3 mt-2 white-space-nowrap">
          <span class="averta-bold">{{renderTitleStore}} </span>
          <span class="text-merah">*</span>
        </p>
        <StoreAutoComplete />
      </div>
    </div>
    <div class="border-radius-20 overflow-hidden" :class="{'body-store' : !typeContent}">
      <div v-if="!typeContent">
        <div class="flex-center-vertical is-justify-content-space-between mb-4">
          <div class="input-label">
            <p class="averta-bold">Nama {{renderTitleStore}} <span class="color-red">*</span></p>
          </div>
          <b-input
            v-model="formStore.store_name"
            :disabled="disabledForm"
            :placeholder="`Masukkan nama ${renderTitleStore}`"
            class="input-search-2 c-w-85 input-none"
          ></b-input>
        </div>
        <div class="flex-center-vertical is-justify-content-space-between mb-4">
          <div class="input-label">
            <p><span class="averta-bold">No. HP</span> <span class="color-grey">(Optional)</span></p>
          </div>
          <b-input
            v-model="formStore.store_phone"
            :disabled="disabledForm"
            :placeholder="`Masukkan no. HP ${renderTitleStore}`"
            class="input-search-2 c-w-85 input-none"
            type="number"
            maxlength="14"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></b-input>
        </div>
        <div class="flex-center-vertical is-justify-content-space-between mb-4">
          <div class="input-label">
            <p class="averta-bold">Alamat {{renderTitleStore}} <span class="color-red">*</span></p>
          </div>
          <div @click="addAddressByMaps" class="c-w-85 is-clickable is-relative">
            <b-input
              v-model="formStore.store_address"
              :disabled="disabledForm"
              readonly="readonly"
              onfocus="this.blur();"
              :placeholder="`Masukkan alamat ${renderTitleStore}`"
              class="input-search-2 bg-white c-w-100 input-none pr-5"
              icon-right-clickable
              @icon-right-click="addAddressByMaps"
            ></b-input>
            <div class="location-icon">
              <Location color="#868686" size="24" />
            </div>
          </div>
        </div>
        <div class="flex-center-vertical is-justify-content-space-between mb-4">
          <div class="input-label flex-shrink-0">
            <p class="averta-bold">Detail Alamat <span class="color-red">*</span></p>
          </div>
          <div class="c-w-85">
            <textarea
              style="resize: none;"
              v-model="formStore.detail_address"
              :disabled="disabledForm || !formStore.store_address"
              rows="3"
              class="textarea input-search-2 input-none"
              placeholder="Detail alamat lebih lengkap"
            ></textarea>
          </div>
        </div>
      </div>

      <div v-if="!hideFormPIC" class="wrap-client-form">
        <div v-for="(item, index) in formPIC" :key="index" class="pb-4 mb-4" :class="{'border-bottom-1': typeContent !== 'edit-pic'}">
          <div class="flex-center-vertical is-justify-content-space-between mb-4">
            <div class="input-label">
              <p class="averta-bold">Nama {{renderTitlePICStore}} <span class="color-red">*</span></p>
            </div>
            <div class="flex-center-vertical c-w-85">
              <b-input
                v-model="item.pic_name"
                :disabled="disabledForm"
                :placeholder="`Masukkan nama ${renderTitlePICStore}`"
                class="input-search-2 input-none"
                :class="`${index > 0 ? 'c-w-100 mr-4' : 'c-w-100'}`"
              ></b-input>
              <div
                v-if="index > 0"
                class="is-clickable wrap-delete is-flex is-align-items-center"
                @click="handleDeletePIC(index)"
              >
                <TrashIcon />
              </div>
            </div>
          </div>
          <div class="flex-center-vertical is-justify-content-space-between mb-4">
            <div class="input-label">
              <p>
                <span class="averta-bold">Email</span> <span class="color-red">*</span>
              </p>
            </div>
            <b-input
              v-model="item.pic_email"
              :disabled="disabledForm"
              :placeholder="`Masukkan email ${renderTitlePICStore}`"
              class="input-search-2 c-w-85 input-none"
            ></b-input>
          </div>
          <div class="flex-center-vertical is-justify-content-space-between mb-4">
            <div class="input-label">
              <p class="averta-bold">No. Handphone/WA <span class="color-red">*</span></p>
            </div>
            <b-input
              v-model="item.pic_phone"
              :disabled="disabledForm"
              :placeholder="`Masukkan nama no. handphone/WA ${renderTitlePICStore}`"
              class="input-search-2 c-w-85 input-none"
              type="number"
              maxlength="14"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            ></b-input>
          </div>
        </div>

        <div v-if="typeContent !== 'edit-pic'" class="add-pic-store flex-center-vertical is-justify-content-space-between">
          <div class="flex-center-vertical">
            <img src="@/assets/img/worker-circle.png" alt="" style="height: 110px" />
            <div class="ml-5">
              <p class="averta-black label-18">Tambah {{renderTitlePICStore}} lainnya di cabang ini?</p>
              <p>Kamu dapat menambahkan lebih dari 1 {{renderTitlePICStore}}</p>
            </div>
          </div>
          <div>
            <ButtonApp width="220px" height="33px" @click="handleIncreasePIC" :isDisabled="disabledForm">
              <p class="averta-bold">+ {{renderTitlePICStore}}</p>
            </ButtonApp>
          </div>
        </div>
      </div>
    </div>

    <ModalMapsAddress
      :showModal="modalAddAddressByMap"
      @closeModal="closeModalAddAddressByMap"
      @selectAddress="handleSelectAddress($event)"
    />

    <!-- Delete PIC -->
    <ModalConfirm
      :showModal="showModalDelete"
      imageProps="sunset.png"
      imageClass="mb-3"
      titleProps="Hapus PIC?"
      descProps="Pastikan kamu sudah lakukan pengecekkan sebelum menghapus data ini."
    >
      <template v-slot:button>
        <div class="is-flex">
          <ButtonApp @click="handleCancelDeletePIC" class="mr-3" :isSecondary="true" width="185px" height="52px">
            <p class="averta-bold px-4 py-1">Kembali</p>
          </ButtonApp>
          <ButtonApp @click="handleConfirmDeletePIC" width="185px" height="52px">
            <p class="averta-bold px-4 py-1">Hapus</p>
          </ButtonApp>
        </div>
      </template>
    </ModalConfirm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validationClientAndBranch, titleBranch, titleStore, titlePICStore } from '@/helpers'
import { TrashIcon, Location } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
import StoreAutoComplete from './StoreAutoComplete.vue'
import ModalConfirm from '../ModalConfirm.vue'

export default {
  name: 'StoreInformation',
  props: {
    typeContent: {
      type: String, // add-pic or edit-pic
      default: null
    },
    detailPIC: {
      type: Object
    }
  },
  components: {
    ButtonApp,
    TrashIcon,
    Location,
    StoreAutoComplete,
    ModalConfirm,
    ModalMapsAddress: () => import('../ModalMapsAddress.vue')
  },
  computed: {
    ...mapGetters({
      payloadAddPicBranch: 'client/getPayloadAddPicBranch',
      typeSidebar: 'client/getTypeSidebarStore',
      payloadStore: 'client/getPayloadStore',
      detailClient: 'client/getDetailClient'
    }),
    isSMRCode () {
      return this.detailClient && this.detailClient.code
    },
    renderTitlePICStore () {
      return titlePICStore(this.isSMRCode)
    },
    renderTitleStore () {
      return titleStore(this.isSMRCode)
    },
    renderTitleBranch () {
      return titleBranch(this.isSMRCode)
    }
  },
  data () {
    return {
      indexPICDelete: null,
      showModalDelete: false,
      hideFormPIC: false,
      disabledForm: true,
      formStore: {
        store_name: '',
        store_phone: '',
        store_address: '',
        latitude: '',
        longitude: '',
        detail_address: ''
      },
      formPIC: [
        {
          pic_name: '',
          pic_email: '',
          pic_phone: ''
        }
      ],
      modalAddAddressByMap: false
    }
  },
  methods: {
    handleIncreasePIC () {
      this.formPIC.push({
        pic_name: '',
        pic_email: '',
        pic_phone: ''
      })
    },
    handleDeletePIC (index) {
      this.indexPICDelete = index
      const tempPIC = JSON.parse(JSON.stringify(this.formPIC))
      if (this.formPIC.length > 1) {
        const isFilledExceptFirst = tempPIC.slice(1).some(obj => {
          return Object.values(obj).some(value => value !== '')
        })
        if (isFilledExceptFirst) {
          this.showModalDelete = true
        } else {
          this.formPIC.splice(index, 1)
        }
      }
    },
    handleCancelDeletePIC () {
      this.showModalDelete = false
    },
    handleConfirmDeletePIC () {
      this.showModalDelete = false
      this.formPIC.splice(this.indexPICDelete, 1)
    },
    handleSelectedPIC (data) {
      if (this.typeContent === 'edit-pic') {
        if (data) {
        // eslint-disable-next-line camelcase
          const { email, name, phone } = data
          this.formPIC = [
            {
              pic_name: name,
              pic_email: email,
              pic_phone: phone
            }
          ]
        }
      } else if (this.typeContent === 'detail-add-pic') {
        this.formPIC = [
          {
            pic_name: '',
            pic_email: '',
            pic_phone: ''
          }
        ]
      }
    },
    handleSetForm () {
      this.disabledForm = validationClientAndBranch(this.payloadAddPicBranch)
      if (this.typeSidebar === 'edit-store') {
        this.hideFormPIC = true

        const store = this.payloadStore.formStore
        this.formStore = {
          store_name: store.name,
          store_phone: store.phone,
          store_address: store.address,
          latitude: store.latitude,
          longitude: store.longitude,
          detail_address: store.detail_address
        }
      } else {
        this.formStore = {
          store_name: '',
          store_phone: '',
          store_address: '',
          latitude: '',
          longitude: '',
          detail_address: ''
        }
        this.formPIC = [
          {
            pic_name: '',
            pic_email: '',
            pic_phone: ''
          }
        ]
        this.hideFormPIC = false
      }
    },
    addAddressByMaps () {
      this.modalAddAddressByMap = !this.modalAddAddressByMap
    },
    closeModalAddAddressByMap () {
      this.modalAddAddressByMap = false
    },
    handleSelectAddress (props = {}) {
      const { lat, lon, address } = props.data
      this.formStore = {
        ...this.formStore,
        latitude: `${lat || props.currentPlace.lat}`,
        longitude: `${lon || props.currentPlace.lng}`,
        store_address: address
      }
    }
  },
  mounted () {
    this.handleSetForm()
  },
  watch: {
    formStore: {
      handler (value) {
        let body = value
        if (this.typeSidebar === 'edit-store') {
          body = {
            ...this.payloadStore.formStore,
            ...value
          }
        }
        this.$store.commit('client/setPayloadStore', { formStore: body })
      },
      deep: true
    },
    formPIC: {
      handler (value) {
        this.$emit('formPICEdit', value[0])
        this.$store.commit('client/setPayloadStore', { formPIC: value })
      },
      deep: true
    },
    detailPIC: {
      handler (value) {
        this.handleSelectedPIC(value)
      },
      deep: true
    },
    payloadStore: {
      handler (value) {
        // this.formStore = value.formStore
        this.formPIC = value.formPIC
      },
      deep: true
    },
    payloadAddPicBranch: {
      handler () {
        this.handleSetForm()
      },
      deep: true
    },
    typeSidebar () {
      this.handleSetForm()
    },
    typeContent (value) {
      this.formStore = {
        store_name: '',
        store_phone: '',
        store_address: '',
        latitude: '',
        longitude: '',
        detail_address: ''
      }

      this.formPIC = [
        {
          pic_name: '',
          pic_email: '',
          pic_phone: ''
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';
.input-label {
  width: 180px;
  flex-shrink: 0;
}

.body-store {
  border: 1px solid $color-grey-2;
  padding: 20px;
  background: $color-grey-8;
}

.add-pic-store {
  background: linear-gradient(90deg, #DEFFFB 0%, #E7DEFF 100%);
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
}

.location-icon {
  position: absolute;
  right: 5px;
  top: 7px;
}
</style>
