<template>
  <svg
    :width="size"
    height="auto"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4392 11.24C13.2449 11.4282 13.1557 11.7005 13.1999 11.9675L13.8667 15.6575C13.9229 15.9702 13.7909 16.2868 13.5292 16.4675C13.2727 16.655 12.9314 16.6775 12.6517 16.5275L9.32991 14.795C9.21441 14.7335 9.08616 14.7005 8.95491 14.6968H8.75166C8.68116 14.7073 8.61216 14.7297 8.54916 14.7642L5.22666 16.505C5.06241 16.5875 4.87641 16.6168 4.69416 16.5875C4.25016 16.5035 3.95391 16.0805 4.02666 15.6343L4.69416 11.9443C4.73841 11.675 4.64916 11.4012 4.45491 11.21L1.74666 8.585C1.52016 8.36525 1.44141 8.03525 1.54491 7.7375C1.64541 7.4405 1.90191 7.22375 2.21166 7.175L5.93916 6.63425C6.22266 6.605 6.47166 6.4325 6.59916 6.1775L8.24166 2.81C8.28066 2.735 8.33091 2.666 8.39166 2.6075L8.45916 2.555C8.49441 2.516 8.53491 2.48375 8.57991 2.4575L8.66166 2.4275L8.78916 2.375H9.10491C9.38691 2.40425 9.63516 2.573 9.76491 2.825L11.4292 6.1775C11.5492 6.42275 11.7824 6.593 12.0517 6.63425L15.7792 7.175C16.0942 7.22 16.3574 7.4375 16.4617 7.7375C16.5599 8.03825 16.4752 8.36825 16.2442 8.585L13.4392 11.24Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'StarRatingIcon',
  props: {
    color: {
      type: String,
      default: '#ffffff'
    },
    size: {
      type: String,
      default: '20'
    }
  }
}
</script>
