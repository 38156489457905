<template>
<div v-if="isLoading" class="c-w-100 my-5 mx-5">
    <ListSkeleton />
  </div>
  <div v-else class="py-5 wrapper-project" id="listSM">
    <InputSearchGlobal :title="titleStack" />
    <b-tabs v-model="activeTab">
      <b-tab-item v-for="countData in countDataTabs" :key="countData.label">
        <template #header>
          <b-icon icon="information"></b-icon>
          <span>
            {{ countData.label }} <b-tag rounded> {{ countData.count }} </b-tag>
          </span>
        </template>

        <ListSurvey
          v-if="countData.label == 'Survey'"
          type="survey"
          selectedSubstatus="late"
          :activeTab="0"
          :currentTab="0"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListPenawaran
          v-if="countData.label == 'Approval'"
          type="offering"
          selectedSubstatus="internal"
          :isWaitingConfirmSM="true"
          :activeTab="1"
          :currentTab="1"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListProyek
          v-if="countData.label == 'Proyek'"
          type="project-active"
          selectedSubstatus="in-planning"
          :activeTab="2"
          :currentTab="2"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
        <ListProyek
          v-if="countData.label == 'Pembayaran'"
          type="project-complete"
          selectedSubstatus="waiting"
          :activeTab="3"
          :currentTab="3"
          :selectedTab="activeTab"
          :triggerAPIFromScroll="triggerScroll"
          :triggerTop="triggerTop"
          @reloadData="countTotalDataTabs($event)"
        />
      </b-tab-item>
    </b-tabs>

    <div>
      <ButtonScrollTop :offsetWindowScroll="200" elementPoint="listSM" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListSkeleton from '@/components/skeleton/ListSkeleton'

import InputSearchGlobal from '@/components/project/list/InputSearchGlobal'
import ListSurvey from '@/components/project/list/ListSurvey.vue'
import ListPenawaran from '@/components/project/list/ListPenawaran.vue'
import ListProyek from '@/components/project/list/ListProyek.vue'
import ButtonScrollTop from '@/components/button/ButtonScrollTop.vue'

export default {
  name: 'ListProjectSM',
  components: {
    ListSkeleton,
    ListSurvey,
    ListPenawaran,
    ListProyek,
    InputSearchGlobal,
    ButtonScrollTop
  },
  computed: {
    titleStack () {
      return ['Proyek']
    },
    ...mapGetters({
      url: 'setup/getUrl',
      tab: 'list/getSelectedTabList',
      tabPersist: 'list/getPersistTabProject'
    })
  },
  data () {
    return {
      isLoading: true,
      countDataTabs: null,
      activeTab: 0,
      triggerScroll: 0,
      triggerTop: false,
      callCountTabs: false
    }
  },
  methods: {
    countTotalDataTabs (props) {
      if (this.callCountTabs) return

      if (!props || !props.disableLoading) {
        this.isLoading = true
      } else {
        this.callCountTabs = true
      }
      if (props && props.fromDetail) {
        this.$store.commit('list/resetPersistTabProject')
        this.$store.commit('list/resetPersistDataList')
      }

      const body = {
        url: this.url
      }

      if (props && props.vendor) {
        body.vendor = props.vendor
      }

      this.$store
        .dispatch('projectV2/getTabsProject', body)
        .then((response) => {
          this.countDataTabs = response.data.data
          this.isLoading = false
          this.persistDataTab()
          this.callCountTabs = false
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
        })
    },
    handleScroll () {
      const windowHeight = window.innerHeight
      const scrollY = window.scrollY || window.pageYOffset
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight
      )

      const offset = 250

      if (documentHeight - (scrollY + windowHeight) < offset) {
        this.triggerScroll += 1
      }

      if (scrollY >= 100) {
        this.triggerTop = true
      } else if (scrollY === 0) {
        this.triggerTop = false
      }
    },
    persistDataTab () {
      // Store Data Temporary
      const storeObj = {
        countDataTabs: this.countDataTabs,
        activeTab: this.activeTab
      }
      this.$store.commit('list/setPersistTabProject', storeObj)
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.activeTab = this.tab || 0
  },
  beforeRouteLeave (to, from, next) {
    const destinationPath = to.path
    const parts = destinationPath.split('/')
    const lastPart = parts.pop()

    if (lastPart !== 'detail') {
      this.$store.commit('list/resetPersistTabProject')
      this.$store.commit('list/resetPersistDataList')
    }
    next()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
    this.triggerScroll = 0
  },
  created () {
    if (this.tabPersist && Object.keys(this.tabPersist).length > 0) {
      const { countDataTabs, activeTab } = this.tabPersist
      this.countDataTabs = countDataTabs
      this.activeTab = activeTab
      this.isLoading = false
    } else {
      this.countTotalDataTabs()
    }
  },
  watch: {
    activeTab (val) {
      this.$store.commit('list/setSelectedTabList', val)
      this.$store.commit('list/setPersistDataList', { activeTab: val })
      this.persistDataTab()
    }
  }
}
</script>

<style scoped>
.wrapper-project {
  width: 95%;
  margin: auto;
}
</style>
