<template>
  <Skeleton v-if="isLoading" class="p-6" />
  <div v-else class="is-relative">

    <!-- NOT READY -->
    <div class="is-flex">
      <CardClientType
        class="mb-5 mr-3"
        :title="dataPreview.client_type ? dataPreview.client_type.name : 'Perusahaan'"
        :desc="dataPreview.client_type ? dataPreview.client_type.description : 'Invoice yang dikirimkan menampilkan PPN dan PPh'"
        :active="true"
      />
    </div>
    <div>
      <div>
        <TimelinePhase2
          :dataProps="dataPreview.timeline"
          :durationProps="dataPreview.duration"
          :slotSkillsProps="dataPreview.slot_skills"
        />
      </div>
      <div class="mb-5 wrapper-list-total">
        <div class="is-flex is-justify-content-space-between mb-3 px-3">
          <p class="averta-bold is-size-5">Total Harga Penawaran</p>
          <!-- <ButtonApp @click="previewOffering" :is-bold="true" height="3em">
            <div class="is-flex is-align-items-center pr-2">
              <p class="mr-2 averta-bold">Preview Unduh Penawaran</p>
              <img src="@/assets/img/download.png" class="ml-2" />
              <ArrowRightIcon color="#fff" />
            </div>
          </ButtonApp> -->
        </div>

        <div v-if="isSMRC" class="is-flex mb-5 px-2">
          <button
            class="button-tabs-blue mr-3"
            :class="{ 'active': currentTab === 'actual' }"
            @click="handleTab('actual')"
          >
            Harga Dasar
          </button>
          <button
            class="button-tabs-blue"
            :class="{ 'active': currentTab === 'tenant' }"
            @click="handleTab('tenant')"
          >
            Harga Tenant
          </button>
        </div>

        <!-- TAB COMPONENTS -->
        <TabActualPrice v-if="currentTab === 'actual'" :dataPreview="dataPreview" />
        <TabTenantPrice v-else :dataPreview="dataPreview" />

      </div>
    </div>
    <div class="divider my-6"></div>
    <div>
      <NewDetailPenawaran
        :hideInformasiPenawaran="true"
        :hideTimeline="true"
        :offeringId="dataPreview.offering_id"
        :isBS="true"
        :isFromDetail="false"
        :isProjectUnitPrice="isProjectUnitPrice"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { ArrowRightIcon } from '@/components/icons'
import { formatCurrency, alertErrorMessage, Terbilang, isSMRC } from '@/helpers'

import Skeleton from '@/components/skeleton/Skeleton'
// import ButtonApp from '@/components/button/ButtonApp.vue'

import NewDetailPenawaran from '@/components/project/NewDetailPenawaran'
import TimelinePhase2 from '@/components/project/TimelinePhase2.vue'
import CardClientType from '@/components/card/CardClientType.vue'
import TabActualPrice from './TabActualPrice.vue'
import TabTenantPrice from './TabTenantPrice.vue'

export default {
  name: 'PreviewCompleteOffering',
  components: {
    // ArrowRightIcon,
    Skeleton,
    // ButtonApp,
    NewDetailPenawaran,
    TimelinePhase2,
    CardClientType,
    TabActualPrice,
    TabTenantPrice
  },
  props: {
    isProjectUnitPrice: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    currentPath () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      return lastElement
    },
    dataPreviewOffering () {
      const obj = {
        item_components: this.dataPreview.preview_client,
        client_type_code: this.dataPreview.client_type ? this.dataPreview.client_type.code : null,
        prices: {
          ...this.dataPreview.preview_price,
          ppn_percent: this.dataPreview.preview_price.ppn_percent.replace('%', ''),
          pph_percent: this.dataPreview.preview_price.pph_percent.replace('%', ''),
          total: this.dataPreview.preview_price.total_after_rounding
        }
      }

      let numbering = 0
      if (obj.item_components) {
        obj.item_components.forEach(area => {
          area.components.forEach(subArea => {
            subArea.item_components.forEach(component => {
              numbering++
              component.numbering = numbering
            })
          })
        })
      }
      return obj
    },
    isSMRC () {
      return isSMRC(this.dataPreview.vendor_code || '')
    }
  },
  data () {
    return {
      isLoading: false,
      dataPreview: null,
      area: null,
      btnTabDetailHarga: true,
      btnTabDetailTukangMaterial: false,

      currentTab: 'actual'
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x, true)
    },
    changeIsActive (areaName) {
      this.area = areaName
    },
    changeIsActiveTab (type) {
      if (type === 'detail-harga') {
        this.btnTabDetailHarga = true
        this.btnTabDetailTukangMaterial = false
      } else {
        this.btnTabDetailHarga = false
        this.btnTabDetailTukangMaterial = true
      }
    },
    getPreviewLengkapiPenawaran () {
      this.isLoading = true
      if (this.currentPath === 'complete-offering') {
        const payload = {
          url: this.url,
          survey_id: this.$route.params.surveyId
        }

        this.$store
          .dispatch('project/getPreviewLengkapiPenawaran', payload)
          .then(response => {
            this.dataPreview = response.data.data
            this.$store.commit('project/setDataDetailPenawaran', response.data.data)
            this.$emit('handleDataPreview', this.dataPreview)
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            alertErrorMessage(error)
          })
      } else if (this.currentPath === 'update-price') {
        const payload = {
          url: this.url,
          offering_id: this.$route.params.offeringId
        }

        this.$store
          .dispatch('projectV2/getPreviewReject', payload)
          .then(response => {
            this.dataPreview = response.data.data
            this.$store.commit('project/setDataDetailPenawaran', response.data.data)
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            alertErrorMessage(error)
          })
      }
    },
    spellRupiah (value) {
      return Terbilang(value)
    },
    previewOffering () {
      let path
      let query

      if (this.currentTab === 'actual') {
        path = `/am/offering-project/${this.dataPreview.offering_id}`
        query = { preview: true }
        if (this.isSMRC) query.actual = true
      } else if (this.currentTab === 'tenant') {
        path = `/am/offering-tenant/${this.dataPreview.offering_id}`
        query = { preview: true }
      }
      const routeData = this.$router.resolve({ path, query })
      window.open(routeData.href, '_blank')
    },
    handleTab (value) {
      this.currentTab = value
    }
  },
  created () {
    this.getPreviewLengkapiPenawaran()
    // set true for download offering ( OfferingProject ), from PreviewPenawaran
    this.$store.commit('project/setPreviewDownloadOffering', true)
  }
}
</script>

<style scoped>
.divider {
  border-top: 2px dashed #E1E1E1;
  margin: 24px 0px;
}

.wrapper-list-total {
  padding: 20px 15px 20px 10px;
  border-radius: 20px;
  border: 1px solid #E1E1E1;
}

</style>
