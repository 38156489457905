<template>
  <div v-if="isLoading" class="container-unit-price min-h-90vh">
    <b-loading :is-full-page="true" :active="true" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
  <div v-else class="container-unit-price min-h-90vh">
    <div class="c-w-100 py-5 px-6">
      <NavbarUnitPrice />
      <FormItemWorking />
    </div>
    <div class="divider-bold"></div>
    <div class="c-w-100 py-5 px-6">
      <InputPriceItemWorking />
    </div>
  </div>
</template>

<script>
import { alertErrorMessage } from '@/helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'UnitPrice',
  components: {
    NavbarUnitPrice: () => import('../components/NavbarUnitPrice.vue'),
    FormItemWorking: () => import('../components/FormItemWorking.vue'),
    InputPriceItemWorking: () => import('../components/InputPriceItemWorking.vue'),
    SpinnerIcon: () => import('@/components/icons')
  },
  data () {
    return {
      isLoading: false,
      initUnitPrice: [],
      dataUnitPrice: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    getDetailUnitPrice () {
      this.isLoading = true
      const payload = {
        url: this.url,
        id: this.$route.query.noteId
      }

      this.$store
        .dispatch('unitPrice/getDetailUnitPrice', payload)
        .then(response => {
          const res = response.data.data
          this.$store.commit('unitPrice/setDataAPIUnitPrice', res)

          // eslint-disable-next-line camelcase
          const { note_item_working, unit_price, service_price, material_price, unit_id, unit_name, detail } = res

          const updatedMaterials = detail.materials.map(item => ({
            ...item,
            material_price: Number(item.volume) * item.price
          }))

          const resMaterials = detail.work_tools || detail.workTools
          const updatedWorkTools = resMaterials.map(item => ({
            ...item,
            material_price: Number(item.volume) * item.price
          }))

          const resAuxiliary = detail.auxiliary_tools || detail.auxiliaryTools
          const updatedAuxiliary = resAuxiliary.map(item => ({
            ...item,
            material_price: Number(item.volume) * item.price
          }))

          const resWorkFees = detail.worker_fees || detail.workerFees
          const updatedWorkFees = resWorkFees.map(item => ({
            ...item,
            worker_price: Number(item.volume) * item.price
          }))

          this.$store.commit('unitPrice/setUnitPrice', {
            // eslint-disable-next-line camelcase
            note_item_working: note_item_working || 'empty',
            unit_price,
            service_price,
            material_price,
            unit: { id: unit_id, name: unit_name },
            detail: {
              summary: detail.summary,
              materials: updatedMaterials,
              work_tools: updatedWorkTools,
              auxiliary_tools: updatedAuxiliary,
              worker_fees: updatedWorkFees
            }
          })

          this.isLoading = false
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          }, 100)
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoading = false
        })
    }
  },
  created () {
    if (this.$route.query.noteId) {
      this.getDetailUnitPrice()
    } else {
      this.$store.commit('unitPrice/setResetUnitPrice')
      this.$store.commit('unitPrice/resetDataUnitPrice')
    }
  },
  beforeRouteLeave (to, from, next) {
    const targetPath = `/am/vendors/${this.$route.params.vendorId}/branch`

    // Prevent infinite loop by checking if the target is the same as the current route
    if (to.path !== targetPath || to.query.type !== 'unit-price') {
      next({ path: targetPath, query: { type: 'unit-price' } })
    } else {
      next()
    }
  }
}
</script>

<style scoped>
.container-unit-price {
  width: calc(100% - 70px); /* 70px = WIDTH SIDEBAR */
}
</style>
