<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-modal v-model="showModal" :can-cancel="false">
      <div class="modal-content">
        <div class="modal-header">
          <div
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
          >
            <p class="averta-bold label-20 color-white">Berikan Penilaian</p>
          </div>
          <div
            @click="closeModals('Rating Under Three')"
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center is-clickable"
          >
            <XMarkIcon />
          </div>
        </div>
        <div class="modal-body">
          <div class="gradient-effect"></div>

          <div
            style="z-index: 99999"
            class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-6 c-w-100"
          >
            <div
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center gap-1"
            >
              <RatingShineIcon />
              <p class="averta-bold label-30 has-text-centered">
                Berikan pendapatmu tentang pekerjaan Jagoan Kanggo
              </p>
              <span
                style="background: #fce5e6"
                class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 p-2 color-red label-14 border-radius-50 averta-bold"
              >
                Penilaian dan ulasan akan ditampilkan di client, pastikan kata-kata dan penilaian
                benar!
              </span>
            </div>

            <div class="card-content-rating-mitra gap-4">
              <div
                class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
              >
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-2"
                >
                  <div class="avatar-circle-users">
                    <img
                      :src="
                        selectedWorker && selectedWorker.image
                          ? selectedWorker.image
                          : selectedWorker && selectedWorker.worker_image
                          ? selectedWorker.worker_image
                          : ''
                      "
                      alt=""
                    />
                  </div>
                  <div class="is-flex is-flex-direction-column gap-1">
                    <p class="averta-bold label-16">
                      {{
                        selectedWorker && selectedWorker.name
                          ? selectedWorker.name
                          : selectedWorker && selectedWorker.worker_name
                          ? selectedWorker.worker_name
                          : ''
                      }}
                    </p>
                    <p class="averta-reguler label-14">
                      {{
                        selectedWorker && selectedWorker.skillName
                          ? selectedWorker.skillName
                          : selectedWorker && selectedWorker.skill_name
                          ? selectedWorker.skill_name
                          : ''
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center"
                >
                  <p class="averta-reguler label-14">
                    {{
                      selectedWorker && selectedWorker.workItemId
                        ? selectedWorker.workItemId
                        : selectedWorker && selectedWorker.worker_id
                        ? selectedWorker.worker_id
                        : ''
                    }}
                  </p>
                </div>
              </div>
              <div class="divider-line-1"></div>
              <div
                style="background: #f0f6ff"
                class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 px-5 py-4 border-radius-6"
              >
                <div class="is-flex is-flex-direction-column gap-1">
                  <p class="averta-reguler label-14">Item Pekerjaan</p>
                  <p class="averta-bold label-16">
                    {{
                      selectedWorker && selectedWorker.workItemName
                        ? selectedWorker.workItemName
                        : selectedWorker &&
                          selectedWorker.itemWorking &&
                          selectedWorker.itemWorking.item_component_name
                        ? selectedWorker &&
                          selectedWorker.itemWorking &&
                          selectedWorker.itemWorking.item_component_name
                        : ''
                    }}
                  </p>
                </div>
              </div>
              <div
                class="c-w-100 is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
              >
                <b-rate
                  :value="1"
                  v-model="rating"
                  :disabled="false"
                  size="is-medium"
                  :spaced="true"
                ></b-rate>
              </div>
            </div>

            <div
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 gap-3 is-flex-wrap-wrap"
            >
              <button
                v-for="(option, index) in currentOptions"
                :key="index"
                class="button-comments-mitra"
                :class="{ isSelect: selectedOptions.includes(option) }"
                @click="toggleSelect(option)"
              >
                <p class="averta-reguler label-14">{{ option.name }}</p>
              </button>
            </div>

            <div
              class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100"
            >
              <input
                class="input-comments-mitra"
                type="text"
                placeholder="Tulis komentar kamu disini"
                v-model="comment"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer z-index-9999">
          <ButtonApp
            width="100%"
            height="45px"
            @click="submitRating()"
            :isDisabled="rating > 3 ? !isFormValid : !isFormValidRatingUnderThree"
          >
            <p class="averta-reguler label-16">Simpan</p>
          </ButtonApp>
        </div>
        <div style="z-index: 99999">
          <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false">
            <SpinnerIcon />
          </b-loading>
        </div>
      </div>
    </b-modal>
    <modalsHasNoRate :isHandleOpenModalPageRating="isHandleOpenModalPageRating" />
    <modalsConfirmRating
      :isHandleOpenModalsConfirmRating="isHandleOpenModalsConfirmRating"
      @closeModalConfirmRating="closeModalConfirmRating"
      @submitRating="submitRating"
    />
    <!-- Rating Under Three (Ticketing) -->
    <modalsRateUnderThree
      ref="modalsRateUnderThree"
      :isHandleOpenModalsRateUnderThree="isHandleOpenModalsRateUnderThree"
      :selectedWorker="selectedWorker"
      :getDataBranch="getDataBranch"
      :maxRating5="maxRating5"
      :maxRatingUnder4="maxRatingUnder4"
      :maxRatingUnder2="maxRatingUnder2"
      :getProjectId="getProjectId"
      :selectedDate="selectedDate"
      :rating="rating"
      :currentOptions="currentOptions"
      :selectedOptions="selectedOptions"
      :dataHeader="dataHeader"
      :getDataClient="getDataClient"
      :getDateCreateAt="getDateCreateAt"
      v-model="comment"
      @approveDataSubmitRating="handleApproveDataSubmitRating"
      @submit-rating="submitRating"
      @closeModalsRateUnderThree="closeModalsRateUnderThree"
      @update:isHandleOpenModalsRateUnderThree="
        (newVal) => (isHandleOpenModalsRateUnderThree = newVal)
      "
      @update:selectedOptions="(newOptions) => (selectedOptions = newOptions)"
    />
    <!-- Rating Under Three (Ticketing) -->
  </div>
</template>

<script>
import { RatingShineIcon, XMarkIcon } from '@/components/icons'
import { mapGetters } from 'vuex'
import ButtonApp from '@/components/button/ButtonApp.vue'
import confetti from 'canvas-confetti'
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'
import modalsConfirmRating from './modalsConfirmRating.vue'
import modalsHasNoRate from './modalsHasNoRate.vue'
import modalsRateUnderThree from './modalsRateUnderThree'

export default {
  name: 'modalsRating',
  components: {
    RatingShineIcon,
    ButtonApp,
    XMarkIcon,
    SpinnerIcon,
    modalsConfirmRating,
    modalsHasNoRate,
    modalsRateUnderThree
  },
  props: {
    isHandleOpenModalsRating: {
      type: Boolean,
      default: false
    },
    isRatingOverThree: {
      type: Boolean,
      default: false
    },
    selectedWorker: {
      type: Object
    },
    dataHeader: {
      type: Object
    },
    getDataClient: {
      type: Object
    },
    getDateCreateAt: {
      type: String
    },
    getDataBranch: {
      type: Object
    },
    maxRating5: {
      type: Array,
      default: () => []
    },
    maxRatingUnder4: {
      type: Array,
      default: () => []
    },
    maxRatingUnder2: {
      type: Array,
      default: () => []
    },
    getProjectId: {
      type: Number,
      default: null
    },
    selectedDate: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      showModal: false,
      selectedOptions: [],
      comment: '',
      isLoading: false,
      isFullPage: true,
      rating: 1,
      isHandleOpenModalPageRating: false,
      isHandleOpenModalsRateUnderThree: false,
      isHandleOpenModalsConfirmRating: false,
      approveDataSubmitRating: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    currentOptions () {
      if (!this.rating || !this.rating) {
        return []
      }
      const rating = this.rating
      if (rating === 5) {
        return this.maxRating5
      } else if (rating === 3 || rating === 4) {
        return this.maxRatingUnder4
      } else if (rating === 1 || rating === 2) {
        return this.maxRatingUnder2
      }
      return []
    },
    isFormValidRatingUnderThree () {
      return (
        this.selectedWorker && this.rating && this.selectedOptions.length > 0 && this.comment.trim()
      )
    },
    isFormValid () {
      return this.selectedWorker && this.rating && this.selectedOptions.length > 0
    },
    isPermissionValidate () {
      return (
        this.selectedWorker.has_been_rated === false &&
        this.selectedWorker.formDetailProject === true
      )
    }
  },
  watch: {
    isHandleOpenModalsRating (newVal) {
      this.showModal = newVal
      if (newVal && this.rating === true) {
        this.$nextTick(() => {
          this.runConfetti()
        })
      }
    },
    rating (newRating) {
      if (newRating > 3 && this.$el) {
        this.$nextTick(() => {
          this.runConfetti()
        })
      }
    },
    currentOptions () {
      this.selectedOptions = []
    },
    isHandleOpenModalsRateUnderThree (newVal) {
      this.isHandleOpenModalsRateUnderThree = newVal
    }
  },
  methods: {
    closeModalsRateUnderThree () {
      this.isHandleOpenModalsRateUnderThree = false
    },
    closeModalConfirmRating () {
      this.isHandleOpenModalsConfirmRating = false
    },
    handleApproveDataSubmitRating (value) {
      this.approveDataSubmitRating = value
    },
    async submitRating () {
      this.isLoading = true
      this.$store.commit('rating/setDataComment', this.comment)

      if (!this.isFormValid) {
        this.isLoading = false
        return
      }

      try {
        if (this.rating <= 3 && !this.approveDataSubmitRating) {
          this.isHandleOpenModalsRateUnderThree = true
          this.isLoading = false
          return
        }

        const payload = {
          worker_id: this.selectedWorker.id || this.selectedWorker.worker_id,
          project_id: this.getProjectId,
          work_item_id:
            this.selectedWorker.workItemId || this.selectedWorker.itemWorking.item_component_id,
          skill_id: this.selectedWorker.skillId || this.selectedWorker.skill_id,
          date: this.selectedWorker.date || this.selectedDate,
          rating: this.rating,
          tags: this.selectedOptions.map((option) => option.id),
          description: this.comment.trim()
        }

        const res = await this.$store.dispatch('rating/postRating', { url: this.url, payload })

        if (res?.data?.code === 1) {
          await this.$swal({
            icon: 'success',
            title: 'Success',
            text: res.data.message
          })

          this.$buefy.toast.open({
            message: 'Jagoan Kanggo sudah Anda rating'
          })

          this.$refs.modalsRateUnderThree.submitComplaint()

          if (this.rating >= 4) {
            window.location.reload()
          }

          this.isLoading = false
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: res?.data?.message || 'An error occurred while submitting the rating.'
          })
        }
      } catch (error) {
        console.error('Error submitting rating:', error.response?.data || error.message)

        this.$swal({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'An unexpected error occurred. Please try again.'
        })
      } finally {
        this.isLoading = false
      }
    },
    handleOpenModalPageRating () {
      this.isHandleOpenModalsConfirmRating = false
    },
    closeModals (modalType) {
      this.showModal = false
      this.rating = 1
      this.$emit('closeModalsRating', modalType)
    },
    toggleSelect (option) {
      if (this.selectedOptions.includes(option)) {
        this.selectedOptions = this.selectedOptions.filter((item) => item !== option)
      } else {
        this.selectedOptions.push(option)
      }
    },
    runConfetti () {
      const modalContent = this.$el?.querySelector('.modal-content')
      if (!modalContent) return

      const canvas = document.createElement('canvas')
      canvas.style.position = 'absolute'
      canvas.style.top = '0'
      canvas.style.left = '0'
      canvas.style.width = '100%'
      canvas.style.height = '100%'
      canvas.style.pointerEvents = 'none'
      canvas.style.overflow = 'hidden'
      modalContent.appendChild(canvas)

      const showConfettiOverThree = confetti.create(canvas, {
        resize: true,
        useWorker: true
      })

      const pumpkin = confetti.shapeFromPath({
        path: 'M449.4 142c-5 0-10 .3-15 1a183 183 0 0 0-66.9-19.1V87.5a17.5 17.5 0 1 0-35 0v36.4a183 183 0 0 0-67 19c-4.9-.6-9.9-1-14.8-1C170.3 142 105 219.6 105 315s65.3 173 145.7 173c5 0 10-.3 14.8-1a184.7 184.7 0 0 0 169 0c4.9.7 9.9 1 14.9 1 80.3 0 145.6-77.6 145.6-173s-65.3-173-145.7-173zm-220 138 27.4-40.4a11.6 11.6 0 0 1 16.4-2.7l54.7 40.3a11.3 11.3 0 0 1-7 20.3H239a11.3 11.3 0 0 1-9.6-17.5zM444 383.8l-43.7 17.5a17.7 17.7 0 0 1-13 0l-37.3-15-37.2 15a17.8 17.8 0 0 1-13 0L256 383.8a17.5 17.5 0 0 1 13-32.6l37.3 15 37.2-15c4.2-1.6 8.8-1.6 13 0l37.3 15 37.2-15a17.5 17.5 0 0 1 13 32.6zm17-86.3h-82a11.3 11.3 0 0 1-6.9-20.4l54.7-40.3a11.6 11.6 0 0 1 16.4 2.8l27.4 40.4a11.3 11.3 0 0 1-9.6 17.5z',
        matrix: [
          0.020491803278688523, 0, 0, 0.020491803278688523, -7.172131147540983, -5.9016393442622945
        ]
      })

      const tree = confetti.shapeFromPath({
        path: 'M120 240c-41,14 -91,18 -120,1 29,-10 57,-22 81,-40 -18,2 -37,3 -55,-3 25,-14 48,-30 66,-51 -11,5 -26,8 -45,7 20,-14 40,-30 57,-49 -13,1 -26,2 -38,-1 18,-11 35,-25 51,-43 -13,3 -24,5 -35,6 21,-19 40,-41 53,-67 14,26 32,48 54,67 -11,-1 -23,-3 -35,-6 15,18 32,32 51,43 -13,3 -26,2 -38,1 17,19 36,35 56,49 -19,1 -33,-2 -45,-7 19,21 42,37 67,51 -19,6 -37,5 -56,3 25,18 53,30 82,40 -30,17 -79,13 -120,-1l0 41 -31 0 0 -41z',
        matrix: [
          0.03597122302158273, 0, 0, 0.03597122302158273, -4.856115107913669, -5.071942446043165
        ]
      })

      const heart = confetti.shapeFromPath({
        path: 'M167 72c19,-38 37,-56 75,-56 42,0 76,33 76,75 0,76 -76,151 -151,227 -76,-76 -151,-151 -151,-227 0,-42 33,-75 75,-75 38,0 57,18 76,56z',
        matrix: [
          0.03333333333333333, 0, 0, 0.03333333333333333, -5.566666666666666, -5.533333333333333
        ]
      })

      const defaults = {
        scalar: 2,
        spread: 180,
        particleCount: 30,
        origin: { y: -0.1 },
        startVelocity: -35
      }

      showConfettiOverThree({
        ...defaults,
        shapes: [pumpkin],
        colors: ['#ff9a00', '#ff7400', '#ff4d00']
      })

      showConfettiOverThree({
        ...defaults,
        shapes: [tree],
        colors: ['#8d960f', '#be0f10', '#445404']
      })

      showConfettiOverThree({
        ...defaults,
        shapes: [heart],
        colors: ['#f93963', '#a10864', '#ee0b93']
      })

      setTimeout(() => {
        if (canvas.parentElement) {
          canvas.parentElement.removeChild(canvas)
        }
      }, 3000)
    }
  }
}
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  width: 75%;
  height: auto;
  min-height: auto;
  max-height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
}
.modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 2rem;
  background: #b40007;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.modal-body {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 80%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 1.5rem;
  background: #ffffff;
}
.gradient-effect {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  background: linear-gradient(180deg, #defff9 0%, rgba(255, 255, 255, 0) 100%) !important;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #ffffff;
}
.card-content-rating-mitra {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
}
.avatar-circle-users {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-circle-users > img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.button-comments-mitra {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 35px;
  max-height: 100%;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(1em - 1px);
  padding-right: calc(1em - 1px);
  padding-top: calc(0.5em - 1px);
  border-radius: 100px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  gap: 0.5rem;
  border: 1px solid #e1e1e1;
  background: #ffffff;
}
.button-comments-mitra.isSelect {
  background-color: #fce5e6 !important;
  border: 1px solid #b40007 !important;
  color: #000000;
}
.input-comments-mitra {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 10px;
  border-radius: 10px;
  text-align: left;
  white-space: nowrap;
  gap: 0.5rem;
  border: 1px solid #e1e1e1;
  background: #ffffff;
}
@media (max-width: 1600px) {
  .modal-content {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
  .modal-body {
    overflow: auto !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
}
</style>
