<template>
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="33" height="33" rx="16.5" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5661 11.1017C22.5661 14.4671 19.8678 17.1655 16.5001 17.1655C13.1335 17.1655 10.4341 14.4671 10.4341 11.1017C10.4341 7.73638 13.1335 5.03906 16.5001 5.03906C19.8678 5.03906 22.5661 7.73638 22.5661 11.1017ZM16.4999 27.9534C11.5297 27.9534 7.33325 27.1456 7.33325 24.0289C7.33325 20.911 11.5561 20.1318 16.4999 20.1318C21.4713 20.1318 25.6666 20.9397 25.6666 24.0564C25.6666 27.1742 21.4438 27.9534 16.4999 27.9534Z"
      fill="#252525"
    />
  </svg>
</template>

<script>
export default {
  name: 'EmptyAvatarIcon',
  props: ['color']
}
</script>
