/* eslint-disable camelcase */
import axios from 'axios'

const state = () => ({
  adminTools: {
    can_extend: false,
    can_reschedule: false
  },
  dataTimelineProject: null,
  refreshData: null, // BS or other
  specific_search: {
    keyword: '',
    client: null,
    area: null,
    unit: null,
    building: null,
    start_date: null,
    end_date: null,
    status: null
  },
  list_status_specific: []
})

const getters = {
  getAdminTools: (state, getters) => {
    return state.adminTools
  },
  getDataTimelineProject: (state, getters) => {
    return state.dataTimelineProject
  },
  getRefreshData: (state, getters) => {
    return state.refreshData
  },
  getSpecificSearch: (state, getters) => {
    return state.specific_search
  },
  getStatusSpecific: (state, getters) => {
    return state.list_status_specific
  }
}

const actions = {
  getTabsProject ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${payload.url}${process.env.VUE_APP_TOTAL_DATA_TABS_V2}${payload.filter ? '/filter' : ''}${payload.vendor ? `?vendor=${payload.vendor}` : ''}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getListProject ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    if (payload.status) {
      concatUrl = `${concatUrl}&status=${payload.status}`
    }

    if (payload.substatus) {
      concatUrl = `${concatUrl}&substatus=${payload.substatus}`
    }

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}?type=${
            payload.type
          }&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${
            payload.limit
          }`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getSpecificListProject ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/specific?survey_id=${payload.surveyId}`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  searchListGlobal ({ commit, state }, payload) {
    // pagination not ready

    let concatUrl = ''
    const keyword = encodeURIComponent(
      payload.keyword.replace(/#inv|#id/g, match => match.toUpperCase())
    )

    if (payload.keyword) {
      concatUrl += `search=${keyword}&`
    }
    if (payload.vendor && payload.vendor !== 'all') {
      concatUrl += `vendor=${payload.vendor}&`
    }
    if (payload.sort) {
      concatUrl += `sort=${payload.sort}&`
    }
    if (payload.area_id) {
      concatUrl += `area_id=${payload.area_id}&`
    }
    if (payload.branch_id) {
      concatUrl += `branch_id=${payload.branch_id}&`
    }
    if (payload.outlet_id) {
      concatUrl += `outlet_id=${payload.outlet_id}&`
    }
    if (payload.start_date) {
      concatUrl += `start_date=${payload.start_date}&`
    }
    if (payload.end_date) {
      concatUrl += `end_date=${payload.end_date}&`
    }
    if (payload.type) {
      concatUrl += `type=${payload.type}&`
    }
    if (payload.status) {
      concatUrl += `status=${payload.status}&`
    }

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/search${concatUrl ? `?${concatUrl}` : ''}`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // upload step 2 upload photo
  uploadPhotoStep2 ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    const newObj = {}
    for (const key in payload) {
      if (payload[key] !== null) {
        newObj[key] = payload[key]
      }
    }

    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const formData = new FormData()
    formData.append('survey_id', newObj.survey_id || dataFromBS.surveyId)
    formData.append('key_photo', newObj.key_photo)
    formData.append('image', newObj.image)

    const path = dataFromBS.isUpdate
      ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject/photo`
      : '/v2/step/photo'

    return new Promise((resolve, reject) => {
      axios
        .post(`${payload.url}${path}`, formData, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // delete photo non-bulk step-2
  deletePhotoStep2 ({ commit, state, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS
      const path = dataFromBS.isUpdate
        ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject/photo`
        : 'v2/step/photo'

      axios
        .delete(`${payload.url}/${path}/${payload.id}/delete`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getDetailProject ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      let concatUrl = ''

      // Detail Proyek, Riwayat Survey, Penawaran Rejected, Komplain, Catatan Proyek
      if (payload.category) {
        concatUrl = `?category=${payload.category}`

        if (payload.subcategory) {
          concatUrl += `&subcategory=${payload.subcategory}`
        }
      }

      // Laporan Harian & Rekap Hasil Proyek
      if (payload.path_name) {
        concatUrl = `/${payload.path_name}?` // dialy-attendance & recap-attendance using '/'

        if (payload.selected_date) {
          concatUrl += `date=${payload.selected_date}&`
        }
      }

      axios
        .get(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/${payload.proyek_id}/body${concatUrl}`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getDetailTimeline ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const pathUrl = `timeline?item_component_id=${payload.item_component_id}&date=${payload.date}&skill_id=${payload.skill_id}`

      axios
        .get(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/${payload.proyek_id}/${pathUrl}`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getProjectHeader ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/${payload.proyek_id}/header`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // Assign Tukang & Ganti Tukang
  getListWorkers ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      let concatUrl = ''

      if (payload.keyword) {
        concatUrl += `&search=${payload.keyword}`
      }

      if (payload.page) {
        concatUrl += `&page=${payload.page}`
      }

      if (payload.limit) {
        concatUrl += `&limit=${payload.limit}`
      }

      axios
        .get(
          `${payload.url}${process.env.VUE_APP_DATA_WORKERS_V2}?skill_id=${payload.skill_id}${concatUrl}`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getListWorkersCertified ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      let concatUrl = ''

      if (payload.keyword) {
        concatUrl += `&search=${payload.keyword}`
      }

      if (payload.worker_id) {
        concatUrl += `&worker_id=${payload.worker_id}`
      }

      if (payload.page) {
        concatUrl += `&page=${payload.page}`
      }

      if (payload.limit) {
        concatUrl += `&limit=${payload.limit}`
      }

      axios.get(`${payload.url}${process.env.VUE_APP_DATA_WORKERS_V2}/certificates?skill_id=${payload.skill_id}${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postAssignChangeWorker ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const body = {
        worker_id: payload.worker_id,
        job_id: payload.job_id
      }

      axios
        .post(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/action/assign`,
          body
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // Add Skill & Delete Slot
  addSkill ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/action/skill`,
          payload.data
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteSlot ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/action/${payload.job_id}/delete`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // Worker attendance API
  workerAttandance ({ commit }, payload) {
    const newObj = {}
    for (const key in payload) {
      if (payload[key] !== null) {
        newObj[key] = payload[key]
      }
    }

    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const formData = new FormData()
    formData.append('is_present', newObj.is_present)

    if (newObj.attendance_time) {
      formData.append('attendance_time', newObj.attendance_time)
    }

    for (let i = 0; i < payload.image_befores.length; i++) {
      formData.append('image_befores', payload.image_befores[i].picture)
    }

    for (let i = 0; i < payload.image_afters.length; i++) {
      formData.append('image_afters', payload.image_afters[i].picture)
    }

    if (payload.image_afters.length > 0) {
      const filledPhotoAfter = payload.image_afters
        .map((item, index) => (item.picture ? index + 1 : -1))
        .filter(index => index !== -1)

      for (let i = 0; i < filledPhotoAfter.length; i++) {
        formData.append('image_after_linked_idxs[]', filledPhotoAfter[i])
      }
    }

    // formData.append('image_befores', newObj.image_befores)
    // formData.append('image_afters', newObj.image_afters)

    return new Promise((resolve, reject) => {
      axios
        .put(
          `${newObj.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/action/worker-attendance/${newObj.job_id}`,
          formData,
          config
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getDataPilihTukang ({ commit, state, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

      axios
        .get(
          `${payload.url}${
            process.env.VUE_APP_STEP_V2
          }/skill?survey_id=${payload.survey_id || dataFromBS.surveyId}`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  postDataPilihTukang ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    const req = {
      survey_id: payload.survey_id || dataFromBS.surveyId,
      duration: payload.duration,
      area_components: payload.area_components
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${payload.url}${process.env.VUE_APP_STEP_V2}/skill`, req)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getDataJadwalPekerjaan ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${payload.url}${
            process.env.VUE_APP_STEP_V2
          }/schedule?survey_id=${payload.survey_id || dataFromBS.surveyId}`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  postDataJadwalPekerjaan ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios
        .post(`${payload.url}${process.env.VUE_APP_STEP_V2}/schedule`, {
          survey_id: payload.survey_id || dataFromBS.surveyId,
          area_components: payload.area_components
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getDataLengkapiMaterial ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${payload.url}${
            process.env.VUE_APP_STEP_V2
          }/material?survey_id=${payload.survey_id || dataFromBS.surveyId}`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  postDataLengkapiMaterial ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios
        .post(`${payload.url}${process.env.VUE_APP_STEP_V2}/material`, {
          survey_id: payload.survey_id || dataFromBS.surveyId,
          area_components: payload.area_components
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getDataPreview ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${payload.url}${
            process.env.VUE_APP_STEP_V2
          }/overview?survey_id=${payload.survey_id || dataFromBS.surveyId}`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createPenawaran ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios
        .post(`${payload.url}${process.env.VUE_APP_STEP_V2}/overview/submit`, {
          survey_id: payload.survey_id || dataFromBS.surveyId
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  cancelOffering ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${payload.url}${
            process.env.VUE_APP_STEP_V2
          }/overview/cancel?survey_id=${payload.survey_id ||
            dataFromBS.surveyId}`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getPreviewReject ({ commit, state, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${payload.url}${process.env.VUE_APP_DATA_PENAWARAN_V2}/unit-reject/${payload.offering_id}/preview`
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateRejectPenawaran ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${payload.url}${process.env.VUE_APP_DATA_PENAWARAN_V2}/unit-reject/submit`,
          {
            offering_id: payload.offering_id
          }
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  extendProject ({ commit, state }, payload) {
    // eslint-disable-next-line camelcase
    const { project_id, duration } = payload
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/extend`,
          {
            project_id,
            duration
          }
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  rescheduleProject ({ commit, state }, payload) {
    // eslint-disable-next-line camelcase
    const { project_id, start_date, end_date } = payload
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/reschedule`,
          {
            project_id,
            start_date,
            end_date
          }
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // Add Skill, Delete Slot & Get Consultant
  getListConsultant ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/consultant/list`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postAssignConsultant ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/consultant/assign`, {
        survey_id: payload.payload.survey_id,
        homecare_project_id: payload.payload.homecare_project_id,
        worker_id: payload.payload.worker_id
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  postDelectConsultant ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/consultant/remove`,
          {
            survey_id: payload.payload.survey_id,
            homecare_project_id: payload.payload.homecare_project_id,
            worker_id: payload.payload.worker_id
          }
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  postEditPhotos ({ commit }, { url, payload }) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      axios.put(`${url}${process.env.VUE_APP_STEP_V2}/photo`, payload, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  }
}

const mutations = {
  setAdminTools (state, val) {
    if (!val) {
      state.adminTools = null
    } else {
      state.adminTools = val
    }
  },
  setDataTimelineProject (state, val) {
    state.dataTimelineProject = val
  },
  setRefreshData (state, val) {
    if (!val) {
      state.refreshData = null
    } else {
      state.refreshData = val
    }
  },
  setSpecificSearch (state, payload) {
    state.specific_search = payload
  },
  setStatusSpecific (state, payload) {
    state.list_status_specific = payload
  },
  resetSpecificSearch (state) {
    state.specific_search = {
      keyword: '',
      client: null,
      area: null,
      unit: null,
      building: null,
      start_date: null,
      end_date: null,
      status: null
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
