<template>
  <div class="is-flex is-justify-content-space-between mb-5">
    <div class="is-flex is-align-items-center is-clickable" @click="goBack()">
      <ArrowLeftIcon class="mr-3" />
      <p class="averta-bold is-size-5">Kembali</p>
    </div>
    <div>
      <ButtonApp :isDisabled="disabled" @click="handleSubmit">
        <div class="flex-center pr-1 py-2">
          <p class="averta-bold mr-3">Simpan</p>
          <ArrowRightIconLong color="#fff" />
        </div>
      </ButtonApp>
    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage, toastMessage, removeComma } from '@/helpers'
import { ArrowRightIconLong, ArrowLeftIcon, SpinnerIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'
import confirmOfferingImage from '@/assets/img/delete-area-component.png'

export default {
  name: 'NavbarUnitPrice',
  components: {
    SpinnerIcon,
    ArrowLeftIcon,
    ArrowRightIconLong,
    ButtonApp
  },
  data () {
    return {
      isLoading: false,
      disabled: true
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      getUnitPrice: 'unitPrice/getUnitPrice'
    })
  },
  methods: {
    goBack () {
      this.backToListBranch()
    },
    backToListBranch () {
      this.$router.push(`/am/vendors/${this.$route.params.vendorId}/branch?type=unit-price&unitPrice=true`)
    },
    handleSubmit () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm',
        width: '545px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22" style="margin-top: -15px; margin-bottom: -15px;">Simpan Perubahan Informasi</p>',
        html: '<p class="averta-regular">Pastikan seluruh informasi telah sesuai</p>',
        showCancelButton: true,
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        },
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true

          const detail = this.getUnitPrice.detail
          const materials = detail.materials.map(item => ({
            ...item,
            price: removeComma(item.price)
          }))
          const workTools = detail.work_tools.map(item => ({
            ...item,
            price: removeComma(item.price)
          }))
          const auxiliaryTools = detail.auxiliary_tools.map(item => ({
            ...item,
            price: removeComma(item.price)
          }))
          const workerFees = detail.worker_fees.map(item => ({
            ...item,
            price: removeComma(item.price)
          }))

          const newDetail = {
            ...detail,
            materials,
            work_tools: workTools,
            auxiliary_tools: auxiliaryTools,
            worker_fees: workerFees
          }

          const payload = {
            ...this.getUnitPrice,
            detail: newDetail,
            url: this.url,
            vendor_id: this.$route.params.vendorId
          }

          if (this.$route.query.noteId) {
            payload.note_id = this.$route.query.noteId
          }

          this.$store
            .dispatch('unitPrice/addEditUnitPrice', payload)
            .then(() => {
              this.isLoading = false
              toastMessage('Harga Satuan Item Pekerjaan berhasil disimpan', 'is-dark')
              this.$store.commit('unitPrice/setResetUnitPrice')
              this.backToListBranch()
            })
            .catch((error) => {
              this.isLoading = false
              alertErrorMessage(error)
            })
        }
      })
    }
  },
  watch: {
    getUnitPrice: {
      handler (val) {
        this.disabled = !val.note_item_working || !val.unit_price || !(val.unit && val.unit.id)
      },
      deep: true
    }
  }
}
</script>
