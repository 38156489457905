<template>
  <main>
    <div v-if="!isLoading && isSM" class="c-w-100">
      <!-- Back Navigation -->
      <div
        class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 p-5"
      >
        <div
          @click="goBackNavigation"
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-50 cursor-pointer"
          style="gap: 0.5rem"
        >
          <ArrowLeftIcon />
          <p class="averta-bold label-18">Kembali</p>
        </div>
        <div
          class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center c-w-50"
        >
          <b-field class="c-w-50">
            <b-datepicker
              placeholder="Click to select..."
              v-model="dates"
              range
              trap-focus
              icon-right-clickable
              icon="calendar-today"
              is-large
              append-to-body
              open-on-focus
              :loading="false"
              :icon-right="dates ? 'close-circle' : ''"
              @icon-right-click="clearDate"
            >
            </b-datepicker>
          </b-field>
        </div>
      </div>
      <!-- Back Navigation -->

      <!-- Infomation Rating Banner -->
      <div
        class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 p-5"
      >
        <div
          class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
        >
          <div
            style="gap: 1rem"
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-50"
          >
            <RatingShineIcon />
            <div
              class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
            >
              <p class="averta-bold label-24 m-0">Rating Tukang</p>
              <p class="averta-regular label-14 m-0">
                Berikan rating kamu sesuai dengan kinerja tukang
              </p>
            </div>
          </div>
          <div
            style="gap: 1rem"
            class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center c-w-50"
          >
            <div class="card-rating-count">
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
              >
                <p class="averta-regular label-14 color-white">Proyek Belum Di-rating</p>
                <p class="averta-bold label-28 color-white">
                  {{ getDataSummary.has_no_rate === null ? 0 : getDataSummary.has_no_rate }} Proyek
                </p>
              </div>
              <div
                class="divider-vertical"
                style="min-height: 70px; background-color: #ffffff"
              ></div>
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
              >
                <p class="averta-regular label-14 color-white">Proyek Sudah Di-rating</p>
                <p class="averta-bold label-28 color-white">
                  {{ getDataSummary.has_been_rated === null ? 0 : getDataSummary.has_been_rated }}
                  Proyek
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Infomation Rating Banner -->

      <!-- Badge Information Commentar -->
      <!-- <div
        class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 p-5"
      >
        <div
          style="background-color: #fce5e6; border-radius: 20px"
          class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 p-4"
        >
          <div
            style="gap: 0.5rem"
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-50"
          >
            <CommentIcon />
            <p class="averta-bold label-16">Ada komentar perlu kamu respon</p>
          </div>
          <div
            style="gap: 0.5rem"
            class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center c-w-50"
          >
            <p class="averta-bold label-14 color-red">Lihat Komentar</p>
            <ArrowRightIcon color="#E10009" size="14" />
          </div>
        </div>
      </div> -->
      <!-- Badge Information Commentar -->

      <!-- Tab Active Rating -->
      <div
        class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100 p-5"
      >
        <div
          style="gap: 1.5rem"
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100"
        >
          <div
            class="btn-tabs-2"
            @click="handleClickTab('not-rating')"
            :class="{ 'is-active': tabActive === 'not-rating' }"
          >
            <p>Belum Rating</p>
          </div>
          <div
            class="btn-tabs-2"
            @click="handleClickTab('done-rating')"
            :class="{ 'is-active': tabActive === 'done-rating' }"
          >
            <p>Sudah Rating</p>
          </div>
        </div>
        <div
          class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center c-w-100"
        >
          <b-field class="c-w-50">
            <b-input
              placeholder="Search by Survey ID..."
              type="search"
              icon="magnify"
              icon-clickable
              v-model="searchQuery"
            >
            </b-input>
          </b-field>
        </div>
      </div>
      <!-- Tab Active Rating -->

      <!-- Card Rating Content (not-rating) -->
      <div
        v-if="tabActive === 'not-rating'"
        class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100"
      >
        <div
          v-if="tabActive === 'not-rating' && projectListNotRate.length > 0"
          style="gap: 3rem"
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100 p-5"
        >
          <div
            v-for="(item, index) in projectListNotRate"
            :key="index + '-' + item.id"
            style="
              background-color: #fafafa;
              border-radius: 20px;
              border: 1px solid #e1e1e1;
              position: relative;
            "
            class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 p-5"
          >
            <!-- Badge Done Rating -->
            <code class="badge-done-rating" v-if="item && item.has_no_rate === 0">
              <CheckIcon size="15" />
              <p class="averta-regular label-12 color-white">Sudah Rating</p>
            </code>
            <!-- Badge Done Rating -->

            <!-- Image Content -->
            <div
              class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center image-logo"
            >
              <img :src="item.branch_logo" alt="logo-kanggo" v-if="item.branch_logo" />
              <img src="@/assets/img/no_empty_image.png" alt="logo-kanggo" v-else />
            </div>
            <!-- Image Content -->

            <div
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start"
              style="gap: 6rem"
            >
              <!-- ID Rating Content -->
              <div
                style="gap: 0.25rem; max-width: 150px; min-width: 150px; width: 150px"
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
              >
                <p class="averta-regular label-14">ID & Building</p>
                <p class="averta-bold label-14 color-red">ID {{ item.survey_id }}</p>
                <p class="averta-bold label-14">
                  {{ item.branch_name }} - {{ item.branch_unit_name }}
                </p>
              </div>
              <!-- ID Rating Content -->

              <!-- Address Rating Content -->
              <div
                style="gap: 0.25rem; max-width: 450px; min-width: 450px; width: 450px"
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
              >
                <p class="averta-regular label-14">Alamat</p>
                <p class="averta-bold label-14" style="line-height: 1.65">
                  {{ item.branch_address }}
                </p>
              </div>
              <!-- Address Rating Content -->
            </div>

            <!-- Rating Count By ID Content -->
            <div
              style="
                background-color: #ffffff;
                border: 1px solid #e1e1e1;
                border-radius: 10px;
                gap: 1rem;
              "
              class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center p-4"
            >
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
              >
                <p class="averta-regular label-14">Belum Dirating</p>
                <p class="averta-bold label-22 color-red">
                  {{ item.has_no_rate === null ? 0 : item.has_no_rate }}
                </p>
              </div>
              <div
                class="divider-vertical"
                style="min-height: 70px; background-color: #e1e1e1"
              ></div>
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
              >
                <p class="averta-regular label-14">Sudah Dirating</p>
                <p class="averta-bold label-22 color-green">
                  {{ item.has_been_rated === null ? 0 : item.has_been_rated }}
                </p>
              </div>
            </div>
            <!-- Rating Count By ID Content -->

            <!-- Button Rating Content -->
            <ButtonApp height="40px" @click="getDetailRatingItem(item)">
              <div
                style="gap: 0.5rem"
                class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
              >
                <StarRatingIcon />
                <p class="averta-bold label-14 color-white">Rating Tukang</p>
              </div>
            </ButtonApp>
            <!-- Button Rating Content -->
          </div>
        </div>
        <div class="columns" v-else>
          <div class="column">
            <div class="columns is-centered">
              <div class="column has-text-centered">
                <ProyekEmptyIcon />
                <p class="averta-bold mt-2">Kamu Belum Memiliki Proyek sedang berjalan</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Card Rating Content -->

      <!-- Card Rating Content (done-rating) -->
      <div
        v-if="tabActive === 'done-rating'"
        class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100"
      >
        <div
          v-if="tabActive === 'done-rating' && projectListDoneRate.length > 0"
          style="gap: 3rem"
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center c-w-100 p-5"
        >
          <div
            v-for="(item, index) in projectListDoneRate"
            :key="index + '-' + item.id"
            style="
              background-color: #fafafa;
              border-radius: 20px;
              border: 1px solid #e1e1e1;
              position: relative;
            "
            class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100 p-5"
          >
            <!-- Badge Done Rating -->
            <code class="badge-done-rating" v-if="item && item.has_no_rate === 0">
              <CheckIcon size="15" />
              <p class="averta-regular label-12 color-white">Sudah Rating</p>
            </code>
            <!-- Badge Done Rating -->

            <!-- Image Content -->
            <div
              class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center image-logo"
            >
              <img :src="item.branch_logo" alt="logo-kanggo" v-if="item.branch_logo" />
              <img src="@/assets/img/no_empty_image.png" alt="logo-kanggo" v-else />
            </div>
            <!-- Image Content -->

            <div
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start"
              style="gap: 6rem"
            >
              <!-- ID Rating Content -->
              <div
                style="gap: 0.25rem"
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
              >
                <p class="averta-regular label-14">ID & Building</p>
                <p class="averta-bold label-14 color-red">ID {{ item.survey_id }}</p>
                <p class="averta-bold label-14">
                  {{ item.branch_name }} - {{ item.branch_unit_name }}
                </p>
              </div>
              <!-- ID Rating Content -->

              <!-- Address Rating Content -->
              <div
                style="gap: 0.25rem; max-width: 450px; min-width: 450px; width: 450px"
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
              >
                <p class="averta-regular label-14">Alamat</p>
                <p class="averta-bold label-14" style="line-height: 1.65">
                  {{ item.branch_address }}
                </p>
              </div>
              <!-- Address Rating Content -->
            </div>

            <!-- Rating Count By ID Content -->
            <div
              style="
                background-color: #ffffff;
                border: 1px solid #e1e1e1;
                border-radius: 10px;
                gap: 1rem;
              "
              class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center p-4"
            >
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
              >
                <p class="averta-regular label-14">Belum Dirating</p>
                <p class="averta-bold label-22 color-red">
                  {{ item.has_no_rate === 0 ? 0 : item.has_no_rate }}
                </p>
              </div>
              <div
                class="divider-vertical"
                style="min-height: 70px; background-color: #e1e1e1"
              ></div>
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
              >
                <p class="averta-regular label-14">Sudah Dirating</p>
                <p class="averta-bold label-22 color-green">
                  {{ item.has_been_rated === 0 ? 0 : item.has_been_rated }}
                </p>
              </div>
            </div>
            <!-- Rating Count By ID Content -->

            <!-- Button Rating Content -->
            <ButtonApp height="40px" @click="getDetailRatingItem(item)">
              <div
                style="gap: 0.5rem"
                class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
              >
                <StarRatingIcon />
                <p class="averta-bold label-14 color-white">Rating Tukang</p>
              </div>
            </ButtonApp>
            <!-- Button Rating Content -->
          </div>
        </div>
        <div class="columns" v-else>
          <div class="column">
            <div class="columns is-centered">
              <div class="column has-text-centered">
                <ProyekEmptyIcon />
                <p class="averta-bold mt-2">Kamu Belum Memiliki Proyek sedang berjalan</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Card Rating Content -->
    </div>

    <!-- Loading -->
    <div v-else>
      <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
    <!-- Loading -->
  </main>
</template>
<script>
import {
  ArrowLeftIcon,
  RatingShineIcon,
  StarRatingIcon,
  CheckIcon,
  SpinnerIcon,
  ProyekEmptyIcon
} from '@/components/icons'
import { alertErrorMessage } from '@/helpers'
import ButtonApp from '@/components/button/ButtonApp'
import { mapGetters } from 'vuex'

export default {
  name: 'ViewsRating',
  components: {
    ArrowLeftIcon,
    RatingShineIcon,
    ButtonApp,
    StarRatingIcon,
    CheckIcon,
    SpinnerIcon,
    ProyekEmptyIcon
  },
  data () {
    return {
      dates: [],
      searchQuery: '',
      tabActive: 'not-rating',
      isFullPage: true,
      isLoading: false,
      projectListNotRate: [],
      projectListDoneRate: [],
      getDataSummary: {}
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      dataHeader: 'project/getHeaderProject'
    }),
    isSM () {
      return this.user.roles[0].name === 'Site Manager'
    }
  },
  methods: {
    goBackNavigation () {
      this.$router.go(-1)
    },
    getDetailRatingItem (item) {
      this.$router.push({
        name: 'DetailsViewsRating',
        params: { id: item.project_id }
      })
    },
    handleClickTab (tab) {
      this.tabActive = tab
    },
    getProjectList () {
      if (this.searchQuery) {
        this.isLoading = false
      } else {
        this.isLoading = true
      }

      const startDate = this.dates[0] ? this.formatDate(this.dates[0]) : ''
      const endDate = this.dates[1] ? this.formatDate(this.dates[1]) : ''
      const search = this.searchQuery

      this.$store
        .dispatch('rating/getProjectList', {
          url: this.url,
          start_date: startDate,
          end_date: endDate,
          search
        })
        .then((response) => {
          const allProjects = response.data.data.data || []
          this.getDataSummary = response.data.data.summary || {}

          this.projectListNotRate = allProjects.filter((project) => project.has_no_rate > 0)
          this.projectListDoneRate = allProjects.filter((project) => project.has_no_rate <= 0)
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    formatDate (date) {
      const d = new Date(date)
      const year = d.getFullYear()
      const month = String(d.getMonth() + 1).padStart(2, '0')
      const day = String(d.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    clearDate () {
      this.dates = null || []
    }
  },
  watch: {
    dates: {
      handler () {
        this.getProjectList()
      },
      deep: true
    },
    searchQuery: {
      handler () {
        this.getProjectList()
      },
      immediate: true
    }
  },
  created () {
    this.getProjectList()
  }
}
</script>

<style scoped>
main {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0;
}
.card-rating-count {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: auto;
  background: linear-gradient(90deg, #ed4949 0%, #a40707 100%);
  border-radius: 20px;
  margin: 0;
  padding: 1.5rem;
  gap: 1rem;
}
.divider-vertical {
  width: 1px;
  height: 100%;
  margin: 0 1rem;
}
.btn-tabs,
.btn-tabs-2 {
  border-radius: 0 !important;
  color: #868686;
  font-family: 'Averta';
  border: none;
  border-bottom: 1px solid transparent !important;
}

.btn-tabs-2 {
  padding: 5px 8px;
  color: #868686;
  border: none;
  border-bottom: 1px solid transparent !important;
  border-radius: 100px;
  cursor: pointer;
}

.btn-tabs.is-active,
.btn-tabs-2.is-active {
  background: transparent;
  color: #3185fc;
  border: none !important;
  border-bottom: 2px solid #3185fc !important;
  font-family: 'Averta-Bold';
  font-weight: bold;
}
.badge-done-rating {
  position: absolute;
  top: -12.5px;
  left: 50px;
  display: flex;
  flex-direction: row;
  width: auto;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0 0.5rem;
  gap: 0.25rem;
  color: #ffffff;
  background: #10bd41;
  border-radius: 100px;
}
.image-logo {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}
.image-logo > img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: auto;
}
</style>
