<template>
  <div class="durasi-wrapper">
    <div class="flex-center-vertical">
      <div v-if="isSMRC" class="flex-center-vertical">
        <div class="external-id-wrapper">
          <p>ID</p>
          <p class="averta-bold">ID{{ surveyId }}</p>
        </div>
        <div v-if="externalId" class="external-id-wrapper">
          <p>{{ TITLE_WO_LESS }}</p>
          <p class="averta-bold">{{ externalId || '-' }}</p>
        </div>
        <div>
          <p class="averta-bold">{{ storeName }} {{unitName ? ` - ${unitName}` : ''}}</p>
        </div>
      </div>
      <p v-else class="averta-bold">{{ storeName }} | ID{{ surveyId }}</p>
    </div>
    <p v-if="duration">
      Durasi Kerja: <span class="averta-black color-blue-1 label-22">{{ duration }}</span> Hari
    </p>
  </div>
</template>

<script>
import { TITLE_WO_LESS } from '@/helpers/constants'

export default {
  name: 'CardDurationProject',
  props: {
    storeName: {
      type: String,
      default: ''
    },
    unitName: {
      type: String,
      default: ''
    },
    isSMRC: {
      type: Boolean,
      default: false
    },
    surveyId: {
      type: [String, Number]
    },
    externalId: {
      type: [String, Number]
    },
    duration: {
      type: [String, Number]
    }
  },
  computed: {
    TITLE_WO_LESS () {
      return TITLE_WO_LESS
    }
  }
}
</script>

<style scoped>
.durasi-wrapper {
  border: 2px solid #3185fc;
  border-radius: 12px;
  background: rgba(49, 133, 252, 0.1);
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.external-id-wrapper {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #000;
}
</style>
