<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-modal v-model="isHandleOpenModalOffering" :can-cancel="false">
    <div class="modalLihatPenawaran">
      <div class="header-modal">
        <h5>Lihat Penawaran</h5>
        <div class="clickable" @click="closeModal()">
          <XMarkIcon />
        </div>
      </div>
      <div class="body-modal">
        <p class="mb-5">Pilih salah satu format penawaran untuk ditampilkan!</p>
        <div
          @click="tipeOffering('price-actual')"
          class="notification clickable is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-light border-radius-20 overflow-hidden border-2 c-w-100"
        >
          <div
            class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
          >
            <div
              style="gap: 1rem"
              class="is-flex is-flex-direction-row is-justify-content-flex-start c-w-85"
            >
              <img src="@/assets/img/ActualPriceIcon.svg" alt="ActualPriceIcon" />
              <div class="is-flex is-flex-direction-column is-justify-content-center">
                <h5 class="averta-bold">Harga Dasar</h5>
                <p class="averta-regular">
                  Tampilan ini adalah detail penawaran yang disajikan oleh Kanggo untuk Client
                </p>
              </div>
            </div>
            <div class="is-flex is-flex-direction-row is-justify-content-flex-end clickable">
              <ArrowRightIcon />
            </div>
          </div>
        </div>

        <div
          @click="tipeOffering('price-tenant')"
          class="notification clickable is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-light border-radius-20 overflow-hidden border-2 c-w-100"
        >
          <div
            class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
          >
            <div
              style="gap: 1rem"
              class="is-flex is-flex-direction-row is-justify-content-flex-start c-w-85"
            >
              <img src="@/assets/img/TetantPriceIcon.svg" alt="ActualPriceIcon" />
              <div class="is-flex is-flex-direction-column is-justify-content-center">
                <h5 class="averta-bold">Harga Tenant</h5>
                <p class="averta-regular">
                  Tampilan ini adalah detail penawaran yang disajikan Client Kanggo untuk
                  Customer/Tenant
                </p>
              </div>
            </div>
            <div class="is-flex is-flex-direction-row is-justify-content-flex-end clickable">
              <ArrowRightIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { XMarkIcon, ArrowRightIcon } from '@/components/icons'
import { isSMRC } from '@/helpers'

export default {
  name: 'ModalPreviewOffering',
  props: {
    isHandleOpenModalOffering: {
      type: Boolean,
      default: false
    },
    dataPreview: {
      type: Object,
      default: null
    }
  },
  components: {
    XMarkIcon,
    ArrowRightIcon
  },
  computed: {
    isSMRC () {
      return isSMRC(this.dataPreview.vendor_code || '')
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    tipeOffering (value) {
      let path
      let query

      if (value === 'price-actual') {
        path = `/am/offering-project/${this.dataPreview.offering_id}`
        query = { preview: true }
        if (this.isSMRC) query.actual = true
      } else if (value === 'price-tenant') {
        path = `/am/offering-tenant/${this.dataPreview.offering_id}`
        query = { preview: true }
      }

      const routeData = this.$router.resolve({ path, query })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.modalLihatPenawaran {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 757px;
  max-width: 757px;
  height: 450px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  border-radius: 20px;
}
.header-modal {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  background-color: #b40007;
}
.header-modal > h5 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
}
.body-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  align-items: flex-start;
  margin: 0 auto;
  padding: 2rem;
  gap: 0rem;
  background-color: #ffffff;
}
.clickable {
  cursor: pointer !important;
}
</style>
