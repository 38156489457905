<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99935 1.6649C5.39685 1.6649 1.66602 5.39573 1.66602 9.99823C1.66531 11.226 1.93593 12.4388 2.45852 13.5499L1.68352 17.3307C1.65592 17.4657 1.66224 17.6055 1.70188 17.7374C1.74153 17.8694 1.81329 17.9894 1.91071 18.0869C2.00814 18.1843 2.12821 18.256 2.26017 18.2957C2.39213 18.3353 2.53186 18.3417 2.66685 18.3141L6.44768 17.5391C7.52602 18.0474 8.73102 18.3307 9.99935 18.3307C14.6019 18.3307 18.3327 14.6007 18.3327 9.9974C18.3327 5.39573 14.6019 1.66406 9.99935 1.66406"
      fill="#E10009"
    />
  </svg>
</template>

<script>
export default {
  name: 'CommentIcon'
}
</script>
