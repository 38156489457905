<template>
  <ModalTemplate
    :showModal="showModal"
    :width="widthModal"
    @closeModal="closeModal"
  >
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <p class="averta-bold label-18 ml-2">Ubah PIC Toko</p>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>
    <template v-slot:bodyModal>
      <div v-if="isLoading" class="body-modal p-5">
        <ModalPICSkeleton />
      </div>
      <div v-else>
        <div class="body-modal">
          <div class="mb-4 address-modal">
            <p class="averta-bold label-18 mb-1">Kantor</p>
            <p>
              Foresta Business Loft 1, Jl. BSD Raya Utama No.32, Pagedangan, Kec. Pagedangan, BSD
              City, Banten 15339
            </p>
            <b-input
              @input="handleSearchPIC"
              type="text"
              class="input-search-2 input-none mt-2 mb-3"
              placeholder="Cari nama atau nomor HP PIC"
            ></b-input>
            <ButtonApp
              class="c-w-100"
              :isSecondary="true"
              @click="handleCreatePIC"
            >
              <p class="averta-bold py-1">+ Tambah PIC Baru</p>
            </ButtonApp>
          </div>
          <div class="list-pic-wrapper">
            <div v-if="listPIC.length > 0">
              <div
                v-for="(item, index) in listPIC"
                :key="index"
                class="list-pic"
                :class="{ active: item.selected }"
                @click="handleClickPIC(item, index)"
              >
                <div class="flex-center-vertical">
                  <PersonIcon />
                  <div class="ml-3">
                    <div class="flex-center-vertical">
                      <p :class="{'averta-black': item.selected, 'averta-bold': !item.selected}">
                        {{ item.name }}
                      </p>
                      <div v-if="item.selected" class="flex-center-vertical ml-1">
                        <CheckIcon color="#10BD41" size="13" />
                        <p class="averta-bold label-11 color-green is-italic">Terpilih</p>
                      </div>
                    </div>
                    <p>{{ item.phone }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="column c-w-100 has-text-centered">
              <ProyekEmptyIcon height="150" />
              <p class="averta-bold mt-1">Data Tidak Ditemukan</p>
            </div>
          </div>
        </div>
        <div class="footer-modal">
          <ButtonApp
            class="c-w-100"
            :isDisabled="!selectedPIC"
            @click="handleUpdatePIC"
          >
            <p class="averta-bold py-2">Proses</p>
          </ButtonApp>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import { CheckIcon, PersonIcon, ProyekEmptyIcon } from '@/components/icons'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalPICSkeleton from '@/components/skeleton/ModalPICSkeleton.vue'

export default {
  name: 'ModalUpdatePIC',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    dataHeader: {
      type: Object
    },
    dataPIC: {
      type: Object
    }
  },
  components: {
    ModalTemplate,
    ButtonApp,
    CheckIcon,
    PersonIcon,
    ProyekEmptyIcon,
    ModalPICSkeleton
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      isLoading: false,
      widthModal: '520px',
      initListPIC: [],
      listPIC: [],
      selectedPIC: null
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    clearData () {
      this.initListPIC = []
      this.listPIC = []
    },
    handleClickPIC (item, index) {
      this.listPIC.forEach((pic) => {
        pic.selected = false
      })

      const data = {
        ...item,
        selected: true
      }
      this.listPIC.splice(index, 1, data) // dont use this.listPIC[index] = data
      this.selectedPIC = data
    },
    handleSearchPIC (value) {
      const data = this.initListPIC.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase())
      })
      this.listPIC = data
    },
    handleUpdatePIC () {
      this.$emit('updatePIC', this.selectedPIC)
    },
    handleCreatePIC () {
      this.$emit('createPIC')
    },
    getDataPIC () {
      this.isLoading = true
      const branchId = this.dataHeader.store.id
      const payload = {
        url: this.url,
        branchId
      }

      this.$store
        .dispatch('project/getListPICComplaint', payload)
        .then(response => {
          const res = response.data.data
          const idPIC = this.dataPIC.id
          res.forEach(item => {
            if (item.id === idPIC) {
              item.selected = true
              this.selectedPIC = item
            }
          })

          this.initListPIC = res
          this.listPIC = res
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    }
  },
  watch: {
    showModal (val) {
      if (val) {
        this.getDataPIC()
      } else {
        this.clearData()
      }
    }
  }
}
</script>

<style scoped>
.body-modal {
  height: 430px;
}

.address-modal {
  padding: 10px 20px 0 20px;
}

.footer-modal {
  padding: 10px 20px 15px 20px;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}

.list-pic-wrapper {
  height: 240px;
  overflow-y: auto;
}

.list-pic {
  padding: 10px 25px;
  border-bottom: 1px solid #E1E1E1;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.list-pic.active {
  background: #10BD411A;
}
</style>
