<template>
  <div class="container-wrapper">
    <div v-if="isLoading" class="loading-wrapper">
      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
    <div v-else>
      <div class="indicator-wrapper" :style="{ top: navbarPosition }">
        <InfoIcon color="blue" width="20" height="20" />
        <p class="color-blue-1 ml-2 averta-bold">
          Format invoice ini sama dengan format invoice yang diterima oleh client
        </p>
      </div>

      <div class="button-wrapper">
        <ButtonApp :isBold="true" :isSecondary="true" @click="downloadPDF" class="mr-4">
          <div class="is-flex is-align-items-center py-1">
            <DownloadIcon2 />
            <p>{{ isLoadingSpinner ? 'Mengunduh...' : 'Unduh Invoice' }}</p>
          </div>
        </ButtonApp>
        <PublishInvoice
          v-if="!dataInvoice.invoice_due_date"
          :refreshData="getDataInvoice"
          :recipient="dataInvoice.recipient"
          :invoice="dataInvoice.invoice"
          :dataInvoice="dataInvoice"
          :emptyRecipientFunction="handleEmptyRecipient"
          :emptyInvoiceFunction="handleEmptyInvoice"
        />
      </div>
      <div
        class="invoice border-1 my-5"
        ref="document"
        v-for="(dataTable, indexTable) in paginatedList"
        :key="`dataTable-${indexTable}`"
      >
        <div :class="`padding-page-pdf invoice-${indexTable + 1}`">
          <div class="height-body-pdf">
            <!-- HEADER -->
            <HeaderSection
              type="invoice"
              :invoice="dataInvoice.invoice"
              :recipient="dataInvoice.recipient"
              :address="dataInvoice.store_address"
              :index="indexTable"
              :createdDate="dataInvoice.invoice_created_date"
              :dueDate="dataInvoice.invoice_due_date"
              :externalId="dataInvoice.client_external_id"
              :surveyId="dataInvoice.survey_id"
              :projectId="dataInvoice.project_id"
              :storeName="dataInvoice.store_name"
              :unitName="dataInvoice.unit_name"
              :refreshData="getDataInvoice"
              :isSMRC="isSMRC"
            />
            <div
              class="card pdf-table-body mb-3"
              v-if="dataTable.table || dataTable.section.totalSection"
            >
              <!-- TABLE INVOICE -->
              <div v-if="dataTable.table">
                <div class="columns mb-0 averta-bold">
                  <div class="column is-5 px-0 is-flex pl-5">
                    <div class="number-pdf">
                      <p>No.</p>
                    </div>
                    <p>Detail Pekerjaan</p>
                  </div>
                  <div class="column is-2 has-text-right">
                    <p class="mr-4">Volume Pekerjaan</p>
                  </div>
                  <div class="column is-1 px-0">
                    <p>Satuan</p>
                  </div>
                  <div class="column is-2 has-text-centered">
                    <p>Harga Satuan</p>
                  </div>
                  <div class="column is-2 has-text-right">
                    <p>Total Harga</p>
                  </div>
                </div>
                <div class="divider-invoice mb-3"></div>
                <div
                  v-for="(itemWorking, indexWorking) in dataTable.table"
                  :key="`itemWorking-${indexWorking}`"
                >
                  <div v-if="!itemWorking.hide && !itemWorking.empty_list" class="columns mb-1">
                    <div class="column is-10">
                      <p class="averta-black">
                        {{ itemWorking.area_name }}
                      </p>
                    </div>
                    <div class="column is-2 is-flex is-justify-content-space-between pl-1">
                      <p class="averta-black">Rp</p>
                      <p class="averta-black">
                        {{ formatThousand(itemWorking.total_price) }}
                      </p>
                    </div>
                  </div>
                  <div
                    v-for="(itemSubArea, indexSubArea) in itemWorking.components"
                    :key="`subarea-${indexSubArea}`"
                  >
                    <p
                      v-if="!itemSubArea.hide && itemSubArea.item_components.length > 0"
                      class="averta-bold ml-3 pb-2"
                    >
                      {{ itemSubArea.component_name }}
                    </p>
                    <div
                      v-for="(itemListSub, indexListSub) in itemSubArea.item_components"
                      :key="`list-sub-${indexListSub}`"
                      class="columns pb-2 m-0"
                    >
                      <div class="column py-0 pl-3 pr-0 is-5 is-flex">
                        <div class="number-pdf">
                          <p>
                            {{ numberList(itemListSub.no) }}
                          </p>
                        </div>
                        <p>
                          {{ itemListSub.note_item_working }}
                        </p>
                      </div>
                      <div class="column py-0 is-2 has-text-right">
                        <p class="mr-4">
                          {{ itemListSub.volume }}
                        </p>
                      </div>
                      <div class="column py-0 is-1">
                        <p>
                          {{ itemListSub.unit_name }}
                        </p>
                      </div>
                      <div class="column py-0 is-2 is-flex is-justify-content-space-between">
                        <p class="mr-1">Rp</p>
                        <p>
                          {{ formatThousand(itemListSub.unit_price) }}
                        </p>
                      </div>
                      <div class="column py-0 pr-0 is-2 is-flex is-justify-content-space-between">
                        <p>Rp</p>
                        <p>
                          {{ formatThousand(itemListSub.total_price) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="dataTable.table.length !== indexWorking + 1 || dataTable.section.totalSection"
                    class="divider-invoice my-4"
                  ></div>
                </div>
              </div>

              <!-- TOTAL SUMMARY SECTION -->
              <div v-if="dataTable.section.totalSection">
                <div class="is-flex c-w-100 is-justify-content-flex-end">
                  <div
                    class="c-w-100 is-flex is-align-items-flex-end is-flex-direction-column averta-bold"
                  >
                    <div class="title-harga-section">
                      <p v-if="dataInvoice.client_type_code === 'company' ||
                        dataInvoice.client_type_code === null ||
                        !dataInvoice.client_type_code" class="mb-2">{{titleDPP}}</p>

                      <p v-if="dataInvoice.client_type_code === 'personal' &&
                        dataInvoice.price.discount" class="mb-2">Total</p>

                      <p v-if="dataInvoice.price.discount" class="mb-2">Discount</p>

                      <p v-if="dataInvoice.client_type_code === 'company' ||
                        dataInvoice.client_type_code === null ||
                        !dataInvoice.client_type_code" class="mb-2">
                        <!-- PPN {{ dataInvoice.price.ppn_percent }}% -->
                        PPN
                      </p>

                      <p v-if="isClientTypeCode && dataInvoice.price.pph !== 0 && dataInvoice.price.pph_percent !== 0"
                      class="mb-2">
                      PPh Final 4 Ayat 2 ({{ dataInvoice.price.pph_percent }}%)
                      </p>

                    </div>
                    <div
                      class="wrapper-grand-total border-radius-0 c-w-100 is-flex is-justify-content-flex-end py-2"
                    >
                      <div class="title-harga-section">
                        <p>Grand Total</p>
                      </div>
                    </div>
                  </div>
                  <div class="averta-bold total-harga-section">
                    <div v-if="dataInvoice.client_type_code === 'company' || dataInvoice.client_type_code === null || !dataInvoice.client_type_code" class="is-flex is-justify-content-space-between mb-2">
                      <p class="mr-3">RP</p>
                      <p>
                        {{ formatThousand(dataInvoice.price.dpp) }}
                      </p>
                    </div>
                    <div v-if="dataInvoice.client_type_code === 'personal' && dataInvoice.price.discount" class="is-flex is-justify-content-space-between mb-2">
                      <p class="mr-3">RP</p>
                      <p>
                        {{ formatThousand(dataInvoice.price.dpp) }}
                      </p>
                    </div>
                    <div
                      v-if="dataInvoice.price.discount"
                      class="is-flex is-justify-content-space-between mb-2"
                    >
                      <p class="mr-3">RP</p>
                      <p>({{ formatThousand(dataInvoice.price.discount) }})</p>
                    </div>
                    <div v-if="dataInvoice.client_type_code === 'company' || dataInvoice.client_type_code === null || !dataInvoice.client_type_code" class="is-flex is-justify-content-space-between mb-2">
                      <p class="mr-3">RP</p>
                      <p>
                        {{ formatThousand(dataInvoice.price.ppn) }}
                      </p>
                    </div>
                    <div
                    v-if="isClientTypeCode && dataInvoice.price.pph !== 0 && dataInvoice.price.pph_percent !== 0"
                    class="is-flex is-justify-content-space-between mb-2">
                      <p class="mr-3">RP</p>
                      <p>({{ formatThousand(dataInvoice.price.pph) }})</p>
                    </div>
                    <div
                      class="is-flex averta-black color-red is-justify-content-space-between bg-wrapper-grand-total pr-2 py-2"
                      style="width: 102%"
                    >
                      <p class="mr-3">RP</p>
                      <p>
                        {{ formatThousand(dataInvoice.price.grand_total) }}
                      </p>
                    </div>
                  </div>
                </div>

                <p class="has-text-right mt-2 label-13">
                  (<span class="averta-bold">Terbilang: </span>
                  <span class="is-italic">{{ spellRupiah(dataInvoice.price.grand_total) }}</span
                  >)
                </p>
              </div>
            </div>

            <!-- INSTRUKSI PEMBAYARAN -->
            <div>
              <div v-if="dataTable.section.instruksi">
                <p class="averta-bold label-16 mb-1">Instruksi Pembayaran</p>
                <ul class="label-14 dot-list ml-5">
                  <li
                    v-for="(item, index) in dataInvoice.instruction_payment"
                    :key="`instruksi-${index}`"
                    class="mb-1"
                  >
                    {{ item.title }}
                    <div v-if="index + 1 === dataInvoice.instruction_payment.length" class="rekening-wrapper mt-2">
                      <p class="averta-bold label-16 mb-1">
                        {{ dataInvoice.company_account.company_name }}
                      </p>
                      <p>{{ dataInvoice.company_account.bank }}</p>
                      <p>
                        No Rekening:
                        <span class="averta-bold">{{
                          dataInvoice.company_account.account_number
                        }}</span>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-if="dataTable.section.ttd" class="pt-6 ml-5">
                <p class="averta fs-14 mb-ttd">Dibuat Oleh,</p>
                <p class="averta fs-14">
                  <u class="averta-bold">{{ dataInvoice.finance_profile.name }}</u>
                  <br />
                  <span>({{ dataInvoice.finance_profile.division }})</span>
                </p>
              </div>
            </div>
          </div>

          <!-- PAGE NUMBER -->
          <FooterSection :pagePosition="`${ indexTable + 1 }/${ paginatedList.length }`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Helpers
import moment from 'moment'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { mapGetters } from 'vuex'
import { alertErrorMessage, formatCurrency, Terbilang, isSMRC } from '@/helpers'
import sunsetImage from '../../../../assets/img/sunset.png'

// Assets
import {
  SpinnerIcon,
  InfoIcon,
  DownloadIcon2
} from '@/components/icons'

// Components
import HeaderSection from './components/HeaderSection.vue'
import PublishInvoice from './components/PublishInvoice.vue'
import FooterSection from './components/FooterSection.vue'

export default {
  name: 'PDFNewDesign',
  components: {
    // Components
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    HeaderSection,
    PublishInvoice,
    FooterSection,

    // Icons
    SpinnerIcon,
    InfoIcon,
    DownloadIcon2
  },
  data () {
    return {
      isLoading: false,
      isLoadingSpinner: false,
      dataInvoice: null,
      dataList: null,
      url: process.env.VUE_APP_SHELL_URL_API,
      navbarPosition: null,
      tempPages: []
    }
  },
  computed: {
    ...mapGetters({
      heightNavbar: 'dashboard/getNavbarHeight'
    }),
    paginatedList () {
      // 640 didapat ketika height recipient 103, tools yang digunakan Extension Chrome "Page Ruler"
      const INIT_RECIPIENT = 103
      const INIT_START = 630
      const INIT_MIDLE = 775
      const WIDTH_RECIPIENT = 736
      const WIDTH_TEXT_RECIPIENT = 232
      const WIDTH_TEXT_ADDRESS = 281
      const WIDTH_AREA = 611
      const WIDTH_SUB = 698
      const WIDTH_LIST = 249
      const HEIGHT_TOTAL_PRICE = 151
      const HEIGHT_TOTAL_PRICE_DISCOUNT = 153
      const HEIGHT_INSTRUCTION = 194
      const HEIGHT_TTD = 221

      const pages = []
      let currentPage = []
      let currentHeight = 0
      let isReset = false

      let tempHeightArea = 0
      let tempHeightSub = 0

      // RECIPIENT SECTION
      const divRecipient = document.createElement('div')
      const pRecipient = document.createElement('p')
      const pAddress = document.createElement('p')
      pRecipient.textContent = this.dataInvoice.recipient
      pRecipient.classList = 'averta-bold mt-1 white-space-pre-line'
      pRecipient.style.width = `${WIDTH_TEXT_RECIPIENT}px`
      pAddress.classList = 'averta-regular'
      pAddress.style.width = `${WIDTH_TEXT_ADDRESS}px`
      pAddress.textContent = this.dataInvoice.store_address
      divRecipient.className = 'p-4 is-flex is-justify-content-space-between border-radius-20 overflow-hidden'
      divRecipient.style.width = `${WIDTH_RECIPIENT}px`
      divRecipient.appendChild(pRecipient)
      divRecipient.appendChild(pAddress)
      document.body.appendChild(divRecipient)
      const heightRecipient = divRecipient.clientHeight + 25 + 16
      document.body.removeChild(divRecipient)

      // PAGE BODY HEIGHT
      let start = INIT_START
      const midle = INIT_MIDLE

      if (heightRecipient < INIT_RECIPIENT) {
        start += (INIT_RECIPIENT - heightRecipient)
      } else {
        start -= (heightRecipient - INIT_RECIPIENT)
      }

      let countNumber = 0
      if (this.dataList && this.dataList.length > 0) {
        this.dataList.forEach((itemArea) => {
          currentPage.push({
            ...itemArea,
            components: []
          })

          const widthArea = `${WIDTH_AREA}px`
          const widthSub = `${WIDTH_SUB}px`
          const widthList = `${WIDTH_LIST}px`

          // CLONE AREA
          const divAreaColumns = document.createElement('div')
          const divArea = document.createElement('div')
          const pArea = document.createElement('p')

          pArea.className = 'averta-black'
          pArea.textContent = itemArea.area_name

          divAreaColumns.className = 'columns mb-1'
          divArea.className = 'column'
          divArea.appendChild(pArea)
          divAreaColumns.style.width = widthArea
          divAreaColumns.appendChild(divArea)

          document.body.appendChild(divAreaColumns)
          currentHeight += divAreaColumns.clientHeight + 4 + 16 // 4 = margin | 16 divider-invoice
          tempHeightArea = divAreaColumns.clientHeight
          document.body.removeChild(divAreaColumns)

          itemArea.components.forEach((itemSub, indexSub) => {
            const idxArea = currentPage.findIndex((item) => item.area_id === itemArea.area_id)

            currentPage[idxArea].components.push({
              ...itemSub,
              item_components: []
            })

            const divSub = document.createElement('div')
            const pSub = document.createElement('p')

            // CLONE SUB AREA
            pSub.className = 'averta-bold ml-4 pb-2'
            pSub.textContent = itemSub.component_name
            divSub.className = 'ml-5'
            divSub.style.width = widthSub
            divSub.appendChild(pSub)

            document.body.appendChild(divSub)
            currentHeight += divSub.clientHeight
            tempHeightSub = divSub.clientHeight
            document.body.removeChild(divSub)

            itemSub.item_components.forEach((itemList, indexList) => {
              const limitPageHeight = pages.length === 0 ? start : midle

              countNumber++
              itemList.no = countNumber

              const idxArea = currentPage.findIndex((item) => item.area_id === itemArea.area_id)
              const idxSub = currentPage[idxArea].components.findIndex(
                (item) => item.component_id === itemSub.component_id
              )

              // CLONE LIST ITEMS
              const divList = document.createElement('div')
              const pList = document.createElement('p')

              divList.className = 'columns pb-2 m-0'
              divList.style.width = widthList
              pList.className = 'averta-regular'
              pList.textContent = itemList.note_item_working
              divList.appendChild(pList)
              document.body.appendChild(divList)
              if (currentHeight + divList.clientHeight > limitPageHeight) {
                // Remove unused area, if area exceed the limit, dont push into currentPage / remove it from currentPage
                const areaComponents = currentPage[idxArea].components
                if (areaComponents.length === 1 && areaComponents[idxSub].item_components.length === 0) {
                  currentPage = currentPage.filter(item => item.area_id !== currentPage[idxArea].area_id)
                }

                pages.push({
                  table: currentPage,
                  section: {
                    totalSection: false,
                    instruksi: false,
                    ttd: false
                  }
                })

                // currentHeight seharusnya tidak 0, tergantung tinggi area/sub/list yang melebihi limit page
                // Karna jika area/sub/list melampaui page, maka initial currentHeight harusnya tergantung pada element terakhir dari area/sub/list
                if (indexList > 0) {
                  currentHeight = 0
                } else if (indexSub > 0 && indexList === 0) {
                  currentHeight = tempHeightSub
                } else {
                  currentHeight = tempHeightArea + tempHeightSub
                }

                isReset = true
              }

              if (isReset) {
                let hideArea = false
                let hideSub = false

                if (indexList > 0) {
                  hideArea = true
                  hideSub = true
                } else if (indexSub > 0 && indexList === 0) {
                  hideArea = true
                }

                currentPage = [
                  {
                    hide: hideArea,
                    ...itemArea,
                    components: [
                      {
                        hide: hideSub,
                        ...itemSub,
                        item_components: [itemList]
                      }
                    ]
                  }
                ]
                isReset = false
              } else {
                currentPage[idxArea].components[idxSub].item_components.push(itemList)
              }

              currentHeight += divList.clientHeight
              document.body.removeChild(divList)
            })
          })
        })

        // LAST DATA PAGE
        if (currentPage.length) {
          pages.push({
            table: currentPage,
            section: {
              totalSection: false,
              instruksi: false,
              ttd: false
            }
          })
        }

        let totalHeight = 0
        let isBreak = false

        // HEIGHT
        const totalSection = this.dataInvoice.price.discount ? HEIGHT_TOTAL_PRICE_DISCOUNT : HEIGHT_TOTAL_PRICE
        const instruksi = HEIGHT_INSTRUCTION + 24 + 16
        const ttd = HEIGHT_TTD
        const limitBottomHeight = pages.length === 1 ? start : midle

        // IS EXCEED TOTAL PRICE
        const countTotal = currentHeight + totalSection
        if (countTotal < limitBottomHeight) {
          totalHeight = countTotal
          pages[pages.length - 1].section.totalSection = true
        } else {
          isBreak = true
          pages.push({
            section: {
              totalSection: true,
              instruksi: true,
              ttd: true
            }
          })
        }
        // IS EXCEED INSTRUKSI
        const countInstruksi = totalHeight + instruksi
        if (!isBreak && countInstruksi < limitBottomHeight) {
          totalHeight = countInstruksi

          pages[pages.length - 1].section.instruksi = true
        } else if (!isBreak) {
          isBreak = true
          pages.push({
            section: {
              totalSection: false,
              instruksi: true,
              ttd: true
            }
          })
        }

        // IS EXCEED TTD
        const countTTD = totalHeight + ttd
        if (!isBreak && countTTD < limitBottomHeight) {
          totalHeight = countTTD

          pages[pages.length - 1].section.ttd = true
        } else if (!isBreak) {
          isBreak = true
          pages.push({
            section: {
              totalSection: false,
              instruksi: false,
              ttd: true
            }
          })
        }
        return pages
      }

      return []
    },
    isClientTypeCode () {
      return this.dataInvoice.client_type_code === 'company' ||
             this.dataInvoice.client_type_code === null ||
             !this.dataInvoice.client_type_code
    },
    isSMRC () {
      return isSMRC(this.dataInvoice.vendor_code || null)
    },
    titleDPP () {
      if (this.dataInvoice.invoice_created_date) {
        const createdDate = moment(this.dataInvoice.invoice_created_date)
        const thresholdDate = moment('2025-01-01')

        if (createdDate.isSameOrAfter(thresholdDate)) {
          return 'Harga Jual'
        } else {
          return 'DPP'
        }
      } else {
        return 'DPP'
      }
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x, true)
    },
    spellRupiah (value) {
      return Terbilang(value)
    },
    handleEmptyRecipient () {
      this.$swal({
        width: '450px',
        padding: '10px 10px 30px 10px',
        html: `
          <img src="${sunsetImage}" class="mb-4" />
          <p class="averta-bold mb-4 label-22">“Kepada Yth:” Belum Ditentukan</p>
          <p class="averta-regular">Tentukan pihak client yang dirujuk untuk dapat melanjutkan proses</p>
        `,
        confirmButtonText: 'Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component'
        }
      })
    },
    handleEmptyInvoice () {
      this.$swal({
        width: '450px',
        padding: '10px 10px 30px 10px',
        html: `
          <img src="${sunsetImage}" class="mb-4" />
          <p class="averta-bold mb-4 label-22">No. Invoice Belum Ditentukan</p>
          <p class="averta-regular">Atur nomor invoice untuk dapat melanjutkan proses</p>
        `,
        confirmButtonText: 'Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component'
        }
      })
    },
    async downloadPDF () {
      if (!this.dataInvoice.recipient) return this.handleEmptyRecipient()
      if (!this.dataInvoice.invoice) return this.handleEmptyInvoice()
      if (this.isLoadingSpinner) return

      // HIDE WHILE DOWNLOAD
      const hideElements = document.querySelectorAll('.hide-while-download')
      hideElements.forEach(element => {
        element.classList.add('is-hidden')
      })

      this.isLoadingSpinner = true
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', 'a4')

      for (let index = 0; index < this.paginatedList.length; index++) {
        if (index > 0) {
          pdf.addPage()
        }
        const selector = `.invoice-${index + 1}`
        await this.captureContent(selector, pdf)
      }

      const filename = `${
        this.dataInvoice.invoice || 'invoice_' + moment().format('DD-MM-YYYY')
      }.pdf`
      pdf.save(filename)
      this.isLoadingSpinner = false

      // SHOW AFTER DOWNLOAD FINISH
      hideElements.forEach(element => {
        element.classList.remove('is-hidden')
      })
    },
    async captureContent (selector, pdf) {
      const element = document.querySelector(selector)
      return html2canvas(element, { scale: 2 })
        .then((canvas) => {
          const imgWidth = 208
          const imgHeight = (canvas.height * imgWidth) / canvas.width

          const imageData = canvas.toDataURL('image/jpeg')
          pdf.addImage(imageData, 'JPEG', 1, 0, imgWidth, imgHeight, '', 'FAST')
        })
        .catch((error) => console.error('Error capturing content:', error))
    },
    adjustStickyPosition () {
      setTimeout(() => {
        if (this.heightNavbar) {
          this.navbarPosition = this.heightNavbar + 'px'
        }
      }, 600)
    },
    numberList (value) {
      return formatCurrency(value, true) + '.'
    },
    getDataInvoice (props = {}) {
      const { hideLoading } = props

      if (!hideLoading) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        project_id: this.$route.params.projectId
      }

      this.$store
        .dispatch('project/getDataInvoice', payload)
        .then((response) => {
          const res = response.data.data

          this.dataList = res.item_components
          this.dataInvoice = res
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    }
  },
  mounted () {
    this.adjustStickyPosition()
  },
  created () {
    this.getDataInvoice()
  },
  watch: {
    heightNavbar (val) {
      if (val) {
        this.adjustStickyPosition()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.container-wrapper {
  margin-top: 5vh;
  width: 100%;
}

.button-wrapper {
  width: 210mm;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: auto;
  margin-top: 2em;
}

.invoice {
  position: relative;
  width: 210mm;
  height: 297mm;
  padding: 0;
  margin: 0 auto;
  display: block;
}

.divider-invoice {
  border-bottom: 1px solid $color-grey-14;
}

.wrapper-grand-total {
  background: linear-gradient(90deg, #fffff7 0%, #fff5ea 100%);
  border-radius: 8px;
  font-size: 17px;
}

.bg-wrapper-grand-total {
  background: #fff5ea;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.watermarkBelumBayar {
  background: url('../../../../assets/img/belum-bayar.png') center center no-repeat;
  top: 0rem;
  opacity: 0.35;
  position: absolute;
  width: 100%;
  height: 100%;
}

.watermarkLunas {
  background: url('../../../../assets/img/lunas.png') center center no-repeat;
  top: 0rem;
  opacity: 0.35;
  position: absolute;
  width: 100%;
  height: 100%;
}

.dot-list {
  list-style-type: disc;
}

.indicator-wrapper {
  background: #f4f9ff;
  width: 100%;
  position: fixed;
  top: 12vh;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 10px 30px;
}

.mb-ttd {
  margin-bottom: 110px;
}

.title-harga-section {
  width: 200px;
}

.total-harga-section {
  min-width: 145px;
}

</style>
