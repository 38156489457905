<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-modal v-model="isHandleOpenModalsPreviewRatingClient" :can-cancel="false">
    <div class="modal-content">
      <div class="modal-header">
        <div
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
        >
          <p class="averta-bold label-20 color-white">Detail Penilaian</p>
        </div>
        <div
          @click="closeModals()"
          class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center is-clickable"
        >
          <XMarkIcon />
        </div>
      </div>
      <div class="modal-body">
        <div
          class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center z-index-9999 gap-6 c-w-100"
        >
          <div
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 gap-2"
          >
            <ClockFillIcon />
            <p>
              Dirating oleh Admin:
              {{
                selectedWorker &&
                selectedWorker.ratingFromClient &&
                selectedWorker.ratingFromClient.created_by_name ||
                selectedWorker &&
                selectedWorker.rating_from_client &&
                selectedWorker.rating_from_client.created_by_name
              }}
              -
              {{
                moment(
                  selectedWorker &&
                    selectedWorker.ratingFromClient &&
                    selectedWorker.ratingFromClient.created_at
                ).format('DD/MM/YYYY (HH:mm)') ||
                moment(
                  selectedWorker &&
                    selectedWorker.rating_from_client &&
                    selectedWorker.rating_from_client.created_at
                ).format('DD/MM/YYYY (HH:mm)')
              }}
            </p>
          </div>

          <div class="card-content-rating-mitra gap-4">
            <div
              class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100"
            >
              <div
                class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-2"
              >
                <div class="avatar-circle-users">
                  <img
                    :src="
                      selectedWorker && selectedWorker.image
                        ? selectedWorker.image
                        : selectedWorker && selectedWorker.worker_image
                        ? selectedWorker.worker_image
                        : ''
                    "
                    alt=""
                  />
                </div>
                <div class="is-flex is-flex-direction-column gap-1">
                  <p class="averta-bold label-16">
                    {{
                      selectedWorker && selectedWorker.name
                        ? selectedWorker.name
                        : selectedWorker && selectedWorker.worker_name
                        ? selectedWorker.worker_name
                        : ''
                    }}
                  </p>
                  <p class="averta-reguler label-14">
                    {{
                      selectedWorker && selectedWorker.skillName
                        ? selectedWorker.skillName
                        : selectedWorker && selectedWorker.skill_name
                        ? selectedWorker.skill_name
                        : ''
                    }}
                  </p>
                </div>
              </div>
              <div
                class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center"
              >
                <p class="averta-reguler label-14">
                  {{
                    selectedWorker && selectedWorker.workItemId
                      ? selectedWorker.workItemId
                      : selectedWorker && selectedWorker.worker_id
                      ? selectedWorker.worker_id
                      : ''
                  }}
                </p>
              </div>
            </div>
            <div class="divider-line-1"></div>
            <div
              style="background: #f0f6ff"
              class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 px-5 py-4 border-radius-6"
            >
              <div class="is-flex is-flex-direction-column gap-1">
                <p class="averta-reguler label-14">Item Pekerjaan</p>
                <p class="averta-bold label-16">
                  {{ (selectedWorker && selectedWorker.workItemName) || selectedWorker.itemWorking || '' }}
                </p>
              </div>
            </div>
            <div
              class="c-w-100 is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
            >
              <b-rate
                :value="
                  selectedWorker &&
                  selectedWorker.ratingFromClient &&
                  selectedWorker.ratingFromClient.rating ||
                  selectedWorker &&
                  selectedWorker.rating_from_client &&
                  selectedWorker.rating_from_client.rating
                "
                :disabled="true"
                size="is-medium"
                :spaced="true"
              ></b-rate>
            </div>
          </div>

          <div
            class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100 gap-3 is-flex-wrap-wrap"
          >
            <button
              v-for="option in selectedWorker &&
              selectedWorker.ratingFromClient &&
              selectedWorker.ratingFromClient.rating_tag_names ||
              selectedWorker &&
              selectedWorker.rating_from_client &&
              selectedWorker.rating_from_client.rating_tag_names"
              :key="option"
              class="button-comments-mitra"
            >
              <p class="averta-reguler is-font-14">
                {{ option || null }}
              </p>
            </button>
          </div>

          <div
            class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center c-w-100"
          >
            <textarea
              disabled
              class="input-comments-mitra"
              type="text"
              placeholder="Tulis komentar kamu disini"
              :value="
                selectedWorker &&
                selectedWorker.rating_from_client &&
                selectedWorker.rating_from_client.description ?
                selectedWorker.rating_from_client.description :
                selectedWorker &&
                selectedWorker.ratingFromClient &&
                selectedWorker.ratingFromClient.description
                  ? selectedWorker.ratingFromClient.description
                  : '-'
              "
            />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { XMarkIcon, ClockFillIcon } from '@/components/icons'
import moment from 'moment'

export default {
  name: 'modalsPreviewDetailRatingClient',
  components: {
    XMarkIcon,
    ClockFillIcon
  },
  props: {
    isHandleOpenModalsPreviewRatingClient: {
      type: Boolean,
      default: false
    },
    selectedWorker: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedOptions: [],
      comment: '',
      moment: moment
    }
  },
  computed: {},
  watch: {},
  methods: {
    closeModals () {
      this.$emit('closeModalsPreviewRating')
    }
  }
}
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: auto;
  min-height: auto;
  max-height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
}
.modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 2rem;
  background: #b40007;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.modal-body {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  background: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.card-content-rating-mitra {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
}
.avatar-circle-users {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-circle-users > img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.button-comments-mitra {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 35px;
  max-height: 100%;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(1em - 1px);
  padding-right: calc(1em - 1px);
  padding-top: calc(0.5em - 1px);
  border-radius: 100px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  gap: 0.5rem;
  border: 1px solid #e1e1e1;
  background: #ffffff;
}
.input-comments-mitra {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  margin: 0 auto;
  padding: 10px;
  border-radius: 10px;
  text-align: left;
  white-space: nowrap;
  gap: 0.5rem;
  border: 1px solid #e1e1e1;
  background: #f5f5f5;
}
</style>
