<template>
  <svg width="80" height="auto" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.3065 0.272901C19.7995 0.546417 19.4835 1.06651 19.4835 1.63219L19.4835 10.4407L2.09097 10.4407C1.21276 10.4407 0.500002 11.1369 0.500002 11.9947C0.500002 12.8526 1.21276 13.5488 2.09097 13.5488L19.4835 13.5488L19.4835 22.3573C19.4835 22.925 19.7995 23.4451 20.3065 23.7166C20.8135 23.9922 21.4329 23.9735 21.923 23.6731L38.7575 13.3105C39.22 13.0246 39.5 12.5293 39.5 11.9947C39.5 11.4601 39.22 10.9649 38.7575 10.679L21.923 0.316415C21.6642 0.158936 21.3693 0.0781242 21.0744 0.0781242C20.8114 0.0781242 20.5462 0.144431 20.3065 0.272901"
      fill="#E10009"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightChangeImage'
}
</script>
